import React, { useState, useEffect } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [selectedHealthVariables, setSelectedHealthVariables] = useState([]);

  const healthVariables = [
    "I don't track any health variables",
    "Heart Rate",
    "Sleep Duration and Quality",
    "Steps Count",
    "Calorie Intake",
    "Calorie Burn",
    "Blood Pressure",
    "Blood Oxygen Level",
    "Stress Level",
    "Menstrual Cycle Tracking",
    "Body Temperature",
    "Respiratory Rate",
    "Distance Traveled (Running, Walking, Cycling)",
    "Elevation Climbed",
    "Active Minutes",
    "Weight",
    "Body Fat Percentage",
    "Hydration Level",
    "None of the above.",
  ];

  useEffect(() => {
    onUpdate(selectedHealthVariables.join(", "));
  }, [selectedHealthVariables, onUpdate]);

  const handleHealthVariableSelection = (variable) => {
    setSelectedHealthVariables((prevVariables) => {
      if (variable === "None of the above.") {
        return prevVariables.includes(variable) ? [] : ["None of the above."];
      } else {
        if (prevVariables.includes("None of the above.")) {
          return [variable];
        }
        return prevVariables.includes(variable)
          ? prevVariables.filter((item) => item !== variable)
          : [...prevVariables, variable];
      }
    });
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        What, if any, health variables do you measure with devices such as
        Fitbits, Apple Watches, Oura Rings, etc.?
      </p>
      <div className="flex flex-col w-full">
        {healthVariables.map((variable) => (
          <button
            key={variable}
            onClick={() => handleHealthVariableSelection(variable)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              selectedHealthVariables.includes(variable)
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: selectedHealthVariables.includes(variable)
                ? "0 0 2px #FF8C22"
                : "",
            }}
          >
            {variable}
          </button>
        ))}
      </div>
    </div>
  );
}
