import React, { useState } from "react";
import axios from "axios";
import ProtocolOutline from "./ProtocolOutline"; // Import the ProtocolOutline component
import { CoachContext } from "../../../CoachContext";

const AddProtocol = ({ onProtocolAdded }) => {
  const { coachId } = React.useContext(CoachContext);
  const [showForm, setShowForm] = useState(false);
  const [newProtocolData, setNewProtocolData] = useState({
    title: "",
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setNewProtocolData({ ...newProtocolData, [name]: value });
  };

  const handleSubmit = () => {
    newProtocolData.coachId = coachId;
    axios
      .post("/protocols", newProtocolData)
      .then((response) => {
        onProtocolAdded(response.data.protocol);
        setShowForm(false); // Hide the form after submission
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <button
        onClick={() => setShowForm(!showForm)}
        className="bg-slate-50 border-2 hover:bg-slate-200 text-slate-500 hover:text-slate-800 mt-5 font-medium py-2 px-4 rounded "
      >
        + Add New Protocol
      </button>

      {showForm && (
        <div className="mt-4 rounded shadow-lg">
          <h3 className="text-lg font-medium bg-slate-50 p-6 mb-0 text-slate-700">
            Add new protocol
          </h3>
          <form
            onSubmit={(e) => {
              e.preventDefault();
            }}
            className="p-6 bg-white"
          >
            <div className="">
              <label className="block text-md font-medium text-gray-700 mb-2">
                Title
              </label>
              <input
                type="text"
                name="title"
                value={newProtocolData?.title}
                onChange={handleInputChange}
                placeholder="Enter title..."
                className="w-full p-2 border rounded"
              />
            </div>
          </form>
          <div className="bg-slate-50 p-6 rounded-b">
            <button
              type="submit"
              className="bg-slate-200 hover:bg-blue-100 text-slate-600 hover:text-blue-500 font-medium py-2 px-4 rounded"
              onClick={handleSubmit}
            >
              Add protocol
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default AddProtocol;
