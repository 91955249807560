import { motion } from "framer-motion";
import { useNavigate } from "react-router-dom";
import WaitlistSignup from "../ui/WaitlistSignup";
import Mixpanel from "mixpanel-browser";
import React, { useEffect } from "react";

const BetaLanding = ({ userId = null }) => {
  const navigate = useNavigate();
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: {
      opacity: 1,
      transition: { delay: 0.3, duration: 0.6 },
    },
  };

  const cardVariants = {
    hidden: { y: 20, opacity: 0 },
    visible: {
      y: 0,
      opacity: 1,
      transition: { duration: 0.5 },
    },
  };

  useEffect(() => {
    // Initialize Mixpanel with your project token
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", { track_pageview: true });
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "beta_landing" });
  }, []);

  return (
    <motion.div
      className="min-h-screen flex flex-col items-center justify-center bg-black w-full"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="max-w-6xl p-8 md:p-20">
        <div className="flex w-full  flex-col lg:flex-row justify-center lg:justify-between">
          <div className="flex items-center justify-center lg:items-start flex-col">
            <img
              src="/images/logo-white.png"
              className="h-10 mt-10 lg:mt-0 mb-12 lg:mb-0"
            />
            <motion.h1
              className="text-6xl mb-6 lg:mt-8 lg:text-7xl lg:text-left lg:mb-0 font-bold text-center text-white"
              variants={cardVariants}
            >
              Welcome,{" "}
              <motion.span
                className="text-transparent mb-8 lg:block lg:mt-3 bg-clip-text bg-gradient-to-r from-orange-400 to-darkBlue"
                variants={cardVariants}
              >
                Beta Tester!
              </motion.span>
            </motion.h1>

            <motion.div>
              <div className="mt-4 mb-8 lg:my-0 justify-center">
                <button
                  className="relative font-medium text-lg md:text-lg bg-white text-black py-2 hover:py-1.5 px-6 rounded-full cursor-pointer flex items-center justify-center shadow-md transition duration-300 ease-in-out"
                  onClick={() => navigate("/coach")}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.border = "2px solid #FFF";
                    e.currentTarget.style.background = "black";
                    e.currentTarget.firstChild.style.filter = "brightness(100)";
                    e.currentTarget.lastChild.style.color = "white";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.border = "none";
                    e.currentTarget.style.background = "white";
                    e.currentTarget.firstChild.style.filter = "brightness(0)";
                    e.currentTarget.lastChild.style.color = "black";
                  }}
                >
                  <img
                    className="brightness-0 mr-2"
                    src="/images/skip.png"
                    alt="Skip"
                    style={{ height: "20px" }}
                  />
                  <div
                    className="absolute"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%) scale(1.1)",
                      zIndex: "-1",
                    }}
                  >
                    Go to the coach
                  </div>
                  <div>Go to the coach</div>
                </button>
              </div>
            </motion.div>
          </div>
          <div>
            <WaitlistSignup
              text1="Do you want the full 1440 experience?"
              text3="1440's alpha is free as a beta tester"
            />
          </div>
        </div>

        <div className="py-8" style={{ position: "relative", width: "100%" }}>
          <div style={{ padding: "56.25% 0 0 0", position: "relative" }}>
            <iframe
              src="https://player.vimeo.com/video/895330870?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479"
              frameBorder="0"
              allow="autoplay; fullscreen; picture-in-picture"
              style={{
                position: "absolute",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
              }}
              title="1440_V17_5"
            ></iframe>
          </div>
          <script src="https://player.vimeo.com/api/player.js"></script>
        </div>
        <section className="mb-12">
          <div className="flex gap-10 flex-col lg:flex-row">
            <motion.div
              className="bg-gradient-to-bl from-accent/5 to-black p-6 rounded-lg shadow-lg text-white border border-gray-900"
              variants={cardVariants}
            >
              <div className="flex justify-center items-center rounded-2xl bg-gradient-to-r from-gray-600/50 to-gray-700/50 h-16 w-16 mb-6">
                <img src="/images/graduation-hat.png" className="h-10 w-10" />
              </div>
              <h3 className="text-xl opacity-90 font-bold mb-4">
                Unmatched Coaching and Mentoring
              </h3>
              <p className="opacity-80 mb-0">
                Our approach isn't just innovative; it's proven. In a recent
                study, our AI coach outperformed a human coach on every
                parameter, offering insights and support that truly make a
                difference. We're not just a tool; we're a mentor, a guide, a
                companion on your journey to greatness.
              </p>
            </motion.div>
            <motion.div
              className="bg-gradient-to-b from-accent/5 to-black p-6 rounded-lg shadow-lg text-white border border-gray-900"
              variants={cardVariants}
            >
              <div className="flex justify-center items-center rounded-2xl bg-gradient-to-r from-gray-600/50 to-gray-700/50 h-16 w-16 mb-6">
                <img src="/images/understanding.png" className="h-9 w-9" />
              </div>
              <h3 className="text-xl opacity-90 font-bold mb-4">
                Deep Understanding and Proactive Support
              </h3>
              <p className="opacity-80 mb-0">
                At 1440, we get you. Really, we do. Our system is designed to
                understand your needs, your mood, and your goals. We're always
                there, especially when you need us most, reaching out with
                relevant support and insights, ensuring you're never alone in
                your quest for a fulfilling life.
              </p>
            </motion.div>
            <motion.div
              className="bg-gradient-to-br from-accent/5 to-black p-6 rounded-lg shadow-lg text-white border border-gray-900"
              variants={cardVariants}
            >
              <div className="flex justify-center items-center rounded-2xl bg-gradient-to-r from-gray-600/50 to-gray-700/50 h-16 w-16 mb-6">
                <img src="/images/connected.png" className="h-10 w-10" />
              </div>
              <h3 className="text-xl opacity-90 font-bold mb-4">
                Seamless Integration for a Connected Life
              </h3>
              <p className="opacity-80 mb-0">
                Imagine an assistant that's in sync with every aspect of your
                life. 1440 integrates with your calendar and health data,
                providing contextual and connected insights that are tailored to
                your daily rhythm and physical wellbeing. It's about creating a
                harmonious balance that empowers every facet of your life.
              </p>
            </motion.div>
          </div>
          <hr className="border-t border-gray-900 my-12" />
        </section>
        <section className="mb-20">
          <div className="flex flex-col items-center">
            <h2 className="text-4xl font-bold text-white mb-6">
              We'll give you...
            </h2>
            <motion.div
              className="bg-gradient-to-b from-accent/5 to-black p-6 rounded-lg shadow-lg text-white border border-gray-900 flex flex-col md:flex-row gap-8"
              variants={cardVariants}
            >
              <div className="flex items-start space-x-4">
                <img src="/images/padlock.png" className="h-10 w-10" />
                <div>
                  <h3 className="text-xl mb-1 opacity-90 font-bold">
                    Exclusive Access
                  </h3>
                  <p className="opacity-80 mb-0">
                    Get early access to new features and updates, and be the
                    first to experience innovation.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <img src="/images/user.png" className="h-10 w-10" />
                <div>
                  <h3 className="text-xl mb-1 opacity-90 font-bold">
                    Personalized Support
                  </h3>
                  <p className="opacity-80 mb-0">
                    Enjoy direct support from our dedicated team, tailored
                    specifically to your individual needs.
                  </p>
                </div>
              </div>
              <div className="flex items-start space-x-4">
                <img src="/images/development.png" className="h-10 w-10" />
                <div>
                  <h3 className="text-xl mb-1 opacity-90 font-bold">
                    Community Influence
                  </h3>
                  <p className="opacity-80 mb-0">
                    Your feedback is invaluable. Contribute to the evolution of
                    our AI Coach and make your mark.
                  </p>
                </div>
              </div>
            </motion.div>
          </div>
        </section>
        <section className="mb-12 text-center">
          <h2 className="text-4xl font-bold text-white mb-8">
            You'll help us...
          </h2>
          <div className="flex flex-col md:flex-row items-center justify-center md:justify-start md:space-x-3">
            <motion.div
              className="bg-gradient-to-b from-accent/5 to-black p-8 rounded-lg shadow-lg text-white border border-gray-900 flex flex-col items-center mb-4 md:mb-0"
              variants={cardVariants}
            >
              <h3 className="text-xl font-bold mb-2">Engage</h3>
              <p className="opacity-80 mb-0">
                Interact with the coach to explore the full potential of the AI.
              </p>
            </motion.div>
            <div className="w-full md:w-auto flex justify-center my-4 md:my-0">
              <img
                src="/images/submit.png"
                className="h-8 w-8 md:w-auto rotate-90 opacity-50 md:rotate-0"
              />
            </div>
            <motion.div
              className="bg-gradient-to-b from-accent/5 to-black p-8 rounded-lg shadow-lg text-white border border-gray-900 flex flex-col items-center mb-4 md:mb-0"
              variants={cardVariants}
            >
              <h3 className="text-xl font-bold mb-2">Reflect</h3>
              <p className="opacity-80 mb-0">
                Monitor your interactions and identify areas for enhancement.
              </p>
            </motion.div>
            <div className="md:w-auto flex justify-center my-4 md:my-0">
              <img
                src="/images/submit.png"
                className="h-8 w-8 md:w-auto rotate-90 opacity-50 md:rotate-0"
              />
            </div>
            <motion.div
              className="bg-gradient-to-b from-accent/5 to-black p-8 rounded-lg shadow-lg text-white border border-gray-900 flex flex-col items-center"
              variants={cardVariants}
            >
              <h3 className="text-xl font-bold mb-2">Feedback</h3>
              <p className="opacity-80 mb-0">
                Share your thoughts through our feedback form after your
                journey.
              </p>
            </motion.div>
          </div>
          <hr className="border-t border-gray-900 my-8" />
        </section>
        <div className="flex justify-center flex-col">
          <WaitlistSignup
            text1="Do you want the full 1440 experience?"
            text3="1440's alpha is free as a beta tester"
          />
          <div className="flex lg:w-full justify-center my-8">
            <motion.div className="lg:w-full">
              <div className="mt-4 mb-8 lg:my-0 lg:w-full justify-center">
                <button
                  className="relative font-medium lg:w-full text-lg md:text-lg bg-white text-black py-2 hover:py-1.5 px-6 rounded-full cursor-pointer flex items-center justify-center shadow-md transition duration-300 ease-in-out"
                  onClick={() => navigate("/coach")}
                  onMouseEnter={(e) => {
                    e.currentTarget.style.border = "2px solid #FFF";
                    e.currentTarget.style.background = "black";
                    e.currentTarget.firstChild.style.filter = "brightness(100)";
                    e.currentTarget.lastChild.style.color = "white";
                  }}
                  onMouseLeave={(e) => {
                    e.currentTarget.style.border = "none";
                    e.currentTarget.style.background = "white";
                    e.currentTarget.firstChild.style.filter = "brightness(0)";
                    e.currentTarget.lastChild.style.color = "black";
                  }}
                >
                  <img
                    className="brightness-0 mr-2"
                    src="/images/skip.png"
                    alt="Skip"
                    style={{ height: "20px" }}
                  />
                  <div
                    className="absolute"
                    style={{
                      top: "50%",
                      left: "50%",
                      transform: "translate(-50%, -50%) scale(1.1)",
                      zIndex: "-1",
                    }}
                  >
                    Go to the coach
                  </div>
                  <div>Go to the coach</div>
                </button>
              </div>
            </motion.div>
          </div>
        </div>
      </div>
    </motion.div>
  );
};

export default BetaLanding;
