import React, { useState, useEffect } from "react";
import ChatBox from "../chat/ChatBox";
import TypingIndicator from "../chat/TypingIndicator";
import Footer from "../footer/Footer";
import Navbar from "../header/Navbar";

function ConversationalLanding() {
  const [conversation, setConversation] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [darkTheme, setDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("darkTheme");
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });

  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
  }, [darkTheme]);
  function getUserId() {
    let userId = localStorage.getItem("userId");
    if (!userId) {
      userId = generateUserId();
      localStorage.setItem("userId", userId);
    }
    return userId;
  }
  function generateUserId() {
    return (
      Math.random().toString(36).substring(2) +
      "." +
      Math.random().toString(36).substring(2)
    );
  }

  const loadPreviousMessages = async () => {
    try {
      const userId = getUserId();
      const response = await fetch(
        "https://api.1440.ai/get_previous_session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid: userId }),
          mode: "cors",
        }
      );
      const data = await response.json();
      if (data.status !== "false") {
        setConversation(data);
      }
    } catch (error) {
      // Handle error
    }
  };

  const sendMessage = async (message) => {
    // Add user message
    setConversation((prevConversation) => [
      ...prevConversation,
      { role: "user", content: message },
    ]);

    showTypingIndicator();

    let userId = getUserId();
    try {
      const response = await fetch("https://api.1440.ai/personalai_route", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ body: message, uid: userId }),
        mode: "cors",
      });

      const data = await response.json();

      // Add assistant message
      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: data.answer },
      ]);
    } catch (error) {
      // Handle error
      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: "An error occurred. Please try again." },
      ]);
    } finally {
      hideTypingIndicator();
    }
  };

  const showTypingIndicator = () => {
    setIsTyping(true);
  };

  const hideTypingIndicator = () => {
    setIsTyping(false);
  };

  const updateTheme = (theme) => {
    setDarkTheme(theme);
  };

  useEffect(() => {
    loadPreviousMessages();
  }, []);

  return (
    <div
      className={`App${
        darkTheme ? " dark" : ""
      } flex flex-col h-screen bg-white dark:bg-black text-black dark:text-white`}
    >
      <header className="px-6 sm:px-10 md:px-12 pt-9 bg-white dark:bg-black">
        <Navbar coachName="Coach Nia" coachTitle="Connected Coach" />
      </header>
      <main className="flex-1 overflow-y-auto px-6 sm:px-10 md:px-12 bg-white dark:bg-black text-black dark:text-white">
        <ChatBox
          conversation={conversation}
          sendMessage={sendMessage}
          isTyping={isTyping}
        />
      </main>
      <footer className="px-6 sm:px-10 md:px-12 pb-6 bg-white dark:bg-black text-black dark:text-white">
        <Footer darkTheme={darkTheme} updateTheme={updateTheme} />
      </footer>
    </div>
  );
}

export default ConversationalLanding;
