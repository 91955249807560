import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ScorecardList({ onSelectScorecard }) {
  const [scorecards, setScorecards] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortOrder, setSortOrder] = useState("desc"); // "asc" or "desc"
  const [displayLimit, setDisplayLimit] = useState(10);

  useEffect(() => {
    const fetchScorecards = async () => {
      try {
        const apiKey = localStorage.getItem("adminApiKey");
        const response = await axios.get("/api/admin/scorecards", {
          headers: { "Api-Key": apiKey },
        });
        setScorecards(response.data);
      } catch (error) {
        console.error("Error fetching scorecards:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchScorecards();
  }, []);

  const getAverageRating = (scorecard) => {
    const ratings = Object.values(scorecard.results).map((r) => r.rating);
    return ratings.reduce((a, b) => a + b, 0) / ratings.length;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    return `${month} ${date.getDate()}`;
  };

  const filteredAndSortedScorecards = scorecards
    .filter((scorecard) =>
      scorecard.user_id.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      const ratingA = getAverageRating(a);
      const ratingB = getAverageRating(b);
      return sortOrder === "desc" ? ratingB - ratingA : ratingA - ratingB;
    })
    .slice(0, displayLimit);

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="bg-slate-800 rounded-lg p-4">
      <div className="mb-4 flex items-center gap-4">
        <input
          type="text"
          placeholder="Search by user ID..."
          className="bg-slate-700 text-white px-4 py-2 rounded-lg flex-grow"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className="bg-slate-700 text-white px-4 py-2 rounded-lg"
          onClick={() => setSortOrder(sortOrder === "desc" ? "asc" : "desc")}
        >
          Rating {sortOrder === "desc" ? "↓" : "↑"}
        </button>
      </div>
      <div className="divide-y divide-gray-700">
        {filteredAndSortedScorecards.map((scorecard) => (
          <div
            key={scorecard._id}
            className="p-4 hover:bg-slate-700 cursor-pointer flex items-center justify-between"
            onClick={() => onSelectScorecard(scorecard)} // Changed to pass entire scorecard
          >
            <div className="text-white font-medium">{scorecard.user_id}</div>
            <div className="flex items-center gap-4">
              <div className="text-gray-400 text-sm">
                {formatDate(scorecard.timestamp)}
              </div>
              <div className="text-white font-bold">
                {Math.round(getAverageRating(scorecard))}%
              </div>
            </div>
          </div>
        ))}
      </div>
      {scorecards.length > displayLimit && (
        <button
          className="mt-4 bg-slate-700 text-white px-4 py-2 rounded-lg w-full"
          onClick={() => setDisplayLimit((prev) => prev + 10)}
        >
          Load More
        </button>
      )}
    </div>
  );
}
