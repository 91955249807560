import React, { useState } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [ageGroup, setAgeGroup] = useState("");

  const ageGroups = [
    "Under 18",
    "18-24",
    "25-34",
    "35-44",
    "45-54",
    "55-64",
    "65 and above",
  ];

  const handleAgeGroupSelection = (age) => {
    setAgeGroup(age);
    onUpdate(age);
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        What is your age group?
      </p>
      <div className="flex flex-col w-full">
        {ageGroups.map((age) => (
          <button
            key={age}
            onClick={() => handleAgeGroupSelection(age)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              ageGroup === age
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: ageGroup === age ? "0 0 2px #FF8C22" : "",
            }}
          >
            {age}
          </button>
        ))}
      </div>
    </div>
  );
}
