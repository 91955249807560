import React, { useState, useEffect } from "react";
import posthog from "posthog-js";
import { motion, AnimatePresence } from "framer-motion";
import "./theme/theme.css"; // Import the theme CSS variables

// Import all screen components
import {
  IntroScreen,
  SelectionScreen,
  MultiSelectScreen,
  SectionTransition,
  InfoScreen,
  TestimonialScreen,
  AnimationScreen,
  TimeAssessmentScreen,
  ValuePropositionScreen,
  PersonalizedInsightScreen,
  PlanPreviewScreen,
  SubscriptionScreen,
} from "./screens";
import HeaderBar from "./screens/HeaderBar";
import GridBackground from "./screens/GridBackground";
import ContinueButton from "./components/ContinueButton";
import { createOnboardingScreens } from "./data/onboardingScreens";

const OnboardingFlow = () => {
  // State
  const [currentStep, setCurrentStep] = useState(0);
  const [results, setResults] = useState({});
  const [showSectionTitle, setShowSectionTitle] = useState(false);
  const [startTime] = useState(Date.now()); // Track when the user started onboarding

  // Create screens with the setShowSectionTitle function
  const [screens] = useState(createOnboardingScreens(setShowSectionTitle));

  // Track onboarding start when component mounts
  useEffect(() => {
    posthog.capture("onboarding_started", {
      total_steps: screens.length,
    });
  }, [screens.length]);

  // Define the sections for the progress bar
  const sections = [
    "Purpose & Priorities",
    "Your Current Lifestyle",
    "Alignment Assessment",
    "Your Plan",
    "Getting Started",
  ];

  // Helper function to get section name
  const getSectionName = (sectionIndex) => {
    if (sectionIndex < 0) return "Welcome";
    if (sectionIndex >= sections.length) return "Unknown";
    return sections[sectionIndex];
  };

  // Helper function to get screen context data
  const getScreenContext = (stepIndex) => {
    const screen = screens[stepIndex];
    const section = screen.section;

    return {
      step_index: stepIndex,
      step_type: screen.type,
      section_index: section,
      section_name: getSectionName(section),
      step_title: screen.content?.title || screen.content?.question || "",
    };
  };

  // Handler for going back one step when logo is clicked
  const handleGoBack = () => {
    if (currentStep > 0) {
      const fromContext = getScreenContext(currentStep);
      const toContext = getScreenContext(currentStep - 1);

      // Track the back navigation event
      posthog.capture("onboarding_step_back", {
        from_step_index: fromContext.step_index,
        from_step_type: fromContext.step_type,
        from_section_index: fromContext.section_index,
        from_section_name: fromContext.section_name,
        from_step_title: fromContext.step_title,
        to_step_index: toContext.step_index,
        to_step_type: toContext.step_type,
        to_section_index: toContext.section_index,
        to_section_name: toContext.section_name,
        to_step_title: toContext.step_title,
      });

      // Go to previous step
      setCurrentStep(currentStep - 1);
    }
  };

  const handleUpdate = (data) => {
    // Only track when we have actual user-provided data
    if (data && Object.keys(data).length > 0) {
      const context = getScreenContext(currentStep);

      // Track the user response - this is the main event capture per step
      posthog.capture("onboarding_step_completed", {
        step_index: context.step_index,
        step_type: context.step_type,
        section_index: context.section_index,
        section_name: context.section_name,
        step_title: context.step_title,
        response: data,
        time_spent: Date.now() - startTime,
      });
    }

    setResults((prevResults) => ({
      ...prevResults,
      [`screen${currentStep + 1}`]: data,
    }));

    // For intro screen, manually trigger the next step
    if (currentStep === 0) {
      handleContinue();
      return;
    }

    // For TimeAssessmentScreen, handle the completion properly
    if (currentStep === 13 && data.continueClicked) {
      const updatedScreens = [...screens];
      updatedScreens[13].hideDefaultButton = true;
      updatedScreens[13].autoAdvance = true;
      // Advance to the next screen directly when continueClicked is true
      handleContinue();
      return;
    }

    // For AnimationScreen, advance immediately when completed
    const currentScreen = screens[currentStep];
    if (currentScreen.type === "animation" && data.completed) {
      console.log("Animation completed, advancing immediately");
      handleContinue();
      return;
    }

    // Auto advance if specified by the screen type
    if (currentScreen.autoAdvance) {
      setTimeout(() => {
        handleContinue();
      }, currentScreen.delay || 500); // Default delay or custom delay
    }
  };

  const handleContinue = () => {
    if (!isLastStep) {
      // Trigger any onAppear function if present
      const nextScreen = screens[currentStep + 1];
      if (nextScreen.onAppear) {
        nextScreen.onAppear();
      }
      setCurrentStep(currentStep + 1);
    } else {
      // Handle final submission
      console.log("Final results:", results);

      // Track flow completion
      posthog.capture("onboarding_completed", {
        completion_time: Date.now() - startTime,
        total_steps_completed: currentStep + 1,
      });

      // Here you would typically redirect or trigger the next steps
    }
  };

  const isLastStep = currentStep === screens.length - 1;
  const currentScreen = screens[currentStep];
  const currentSection = currentScreen.section || 0;

  return (
    <div className="flex flex-col h-screen relative overflow-hidden">
      {/* Grid background with animated elements - positioned behind everything */}
      <GridBackground />

      {/* Header and progress bar - always shown, but with different states */}
      <HeaderBar
        sections={sections}
        currentSection={currentSection}
        showSectionTitle={showSectionTitle && currentSection >= 0}
        sectionProgress={getSectionProgress(
          screens,
          currentStep,
          currentSection
        )}
        onLogoClick={handleGoBack}
      />

      {/* Main content area with improved scrolling */}
      <OnboardingContent
        currentStep={currentStep}
        currentScreen={currentScreen}
        currentSection={currentSection}
        showSectionTitle={showSectionTitle}
        handleUpdate={handleUpdate}
        results={results}
      />

      {/* Footer with continue button */}
      {!currentScreen.hideDefaultButton && (
        <ContinueButton
          isLastStep={isLastStep}
          handleContinue={handleContinue}
        />
      )}

      {/* Add keyframes for shine animation */}
      <style jsx global>{`
        @keyframes shine-once {
          0% {
            left: -100%;
          }
          100% {
            left: 100%;
          }
        }
        .animate-shine-once {
          animation: shine-once 1.5s ease-out forwards;
        }
      `}</style>
    </div>
  );
};

// Helper function for section progress calculation
const getSectionProgress = (screens, currentStep, currentSection) => {
  if (currentSection < 0) return 0;

  // Count how many screens are in the current section
  const screensInSection = screens.filter(
    (screen) => screen.section === currentSection
  ).length;

  // Count how many screens in current section we've completed
  let completedScreens = 0;
  let foundCurrentScreen = false;

  for (let i = 0; i < screens.length; i++) {
    if (screens[i].section === currentSection) {
      if (i === currentStep) {
        foundCurrentScreen = true;
      } else if (!foundCurrentScreen) {
        completedScreens++;
      }
    }
  }

  // Return progress as a value between 0 and 1
  return screensInSection > 0 ? completedScreens / screensInSection : 0;
};

// Extracted Content component
const OnboardingContent = ({
  currentStep,
  currentScreen,
  currentSection,
  showSectionTitle,
  handleUpdate,
  results,
}) => {
  const renderScreen = (screen) => {
    const props = {
      onUpdate: handleUpdate,
      content: screen.content,
      results: results,
    };

    switch (screen.type) {
      case "intro":
        return <IntroScreen {...props} />;
      case "selection":
        return <SelectionScreen {...props} autoAdvance={screen.autoAdvance} />;
      case "multiSelect":
        return (
          <MultiSelectScreen
            {...props}
            hideDefaultButton={screen.hideDefaultButton}
          />
        );
      case "sectionTransition":
        return <SectionTransition {...props} />;
      case "info":
        return <InfoScreen {...props} />;
      case "testimonial":
        return <TestimonialScreen {...props} />;
      case "animation":
        return <AnimationScreen {...props} />;
      case "timeAssessment":
        return <TimeAssessmentScreen {...props} />;
      case "valueProposition":
        return <ValuePropositionScreen {...props} />;
      case "personalizedInsight":
        return <PersonalizedInsightScreen {...props} />;
      case "planPreview":
        return <PlanPreviewScreen {...props} />;
      case "subscription":
        return <SubscriptionScreen {...props} />;
      default:
        return null;
    }
  };

  // Calculate padding and height based on current screen type
  const isValuePropositionScreen = currentScreen.type === "valueProposition";
  const isSectionTransition = currentScreen.type === "sectionTransition";

  const contentPadding = isValuePropositionScreen ? "4rem" : "8rem";
  const contentMaxHeight = isValuePropositionScreen
    ? "calc(100vh - 140px)"
    : "calc(100vh - 180px)";

  // Don't show scrollbars for section transitions
  const overflowStyle = isSectionTransition ? "hidden" : "auto";

  return (
    <div
      className="flex-grow flex justify-center relative z-10 overflow-hidden"
      style={{
        paddingBottom: showSectionTitle && currentSection >= 0 ? "6rem" : "0",
        paddingTop: showSectionTitle && currentSection >= 0 ? "1.5rem" : "0",
      }}
    >
      <AnimatePresence mode="wait">
        <motion.div
          className={`w-full px-5 max-w-md overflow-${overflowStyle}`}
          style={{
            maxHeight: contentMaxHeight,
            paddingBottom: contentPadding,
          }}
          key={currentStep}
          initial={{ opacity: 0, x: 15 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: -15 }}
          transition={{ duration: 0.4 }}
        >
          {renderScreen(currentScreen)}
        </motion.div>
      </AnimatePresence>
    </div>
  );
};

export default OnboardingFlow;
