import React from "react";
import EditProtocol from "../protocol/EditProtocol";

const UserProtocolEditor = ({ protocol, updateProtocol, user_id }) => {
  // Handle the protocol update logic here if needed

  return (
    <EditProtocol
      protocol={protocol}
      updateProtocol={updateProtocol}
      isUserProtocol={user_id}
    />
  );
};

export default UserProtocolEditor;
