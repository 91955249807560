import React, { useState } from "react";
import ModulePromptEditor from "./ModulePromptEditor";
import ActivationEditor from "./ActivationEditor";
import { PRIORITY_LABELS } from "./ProtocolRoadmap";
import { TrashIcon, PlusIcon, PencilIcon } from "@heroicons/react/24/outline";

const ModuleEditor = ({ module, onSave, onCancel }) => {
  const [editedModule, setEditedModule] = useState(module);
  const [isEditingTitle, setIsEditingTitle] = useState(false);

  const handleChange = (field, value) => {
    setEditedModule({ ...editedModule, [field]: value });
  };

  const handlePromptChange = ({ type, data, options }) => {
    setEditedModule({
      ...editedModule,
      prompt_type: type,
      prompt_data: data,
      options: options,
    });
  };

  return (
    <div className="bg-white shadow-lg divide-y divide-gray-200 border-2 border-dashed border-indigo-400/40">
      {/* Top Bar */}
      <div className="px-6 py-3 bg-gray-50 flex items-center justify-between border-b ">
        <div className="flex items-center space-x-2 text-sm text-gray-600">
          <span className="text-indigo-600 font-medium">Editing Module</span>
          <span className="text-gray-300">/</span>
          <span className="font-medium text-gray-900">
            {editedModule.title || "Untitled"}
          </span>
        </div>
        <div className="flex items-center space-x-2">
          <button
            onClick={onCancel}
            className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => onSave(editedModule)}
            className="px-3 py-1.5 text-sm font-medium text-white bg-gradient-to-r from-indigo-600 to-indigo-500 rounded-md hover:from-indigo-500 hover:to-indigo-400 shadow-sm transition-all hover:shadow"
          >
            Save Changes
          </button>
        </div>
      </div>

      {/* Header */}
      <div className="px-8 py-6">
        <div className="flex justify-between items-start">
          <div className="space-y-1.5">
            <label className="block text-sm font-medium text-gray-500">
              Module Title
            </label>
            <div className="group relative">
              <input
                type="text"
                className="text-2xl font-semibold text-gray-900 bg-transparent border-0 p-0 focus:ring-0 placeholder-gray-400 w-[500px]"
                value={editedModule.title}
                onChange={(e) => handleChange("title", e.target.value)}
                placeholder="Enter module title..."
                onFocus={() => setIsEditingTitle(true)}
                onBlur={() => setIsEditingTitle(false)}
              />
              <div
                className={`absolute right-0 top-1/2 -translate-y-1/2 transition-opacity ${
                  isEditingTitle
                    ? "opacity-0"
                    : "opacity-0 group-hover:opacity-100"
                }`}
              >
                <PencilIcon className="h-5 w-5 text-gray-400" />
              </div>
            </div>
          </div>
          <div className="relative">
            <label className="block text-sm font-medium text-gray-500 mb-1.5">
              Priority
            </label>
            <select
              className="appearance-none pl-3 pr-8 py-1.5 text-sm font-medium border border-gray-300 rounded-md focus:border-indigo-500 focus:ring-1 focus:ring-indigo-500 bg-white"
              value={editedModule.priority}
              onChange={(e) => handleChange("priority", e.target.value)}
            >
              {Object.entries(PRIORITY_LABELS).map(([value, label]) => (
                <option key={value} value={value}>
                  {label}
                </option>
              ))}
            </select>
            <div className="pointer-events-none absolute right-2 bottom-2">
              <svg
                className="h-4 w-4 text-gray-500"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </div>
          </div>
        </div>
      </div>

      {/* Content */}
      <div className="px-8 py-8 space-y-12">
        {/* Activations Section */}
        <div>
          <div className="flex justify-between items-center mb-6">
            <div>
              <h2 className="text-lg font-semibold text-gray-900 mb-1">
                Activations
              </h2>
              <p className="text-sm text-gray-500 m-0">
                Define when this module should be triggered
              </p>
            </div>
            <button
              onClick={() =>
                handleChange("activations", [...editedModule.activations, ""])
              }
              className="inline-flex items-center px-4 py-2 text-sm font-medium text-indigo-700 bg-indigo-50 rounded-lg hover:bg-indigo-100 transition-colors"
            >
              <PlusIcon className="w-4 h-4 mr-1.5" />
              Add Activation
            </button>
          </div>

          {editedModule.activations.length > 0 ? (
            <div className="bg-white rounded-xl border border-gray-200 shadow-sm hover:border-gray-300 transition-all duration-200">
              <div className="divide-y divide-gray-100">
                {editedModule.activations.map((activation, index) => (
                  <div key={index} className="p-6">
                    <ActivationEditor
                      value={activation}
                      onChange={(newValue) => {
                        const newActivations = [...editedModule.activations];
                        newActivations[index] = newValue;
                        handleChange("activations", newActivations);
                      }}
                      onRemove={() => {
                        const newActivations = editedModule.activations.filter(
                          (_, i) => i !== index
                        );
                        handleChange("activations", newActivations);
                      }}
                    />
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <div className="text-center py-12 bg-gray-50 rounded-lg border-2 border-dashed border-gray-300">
              <p className="text-sm text-gray-500">No activations added yet</p>
              <button
                onClick={() =>
                  handleChange("activations", [...editedModule.activations, ""])
                }
                className="mt-2 inline-flex items-center px-3 py-1.5 text-sm font-medium text-blue-700 hover:text-blue-800"
              >
                <PlusIcon className="w-4 h-4 mr-1.5" />
                Add your first activation
              </button>
            </div>
          )}
        </div>

        {/* Prompt Section */}
        <div>
          <div className="mb-6">
            <h2 className="text-lg font-semibold text-gray-900">
              Prompt Configuration
            </h2>
            <p className="mt-1 text-sm text-gray-500">
              Configure how the AI should interact in this module
            </p>
          </div>
          <div className="bg-white rounded-xl border border-gray-200 shadow-sm">
            <div className="p-6">
              <ModulePromptEditor
                promptType={editedModule.prompt_type}
                promptData={editedModule.prompt_data}
                options={editedModule.options}
                onChange={handlePromptChange}
              />
            </div>
          </div>
        </div>
      </div>

      {/* Bottom Save Button */}
      <div className="px-8 py-6 bg-gray-50 flex justify-end">
        <div className="flex items-center space-x-2">
          <button
            onClick={onCancel}
            className="px-3 py-1.5 text-sm font-medium text-gray-700 bg-white border border-gray-300 rounded-md hover:bg-gray-50 transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => onSave(editedModule)}
            className="px-3 py-1.5 text-sm font-medium text-white bg-gradient-to-r from-indigo-600 to-indigo-500 rounded-md hover:from-indigo-500 hover:to-indigo-400 shadow-sm transition-all hover:shadow"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

export default ModuleEditor;
