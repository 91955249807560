import React, { useState, useEffect } from "react";
import axios from "axios";
import LogEditor from "./LogEditor";
import BasicFieldsEditor from "./BasicFieldsEditor";
import ComplexFieldsEditor from "./ComplexFieldsEditor";
import ProtocolSelector from "./ProtocolSelector";

export default function Personas() {
  const [personas, setPersonas] = useState([]);
  const [selectedPersona, setSelectedPersona] = useState(null);
  const [newPersonaName, setNewPersonaName] = useState("");
  const [loading, setLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState("idle"); // 'idle' | 'loading' | 'success'

  useEffect(() => {
    fetchPersonas();
  }, []);

  const fetchPersonas = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.1440.ai/api/admin/personas",
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setPersonas(response.data);
    } catch (error) {
      console.error("Error fetching personas:", error);
    } finally {
      setLoading(false);
    }
  };

  const createPersona = async () => {
    if (!newPersonaName.trim()) return;

    try {
      const response = await axios.post(
        "https://api.1440.ai/api/admin/personas",
        { name: newPersonaName },
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setPersonas([...personas, response.data]);
      setNewPersonaName("");
    } catch (error) {
      console.error("Error creating persona:", error);
    }
  };

  const deletePersona = async (id) => {
    try {
      await axios.delete(`https://api.1440.ai/api/admin/personas/${id}`, {
        headers: {
          "Api-Key": localStorage.getItem("adminApiKey"),
        },
      });
      setPersonas(personas.filter((p) => p.id !== id));
    } catch (error) {
      console.error("Error deleting persona:", error);
    }
  };

  const openPersonaEditor = async (id) => {
    try {
      const response = await axios.get(
        `https://api.1440.ai/api/admin/personas/${id}`,
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setSelectedPersona(response.data);
    } catch (error) {
      console.error("Error fetching persona details:", error);
    }
  };

  const updatePersona = async (updatedData) => {
    try {
      setSaveStatus("loading");
      const response = await axios.put(
        `https://api.1440.ai/api/admin/personas/${
          selectedPersona["user_id"].split("_")[1]
        }`,
        updatedData,
        {
          headers: {
            "Api-Key": localStorage.getItem("adminApiKey"),
          },
        }
      );
      setPersonas(
        personas.map((p) => (p.id === selectedPersona.id ? response.data : p))
      );
      setSaveStatus("success");
      setTimeout(() => {
        setSaveStatus("idle");
      }, 1500);
    } catch (error) {
      console.error("Error updating persona:", error);
      setSaveStatus("idle");
    }
  };

  const renderSaveButtonContent = () => {
    switch (saveStatus) {
      case "loading":
        return (
          <svg className="animate-spin h-5 w-5" viewBox="0 0 24 24">
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
              fill="none"
            />
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
            />
          </svg>
        );
      case "success":
        return (
          <svg className="h-5 w-5" viewBox="0 0 20 20" fill="currentColor">
            <path
              fillRule="evenodd"
              d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
              clipRule="evenodd"
            />
          </svg>
        );
      default:
        return "Save Changes";
    }
  };

  return (
    <div className="">
      <h2 className="text-lg font-medium text-white mb-2">Personas</h2>

      <div className="flex gap-2 mb-4">
        <input
          type="text"
          value={newPersonaName}
          onChange={(e) => setNewPersonaName(e.target.value)}
          placeholder="New persona name..."
          className="flex-grow px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={createPersona}
          className="px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
        >
          Add
        </button>
      </div>

      <div className="space-y-2 max-h-[300px] overflow-y-auto">
        {personas.map((persona) => (
          <div
            key={persona.id}
            className="flex items-center justify-between bg-slate-700 p-2 rounded-lg"
          >
            <span className="text-white">{persona.name}</span>
            <div className="space-x-2">
              <button
                onClick={() => openPersonaEditor(persona.id)}
                className="px-2 py-1 bg-blue-600 hover:bg-blue-700 text-white rounded text-sm"
              >
                Edit
              </button>
              <button
                onClick={() => deletePersona(persona.id)}
                className="px-2 py-1 bg-red-600 hover:bg-red-700 text-white rounded text-sm"
              >
                Delete
              </button>
            </div>
          </div>
        ))}
      </div>

      {selectedPersona && (
        <div className="fixed top-0 right-0 h-screen w-[600px] bg-slate-800 shadow-xl overflow-hidden">
          <div className="h-full p-6 flex flex-col">
            <div className="flex justify-between items-center mb-4">
              <div className="flex items-center gap-2">
                <h3 className="text-xl font-bold text-white">
                  Edit {selectedPersona.name}
                </h3>
                <button
                  onClick={() =>
                    navigator.clipboard.writeText(selectedPersona.user_id)
                  }
                  className="px-2 py-1 bg-slate-600 hover:bg-slate-700 text-white rounded text-sm"
                >
                  Copy ID
                </button>
              </div>
              <button
                onClick={() => setSelectedPersona(null)}
                className="text-gray-400 hover:text-white"
              >
                ✕
              </button>
            </div>

            <div className="flex-1 overflow-y-auto">
              <div className="space-y-6">
                {/* Protocol Selector */}
                <ProtocolSelector
                  selectedProtocolId={
                    selectedPersona.protocols?.[0]?.protocol_id
                  }
                  onChange={(protocolId) => {
                    setSelectedPersona((prev) => ({
                      ...prev,
                      protocols: [
                        {
                          protocol_id: protocolId,
                          touchpoints: [],
                          active_module: {},
                          active_topic: {},
                        },
                      ],
                    }));
                  }}
                />

                {/* Log Editor */}
                <div className="mb-6">
                  <h4 className="text-gray-300 text-sm font-medium mb-2">
                    Conversation Log
                  </h4>
                  <LogEditor
                    data={selectedPersona.log || []}
                    onChange={(newLog) => {
                      setSelectedPersona((prev) => ({
                        ...prev,
                        log: newLog,
                      }));
                    }}
                  />
                </div>

                {/* Basic Fields */}
                <BasicFieldsEditor
                  data={selectedPersona}
                  onChange={(updatedData) => {
                    setSelectedPersona(updatedData);
                  }}
                />

                {/* Complex Fields */}
                <ComplexFieldsEditor
                  data={selectedPersona}
                  onChange={(updatedData) => {
                    setSelectedPersona(updatedData);
                  }}
                />
              </div>
            </div>

            <div className="flex justify-end space-x-2 mt-4 pt-4 border-t border-slate-700">
              <button
                onClick={() => setSelectedPersona(null)}
                className="px-4 py-2 bg-gray-600 hover:bg-gray-700 text-white rounded-lg"
              >
                Cancel
              </button>
              <button
                onClick={() => updatePersona(selectedPersona)}
                disabled={saveStatus !== "idle"}
                className={`px-4 py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg flex items-center justify-center min-w-[120px] ${
                  saveStatus !== "idle" ? "opacity-75 cursor-not-allowed" : ""
                }`}
              >
                {renderSaveButtonContent()}
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}
