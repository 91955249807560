import React from "react";
import { motion } from "framer-motion";
import { primary, text, gradient } from "../theme/variables";
import "../theme/theme.css";
import PersonalizedPlanSummary from "../components/PersonalizedPlanSummary";

const SubscriptionScreen = ({ onUpdate, content, results }) => {
  const handleSubscribe = () => {
    onUpdate({ subscribed: true });
    // In a real app, this would trigger a payment flow
  };

  return (
    <motion.div
      className="flex flex-col h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <motion.h1
        className="text-3xl font-bold mt-6 mb-3 text-center"
        style={{ color: text.white }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        Your personalized<br></br>3-month plan
      </motion.h1>

      {/* Import the personalized plan summary component */}
      <PersonalizedPlanSummary results={results} />
    </motion.div>
  );
};

export default SubscriptionScreen;
