import React, { useState, useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { primary, text } from "../theme/variables";
import "../theme/theme.css";

const AnimationScreen = ({ content, onUpdate }) => {
  const [completedSteps, setCompletedSteps] = useState([]);
  const [activeStep, setActiveStep] = useState(0);
  const [allCompleted, setAllCompleted] = useState(false);
  const [percentages, setPercentages] = useState(content.steps.map(() => 0));
  const [barWidths, setBarWidths] = useState(content.steps.map(() => "0%"));

  // Reference for animation intervals
  const animationIntervals = useRef([]);
  const hasSignaledCompletion = useRef(false);

  // Calculate total animation duration for backup timer
  const totalDuration = content.steps.reduce(
    (total, step) => total + step.duration * 0.6,
    0
  );

  // Icons for each step
  const stepIcons = [
    // Analysis icon
    <svg
      className="w-5 h-5"
      style={{ color: text.white }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      key="analysis-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M9 19v-6a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2a2 2 0 002-2zm0 0V9a2 2 0 012-2h2a2 2 0 012 2v10m-6 0a2 2 0 002 2h2a2 2 0 002-2m0 0V5a2 2 0 012-2h2a2 2 0 012 2v14a2 2 0 01-2 2h-2a2 2 0 01-2-2z"
      />
    </svg>,
    // Misalignment icon
    <svg
      className="w-5 h-5"
      style={{ color: text.white }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      key="misalignment-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M10 21h7a2 2 0 002-2V9.414a1 1 0 00-.293-.707l-5.414-5.414A1 1 0 0012.586 3H7a2 2 0 00-2 2v11m0 5l4.879-4.879m0 0a3 3 0 104.243-4.242 3 3 0 00-4.243 4.242z"
      />
    </svg>,
    // Design icon
    <svg
      className="w-5 h-5"
      style={{ color: text.white }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      key="design-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M15.232 5.232l3.536 3.536m-2.036-5.036a2.5 2.5 0 113.536 3.536L6.5 21.036H3v-3.572L16.732 3.732z"
      />
    </svg>,
    // Framework icon
    <svg
      className="w-5 h-5"
      style={{ color: text.white }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      key="framework-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"
      />
    </svg>,
    // Support system icon
    <svg
      className="w-5 h-5"
      style={{ color: text.white }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      key="support-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M17 20h5v-2a3 3 0 00-5.356-1.857M17 20H7m10 0v-2c0-.656-.126-1.283-.356-1.857M7 20H2v-2a3 3 0 015.356-1.857M7 20v-2c0-.656.126-1.283.356-1.857m0 0a5.002 5.002 0 019.288 0M15 7a3 3 0 11-6 0 3 3 0 016 0zm6 3a2 2 0 11-4 0 2 2 0 014 0zM7 10a2 2 0 11-4 0 2 2 0 014 0z"
      />
    </svg>,
  ];

  // Checkmark icon for completed steps
  const checkmarkIcon = (
    <svg
      className="w-5 h-5"
      style={{ color: text.white }}
      fill="none"
      viewBox="0 0 24 24"
      stroke="currentColor"
      key="checkmark-icon"
    >
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth={2}
        d="M5 13l4 4L19 7"
      />
    </svg>
  );

  // Handle auto-advance after all animations complete
  useEffect(() => {
    if (allCompleted && !hasSignaledCompletion.current) {
      console.log("Animations completed, triggering auto-advance");
      hasSignaledCompletion.current = true;

      // Use the onUpdate callback to signal completion to parent component immediately
      onUpdate({ completed: true });
    }
  }, [allCompleted, onUpdate]);

  // Add backup timer to ensure auto-advance happens regardless
  useEffect(() => {
    const backupTimer = setTimeout(() => {
      if (!hasSignaledCompletion.current) {
        console.log("Backup timer triggered for auto-advance");
        hasSignaledCompletion.current = true;
        onUpdate({ completed: true });
      }
    }, totalDuration + 300); // Reduced buffer time to 300ms

    return () => clearTimeout(backupTimer);
  }, [onUpdate, totalDuration]);

  // Function to create non-linear progress using easing and random variations
  const animateProgressBar = (index) => {
    const duration = content.steps[index].duration * 0.6; // Speed up by 40%
    const totalFrames = 100; // Increased frames for smoother animation
    const frameDuration = duration / totalFrames;

    // Clear any existing interval for this index
    if (animationIntervals.current[index]) {
      clearInterval(animationIntervals.current[index]);
    }

    let frame = 0;

    // Create smoother easing patterns with cubic bezier-like curves
    const speedPattern = [
      // Analysis - smooth acceleration curve
      (progress) => {
        return progress < 0.8 ? progress : progress * 1.2;
      },
      // Misalignment - smooth start and end with slower middle
      (progress) => {
        // Cubic bezier-like curve
        return 0.2 * Math.pow(progress, 3) + 0.8 * progress;
      },
      // Design - sinusoidal wave pattern for smooth variation
      (progress) => {
        const base = progress;
        const wave = 0.05 * Math.sin(progress * Math.PI * 4);
        return Math.max(0, Math.min(1, base + wave));
      },
      // Framework - gradual acceleration
      (progress) => {
        // Ease in quad
        return progress * progress;
      },
      // Support - almost linear with very subtle variations
      (progress) => {
        // Small sine wave added for tiny variations
        const variation = 0.02 * Math.sin(progress * 10);
        return progress + variation;
      },
    ];

    // Use the specific pattern for this step index
    const applyPattern = speedPattern[index % speedPattern.length];

    // Set interval for updating the progress
    animationIntervals.current[index] = setInterval(() => {
      frame++;
      if (frame <= totalFrames) {
        let linearProgress = frame / totalFrames;
        let adjustedProgress = applyPattern(linearProgress);

        // Ensure progress is between 0 and 1
        adjustedProgress = Math.max(0, Math.min(1, adjustedProgress));

        // Update percentage display and bar width simultaneously
        const percentage = Math.round(adjustedProgress * 100);
        const width = `${adjustedProgress * 100}%`;

        setPercentages((prev) => {
          const newPercentages = [...prev];
          newPercentages[index] = percentage;
          return newPercentages;
        });

        setBarWidths((prev) => {
          const newWidths = [...prev];
          newWidths[index] = width;
          return newWidths;
        });
      } else {
        // Ensure we reach exactly 100%
        setPercentages((prev) => {
          const newPercentages = [...prev];
          newPercentages[index] = 100;
          return newPercentages;
        });

        setBarWidths((prev) => {
          const newWidths = [...prev];
          newWidths[index] = "100%";
          return newWidths;
        });

        clearInterval(animationIntervals.current[index]);
      }
    }, frameDuration);
  };

  useEffect(() => {
    let stepTimers = [];

    // Function to process each step
    const processStep = (index) => {
      if (index >= content.steps.length) {
        // All steps completed
        console.log("All steps processed, setting allCompleted");
        setAllCompleted(true);
        return;
      }

      // Start the step
      setActiveStep(index);

      // Start animating this progress bar
      animateProgressBar(index);

      // After the step duration, mark it as completed and move to the next step
      const completionTimer = setTimeout(() => {
        setCompletedSteps((prev) => [...prev, index]);

        // Short delay before moving to next step
        const nextStepTimer = setTimeout(() => {
          processStep(index + 1);
        }, 300); // Reduced delay between steps

        stepTimers.push(nextStepTimer);
      }, content.steps[index].duration * 0.6); // Speed up by 40%

      stepTimers.push(completionTimer);
    };

    // Start with the first step
    processStep(0);

    // Clean up all timers on unmount
    return () => {
      stepTimers.forEach(clearTimeout);
      animationIntervals.current.forEach((interval) => {
        if (interval) clearInterval(interval);
      });
    };
  }, [content.steps]);

  return (
    <div className="pt-8 px-1">
      <motion.h3
        className="text-xl font-semibold mb-6"
        style={{ color: text.white }}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.4 }}
      >
        {content.title}
      </motion.h3>

      <div className="space-y-5">
        {content.steps.map((step, index) => (
          <motion.div
            key={index}
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: index * 0.1, duration: 0.4 }}
          >
            <div className="flex items-center justify-between mb-2">
              <div className="flex items-center">
                <div
                  className="w-8 h-8 rounded-full flex items-center justify-center mr-3 flex-shrink-0"
                  style={{ backgroundColor: primary[600] }}
                >
                  {completedSteps.includes(index)
                    ? checkmarkIcon
                    : stepIcons[index % stepIcons.length]}
                </div>
                <p
                  className="text-left text-base mb-0"
                  style={{ color: text.white }}
                >
                  {step.text}
                </p>
              </div>
              {activeStep === index && !completedSteps.includes(index) && (
                <span
                  className="font-medium text-sm"
                  style={{ color: primary[400] }}
                >
                  {percentages[index]}%
                </span>
              )}
            </div>
            <div
              className="w-full h-2 rounded-full overflow-hidden"
              style={{ backgroundColor: `${primary[900]}4D` }} // 30% opacity
            >
              <motion.div
                className="h-full rounded-full"
                style={{
                  width: barWidths[index],
                  backgroundColor: primary[500],
                  transformOrigin: "left",
                }}
                initial={{ width: "0%" }}
                animate={{ width: barWidths[index] }}
                transition={{ duration: 0 }}
              />
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default AnimationScreen;
