import React from "react";

function LogEditor({ data, onChange }) {
  const addNewMessage = () => {
    onChange([
      ...data,
      {
        role: "user",
        content: "",
        time: new Date()
          .toLocaleString("en-US", {
            year: "numeric",
            month: "2-digit",
            day: "2-digit",
            hour: "2-digit",
            minute: "2-digit",
            second: "2-digit",
            hour12: false,
          })
          .replace(
            /(\d+)\/(\d+)\/(\d+), (\d+):(\d+):(\d+)/,
            "$3-$1-$2 $4:$5:$6"
          ),
        via: false,
      },
    ]);
  };

  const updateMessage = (index, field, value) => {
    const newData = [...data];
    newData[index] = { ...newData[index], [field]: value };
    onChange(newData);
  };

  const deleteMessage = (index) => {
    const newData = [...data];
    newData.splice(index, 1);
    onChange(newData);
  };

  return (
    <div className="space-y-4">
      {data.map((message, index) => (
        <div key={index} className="bg-slate-700 p-4 rounded-lg space-y-3">
          <div className="flex items-center justify-between gap-2">
            <select
              value={message.role}
              onChange={(e) => updateMessage(index, "role", e.target.value)}
              className="bg-slate-600 text-white px-3 py-1 rounded-md"
            >
              <option value="user">User</option>
              <option value="assistant">Assistant</option>
            </select>
            <select
              value={message.via || "web"}
              onChange={(e) => updateMessage(index, "via", e.target.value)}
              className="bg-slate-600 text-white px-3 py-1 rounded-md"
            >
              <option value={false}>None</option>
              <option value="app">App</option>
              <option value="whatsapp">WhatsApp</option>
              <option value="sms">SMS</option>
              <option value="messenger">Messenger</option>
              <option value="phone">Phone call</option>
            </select>

            <button
              onClick={() => deleteMessage(index)}
              className="text-red-400 hover:text-red-300"
            >
              ✕
            </button>
          </div>
          <input
            value={message.content}
            onChange={(e) => updateMessage(index, "content", e.target.value)}
            className="w-full bg-slate-600 text-white rounded-md p-2"
            placeholder="Message content..."
          />
        </div>
      ))}
      <button
        onClick={addNewMessage}
        className="w-full py-2 bg-blue-600 hover:bg-blue-700 text-white rounded-lg"
      >
        Add Message
      </button>
    </div>
  );
}

export default LogEditor;
