import Mixpanel from "mixpanel-browser";
import "./PrivacyPolicy.css";
import { useNavigate } from "react-router-dom";
import React, { useEffect } from "react";

const PrivacyPolicy = ({ userId = null }) => {
  const navigate = useNavigate();
  useEffect(() => {
    // Initialize Mixpanel with your project token
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", {
      track_pageview: true,
    });
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "about" });
  }, []);

  return (
    <div className="bg-light text-dark">
      <div className="sm:m-32 m-8">
        <button
          onClick={() => navigate("/")}
          className="flex items-center mb-10 justify-center text-accent opacity-50 hover:opacity-100 hover:font-bold"
        >
          <img src="/images/submit.png" className="h-5 rotate-180 w-5 mr-2" />
          Back
        </button>
        <h1 className="text-3xl">Privacy Policy</h1>
        <h1>1440 Privacy Policy</h1>
        <h3>Introduction</h3>
        <p>
          Welcome to 1440, an AI-powered coach designed to assist and guide you
          throughout your daily life. At 1440, a venture initiative by Share
          Ventures, we prioritize your privacy and are committed to maintaining
          the trust and confidence of our users. This Privacy Policy outlines
          how we collect, use, and protect your personal information. By using
          our services, you agree to the terms and conditions of this policy.
        </p>
        <h3>Information We Collect</h3>
        <p>
          At 1440, our primary goal is to provide a personalized and efficient
          coaching experience. To achieve this, we collect a wide range of
          information, both directly from you and automatically as you use our
          services. Here's a detailed breakdown:
        </p>
        <p>1. Direct Information:</p>
        <ul>
          <li>
            Personal Identifiers: This includes your name, email address, and
            date of birth. These details help us tailor our services to your
            specific needs.
          </li>
          <li>
            Personal Insights: To offer a comprehensive coaching experience, we
            gather information about your projects, relationships, thoughts,
            values, goals, and other relevant aspects of your life. This data
            allows our AI coach to understand and assist you better.
          </li>
        </ul>
        <p>2. Automated Information:</p>
        <ul>
          <li>
            Usage Data: As you interact with 1440, we collect data related to
            your usage patterns, preferences, and interactions with our AI
            coach. This helps us refine and improve our services.
          </li>
          <li>
            Device Information: We may gather details about the device you use
            to access 1440, including the device type, operating system, and
            other relevant technical information.
          </li>
        </ul>
        <p>3. Third-Party Integrations:</p>
        <ul>
          <li>
            Google Calendar: Our integration with Google Calendar involves
            accessing and updating your calendar events and reminders. This data
            is utilized solely to enhance your coaching experience with 1440 and
            is not shared with any other third parties. We commit to maintaining
            transparency as we expand our integrations with other productivity
            apps, ensuring that the way data is shared, used, and protected is
            clear to our users.
          </li>
        </ul>
        <h3>How We Use Your Information</h3>
        <p>
          The information we collect serves multiple purposes, all aimed at
          enhancing your experience with 1440 and ensuring the efficient
          operation of our services. Here's how we utilize the data:
        </p>
        <p>1. Service Provision and Personalization:</p>
        <ul>
          <li>
            To deliver the core functionalities of 1440, offering a tailored
            coaching experience based on your personal insights, goals, and
            preferences.
          </li>
          <li>
            To refine and adapt our AI algorithms, ensuring they align with your
            evolving needs and circumstances.
          </li>
        </ul>
        <p>2. Communication:</p>
        <ul>
          <li>
            To keep you informed about updates, enhancements, and changes to our
            services.
          </li>
          <li>To provide support, address queries, and respond to feedback.</li>
        </ul>
        <p>3. Research &amp; Development:</p>
        <ul>
          <li>
            To analyze usage patterns and user feedback, driving continuous
            improvement and innovation within our platform.
          </li>
          <li>
            To test new features, ensuring they align with user expectations and
            provide value.
          </li>
        </ul>
        <p>4. Legal &amp; Compliance:</p>
        <ul>
          <li>
            To adhere to regulatory requirements, legal obligations, and to
            protect the rights and safety of our users and our platform.
          </li>
        </ul>
        <h3>How We Share Your Information</h3>
        <p>
          Your trust is paramount, and we exercise caution and discretion when
          it comes to sharing your data. Here's a detailed overview of our
          data-sharing practices:
        </p>
        <p>1. Internal Sharing:</p>
        <ul>
          <li>
            Data is shared internally among our teams, strictly for the purpose
            of service provision, support, and improvement.
          </li>
        </ul>
        <p>2. Third-Party Service Providers:</p>
        <ul>
          <li>
            We collaborate with trusted third-party providers, such as cloud
            storage solutions (e.g., Amazon, Google), to store and manage user
            data. These providers are bound by strict confidentiality agreements
            and are prohibited from using the data for any purpose other than
            what's explicitly agreed upon.
          </li>
          <li>
            As we expand our integrations with productivity apps, we'll ensure
            that data sharing aligns with the highest privacy standards.
          </li>
        </ul>
        <p>3. Legal &amp; Regulatory Bodies:</p>
        <ul>
          <li>
            We may disclose user data if required by law, in response to legal
            processes, or to protect the rights and safety of individuals.
          </li>
        </ul>
        <p>4. Business Transfers:</p>
        <ul>
          <li>
            In the event of a merger, acquisition, or any form of sale of some
            or all of our assets, user data may be part of the transferred
            assets. Users will be notified in advance of any such change in
            ownership or control of their personal information.
          </li>
        </ul>
        <h3>Data Sharing with Third-Party Tools and AI Models</h3>
        <p>
          To enrich your coaching experience, 1440 occasionally shares certain
          user data with trusted third-party AI models. This section elaborates
          on this aspect of our data handling.
        </p>
        <ul>
          <li>
            <strong>Type of Data Shared:</strong> We share select data such as
            personal identifiers (like your name and email), insights from your
            interactions with our services, data from integrations, such as
            Google Calendar, usage patterns, and technical details of your
            device.
          </li>
          <li>
            <strong>Purpose of Data Sharing:</strong> This sharing is vital for
            enhancing the personalization of our services. It allows our AI to
            tailor its responses and functionalities to your unique preferences
            and needs.
          </li>
          <li>
            <strong>Privacy and Security:</strong> We strictly collaborate with
            third-party services that maintain high standards of data protection
            and privacy. Your data is shared securely, ensuring its
            confidentiality and integrity.
          </li>
        </ul>
        <p>
          By using 1440, you acknowledge and agree to this aspect of data
          sharing, which is crucial for delivering a bespoke and effective
          coaching experience. We remain committed to transparency in our data
          practices. For any inquiries or concerns, please contact us.
        </p>
        <h3>User Rights &amp; Choices</h3>
        <p>
          At 1440, we believe in empowering our users with control over their
          personal data. Here's an overview of the rights and choices available
          to you:
        </p>
        <p>1. Access &amp; Correction:</p>
        <ul>
          <li>
            You have the right to access the personal information we hold about
            you. If you find any inaccuracies or incomplete data, you can
            request corrections to ensure it reflects the most up-to-date and
            accurate information.
          </li>
        </ul>
        <p>2. Deletion:</p>
        <ul>
          <li>
            If you wish to discontinue using our services, you can request the
            deletion of your personal data. We'll process this request promptly,
            retaining only the data necessary for legal or regulatory purposes.
          </li>
        </ul>
        <p>3. Opt-Out:</p>
        <ul>
          <li>
            You can opt-out of receiving marketing communications from us at any
            time. Each communication will include an "unsubscribe" link for your
            convenience.
          </li>
        </ul>
        <h3>Data Protection &amp; Security</h3>
        <p>
          Protecting your data is our utmost priority. We've implemented a range
          of measures to ensure the security and integrity of your information:
        </p>
        <p>1. Encryption:</p>
        <ul>
          <li>
            All data transmitted to and from our platform is encrypted using
            industry-standard protocols.
          </li>
        </ul>
        <p>2. Secure Storage:</p>
        <ul>
          <li>
            Your data is securely stored on reputable cloud service providers,
            such as Amazon or Google. These providers are known for their robust
            security measures and compliance with global data protection
            regulations.
          </li>
        </ul>
        <p>3. Regular Audits:</p>
        <ul>
          <li>
            We conduct periodic security audits to identify and address
            potential vulnerabilities.
          </li>
        </ul>
        <p>4. Data Breach Protocols:</p>
        <ul>
          <li>
            In the unlikely event of a data breach, we have protocols in place
            to promptly notify affected users and take necessary remedial
            actions.
          </li>
        </ul>
        <h3>Third-Party Integrations &amp; Services</h3>
        <p>
          To enhance the functionality of 1440, we integrate with third-party
          services:
        </p>
        <p>1. Google Calendar:</p>
        <ul>
          <li>
            To enhance your coaching experience, 1440 integrates with Google
            Calendar. This allows us to access your calendar events, reminders,
            and other related data, adhering strictly to the Google API Services
            User Data Policy, including the Limited Use requirements. Our use
            and transfer of information received from Google APIs will adhere to
            this policy, as detailed here:{" "}
            <a
              target="_blank"
              class="text-blue-500 underline"
              href="https://developers.google.com/terms/api-services-user-data-policy"
            >
              https://developers.google.com/terms/api-services-user-data-policy
            </a>
            . Future integrations with other apps will continue to prioritize
            your privacy and transparency.
          </li>
        </ul>
        <p>2. Future Integrations:</p>
        <ul>
          <li>
            We're continuously exploring opportunities to integrate with other
            productivity apps. Any new integration will be communicated to our
            users, and we'll update our privacy policy accordingly.
          </li>
        </ul>
        <h3>Children's Privacy</h3>
        <p>
          1440 is not intended for use by children under the age of 13, and we
          do not knowingly collect personal information from children under this
          age. If we become aware that a child under 13 has provided us with
          personal information, we will take steps to remove such information
          and terminate the child's account. Users are responsible for ensuring
          they meet the age requirements to use our services.
        </p>
        <h3>International Data Transfers</h3>
        <p>
          Given the global nature of our operations and our reliance on reputed
          cloud service providers, your data may be stored and processed in any
          country where we have facilities or in which we engage service
          providers. By using our services, you understand that your information
          may be transferred to countries outside of your country of residence,
          which may have data protection rules that differ from those of your
          country. We will ensure that adequate safeguards are in place to
          protect your personal data regardless of where it resides.
        </p>
        <h3>Changes to This Privacy Policy</h3>
        <p>
          We may update our Privacy Policy from time to time to reflect changes
          in our practices, service offerings, or regulatory requirements. When
          we make significant changes, we will notify you through our platform
          or other communication methods. We encourage you to review our Privacy
          Policy periodically to stay informed about how we use and protect your
          data.
        </p>
        <h3>Contact Information</h3>
        <p>
          For any questions, concerns, or feedback regarding this Privacy Policy
          or our data practices, please reach out to us at:
        </p>
        <p>Email: hello@share.vc</p>
        <p>
          As a venture initiative by Share Ventures, we are committed to
          upholding the highest standards of data privacy and protection.
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
