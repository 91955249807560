import React, { useState } from "react";
import ScorecardList from "./ScorecardList";
import ScorecardDetails from "./ScorecardDetails";

export default function ScorecardView() {
  const [selectedScorecard, setSelectedScorecard] = useState(null);

  const handleSelectScorecard = (scorecard) => {
    setSelectedScorecard(scorecard);
  };

  const handleBack = () => {
    setSelectedScorecard(null);
  };

  if (selectedScorecard) {
    return (
      <ScorecardDetails scorecard={selectedScorecard} onBack={handleBack} />
    );
  }

  return <ScorecardList onSelectScorecard={handleSelectScorecard} />;
}
