import React, { useState, useEffect } from "react";
import Modal from "react-modal";
import GoogleIcon from "./GoogleIcon";
import { ChevronRightIcon } from "@heroicons/react/24/outline";

Modal.setAppElement("#root");

const GoogleCalendarButton = ({ userId: propUserId, isSynced, isLoading }) => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [acceptedPrivacy, setAcceptedPrivacy] = useState(false);

  const userId = propUserId || localStorage.getItem("userId");

  const openModal = () => {
    setModalIsOpen(true);
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const handleConnectCalendar = () => {
    openModal();
  };

  const handleSync = () => {
    window.location.href = `https://api.1440.ai/auth/${userId}/`;
    closeModal();
  };

  const handleRevokeAccess = () => {
    window.location.href = `https://api.1440.ai/revoke/${userId}`;
  };

  const handleAcceptPrivacy = (e) => {
    setAcceptedPrivacy(e.target.checked);
  };

  return (
    <div className="flex items-center justify-center">
      <button
        onClick={handleConnectCalendar}
        disabled={isLoading}
        className={`relative inline-flex items-center justify-between w-full px-4 py-3 rounded text-sm font-medium shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 ${
          isLoading
            ? "bg-slate-600 text-white cursor-not-allowed"
            : isSynced
            ? "bg-slate-200 text-black hover:bg-slate-300 focus:ring-indigo-300"
            : "bg-slate-800 text-white hover:bg-slate-700 focus:ring-indigo-500"
        }`}
      >
        <div className="flex items-center">
          {isLoading ? (
            <>
              <svg
                className="animate-spin h-5 w-5 mr-2 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                ></path>
              </svg>
              <span>Checking calendar connection</span>
            </>
          ) : (
            <>
              <GoogleIcon className="h-5 w-5 mr-2" />
              <span>
                {isSynced ? "Disconnect Calendar" : "Connect Calendar"}
              </span>
            </>
          )}
        </div>
        {!isLoading && <ChevronRightIcon className="h-5 w-5 ml-2" />}
      </button>

      <Modal
        isOpen={modalIsOpen}
        onRequestClose={closeModal}
        contentLabel="Calendar Sync Modal"
        className="fixed top-1/2 left-1/2 max-w-lg w-full shadow-xl shadow-slate-950 border border-slate-800 text-white p-6 bg-black rounded-lg  transform -translate-x-1/2 -translate-y-1/2"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        {!acceptedPrivacy ? (
          <div>
            <h2 className="text-lg font-semibold">
              Privacy Policy Acknowledgement
            </h2>
            <p className="mb-4">
              Before you can connect any external services, we need you to
              accept the requirements from our{" "}
              <a
                href="/privacy-policy"
                target="_blank"
                className="text-blue-500 underline"
              >
                Privacy Policy
              </a>
              .
            </p>
            <div className="flex items-center mb-4">
              <input
                type="checkbox"
                id="acceptPrivacy"
                onChange={handleAcceptPrivacy}
                className="mr-2"
              />
              <label htmlFor="acceptPrivacy" className="text-md">
                I accept the Privacy Policy
              </label>
            </div>
          </div>
        ) : (
          <div>
            <h2 className="text-lg font-semibold">Sync Google Calendar</h2>
            {isSynced ? (
              <div>
                <p className="mb-4">
                  Your calendar is currently connected. You can disconnect it to
                  stop syncing your calendar events.
                </p>
                <button
                  onClick={handleRevokeAccess}
                  className="relative inline-flex items-center justify-center px-4 py-2 border border-gray-700 bg-black text-sm font-medium text-white shadow-sm hover:bg-slate-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <GoogleIcon className="h-5 w-5 mr-2" />
                  <span>Disconnect Calendar</span>
                </button>
              </div>
            ) : (
              <div className="">
                <p className="mb-4">
                  Our enhanced performance coach uses your calendar events to
                  provide personalized coaching, helping you organize your day
                  effectively. With your permission, it can also make
                  adjustments to optimize your schedule.
                </p>
                <button
                  onClick={handleSync}
                  className="relative inline-flex items-center justify-center px-4 py-2 border border-gray-700 bg-black text-sm font-medium text-white shadow-sm hover:bg-slate-700 rounded focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                >
                  <GoogleIcon className="h-5 w-5 mr-2" />
                  <span>Connect Calendar</span>
                </button>
              </div>
            )}
          </div>
        )}
      </Modal>
    </div>
  );
};

export default GoogleCalendarButton;
