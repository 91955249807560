import React, { useState } from "react";
import {
  ChartBarIcon,
  UsersIcon,
  DocumentTextIcon,
  ChatBubbleLeftRightIcon,
  XMarkIcon,
  Bars3Icon,
} from "@heroicons/react/24/outline";

export default function Sidebar({ activeView, onViewChange }) {
  const [isOpen, setIsOpen] = useState(true);

  const navItems = [
    {
      id: "dashboard",
      label: "Dashboard",
      icon: <ChartBarIcon className="h-5 w-5" />,
    },
    {
      id: "users",
      label: "Users",
      icon: <UsersIcon className="h-5 w-5" />,
    },
    {
      id: "scorecards",
      label: "Scorecards",
      icon: <DocumentTextIcon className="h-5 w-5" />,
    },
    {
      id: "ai-conversations",
      label: "AI convos",
      icon: <ChatBubbleLeftRightIcon className="h-5 w-5" />,
    },
  ];

  return (
    <>
      {!isOpen && (
        <button
          onClick={() => setIsOpen(true)}
          className="absolute left-4 top-24 text-gray-400 hover:text-white transition-colors"
          aria-label="Open sidebar"
        >
          <Bars3Icon className="h-6 w-6" />
        </button>
      )}

      {isOpen && (
        <div className="absolute left-0 top-22 w-64 bg-slate-900 h-screen border-r border-slate-800 shadow-lg">
          <div className="p-4">
            <div className="flex justify-end mb-4">
              <button
                onClick={() => setIsOpen(false)}
                className="text-gray-400 hover:text-white transition-colors"
                aria-label="Close sidebar"
              >
                <XMarkIcon className="h-6 w-6" />
              </button>
            </div>
            <nav className="space-y-2">
              {navItems.map((item) => (
                <button
                  key={item.id}
                  onClick={() => onViewChange(item.id)}
                  className={`w-full flex items-center space-x-3 p-3 rounded-lg transition-colors ${
                    activeView === item.id
                      ? "bg-blue-600 text-white"
                      : "text-gray-300 hover:bg-slate-800"
                  }`}
                >
                  {item.icon}
                  <span>{item.label}</span>
                </button>
              ))}
            </nav>
          </div>
        </div>
      )}
    </>
  );
}
