import React from "react";

const EditStringList = ({ items, onChange }) => {
  const handleItemChange = (index, newValue) => {
    const updatedItems = [...items];
    updatedItems[index] = newValue;
    onChange(updatedItems);
  };

  const handleAddItem = () => {
    onChange([...items, ""]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onChange(updatedItems);
  };

  return (
    <div>
      {items.map((item, index) => (
        <div key={index} className="flex items-center mb-2">
          <input
            type="text"
            value={item}
            onChange={(e) => handleItemChange(index, e.target.value)}
            className="border rounded px-2 py-1 flex-grow"
          />
          <button
            onClick={() => handleRemoveItem(index)}
            className="ml-2 bg-red-500 text-white px-2 py-1 rounded"
          >
            Remove
          </button>
        </div>
      ))}
      <button
        onClick={handleAddItem}
        className="mt-2 bg-blue-500 text-white px-4 py-2 rounded"
      >
        Add Item
      </button>
    </div>
  );
};

export default EditStringList;