// AspectRatioCarousel.js
import React, { useRef, useEffect, useState } from 'react';
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from 'react-responsive-carousel';
import ResolutionCard from './ResolutionCard';


const AspectRatioCarousel = ({ resolutions, setCurrentItem }) => {
    return (
                    <Carousel
                        showArrows={true}
                        showStatus={false}
                        showIndicators={resolutions.length > 1}
                        showThumbs={false}
                        swipeable={true}
                        emulateTouch={true}
                        useKeyboardArrows={true}
                        className="w-full h-full rounded-lg overflow-hidden"
                        onChange={setCurrentItem}
                    >
                        {resolutions.map((resolution, index) => (
                            <div key={index}>
                                <ResolutionCard
                                    id={`resolutionCard-${index}`}
                                    title={resolution.title}
                                    description={resolution.description}
                                    backgroundImage={"/images/background" + index % 3 + ".png"}
                                />
                            </div>
                        ))}
                    </Carousel>
    );
};

export default AspectRatioCarousel