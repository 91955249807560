import React, { useState } from "react";

const EditTask = ({ task, onUpdate, handleRemove, isUserTask = false }) => {
  const [description, setDescription] = useState(task.description);
  const [executeAt, setExecuteAt] = useState(new Date(task.execute_at.time));
  const [type, setType] = useState(task.type);
  const [dayOfWeek, setDayOfWeek] = useState(task.execute_at.date);
  const [daysAfterCreation, setDaysAfterCreation] = useState(
    task.execute_at.date
  );
  const [executeDate, setExecuteDate] = useState(task.execute_at.date);
  const [executeTime, setExecuteTime] = useState(
    task.execute_at.time || "09:00"
  );

  const [updateSuccess, setUpdateSuccess] = useState(false); // State to track update success

  const [editable, setEditable] = useState(task.editable || false); // Initialize editable state
  const [modality, setModality] = useState(task.modality || "Text"); // Initialize modality state
  const [musicType, setMusicType] = useState("Motivational"); // Always set music type to "Motivational"
  const [appendString, setAppendString] = useState(false); // New state for the additional toggle

  const handleUpdate = () => {
    const updatedModality =
      modality === "Voicemail with music" || modality === "Voicemail"
        ? "voice_message"
        : modality;

    const updatedMusicType =
      modality === "Voicemail with music" && musicType === "Motivational"
        ? "Motivational.MP3"
        : musicType;

    const updatedDescription = appendString
      ? `${description} \n\nUse the following information about the user to make your response more personal: \n\n[calendar] [health] [memory]`
      : description;

    const userTaskExecuteAt = isUserTask
      ? `${executeDate}T${executeTime}:00`
      : {
          date: type === "weekly" ? dayOfWeek : daysAfterCreation,
          time: executeAt,
        };

    const updatedTask = {
      ...task,
      description: updatedDescription,
      execute_at: userTaskExecuteAt,
      type,
      editable,
      modality: updatedModality,
      musicType: modality === "Voicemail with music" ? musicType : "",
      musicType: updatedMusicType,
    };
    onUpdate(updatedTask);
    setUpdateSuccess(true); // Set success state to true
    setTimeout(() => setUpdateSuccess(false), 3000); // Hide the message after 3 seconds
  };

  return (
    <div className="bg-white p-6 shadow rounded-lg mb-4">
      <input
        type="text"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Reminder description"
        className="w-full p-2 mb-4 border rounded"
      />

      <select
        value={type}
        onChange={(e) => setType(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      >
        <option value="one-time">One-time</option>
        <option value="daily">Daily</option>
        <option value="weekly">Weekly</option>
        <option value="monthly">Monthly</option>
      </select>

      <select
        value={modality}
        onChange={(e) => setModality(e.target.value)}
        className="w-full p-2 mb-4 border rounded"
      >
        <option value="Text">Text Message (SMS)</option>
        <option value="Voicemail">Voice Message (SMS)</option>
        <option value="Voicemail with music">
          Voice Message with music (SMS)
        </option>
        <option value="phone_call">Phone call</option>
      </select>

      {modality === "Voicemail with music" && (
        <select
          value={musicType}
          onChange={(e) => setMusicType(e.target.value)} // Set music type to the selected value
          className="w-full p-2 mb-4 border rounded"
        >
          <option value="Motivational">Motivational</option>
        </select>
      )}

      <div className="flex flex-col md:flex-row justify-between">
        {isUserTask ? (
          <div className="mb-4 md:mb-0 md:mr-4 flex-1">
            <label className="block text-sm mb-3 mt-2 font-medium text-slate-700">
              Date for sending reminder
            </label>
            <input
              type="date"
              value={executeDate}
              onChange={(e) => setExecuteDate(e.target.value)}
              className="w-full p-2 border rounded"
            />
          </div>
        ) : (
          type !== "weekly" && (
            <div className="mb-4 md:mb-0 md:mr-4 flex-1">
              <label className="block text-sm mb-3 mt-2 font-medium text-slate-700">
                Days after account creation before sending first reminder
              </label>
              <input
                type="number"
                value={daysAfterCreation}
                onChange={(e) => setDaysAfterCreation(e.target.value)}
                placeholder="0"
                className="w-full p-2 border rounded"
              />
            </div>
          )
        )}

        {/* Conditionally render the "Day of the Week" select input */}
        {type === "weekly" && (
          <div className="mb-4 md:mb-0 md:mr-4 flex-1">
            <label className="block text-sm font-medium mb-3 mt-2 text-slate-700">
              Day of the week
              <span className="text-slate-400 text-sm font-medium">
                {" "}
                (Which day of the week the task should occur)
              </span>
            </label>
            <select
              value={dayOfWeek}
              onChange={(e) => setDayOfWeek(e.target.value)}
              className="w-full p-2 border rounded"
            >
              <option value="">Select Day of the Week</option>
              <option value="Sunday">Sunday</option>
              <option value="Monday">Monday</option>
              <option value="Tuesday">Tuesday</option>
              <option value="Wednesday">Wednesday</option>
              <option value="Thursday">Thursday</option>
              <option value="Friday">Friday</option>
              <option value="Saturday">Saturday</option>
            </select>
          </div>
        )}

        {/* Time of day input */}
        <div className="flex-1">
          <label className="block text-sm font-medium mb-3 mt-2 text-slate-700">
            Time of day
            <span className="text-slate-400 text-sm font-medium">
              {" "}
              (What time of day the reminder should be sent)
            </span>
          </label>
          <select
            value={executeAt}
            onChange={(e) => setExecuteAt(e.target.value)}
            className="w-full p-2 border rounded"
          >
            {Array.from({ length: 24 * 4 }, (_, i) => {
              const hours = Math.floor(i / 4);
              const minutes = (i % 4) * 15;
              const timeString = `${hours % 12 === 0 ? 12 : hours % 12}:${
                minutes < 10 ? "0" : ""
              }${minutes} ${hours < 12 ? "AM" : "PM"}`;
              return (
                <option key={i} value={timeString}>
                  {timeString}
                </option>
              );
            })}
          </select>
        </div>
      </div>
      <div className="flex items-center justify-between my-4">
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              id="toggleEditable"
              className="sr-only"
              checked={editable}
              onChange={(e) => setEditable(e.target.checked)} // Ensure this line is correct
            />
            <div
              className={`block w-14 h-8 rounded-full ${
                editable ? "bg-accent" : "bg-slate-600"
              }`}
            ></div>
            <div
              className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                editable ? "translate-x-6" : ""
              }`}
            ></div>{" "}
            {/* Add dynamic styling for the toggle position */}
          </div>
          <div className="flex items-start justify-center flex-col ml-3">
            <div className="text-sm font-medium text-slate-700">
              Allow coach to remove this reminder if not relevant anymore?
            </div>
            <div className="text-slate-400 text-sm font-medium">
              (Toggle ON if the coach can remove it, OFF if it should always
              run.)
            </div>
          </div>
        </label>
      </div>

      {/* New toggle for appending string to description */}
      <div className="flex items-center justify-between my-4">
        <label className="flex items-center cursor-pointer">
          <div className="relative">
            <input
              type="checkbox"
              id="toggleAppendString"
              className="sr-only"
              checked={appendString}
              onChange={(e) => setAppendString(e.target.checked)}
            />
            <div
              className={`block w-14 h-8 rounded-full ${
                appendString ? "bg-accent" : "bg-slate-600"
              }`}
            ></div>
            <div
              className={`dot absolute left-1 top-1 bg-white w-6 h-6 rounded-full transition ${
                appendString ? "translate-x-6" : ""
              }`}
            ></div>
          </div>
          <div className="flex items-start justify-center flex-col ml-3">
            <div className="text-sm font-medium text-slate-700">
              Include the user's memory, calendar, and health data as context
              for this reminder?
            </div>
            <div className="text-slate-400 text-sm font-medium">
              (Toggle ON to include the data.)
            </div>
          </div>
        </label>
      </div>

      <button
        onClick={() => {
          handleUpdate();
        }}
        className="bg-blue-100 text-blue-900 py-2 px-4 rounded font-medium hover:bg-blue-200 mr-3 mt-4"
      >
        {updateSuccess ? "Saved successfully!" : "Save reminder"}
      </button>
      <button
        onClick={() => handleRemove(task.task_id)}
        className="bg-red-100 text-red-900 hover:bg-red-200 font-medium py-2 px-4 rounded mt-4"
      >
        Remove reminder
      </button>
    </div>
  );
};

export default EditTask;
