import React from "react";
import MessageList from "./MessageList";
import TypingIndicator from "../../chat/TypingIndicator";
import InputField from "./InputField";
import SuggestedMessages from "../../chat/SuggestedMessages";
import axios from "axios";
import { CoachContext } from "../../../CoachContext";

const UserLogs = ({ conversation, user_id, update_conversation }) => {
  const { coachId } = React.useContext(CoachContext);
  const [userInput, setUserInput] = React.useState("");
  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  const sendMessage = async (message) => {
    setUserInput(message);

    const data = {
      body: message,
      coach_id: coachId,
      user_id: user_id,
    };

    try {
      const response = await axiosWithAuth().post("/api/coach/message", data);
      if (response.data.status === "success") {
        update_conversation({ role: "Noah", content: message });
      } else {
        console.error("Message failed to send:", response.data.message);
      }
    } catch (error) {
      console.error(error);
    }
  };

  const formattedConversation = conversation?.map((message) => {
    let role = message.role;
    let content = message.content;

    if (Array.isArray(role)) {
      role = role[1] === "agent" ? "assistant" : "user";
    }

    if (Array.isArray(content)) {
      content = content[1];
    }

    return { role, content };
  });

  return (
    <div className="overflow-auto mx-auto mt-5" style={{ maxHeight: "36rem" }}>
      <MessageList conversation={formattedConversation} />
      <InputField
        sendMessage={sendMessage}
        userInput={userInput}
        setUserInput={setUserInput}
      />
    </div>
  );
};

export default UserLogs;
