import React from 'react';
import { ArrowRight, Target, BookOpen, Users, Building2, CheckCircle, Menu, X, ChevronDown } from 'lucide-react';
import { Link } from 'react-router-dom';

const StrideLandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = React.useState(false);

  return (
    <div className="min-h-screen bg-white">
      {/* Navigation */}
      <nav className="fixed w-full bg-white/80 backdrop-blur-md z-50 border-b border-gray-100">
        <div className="container mx-auto px-6 py-4">
          <div className="flex items-center justify-between">
            <div className="text-2xl font-bold text-blue-600">stride</div>
            
            {/* Desktop Navigation */}
            <div className="hidden md:flex items-center gap-6">
              <button 
                className="text-gray-600 hover:text-gray-900 transition flex items-center gap-1"
                onMouseEnter={() => setIsDropdownOpen(true)}
                onMouseLeave={() => setIsDropdownOpen(false)}
              >
                Solutions
                <ChevronDown className="w-4 h-4" />
                {isDropdownOpen && (
                  <div className="absolute top-full mt-2 w-48 bg-white rounded-xl shadow-lg border border-gray-100">
                    <div className="p-2">
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">For Job Seekers</button>
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">For Career Changers</button>
                      <button className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-50 rounded-lg">For Companies</button>
                    </div>
                  </div>
                )}
              </button>
              <button className="text-gray-600 hover:text-gray-900 transition">Resources</button>
              <button className="text-gray-600 hover:text-gray-900 transition">Pricing</button>
              <button className="text-gray-600 hover:text-gray-900 transition">Enterprise</button>
              <div className="flex items-center gap-4 ml-6">
                <button className="text-gray-600 hover:text-gray-900 transition">Sign in</button>
                 <Link to="/career_planner">
                  <button className="bg-black text-white px-6 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition">
                  Try Free Assessment
                </button>
                </Link>
              </div>
            </div>

            {/* Mobile Menu Button */}
            <button 
              className="md:hidden"
              onClick={() => setIsMenuOpen(!isMenuOpen)}
            >
              {isMenuOpen ? <X className="w-6 h-6" /> : <Menu className="w-6 h-6" />}
            </button>
          </div>

          {/* Mobile Menu */}
          {isMenuOpen && (
            <div className="md:hidden pt-4 pb-6">
              <div className="flex flex-col space-y-4">
                <button className="text-gray-600 hover:text-gray-900 transition">Solutions</button>
                <button className="text-gray-600 hover:text-gray-900 transition">Resources</button>
                <button className="text-gray-600 hover:text-gray-900 transition">Pricing</button>
                <button className="text-gray-600 hover:text-gray-900 transition">Enterprise</button>
                 <Link to="/career_planner"> 
                <button className="text-gray-600 hover:text-gray-900 transition">Sign in</button>
                </Link>
                <Link to="/career_planner">
                  <button className="bg-black text-white px-6 py-2 rounded-full text-sm font-medium hover:bg-gray-800 transition">
                  Try Free Assessment
                </button>
                </Link>
                 
              </div>
            </div>
          )}
        </div>
      </nav>

      {/* Hero Section */}
      <header className="pt-32 pb-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-block bg-blue-50 text-blue-600 px-4 py-2 rounded-full text-sm font-medium mb-6">
              Now in Beta • Special Launch Pricing
            </div>
            <h1 className="text-5xl font-bold mb-6">Career Growth on Autopilot</h1>
            <p className="text-xl text-gray-600 mb-12">AI-powered career coaching that helps you land better jobs, develop new skills, and increase your market value</p>
            
            {/* Two Paths */}
            <div className="grid md:grid-cols-2 gap-6 max-w-2xl mx-auto">
              <div className="bg-white p-6 rounded-2xl border border-gray-100 shadow-sm hover:shadow-md transition duration-200">
                <div className="flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mx-auto mb-4">
                  <Users className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="font-semibold mb-2">For Individuals</h3>
                <p className="text-sm text-gray-600 mb-4">Accelerate your career growth and increase your market value</p>
                 <Link to="/career_planner"> 
                <button className="w-full bg-black text-white px-6 py-3 rounded-full text-sm font-medium hover:bg-gray-800 transition flex items-center justify-center gap-2">
                  Try Career Planner
                  <ArrowRight className="w-4 h-4" />
                  </button>
                  </Link>
              </div>

              <div className="bg-white p-6 rounded-2xl border border-gray-100 shadow-sm hover:shadow-md transition duration-200">
                <div className="flex items-center justify-center w-12 h-12 bg-blue-50 rounded-xl mx-auto mb-4">
                  <Building2 className="w-6 h-6 text-blue-600" />
                </div>
                <h3 className="font-semibold mb-2">For Companies</h3>
                <p className="text-sm text-gray-600 mb-4">Support your team's growth and manage career transitions</p>
                <button className="w-full bg-blue-600 text-white px-6 py-3 rounded-full text-sm font-medium hover:bg-blue-700 transition flex items-center justify-center gap-2">
                  Schedule Demo
                  <ArrowRight className="w-4 h-4" />
                </button>
              </div>
            </div>

            {/* Trusted By */}
            <div className="mt-16">
              <p className="text-sm text-gray-500 mb-6">Trusted by professionals from leading companies</p>
              <div className="flex flex-wrap justify-center items-center gap-8">
                <span className="text-gray-400 font-semibold">Google</span>
                <span className="text-gray-400 font-semibold">Microsoft</span>
                <span className="text-gray-400 font-semibold">Meta</span>
                <span className="text-gray-400 font-semibold">Apple</span>
              </div>
            </div>
          </div>
        </div>
      </header>

      {/* Stats */}
      <div className="border-y border-gray-100 bg-gray-50">
        <div className="container mx-auto px-6 py-12">
          <div className="grid md:grid-cols-3 gap-8 max-w-3xl mx-auto">
            <div className="bg-white p-6 rounded-2xl shadow-sm text-center">
              <div className="text-3xl font-bold mb-2 text-blue-600">40%</div>
              <div className="text-gray-600">Average Salary Increase</div>
            </div>
            <div className="bg-white p-6 rounded-2xl shadow-sm text-center">
              <div className="text-3xl font-bold mb-2 text-blue-600">14 Days</div>
              <div className="text-gray-600">Average Job Search Time</div>
            </div>
            <div className="bg-white p-6 rounded-2xl shadow-sm text-center">
              <div className="text-3xl font-bold mb-2 text-blue-600">92%</div>
              <div className="text-gray-600">Success Rate</div>
            </div>
          </div>
        </div>
      </div>

      {/* Features */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-3xl font-bold mb-4">Everything You Need to Accelerate Your Career</h2>
            <p className="text-gray-600">Comprehensive tools and guidance for your professional growth</p>
          </div>

          <div className="max-w-5xl mx-auto grid md:grid-cols-2 gap-8">
            <div className="bg-gray-50 p-8 rounded-2xl hover:shadow-md transition duration-200">
              <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                <Target className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-3">AI Career Coach</h3>
              <p className="text-gray-600">24/7 guidance and feedback on your career decisions, powered by advanced AI</p>
            </div>

            <div className="bg-gray-50 p-8 rounded-2xl hover:shadow-md transition duration-200">
              <div className="w-12 h-12 bg-blue-100 rounded-xl flex items-center justify-center mb-6">
                <BookOpen className="w-6 h-6 text-blue-600" />
              </div>
              <h3 className="text-xl font-semibold mb-3">Smart Job Matching</h3>
              <p className="text-gray-600">Automated job discovery and application based on your skills and goals</p>
            </div>
          </div>
        </div>
      </section>

      {/* Pricing */}
      <section className="py-20 bg-gray-50">
        <div className="container mx-auto px-6">
          <div className="max-w-xl mx-auto">
            <div className="bg-white rounded-3xl p-8 shadow-sm border border-gray-100">
              <div className="text-center mb-8">
                <h3 className="text-2xl font-bold mb-2">Career Growth Plan</h3>
                <div className="text-4xl font-bold mb-2">$9<span className="text-lg text-gray-600">/month</span></div>
                <p className="text-gray-600">Everything you need to accelerate your career</p>
              </div>

              <div className="space-y-4 mb-8">
                <div className="flex items-center gap-3">
                  <CheckCircle className="w-5 h-5 text-blue-600 flex-shrink-0" />
                  <span>24/7 AI Career Coach Access</span>
                </div>
                <div className="flex items-center gap-3">
                  <CheckCircle className="w-5 h-5 text-blue-600 flex-shrink-0" />
                  <span>Smart Job Matching</span>
                </div>
                <div className="flex items-center gap-3">
                  <CheckCircle className="w-5 h-5 text-blue-600 flex-shrink-0" />
                  <span>Career Analytics Dashboard</span>
                </div>
                <div className="flex items-center gap-3">
                  <CheckCircle className="w-5 h-5 text-blue-600 flex-shrink-0" />
                  <span>Personalized Learning Path</span>
                </div>
              </div>
               <Link to="/career_planner"> 
              <button className="w-full bg-black text-white py-4 rounded-full font-medium hover:bg-gray-800 transition">
                Start Free Assessment
                </button>
                </Link>
            </div>
          </div>
        </div>
      </section>

      {/* Final CTA */}
      <section className="py-20">
        <div className="container mx-auto px-6">
          <div className="max-w-3xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">Ready to Accelerate Your Career?</h2>

            <p className="text-gray-600 mb-8">Join thousands of professionals taking control of their career growth</p>
            <Link to="/career_planner"> 
            <button className="bg-black text-white px-8 py-4 rounded-full font-medium hover:bg-gray-800 transition flex items-center gap-2 mx-auto">
              Try Career Planner
              <ArrowRight className="w-4 h-4" />
              </button>
              </Link>
          </div>
        </div>
      </section>
    </div>
  );
};

export default StrideLandingPage;