import React, { useState, useEffect } from "react";
import axios from "axios";
import TouchpointHistory from "./TouchpointHistory";
import {
  ChevronRightIcon,
  PlusIcon,
  TrashIcon,
  ClockIcon,
} from "@heroicons/react/24/outline";

function Touchpoints({ userId }) {
  const [touchpoints, setTouchpoints] = useState([]);
  const [filteredTouchpoints, setFilteredTouchpoints] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [editingTouchpoint, setEditingTouchpoint] = useState(null);
  const [viewingHistoryId, setViewingHistoryId] = useState(null);
  const [selectedTouchpoint, setSelectedTouchpoint] = useState(null);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [showAllTouchpoints, setShowAllTouchpoints] = useState(false);
  const ITEMS_PER_PAGE = 5;
  const DAYS_TO_HIDE = 21; // 3 weeks
  const [formData, setFormData] = useState({
    description: "",
    type: "once",
    modality: "text",
    execute_at: "",
    status: "active",
  });
  const [loadingHistory, setLoadingHistory] = useState(false);
  const [touchpointHistory, setTouchpointHistory] = useState([]);
  const [visibleHistoryItems, setVisibleHistoryItems] = useState(5); // Number of history items to show initially

  useEffect(() => {
    fetchTouchpoints();
  }, [userId, page]);

  useEffect(() => {
    // Filter touchpoints to hide older, non-active ones
    if (touchpoints.length > 0) {
      filterTouchpoints();
    }
  }, [touchpoints, showAllTouchpoints]);

  const filterTouchpoints = () => {
    if (showAllTouchpoints) {
      setFilteredTouchpoints(touchpoints);
      return;
    }

    const currentDate = new Date();
    const cutoffDate = new Date();
    cutoffDate.setDate(currentDate.getDate() - DAYS_TO_HIDE);

    const filtered = touchpoints.filter((touchpoint) => {
      // Check if touchpoint is active
      if (
        touchpoint.status !== "completed" &&
        touchpoint.status !== "cancelled" &&
        touchpoint.status !== "skipped"
      ) {
        return true;
      }

      // Check if touchpoint is recent enough
      const executeDate = new Date(
        touchpoint.next_execution || touchpoint.created_at
      );
      return executeDate >= cutoffDate;
    });

    setFilteredTouchpoints(filtered);
  };

  const fetchTouchpoints = async () => {
    try {
      setLoading(true);
      const response = await axios.get(`/api/touchpoints/user/${userId}`);

      // Implement client-side pagination
      const allTouchpoints = response.data;
      const startIndex = 0;
      const endIndex = page * ITEMS_PER_PAGE;
      const paginatedTouchpoints = allTouchpoints.slice(startIndex, endIndex);

      setTouchpoints(paginatedTouchpoints);
      setHasMore(endIndex < allTouchpoints.length);
      setLoading(false);
    } catch (err) {
      console.error("Error fetching touchpoints:", err);
      setError("Failed to load touchpoints");
      setLoading(false);
    }
  };

  const loadMore = () => {
    setPage((prev) => prev + 1);
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const resetForm = () => {
    setFormData({
      description: "",
      type: "once",
      modality: "text",
      execute_at: "",
      status: "active",
    });
    setEditingTouchpoint(null);
    setShowForm(false);
    setTouchpointHistory([]); // Clear history when closing the form
    setVisibleHistoryItems(5); // Reset visible history items
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const payload = {
        ...formData,
        user_id: userId,
      };

      if (editingTouchpoint) {
        // Update existing touchpoint
        await axios.put(`/api/touchpoints/${editingTouchpoint.id}`, payload);
      } else {
        // Create new touchpoint
        await axios.post("/api/touchpoints/", payload);
      }

      resetForm();
      setPage(1); // Reset to first page
      fetchTouchpoints();
    } catch (err) {
      console.error("Error saving touchpoint:", err);
      setError("Failed to save touchpoint");
    }
  };

  const handleEdit = (touchpoint) => {
    setEditingTouchpoint(touchpoint);
    setFormData({
      description: touchpoint.description,
      type: touchpoint.type,
      modality: touchpoint.modality,
      execute_at: new Date(touchpoint.next_execution)
        .toISOString()
        .slice(0, 16),
      status: touchpoint.status || "active",
    });
    setShowForm(true);

    // Fetch history when opening edit form
    fetchTouchpointHistory(touchpoint.id);
  };

  const handleDelete = async (id) => {
    try {
      await axios.delete(`/api/touchpoints/${id}`);
      setSelectedTouchpoint(null);
      resetForm();
      fetchTouchpoints();
    } catch (err) {
      console.error("Error deleting touchpoint:", err);
      setError("Failed to delete touchpoint");
    }
  };

  const handleExecute = async (id) => {
    try {
      await axios.post(`/api/touchpoints/${id}/execute`);
      fetchTouchpoints();
    } catch (err) {
      console.error("Error executing touchpoint:", err);
      setError("Failed to execute touchpoint");
    }
  };

  const handleViewHistory = (id) => {
    setViewingHistoryId(id);
  };

  const closeHistoryModal = () => {
    setViewingHistoryId(null);
  };

  const fetchTouchpointHistory = async (touchpointId) => {
    try {
      setLoadingHistory(true);
      const response = await axios.get(
        `/api/touchpoints/${touchpointId}/history`
      );

      // Handle different API response structures
      let historyArray = [];
      if (response.data && Array.isArray(response.data)) {
        historyArray = response.data;
      } else if (
        response.data &&
        response.data.history &&
        Array.isArray(response.data.history)
      ) {
        historyArray = response.data.history;
      } else if (
        response.data &&
        response.data.execute_history &&
        Array.isArray(response.data.execute_history)
      ) {
        historyArray = response.data.execute_history;
      }

      setTouchpointHistory(historyArray);
    } catch (error) {
      console.error("Error fetching touchpoint history:", error);
      setTouchpointHistory([]);
    } finally {
      setLoadingHistory(false);
    }
  };

  const handleSelectTouchpoint = (touchpoint) => {
    if (editingTouchpoint?.id === touchpoint.id) {
      // If we're already editing this touchpoint, close the form
      resetForm();
    } else {
      // Otherwise, open the form for this touchpoint
      handleEdit(touchpoint);
    }
  };

  // Helper function to get status badge styling
  const getStatusBadge = (status) => {
    if (status === "completed") {
      return (
        <span className="inline-flex items-center rounded-full bg-emerald-950 px-2 py-1 text-xs font-medium text-emerald-400 ring-1 ring-inset ring-emerald-800/30">
          completed
        </span>
      );
    } else if (status === "skipped") {
      return (
        <span className="inline-flex items-center rounded-full bg-indigo-950 px-2 py-1 text-xs font-medium text-indigo-400 ring-1 ring-inset ring-indigo-800/30">
          skipped
        </span>
      );
    } else {
      return (
        <span className="inline-flex items-center rounded-full bg-blue-950 px-2 py-1 text-xs font-medium text-blue-400 ring-1 ring-inset ring-blue-800/30">
          active
        </span>
      );
    }
  };

  // Helper function to format modality for display
  const formatModality = (modality) => {
    switch (modality) {
      case "text":
        return "Text";
      case "voice_message":
        return "Voicemail";
      case "phone_call":
        return "Call";
      case "video":
        return "Video";
      default:
        return modality.replace("_", " ");
    }
  };

  // Helper function to format date in MMM DD HH:MM format
  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];
    const month = months[date.getMonth()];
    const day = date.getDate();
    let hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const ampm = hours >= 12 ? "PM" : "AM";
    hours = hours % 12;
    hours = hours ? hours : 12; // Convert 0 to 12

    return `${month} ${day}, ${hours}:${minutes} ${ampm}`;
  };

  // Helper to get icon for modality
  const getModalityIcon = (modality) => {
    switch (modality) {
      case "text":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        );
      case "voice_message":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
            <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
            <line x1="12" y1="19" x2="12" y2="23"></line>
            <line x1="8" y1="23" x2="16" y2="23"></line>
          </svg>
        );
      case "phone_call":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
        );
      case "video":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polygon points="23 7 16 12 23 17 23 7"></polygon>
            <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
          </svg>
        );
      default:
        return null;
    }
  };

  const loadMoreHistory = () => {
    setVisibleHistoryItems((prev) => prev + 5);
  };

  if (loading && page === 1) {
    return (
      <div className="rounded-lg border border-gray-800 py-6">
        <div className="flex flex-col items-center justify-center space-y-3">
          <div className="animate-spin h-5 w-5 rounded-full border-2 border-t-blue-500 border-b-transparent"></div>
          <div className="text-sm text-gray-400">Loading touchpoints...</div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="rounded-lg border border-red-900/20 bg-red-950/10 px-4 py-3 text-sm text-red-400">
        <div className="flex items-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4 mr-2 text-red-500"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <circle cx="12" cy="12" r="10"></circle>
            <line x1="12" y1="8" x2="12" y2="12"></line>
            <line x1="12" y1="16" x2="12.01" y2="16"></line>
          </svg>
          {error}
        </div>
      </div>
    );
  }

  const hiddenTouchpointsCount =
    touchpoints.length - filteredTouchpoints.length;

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-xl text-slate-400 font-bold text-left mb-0">
          Your touchpoints
        </h3>
        <button
          onClick={() => {
            setEditingTouchpoint(null);
            setShowForm(!showForm);
          }}
          className="relative inline-flex items-center justify-between px-4 py-3 rounded bg-slate-800 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <div className="flex items-center">
            <PlusIcon className="h-5 w-5 mr-2" />
            <span>Add Touchpoint</span>
          </div>
          <ChevronRightIcon className="h-5 w-5 ml-2" />
        </button>
      </div>

      <div className="rounded-lg border border-gray-800 overflow-hidden">
        <div className="border-b border-gray-800 px-4 py-3 flex justify-between items-center">
          <h3 className="text-sm font-medium text-white mb-0">Touchpoints</h3>
          <div className="flex items-center space-x-3">
            {hiddenTouchpointsCount > 0 && (
              <button
                onClick={() => setShowAllTouchpoints(!showAllTouchpoints)}
                className="text-xs text-slate-400 hover:text-white"
              >
                {showAllTouchpoints
                  ? "Hide old touchpoints"
                  : `Show ${hiddenTouchpointsCount} hidden touchpoint${
                      hiddenTouchpointsCount === 1 ? "" : "s"
                    }`}
              </button>
            )}
            <span className="text-xs text-gray-500">
              {filteredTouchpoints.length} touchpoints
            </span>
          </div>
        </div>

        {/* Show create form at the top when adding a new touchpoint */}
        {showForm && !editingTouchpoint && (
          <div className="border-b border-gray-800 bg-slate-900">
            <div className="border-b border-gray-800 px-4 py-3 flex justify-between items-center">
              <h3 className="text-sm font-medium text-white mb-0">
                Create New Touchpoint
              </h3>
              <button
                onClick={resetForm}
                className="text-slate-400 hover:text-white"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                >
                  <line x1="18" y1="6" x2="6" y2="18"></line>
                  <line x1="6" y1="6" x2="18" y2="18"></line>
                </svg>
              </button>
            </div>

            <form onSubmit={handleSubmit} className="p-4">
              <div className="mb-4">
                <label className="block text-xs font-medium text-gray-400 mb-1.5">
                  Description
                </label>
                <input
                  type="text"
                  name="description"
                  value={formData.description}
                  onChange={handleInputChange}
                  className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                  placeholder="Enter a description for your touchpoint"
                  required
                />
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-xs font-medium text-gray-400 mb-1.5">
                    Type
                  </label>
                  <select
                    name="type"
                    value={formData.type}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                      backgroundPosition: `right 0.5rem center`,
                      backgroundRepeat: `no-repeat`,
                      backgroundSize: `1.5em 1.5em`,
                      paddingRight: `2.5rem`,
                    }}
                  >
                    <option value="once">Once</option>
                    <option value="daily">Daily</option>
                    <option value="weekly">Weekly</option>
                    <option value="monthly">Monthly</option>
                  </select>
                </div>

                <div>
                  <label className="block text-xs font-medium text-gray-400 mb-1.5">
                    Communication Method
                  </label>
                  <select
                    name="modality"
                    value={formData.modality}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                      backgroundPosition: `right 0.5rem center`,
                      backgroundRepeat: `no-repeat`,
                      backgroundSize: `1.5em 1.5em`,
                      paddingRight: `2.5rem`,
                    }}
                  >
                    <option value="text">Text Message</option>
                    <option value="voice_message">Voice Message</option>
                    <option value="phone_call">Phone Call</option>
                    <option value="video">Video Call</option>
                  </select>
                </div>
              </div>

              <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                <div>
                  <label className="block text-xs font-medium text-gray-400 mb-1.5">
                    Execute At
                  </label>
                  <input
                    type="datetime-local"
                    name="execute_at"
                    value={formData.execute_at}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                    required
                  />
                </div>

                <div>
                  <label className="block text-xs font-medium text-gray-400 mb-1.5">
                    Status
                  </label>
                  <select
                    name="status"
                    value={formData.status}
                    onChange={handleInputChange}
                    className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                    style={{
                      backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                      backgroundPosition: `right 0.5rem center`,
                      backgroundRepeat: `no-repeat`,
                      backgroundSize: `1.5em 1.5em`,
                      paddingRight: `2.5rem`,
                    }}
                  >
                    <option value="active">Active</option>
                    <option value="completed">Completed</option>
                    <option value="skipped">Skipped</option>
                  </select>
                </div>
              </div>

              <div className="flex justify-end space-x-3">
                <button
                  type="button"
                  onClick={resetForm}
                  className="px-3 py-2 border border-gray-700 text-white hover:bg-slate-800 rounded-md text-sm"
                >
                  Cancel
                </button>
                <button
                  type="submit"
                  className="px-3 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-md text-sm"
                >
                  Create
                </button>
              </div>
            </form>
          </div>
        )}

        {filteredTouchpoints.length === 0 ? (
          <div className="p-12 text-center">
            <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-slate-800 mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6 text-gray-400"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M8 10h.01M12 10h.01M16 10h.01M9 16H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-5l-5 5v-5z"
                />
              </svg>
            </div>
            <h3 className="text-sm font-medium text-white mb-1">
              No touchpoints scheduled
            </h3>
            <p className="text-xs text-gray-500 max-w-xs mx-auto mb-4">
              Create your first touchpoint to stay connected through automated
              interactions.
            </p>
            <button
              onClick={() => {
                setEditingTouchpoint(null);
                setShowForm(true);
              }}
              className="inline-flex items-center px-3 py-2 bg-slate-800 text-sm text-white hover:bg-slate-700 rounded-md"
            >
              <PlusIcon className="h-4 w-4 mr-2" />
              Add Your First Touchpoint
            </button>
          </div>
        ) : (
          <div>
            <div className="divide-y divide-gray-800">
              {filteredTouchpoints.map((touchpoint) => (
                <React.Fragment key={touchpoint.id}>
                  <div
                    onClick={() => handleSelectTouchpoint(touchpoint)}
                    className="p-4 hover:bg-slate-800/50 transition-colors cursor-pointer flex flex-row items-center justify-between"
                  >
                    <div className="flex items-center space-x-3 min-w-0 max-w-[60%]">
                      {getStatusBadge(touchpoint.status)}
                      <span className="text-sm text-white truncate">
                        {touchpoint.description}
                      </span>
                    </div>

                    <div className="flex items-center gap-3 flex-shrink-0">
                      <div className="flex items-center space-x-1.5 text-xs text-slate-300 px-2.5 py-1.5 rounded-full border border-slate-700/50">
                        {getModalityIcon(touchpoint.modality)}
                        <span className="truncate max-w-[80px]">
                          {formatModality(touchpoint.modality)}
                        </span>
                      </div>

                      {touchpoint.next_execution && (
                        <div className="flex items-center space-x-1.5 text-xs text-slate-300 px-2.5 py-1.5 rounded-full border border-slate-700/50">
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-3.5 w-3.5 flex-shrink-0"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <circle cx="12" cy="12" r="10"></circle>
                            <polyline points="12 6 12 12 16 14"></polyline>
                          </svg>
                          <span className="truncate max-w-[120px]">
                            {formatDate(touchpoint.next_execution)}
                          </span>
                        </div>
                      )}

                      <div className="w-px h-4 bg-slate-700 mx-1"></div>

                      <button
                        onClick={(e) => {
                          e.stopPropagation();
                          handleEdit(touchpoint);
                        }}
                        className="text-gray-500 hover:text-white p-1 rounded-full hover:bg-slate-700 flex-shrink-0"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-4 w-4"
                          viewBox="0 0 24 24"
                          fill="none"
                          stroke="currentColor"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        >
                          <path d="M11 4H4a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h14a2 2 0 0 0 2-2v-7"></path>
                          <path d="M18.5 2.5a2.121 2.121 0 0 1 3 3L12 15l-4 1 1-4 9.5-9.5z"></path>
                        </svg>
                      </button>
                    </div>
                  </div>

                  {/* Edit form displayed beneath the current touchpoint */}
                  {showForm && editingTouchpoint?.id === touchpoint.id && (
                    <div className="border-t border-gray-800 bg-slate-900">
                      <div className="border-b border-gray-800 px-4 py-3 flex justify-between items-center">
                        <h3 className="text-sm font-medium text-white mb-0">
                          Edit Touchpoint
                        </h3>
                        <button
                          onClick={resetForm}
                          className="text-slate-400 hover:text-white"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-4 w-4"
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                          >
                            <line x1="18" y1="6" x2="6" y2="18"></line>
                            <line x1="6" y1="6" x2="18" y2="18"></line>
                          </svg>
                        </button>
                      </div>

                      <form onSubmit={handleSubmit} className="p-4">
                        <div className="mb-4">
                          <label className="block text-xs font-medium text-gray-400 mb-1.5">
                            Description
                          </label>
                          <input
                            type="text"
                            name="description"
                            value={formData.description}
                            onChange={handleInputChange}
                            className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                            placeholder="Enter a description for your touchpoint"
                            required
                          />
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          <div>
                            <label className="block text-xs font-medium text-gray-400 mb-1.5">
                              Type
                            </label>
                            <select
                              name="type"
                              value={formData.type}
                              onChange={handleInputChange}
                              className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                              style={{
                                backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                                backgroundPosition: `right 0.5rem center`,
                                backgroundRepeat: `no-repeat`,
                                backgroundSize: `1.5em 1.5em`,
                                paddingRight: `2.5rem`,
                              }}
                            >
                              <option value="once">Once</option>
                              <option value="daily">Daily</option>
                              <option value="weekly">Weekly</option>
                              <option value="monthly">Monthly</option>
                            </select>
                          </div>

                          <div>
                            <label className="block text-xs font-medium text-gray-400 mb-1.5">
                              Communication Method
                            </label>
                            <select
                              name="modality"
                              value={formData.modality}
                              onChange={handleInputChange}
                              className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                              style={{
                                backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                                backgroundPosition: `right 0.5rem center`,
                                backgroundRepeat: `no-repeat`,
                                backgroundSize: `1.5em 1.5em`,
                                paddingRight: `2.5rem`,
                              }}
                            >
                              <option value="text">Text Message</option>
                              <option value="voice_message">
                                Voice Message
                              </option>
                              <option value="phone_call">Phone Call</option>
                              <option value="video">Video Call</option>
                            </select>
                          </div>
                        </div>

                        <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
                          <div>
                            <label className="block text-xs font-medium text-gray-400 mb-1.5">
                              Execute At
                            </label>
                            <input
                              type="datetime-local"
                              name="execute_at"
                              value={formData.execute_at}
                              onChange={handleInputChange}
                              className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500"
                              required
                            />
                          </div>

                          <div>
                            <label className="block text-xs font-medium text-gray-400 mb-1.5">
                              Status
                            </label>
                            <select
                              name="status"
                              value={formData.status}
                              onChange={handleInputChange}
                              className="w-full px-3 py-2 bg-slate-950 border border-gray-800 text-white rounded-md text-sm focus:outline-none focus:ring-1 focus:ring-blue-500 focus:border-blue-500 appearance-none"
                              style={{
                                backgroundImage: `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' fill='none' viewBox='0 0 20 20'%3e%3cpath stroke='%236b7280' stroke-linecap='round' stroke-linejoin='round' stroke-width='1.5' d='M6 8l4 4 4-4'/%3e%3c/svg%3e")`,
                                backgroundPosition: `right 0.5rem center`,
                                backgroundRepeat: `no-repeat`,
                                backgroundSize: `1.5em 1.5em`,
                                paddingRight: `2.5rem`,
                              }}
                            >
                              <option value="active">Active</option>
                              <option value="completed">Completed</option>
                              <option value="skipped">Skipped</option>
                            </select>
                          </div>
                        </div>

                        <div className="flex justify-between items-center">
                          <button
                            type="button"
                            onClick={() => handleDelete(touchpoint.id)}
                            className="flex items-center px-3 py-2 border border-red-900/30 bg-red-950/20 text-red-400 hover:bg-red-900/30 rounded-md text-sm"
                          >
                            <TrashIcon className="h-4 w-4 mr-2" />
                            Delete
                          </button>

                          <div className="flex space-x-3">
                            <button
                              type="button"
                              onClick={resetForm}
                              className="px-3 py-2 border border-gray-700 text-white hover:bg-slate-800 rounded-md text-sm"
                            >
                              Cancel
                            </button>
                            <button
                              type="submit"
                              className="px-3 py-2 bg-blue-600 text-white hover:bg-blue-700 rounded-md text-sm"
                            >
                              Update
                            </button>
                          </div>
                        </div>
                      </form>

                      {/* Only show history section when there's actual history data */}
                      {touchpointHistory && touchpointHistory.length > 0 && (
                        <div className="border-t border-gray-800 bg-slate-900 px-4 py-4">
                          <div className="flex items-center mb-3">
                            <ClockIcon className="h-4 w-4 text-slate-400 mr-2" />
                            <h4 className="text-sm font-medium text-white mb-0">
                              History
                            </h4>
                          </div>

                          {loadingHistory ? (
                            <div className="flex justify-center py-3">
                              <div className="animate-spin h-5 w-5 rounded-full border-2 border-t-blue-500 border-b-transparent"></div>
                            </div>
                          ) : (
                            <div className="space-y-2 max-h-64 overflow-y-auto">
                              {touchpointHistory
                                .slice(0, visibleHistoryItems)
                                .map((historyItem, index) => (
                                  <div
                                    key={historyItem.id || index}
                                    className="bg-slate-950 rounded-md p-3 border border-slate-800"
                                  >
                                    <div className="flex justify-between items-start mb-2">
                                      <div className="flex items-center space-x-2">
                                        <span
                                          className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium 
                                        ${
                                          historyItem.status === "completed"
                                            ? "bg-emerald-950 text-emerald-400 ring-1 ring-inset ring-emerald-800/30"
                                            : historyItem.status === "failed"
                                            ? "bg-red-950 text-red-400 ring-1 ring-inset ring-red-800/30"
                                            : "bg-blue-950 text-blue-400 ring-1 ring-inset ring-blue-800/30"
                                        }`}
                                        >
                                          {historyItem.status || "unknown"}
                                        </span>
                                        <span
                                          className={`inline-flex items-center rounded-full px-2 py-1 text-xs font-medium
                                        ${
                                          historyItem.success
                                            ? "bg-emerald-950 text-emerald-400 ring-1 ring-inset ring-emerald-800/30"
                                            : "bg-red-950 text-red-400 ring-1 ring-inset ring-red-800/30"
                                        }`}
                                        >
                                          {historyItem.success
                                            ? "success"
                                            : "failed"}
                                        </span>
                                      </div>
                                      <span className="text-xs text-slate-400 mb-0">
                                        {historyItem.executed_at
                                          ? formatDate(historyItem.executed_at)
                                          : "No date"}
                                      </span>
                                    </div>

                                    {historyItem.notes && (
                                      <p className="text-sm text-slate-300 mt-1 mb-0">
                                        {historyItem.notes}
                                      </p>
                                    )}

                                    {historyItem.content && (
                                      <div className="mt-2 p-2 rounded bg-slate-900 border border-slate-700">
                                        <p className="text-xs text-slate-300 mb-0">
                                          {historyItem.content}
                                        </p>
                                      </div>
                                    )}

                                    {historyItem.error && (
                                      <div className="mt-2 p-2 rounded bg-red-950/20 border border-red-900/30">
                                        <p className="text-xs text-red-400 mb-0">
                                          {historyItem.error}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                ))}

                              {touchpointHistory.length >
                                visibleHistoryItems && (
                                <div className="text-center pt-2">
                                  <button
                                    onClick={loadMoreHistory}
                                    className="text-xs text-blue-500 hover:text-blue-400"
                                  >
                                    Load more history
                                  </button>
                                </div>
                              )}
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  )}
                </React.Fragment>
              ))}
            </div>

            {hasMore && (
              <div className="border-t border-gray-800 px-4 py-3 text-center">
                <button
                  onClick={loadMore}
                  className="text-xs text-blue-500 hover:text-blue-400 flex items-center justify-center mx-auto"
                  disabled={loading}
                >
                  {loading ? (
                    <span className="flex items-center">
                      <svg
                        className="animate-spin mr-2 h-3 w-3 text-white"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 24 24"
                      >
                        <circle
                          className="opacity-25"
                          cx="12"
                          cy="12"
                          r="10"
                          stroke="currentColor"
                          strokeWidth="4"
                        ></circle>
                        <path
                          className="opacity-75"
                          fill="currentColor"
                          d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                        ></path>
                      </svg>
                      Loading...
                    </span>
                  ) : (
                    "Load more touchpoints"
                  )}
                </button>
              </div>
            )}
          </div>
        )}
      </div>

      {viewingHistoryId && (
        <TouchpointHistory
          touchpointId={viewingHistoryId}
          onClose={closeHistoryModal}
        />
      )}
    </div>
  );
}

export default Touchpoints;
