import React from "react";
import { motion } from "framer-motion";
import ContinueButton from "../components/ContinueButton";
import { primary, text, border } from "../theme/variables";
import "../theme/theme.css";

const IntroScreen = ({ onUpdate, content }) => {
  return (
    <motion.div
      className="flex flex-col items-center justify-center h-full w-full relative overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <div className="relative z-10 flex flex-col items-center px-6 max-w-md">
        {/* Better visual logo */}
        <motion.div
          className="mb-8 relative"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.8 }}
        >
          <div className="w-14 h-14 flex items-center justify-center relative">
            <div
              className="absolute inset-0 rounded-full border"
              style={{
                backgroundColor: `${primary[500]}1A`, // 10% opacity
                borderColor: `${primary[400]}4D`, // 30% opacity
              }}
            ></div>
            <svg
              width="28"
              height="28"
              viewBox="0 0 24 24"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z"
                stroke={primary[400]}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
              <path
                d="M12 6V12L16 14"
                stroke={primary[400]}
                strokeWidth="1.5"
                strokeLinecap="round"
                strokeLinejoin="round"
              />
            </svg>
          </div>
        </motion.div>

        {/* Main message - more compelling with immediate value proposition */}
        <motion.h1
          className="text-3xl font-medium text-center mb-3"
          style={{ color: text.white }}
          initial={{ opacity: 0, y: 10 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {content.title}
        </motion.h1>

        {/* Brief description with stronger call to action */}
        <motion.p
          className="text-lg text-center mb-20"
          style={{ color: `${primary[100]}CC` }} // 80% opacity
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.4, duration: 0.5 }}
        >
          {content.description}
        </motion.p>
      </div>

      {/* Keep the I'M READY button */}
      <ContinueButton
        isLastStep={false}
        handleContinue={() => onUpdate({})}
        customText="I'M READY"
      />
    </motion.div>
  );
};

export default IntroScreen;
