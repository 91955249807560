// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editable-input {
  line-height: 1.5; /* Example line height, adjust as needed */
  height: 1.5em; /* Twice the line height for two lines */
  overflow-y: hidden; /* Hide the vertical scrollbar */
}

.editable-input.empty:not(:focus)::before {
  visibility: visible;
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/InputField.css"],"names":[],"mappings":"AAAA;EACE,gBAAgB,EAAE,0CAA0C;EAC5D,aAAa,EAAE,wCAAwC;EACvD,kBAAkB,EAAE,gCAAgC;AACtD;;AAEA;EACE,mBAAmB;AACrB","sourcesContent":[".editable-input {\n  line-height: 1.5; /* Example line height, adjust as needed */\n  height: 1.5em; /* Twice the line height for two lines */\n  overflow-y: hidden; /* Hide the vertical scrollbar */\n}\n\n.editable-input.empty:not(:focus)::before {\n  visibility: visible;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
