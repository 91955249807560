import React, { useState } from "react";
import axios from "axios";

export default function AdminCoachData() {
  const [data, setData] = useState(null);
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [selectedCoach, setSelectedCoach] = useState(null);
  const [selectedTeam, setSelectedTeam] = useState(null);

  const fetchData = async () => {
    setLoading(true);
    try {
      const apiKey = localStorage.getItem("adminApiKey");
      const response = await axios.get("/api/admin/all_coach_data", {
        headers: { "Api-Key": apiKey },
      });
      setData(response.data);
      setError("");
    } catch (err) {
      setError("Failed to fetch coach data.");
      setData(null);
    } finally {
      setLoading(false);
    }
  };

  // Fetch data on component mount
  React.useEffect(() => {
    fetchData();
  }, []);

  if (loading) {
    return (
      <div className="h-screen flex items-center justify-center">
        <svg
          className="animate-spin h-8 w-8 text-blue-500"
          xmlns="http://www.w3.org/2000/svg"
          fill="none"
          viewBox="0 0 24 24"
        >
          <circle
            className="opacity-25"
            cx="12"
            cy="12"
            r="10"
            stroke="currentColor"
            strokeWidth="4"
          ></circle>
          <path
            className="opacity-75"
            fill="currentColor"
            d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
          ></path>
        </svg>
      </div>
    );
  }

  if (error) {
    return (
      <div className="h-full flex items-center justify-center">
        <div className="bg-red-500 bg-opacity-10 border border-red-400 rounded p-4">
          <p className="text-red-400">{error}</p>
        </div>
      </div>
    );
  }

  if (!data) {
    return (
      <div className="h-full flex items-center justify-center">
        <p className="text-gray-400">No data available</p>
      </div>
    );
  }

  return (
    <div className="flex w-full h-full">
      {/* Sidebar for teams */}
      <div className="w-1/4 bg-slate-900 p-4 overflow-y-auto">
        <h1 className="text-2xl font-bold mb-4 text-white">Teams</h1>
        {data.map((team, index) => (
          <div key={index} className="mb-4">
            <button
              className={`flex items-center space-x-4 w-full text-left p-2 rounded ${
                selectedTeam === index
                  ? "bg-slate-700"
                  : "bg-slate-800 hover:bg-slate-700"
              }`}
              onClick={() =>
                setSelectedTeam(selectedTeam === index ? null : index)
              }
            >
              {team.image ? (
                <img
                  src={team.image}
                  alt={team.name}
                  className="w-10 h-10 rounded-full"
                />
              ) : (
                <div className="w-10 h-10 rounded-full bg-gradient-to-br from-lightBlue to-darkBlue"></div>
              )}
              <span>{team.name}</span>
            </button>
          </div>
        ))}
      </div>

      {/* Main content area for coaches */}
      <div className="w-1/2 p-4 overflow-y-auto">
        {selectedTeam !== null && (
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            {data[selectedTeam].coaches.map((coach, coachIndex) => (
              <div
                key={coachIndex}
                className={`bg-slate-800 rounded-lg p-4 cursor-pointer ${
                  selectedCoach === coach ? "ring-2 ring-blue-500" : ""
                }`}
                onClick={() =>
                  setSelectedCoach(selectedCoach === coach ? null : coach)
                }
              >
                <div className="flex items-center space-x-4 mb-4">
                  <img
                    src={coach.picture}
                    alt={coach.name}
                    className="w-16 h-16 rounded-full"
                  />
                  <div>
                    <h3 className="text-xl font-semibold text-white">
                      {coach.name}
                    </h3>
                    <p className="text-gray-400">{coach.email}</p>
                  </div>
                </div>
              </div>
            ))}
          </div>
        )}
      </div>

      {/* Actions sidebar */}
      <div className="w-1/4 bg-slate-900 p-4 overflow-y-auto">
        {selectedCoach && (
          <div>
            <h2 className="text-2xl font-bold mb-4 text-white">
              {selectedCoach.name}'s Actions
            </h2>
            {selectedCoach.actions && selectedCoach.actions.length > 0 ? (
              <ul className="space-y-2 list-none p-0 max-h-96 overflow-y-auto">
                {selectedCoach.actions
                  .sort((a, b) => new Date(b.timestamp) - new Date(a.timestamp))
                  .map((action, index) => (
                    <li key={index} className="bg-slate-800 rounded p-2">
                      <p className="text-white mb-1">{action.action}</p>
                      <p className="text-sm text-gray-400 mb-0">
                        {new Date(action.timestamp).toLocaleString()}
                      </p>
                    </li>
                  ))}
              </ul>
            ) : (
              <p className="text-gray-400">
                No actions recorded for this coach.
              </p>
            )}
          </div>
        )}
      </div>
    </div>
  );
}
