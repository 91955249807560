import React, { useState, useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import Coach from "./components/chat/Coach";
import AdminCoach from "./components/chat/AdminCoach";
import PrivacyPolicy from "./components/pages/PrivacyPolicy";
import DeleteAccount from "./components/pages/DeleteAccount";
import StaticLanding from "./components/pages/StaticLanding";
import ConversationalLanding from "./components/pages/ConversationalLanding";
import UserInitializationHandler from "./components/handlers/UserInitializationHandler";
import ResolutionBuilder from "./components/new-years/ResolutionHelper";
import Mixpanel from "mixpanel-browser";
import SalesLanding from "./components/sales-agent/SalesLanding";
import Science from "./components/pages/Science";
import BetaLanding from "./components/pages/BetaLanding";
import PurposeAssessment from "./purpose-assessment/PurposeAssessment";
import Ground from "./components/ground/Ground";
import Connect from "./components/Connect";
import TextingConsent from "./components/Texting";
import Landing from "./components/sales-agent/Landing";
import CoachLanding from "./components/coach/landing/BasicLanding";
import CoachRoutes from "./CoachRoutes";
import Subscribe from "./components/Subscribe";
import AdminData from "./components/admin/AdminData";
import StrideLandingPage from "./stride/landing";
import CareerPlanner from "./stride/planning_tool";
import CareerDashboard from "./stride/user_dashboard";
import PanelLanding from "./panel/landing";

 
function App() {
  Mixpanel.init("d724d0d0045430023cc58dd17086256b", {
    track_pageview: true,
  });
  Mixpanel.set_config({ persistence: "localStorage" });
  const [userId, setUserId] = useState(localStorage.getItem("userId") || false);
  const [referrerId, setReferrerId] = useState(
    localStorage.getItem("referrerId") || false
  );

  useEffect(() => {
    if (userId) {
      localStorage.setItem("userId", userId);
      Mixpanel.identify(userId);
    }
    Mixpanel.track("Page View", { page: "app" });
    if (referrerId) localStorage.setItem("referrerId", referrerId);
  }, [userId, referrerId]);

  const handleReferrerUpdate = (newReferrerId) => {
    setReferrerId(newReferrerId);
  };

  return (
    <Router>
      <Routes>
        <Route path="/connect/:phone" element={<Coach userId={userId} />} />

        <Route
          path="/admin/connect/:phone"
          element={<AdminCoach userId={userId} />}
        />

        <Route path="/texting" element={<TextingConsent />} />

        {/* Additional Routes */}
        <Route
          path="/privacy-policy"
          element={<PrivacyPolicy userId={userId} />}
        />
        <Route path="/" element={<CoachLanding />} />
        <Route
          path="/delete-account"
          element={<DeleteAccount userId={userId} />}
        />
        <Route path="/about" element={<StaticLanding userId={userId} />} />
        <Route path="/science" element={<Science userId={userId} />} />
        <Route
          path="/purpose"
          element={<PurposeAssessment userId={userId} />}
        />
        <Route path="/beta" element={<BetaLanding userId={userId} />} />
        <Route path="/onboarding" element={<Ground userId={userId} />} />

        <Route
          path="/welcome"
          element={
            <SalesLanding
              userId={userId}
              setUserId={setUserId}
              onReferrerUpdate={handleReferrerUpdate}
            />
          }
        />

        <Route path="/admin_data" element={<AdminData />} />

        <Route
          path="/landing"
          element={<Landing userId={userId} setUserId={setUserId} />}
        />

        <Route
          path="/2024"
          element={<ResolutionBuilder userId={userId} setUserId={setUserId} />}
        />

        {/* Route for Coach */}
        <Route path="/user" element={<Coach />} />
        <Route path="/connect" element={<Coach />} />
        <Route path="/coach/*" element={<CoachRoutes />} />

        <Route
          path="/coach"
          element={
            <UserInitializationHandler
              userId={userId}
              setUserId={setUserId}
              referrerId={referrerId}
            />
          }
        />
        <Route
          path="/stride"
          element={<StrideLandingPage  />}
        />
         <Route
          path="/career_planner"
          element={<CareerPlanner  />}
        />

        <Route
          path="/stride_dashboard"
          element={<CareerDashboard  />}
        />
         <Route
          path="/stride_dashboard"
          element={<CareerDashboard  />}
        />

          <Route
          path="/panel"
          element={<PanelLanding  />}
        />


         

        
      </Routes>
    </Router>
  );
}

export default App;
