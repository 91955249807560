import React from "react";

const DeleteAccount = () => {
  return (
    <div
      style={{
        margin: "0 auto",
        maxWidth: "600px",
        padding: "20px",
        textAlign: "center",
      }}
    >
      <h1>Delete Your Account</h1>
      <p>
        At 1440, your privacy and data security are our top priorities. We
        understand the importance of the data you share with our AI coach and
        the third-party services you choose to connect, such as your calendar
        and health information.
      </p>
      <p>
        We store only the information you share with our chatbot and any data
        from connected third-party services to enhance your coaching experience.
        We take great care to protect and secure this data.
      </p>
      <p>
        If you decide to delete your account, all data associated with your
        account, including data shared with our chatbot and from connected
        third-party services, will be permanently removed. Please note, some
        data may be retained for legal or regulatory purposes.
      </p>
      <p>
        To request the deletion of your account and associated data, please send
        an email to{" "}
        <a style={{ color: "blue" }} href="mailto:noah@share.vc">
          noah@share.vc
        </a>{" "}
        with the subject line "Account Deletion Request". Please include your
        account details in the email to help us process your request
        efficiently.
      </p>
      <p>
        We're here to support you through this process and answer any questions
        you may have about data deletion or privacy.
      </p>
    </div>
  );
};

export default DeleteAccount;
