import React, { useState } from "react";
import ScorecardView from "./ScorecardView";
import ScorecardTemplateEditor from "./ScorecardTemplateEditor";

export default function Scorecards({ onSelectScorecard }) {
  const [activeView, setActiveView] = useState("list"); // 'list' or 'template'

  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Scorecards</h1>
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded-lg ${
              activeView === "list"
                ? "bg-blue-500 text-white"
                : "bg-slate-700 text-gray-300"
            }`}
            onClick={() => setActiveView("list")}
          >
            View Scorecards
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${
              activeView === "template"
                ? "bg-blue-500 text-white"
                : "bg-slate-700 text-gray-300"
            }`}
            onClick={() => setActiveView("template")}
          >
            Edit Templates
          </button>
        </div>
      </div>
      {activeView === "list" ? (
        <ScorecardView onSelectScorecard={onSelectScorecard} />
      ) : (
        <ScorecardTemplateEditor />
      )}
    </div>
  );
}
