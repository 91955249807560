import React from "react";
import Layout from "./Layout";

const Dashboard = () => {
  return (
    <Layout title="Dashboard">
      <div className="">Dashboard is under development.</div>
    </Layout>
  );
};

export default Dashboard;
