import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Mixpanel from "mixpanel-browser";
import DemoVideo from "../graphics/DemoVideo";
import BetaLanding from "../pages/BetaLanding";

function UserInitializationHandler({ userId, setUserId, referrerId }) {
  const navigate = useNavigate();
  const [showVideo, setShowVideo] = useState(false);
  const [showBetaLanding, setShowBetaLanding] = useState(false);

  useEffect(() => {
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", { track_pageview: true });
    if (userId) Mixpanel.identify(userId);

    if (userId && referrerId) {
      console.log("home");
      navigate("/user");
    } else if (!userId && referrerId) {
      // Generate new user ID only if referrerId exists
      const newUserId = generateUserId();
      setUserId(newUserId);
      localStorage.setItem("userId", newUserId);
      Mixpanel.identify(newUserId);
      Mixpanel.track("User Created", { userId: newUserId, referrerId });
      if (referrerId === "beta-signup") {
        setShowBetaLanding(true);
      } else {
        setShowVideo(true);
        Mixpanel.track("Showing Video", { userId: newUserId });
      }
    } else if (!referrerId) {
      const newUserId = generateUserId();
      setUserId(newUserId);
      localStorage.setItem("userId", newUserId);
      navigate("/user");
    }
  }, [referrerId, navigate]);

  const skipVideo = (watchedTime) => {
    setShowVideo(false);
    Mixpanel.identify(userId);
    console.log("Skipped", watchedTime);
    Mixpanel.track("Skipped Video", { watchedTime });
    navigate("/user");
  };

  function generateUserId() {
    return (
      Math.random().toString(36).substring(2) +
      "." +
      Math.random().toString(36).substring(2)
    );
  }

  return (
    <>
      {showVideo && (
        <DemoVideo
          userId={userId}
          onSkip={(watchedTime) => skipVideo(watchedTime)}
        />
      )}
      {showBetaLanding && <BetaLanding />}
    </>
  );
}

export default UserInitializationHandler;
