import React from "react";

const Testimonial = () => {
  return (
    <div className="p-4 my-8 mx-auto max-w-md bg-black rounded-lg">
      <div className="flex flex-col items-start">
        <div className="text-white text-2xl font-semibold mb-2">
          The Best App
        </div>
        <div className="text-accent text-2xl my-3 font-bold">★★★★★</div>
        <p className="text-white text-2xl leading-snug font-medium mt-2">
          "1440 has changed the game for me. For a long time, I've been avoiding
          coaching because of the cost and scheduling issues. This is exactly
          what I needed. Whenever I get feedback from my boss, I pass it on to
          Coach Nia and she gives me a measurable plan to accomplish my career
          goals before the next review. 1440's also gotten me into the gym twice
          a week for over two months now!"
        </p>
        <div className="text-accent mt-3">Alex Dempsey</div>
        <div className="text-gray-400">Feb 2, 2024</div>
      </div>
    </div>
  );
};

export default Testimonial;
