import React, { useState } from "react";
import { motion } from "framer-motion";
import { primary, text, background, border } from "../theme/variables";
import "../theme/theme.css";

const SelectionScreen = ({ onUpdate, content, autoAdvance = false }) => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleSelection = (option) => {
    setSelectedOption(option);
    if (autoAdvance) {
      // Small delay before advancing
      setTimeout(() => {
        onUpdate(option);
      }, 400);
    }
  };

  const handleContinue = () => {
    if (selectedOption) {
      onUpdate(selectedOption);
    }
  };

  return (
    <motion.div
      className="flex flex-col py-4"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <motion.h2
        className="text-2xl font-bold mb-4"
        style={{ color: text.white }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        {content.question}
      </motion.h2>

      <div className="space-y-2 mb-4">
        {content.options.map((option, index) => (
          <motion.button
            key={index}
            className="w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center relative z-10"
            style={{
              backgroundColor:
                selectedOption === option ? primary[600] : `${primary[900]}33`, // 20% opacity
              color: text.white,
              borderWidth: "1px",
              borderColor:
                selectedOption === option ? primary[500] : `${primary[800]}4D`, // 30% opacity
              boxShadow:
                selectedOption === option
                  ? `0 4px 6px -1px ${primary[900]}33`
                  : "none", // shadow with 20% opacity
            }}
            onClick={() => handleSelection(option)}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1 + index * 0.05, duration: 0.5 }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div
              className="w-5 h-5 rounded-full mr-3 flex-shrink-0 flex items-center justify-center"
              style={{
                borderWidth: "2px",
                borderColor:
                  selectedOption === option ? text.white : primary[500],
              }}
            >
              {selectedOption === option && (
                <motion.div
                  className="w-2.5 h-2.5 rounded-full"
                  style={{ backgroundColor: text.white }}
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.2 }}
                />
              )}
            </div>
            <span>{option}</span>
          </motion.button>
        ))}
      </div>

      {!autoAdvance && (
        <div className="mt-auto">
          <motion.button
            onClick={handleContinue}
            disabled={!selectedOption}
            className="w-full py-3 rounded-md font-medium transition-colors duration-200 shine-effect"
            style={{
              backgroundColor: selectedOption
                ? primary[600]
                : `${primary[900]}66`, // 40% opacity
              color: selectedOption ? text.white : primary[300],
              boxShadow: selectedOption
                ? "0 4px 6px -1px rgba(0, 0, 0, 0.1)"
                : "none",
              cursor: selectedOption ? "pointer" : "not-allowed",
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Continue
          </motion.button>
        </div>
      )}
    </motion.div>
  );
};

export default SelectionScreen;
