import React from "react";
import { primary, gradient, background, border } from "../theme/variables";

const ContinueButton = ({ isLastStep, handleContinue, customText }) => {
  // Handle click with special case for last step
  const handleClick = () => {
    if (isLastStep) {
      // Redirect to Stripe checkout URL when it's the final step
      window.location.href = "https://buy.stripe.com/5kAeVsbGrdcE3Di6oo";
    } else {
      // Otherwise use the normal continue flow
      handleContinue();
    }
  };

  return (
    <div
      className="pb-6 bottom-0 w-full fixed flex justify-center z-50 pt-8"
      style={{ background: background.gradient.darkReverse }}
    >
      <div className="w-full max-w-md px-5">
        <button
          onClick={handleClick}
          className="w-full relative overflow-hidden group text-black font-medium py-4 rounded-xl
          shadow-lg transition-all duration-300 ease-out transform hover:translate-y-[-2px] hover:shadow-xl
          active:translate-y-[1px] active:shadow-md active:scale-[0.98]"
          style={{ background: gradient.primary }}
        >
          <span className="relative z-10 uppercase tracking-wider text-sm font-bold">
            {customText || (isLastStep ? "GET STARTED" : "CONTINUE")}
          </span>

          {/* Corner glows */}
          <div
            className="absolute top-[-10px] left-[-10px] w-[30px] h-[30px] rounded-full blur-md opacity-70"
            style={{ backgroundColor: primary[500] }}
          ></div>
          <div
            className="absolute bottom-[-10px] right-[-10px] w-[30px] h-[30px] rounded-full blur-md opacity-70"
            style={{ backgroundColor: primary[500] }}
          ></div>

          {/* Background glow effect */}
          <div
            className="absolute inset-0 w-full h-full opacity-0 
            group-hover:opacity-100 transition-opacity duration-300 ease-out blur-xl scale-110"
            style={{ background: gradient.primaryHover }}
          ></div>

          {/* Shine effect that only runs on hover */}
          <div
            className="absolute top-0 -left-[100%] w-[120%] h-full bg-gradient-to-r from-transparent 
            via-white/30 to-transparent skew-x-[-20deg] group-hover:animate-shine-once"
          ></div>

          {/* Pulsing border */}
          <div
            className="absolute inset-0 rounded-xl border transition-colors duration-300"
            style={{
              borderColor: border.primary,
              "--hover-border-color": border.primaryHover,
            }}
          ></div>

          {/* Add a subtle pulse animation to the button */}
          <div
            className="absolute inset-0 rounded-xl group-hover:animate-pulse"
            style={{ backgroundColor: `${primary[500]}10` }} // Using hex opacity (10 = 10%)
          ></div>
        </button>
      </div>
    </div>
  );
};

export default ContinueButton;
