import React from "react";
import { Link } from "react-router-dom";
import SignInButton from "./SignInButton";

const MobileMenu = ({ onToggleView, showDashboard }) => {
  return (
    <div className="absolute top-20 left-0 w-full bottom-0 bg-white dark:bg-black z-10 px-4 overflow-y-auto">
      {/* Switch Button */}
      <hr className="border-t border-gray-300 " />
      <div className="flex justify-between items-center pt-4">
        <SignInButton />
      </div>
      <hr className="border-t border-gray-300 my-4" />

      {/* Large Text Links */}
      <div className="text-2xl font-medium mt-32">
        <Link
          to="https://1440.framer.website"
          className="flex py-2 dark:text-white"
        >
          About
          <img
            src="/images/right-up.png"
            className="ml-1 brightness-0 dark:brightness-100 h-7 w-7"
          />
        </Link>
        <Link to="/science" className="flex py-2 dark:text-white">
          Science
          <img
            src="/images/right-up.png"
            className="ml-1 brightness-0 dark:brightness-100 h-7 w-7"
          />
        </Link>
        <Link to="/purpose" className="flex py-2 dark:text-white">
          Discover Your Purpose
          <img
            src="/images/right-up.png"
            className="ml-1 brightness-0 dark:brightness-100 h-7 w-7"
          />
        </Link>
        <Link to="/privacy-policy" className="flex py-2 dark:text-white">
          Privacy Policy
          <img
            src="/images/right-up.png"
            className="ml-1 brightness-0 dark:brightness-100 h-7 w-7"
          />
        </Link>
      </div>
    </div>
  );
};

export default MobileMenu;
