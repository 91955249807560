import React, { useState, useEffect } from "react";
import ChatBox from "../chat/ChatBox";
import Footer from "../footer/Footer";
import Mixpanel from "mixpanel-browser";
import NewYearsNavBar from "./NewYearsNavBar";
import ResolutionsShowcase from "./ResolutionsResult";

function ResolutionBuilder({ userId, setUserId }) {
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showResolutionResult, setShowResolutionResult] = useState(false);
  const [darkTheme, setDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("darkTheme");
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });
  const [audioUrl, setAudioUrl] = useState(null);
  const [referralCode, setReferralCode] = useState(null);

  useEffect(() => {
    // Initialize Mixpanel with your project token
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", { track_pageview: true });
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "new_years" });

    loadPreviousMessages();
  }, [userId]);

  useEffect(() => {
    const savedResolutions = localStorage.getItem("resolutions");
    if (savedResolutions) {
      setShowResolutionResult(JSON.parse(savedResolutions));
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
    loadPreviousMessages();
    const referrer = localStorage.getItem("referrerId");
    if (referrer) {
      setReferralCode(referrer);
    }
    if (!userId) {
      const newUserId = generateUserId();
      setUserId(newUserId);
      localStorage.setItem("userId", newUserId);
    }
  }, [darkTheme]);

  function generateUserId() {
    return (
      Math.random().toString(36).substring(2) +
      "." +
      Math.random().toString(36).substring(2)
    );
  }

  const loadPreviousMessages = async () => {
    if (!userId) return;
    try {
      const response = await fetch(
        "https://api.1440.ai/api/get_previous_session",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ uid: userId }),
          mode: "cors",
        }
      );
      const data = await response.json();
      if (data.status !== "false") {
        setConversation(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const sendMessage = async (message) => {
    setConversation((prevConversation) => [
      ...prevConversation,
      { role: "user", content: message },
    ]);
    showTypingIndicator();
    const includeVoice =
      JSON.parse(localStorage.getItem("isVoiceEnabled")) || false;

    try {
      const response = await fetch("https://api.1440.ai/api/resolution-coach", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          body: message,
          uid: userId,
          voice: includeVoice,
          referralCode: referralCode,
        }),
        mode: "cors",
      });

      const data = await response.json();

      if (data.resolutions) {
        setShowResolutionResult(data.resolutions);
        localStorage.setItem("resolutions", JSON.stringify(data.resolutions));
      } else {
        setConversation((prevConversation) => [
          ...prevConversation,
          { role: "assistant", content: data.answer },
        ]);
        if (data.audio_clip) {
          setAudioUrl(data.audio_clip);
          // Play audio
          const audio = new Audio(data.audio_clip);
          audio.play().catch((e) => console.error("Error playing audio:", e));
        }
      }
    } catch (error) {
      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: "An error occurred. Please try again." },
      ]);
    } finally {
      hideTypingIndicator();
    }
  };

  const showTypingIndicator = () => {
    setIsTyping(true);
  };

  const hideTypingIndicator = () => {
    setIsTyping(false);
  };

  const updateTheme = (theme) => {
    setDarkTheme(theme);
    Mixpanel.track("Theme Toggled", { darkMode: theme });
  };

  return (
    <div
      className={`App${
        darkTheme ? " dark" : ""
      } flex flex-col h-screen bg-white dark:bg-black text-black dark:text-white`}
    >
      <div className="fixed inset-0 z-0 pointer-events-none">
        <img
          src="/images/fireworks.jpg"
          alt="Fireworks"
          className="w-full h-full object-cover"
          style={{ pointerEvents: "none", opacity: darkTheme ? "0.1" : "0.05" }}
        />
      </div>
      <header className="px-6 sm:px-10 md:px-12 pt-9 bg-white dark:bg-black">
        <NewYearsNavBar />
      </header>
      <main className="flex-1 overflow-y-auto px-6 sm:px-10 md:px-12 bg-white dark:bg-black text-black dark:text-white">
        <ChatBox
          conversation={conversation}
          initialMessage="Let's make 2024 resolutions, what's most important to you?"
          sendMessage={sendMessage}
          isTyping={isTyping}
          userInput={userInput}
          setUserInput={setUserInput}
          suggestions={[
            "Career",
            "Relationships",
            "Fitness",
            "Mental health",
            "Personal growth",
            "Hobbies",
          ]}
        />
      </main>
      <footer className="px-6 sm:px-10 md:px-12 pb-6 bg-white dark:bg-black text-black dark:text-white">
        <Footer darkTheme={darkTheme} updateTheme={updateTheme} />
      </footer>
      {showResolutionResult && (
        <ResolutionsShowcase
          resolutions={showResolutionResult}
          onClose={() => setShowResolutionResult(false)}
        />
      )}
    </div>
  );
}

export default ResolutionBuilder;
