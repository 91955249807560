import React, { useState } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [sessionFrequency, setSessionFrequency] = useState("");

  const frequencies = [
    "Daily",
    "3-4 times per week",
    "Once a week",
    "Once every two weeks",
    "Once a month",
    "As needed",
    "Not sure yet",
  ];

  const handleFrequencySelection = (frequency) => {
    setSessionFrequency(frequency);
    onUpdate(frequency);
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        How frequently would you like structured sessions?
      </p>
      <div className="flex flex-col w-full">
        {frequencies.map((frequency) => (
          <button
            key={frequency}
            onClick={() => handleFrequencySelection(frequency)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              sessionFrequency === frequency
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow:
                sessionFrequency === frequency ? "0 0 2px #FF8C22" : "",
            }}
          >
            {frequency}
          </button>
        ))}
      </div>
    </div>
  );
}
