// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `/* For Paragraphs */
p {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 1rem;
  }
  
  /* For Headers */
  h1 {
    font-size: 2.25rem;
    line-height: 1.2;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  h2 {
    font-size: 1.875rem;
    line-height: 1.3;
    font-weight: 700;
    margin-bottom: 1rem;
  }
  
  h3 {
    font-size: 1.5rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  h4 {
    font-size: 1.25rem;
    line-height: 1.4;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  /* For Unordered Lists */
  ul {
    list-style: disc;
    padding-left: 2rem;
    margin-bottom: 1rem;
  }
  
  /* For List Items */
  li {
    font-size: 1rem;
    line-height: 1.5;
    margin-bottom: 0.5rem;
  }`, "",{"version":3,"sources":["webpack://./src/components/pages/PrivacyPolicy.css"],"names":[],"mappings":"AAAA,mBAAmB;AACnB;IACI,eAAe;IACf,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA,gBAAgB;EAChB;IACE,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,mBAAmB;IACnB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,iBAAiB;IACjB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA;IACE,kBAAkB;IAClB,gBAAgB;IAChB,gBAAgB;IAChB,mBAAmB;EACrB;;EAEA,wBAAwB;EACxB;IACE,gBAAgB;IAChB,kBAAkB;IAClB,mBAAmB;EACrB;;EAEA,mBAAmB;EACnB;IACE,eAAe;IACf,gBAAgB;IAChB,qBAAqB;EACvB","sourcesContent":["/* For Paragraphs */\np {\n    font-size: 1rem;\n    line-height: 1.5;\n    margin-bottom: 1rem;\n  }\n  \n  /* For Headers */\n  h1 {\n    font-size: 2.25rem;\n    line-height: 1.2;\n    font-weight: 700;\n    margin-bottom: 1rem;\n  }\n  \n  h2 {\n    font-size: 1.875rem;\n    line-height: 1.3;\n    font-weight: 700;\n    margin-bottom: 1rem;\n  }\n  \n  h3 {\n    font-size: 1.5rem;\n    line-height: 1.4;\n    font-weight: 600;\n    margin-bottom: 1rem;\n  }\n  \n  h4 {\n    font-size: 1.25rem;\n    line-height: 1.4;\n    font-weight: 600;\n    margin-bottom: 1rem;\n  }\n  \n  /* For Unordered Lists */\n  ul {\n    list-style: disc;\n    padding-left: 2rem;\n    margin-bottom: 1rem;\n  }\n  \n  /* For List Items */\n  li {\n    font-size: 1rem;\n    line-height: 1.5;\n    margin-bottom: 0.5rem;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
