import React from "react";

const Button = ({ children }) => {
  const onClick = () => {
    // redirect to / coaching
    window.location.href = "https://1440.ai/coach";
  };
  return (
    <button onClick={onClick} className="text-[#DCFF02] font-light text-sm">
      {children}
    </button>
  );
};

export default Button;
