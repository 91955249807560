import { COLORS } from "./timeConstants";

// Calculate actual time breakdown based on user responses
export const calculateTimeBreakdown = (results) => {
  console.log("Raw results data received:", results);

  // Values based on the image, converted to minutes per day
  // Sleep: 288 months ≈ 8 hours/day (480 min)
  // Work+School: 126 months ≈ 3.5 hours/day (210 min)
  // Cooking/Eating: 36 months ≈ 1 hour/day (60 min)
  // Exercise: part of remaining time

  // Default values aligned with image
  let workMinutes = 210; // Work+School = 3.5 hours default
  let sleepMinutes = 480; // 8 hours default (288 months)
  let exerciseMinutes = 30; // 30 min default

  // Parse work hours from user response if available (screen9)
  if (results.screen9) {
    const workResponse = String(results.screen9);
    console.log("Work response:", workResponse);
    if (workResponse.includes("Less than 10")) {
      workMinutes = Math.round((10 * 60) / 7); // Convert weekly to daily (~85 min)
    } else if (workResponse.includes("10-20")) {
      workMinutes = Math.round((15 * 60) / 7); // Average 15 hours/week (~128 min)
    } else if (workResponse.includes("21-34")) {
      workMinutes = Math.round((28 * 60) / 7); // Average 28 hours/week (~240 min)
    } else if (workResponse.includes("35-50")) {
      workMinutes = Math.round((40 * 60) / 7); // Average 40 hours/week (~343 min)
    } else if (workResponse.includes("51-65")) {
      workMinutes = Math.round((58 * 60) / 7); // Average 58 hours/week (~497 min)
    } else if (workResponse.includes("More than 65")) {
      workMinutes = Math.round((70 * 60) / 7); // Estimate 70 hours/week (~600 min)
    }
  }

  // Parse sleep hours from user response if available (screen10)
  if (results.screen10) {
    const sleepResponse = String(results.screen10);
    console.log("Sleep response:", sleepResponse);
    if (sleepResponse.includes("Less than 5")) {
      sleepMinutes = 270; // 4.5 hours
    } else if (sleepResponse.includes("5-6.5")) {
      sleepMinutes = 345; // 5.75 hours
    } else if (sleepResponse.includes("7-8.5")) {
      sleepMinutes = 465; // 7.75 hours
    } else if (sleepResponse.includes("9-10.5")) {
      sleepMinutes = 585; // 9.75 hours
    } else if (sleepResponse.includes("More than 11")) {
      sleepMinutes = 660; // 11 hours
    }
  }

  // Parse exercise frequency if available (screen11)
  if (results.screen11) {
    const exerciseResponse = String(results.screen11);
    console.log("Exercise response:", exerciseResponse);
    if (exerciseResponse.includes("More than 7 hours")) {
      exerciseMinutes = Math.round((8 * 60) / 7); // Average 8 hours/week (~69 min daily)
    } else if (exerciseResponse.includes("5-7 hours")) {
      exerciseMinutes = Math.round((6 * 60) / 7); // Average 6 hours/week (~51 min daily)
    } else if (exerciseResponse.includes("3-5 hours")) {
      exerciseMinutes = Math.round((4 * 60) / 7); // Average 4 hours/week (~34 min daily)
    } else if (exerciseResponse.includes("1-3 hours")) {
      exerciseMinutes = Math.round((2 * 60) / 7); // Average 2 hours/week (~17 min daily)
    } else if (exerciseResponse.includes("Less than 1 hour")) {
      exerciseMinutes = Math.round((0.5 * 60) / 7); // Average 0.5 hours/week (~4 min daily)
    } else if (exerciseResponse.includes("None")) {
      exerciseMinutes = 0; // 0 min
    }
  }

  // Set a fixed screen time percentage (70% is the default for the average person)
  const screenTimePercentage = 70;

  // Calculate remaining time
  const allocatedMinutes = workMinutes + sleepMinutes + exerciseMinutes;
  const remainingMinutes = Math.max(0, 1440 - allocatedMinutes);

  // Log the calculated values for debugging
  console.log("Time Breakdown Calculation:", {
    workMinutes,
    sleepMinutes,
    exerciseMinutes,
    remainingMinutes,
    screenTimePercentage,
    results: {
      work: results.screen9,
      sleep: results.screen10,
      exercise: results.screen11,
    },
  });

  return [
    {
      id: "work",
      minutes: workMinutes,
      percentage: (workMinutes / 1440) * 100,
      color: COLORS.work,
      label: "Work + School",
    },
    {
      id: "sleep",
      minutes: sleepMinutes,
      percentage: (sleepMinutes / 1440) * 100,
      color: COLORS.sleep,
      label: "Sleep",
    },
    {
      id: "exercise",
      minutes: exerciseMinutes,
      percentage: (exerciseMinutes / 1440) * 100,
      color: COLORS.exercise,
      label: "Exercise",
    },
    {
      id: "remaining",
      minutes: remainingMinutes,
      percentage: (remainingMinutes / 1440) * 100,
      color: COLORS.remaining,
      label: "Remaining",
    },
  ];
};

// Calculate breakdown of remaining time based on research and user's screen time
export const getRemainingTimeBreakdown = (
  remainingMinutes,
  screenTimePercentage = 70
) => {
  // Adjust other percentages based on screen time
  // As screen time increases, other categories decrease proportionally
  const remainingPercentage = 100 - screenTimePercentage;

  // Set fixed percentage for distractions at 10%
  const distractionsPercentage = 10;

  // Distribute remaining percentage (excluding screen time and distractions)
  const distributablePercentage =
    100 - screenTimePercentage - distractionsPercentage;

  // Calculate distribution of remaining percentage
  const essentialsPercentage = 11; // 50% of what's left
  const socialPercentage = 3; // 17% of what's left
  const growthPercentage = 3; // 16% of what's left
  const hobbiesPercentage = 3;

  return [
    {
      id: "screenTime",
      minutes: Math.round(remainingMinutes * (screenTimePercentage / 100)),
      percentage: screenTimePercentage,
      color: COLORS.screenTime,
      label: "Screen",
    },
    {
      id: "distractions",
      minutes: Math.round(remainingMinutes * (distractionsPercentage / 100)),
      percentage: distractionsPercentage,
      color: COLORS.distractions,
      label: "Distractions",
    },
    {
      id: "essentials",
      minutes: Math.round(remainingMinutes * (essentialsPercentage / 100)),
      percentage: essentialsPercentage,
      color: COLORS.essentials,
      label: "Essentials",
    },
    {
      id: "social",
      minutes: Math.round(remainingMinutes * (socialPercentage / 100)),
      percentage: socialPercentage,
      color: COLORS.social,
      label: "Social",
    },
    {
      id: "growth",
      minutes: Math.round(remainingMinutes * (growthPercentage / 100)),
      percentage: growthPercentage,
      color: COLORS.growth,
      label: "Growth",
    },
    {
      id: "hobbies",
      minutes: Math.round(remainingMinutes * (hobbiesPercentage / 100)),
      percentage: hobbiesPercentage,
      color: COLORS.hobbies,
      label: "Hobbies",
    },
  ];
};

// Calculate transformed time breakdown
export const getTransformedTimeBreakdown = (remainingMinutes) => {
  return [
    {
      id: "screenTime",
      minutes: Math.round(remainingMinutes * 0.07),
      percentage: 7,
      color: COLORS.screenTime,
      label: "Screen",
    },
    {
      id: "distractions",
      minutes: Math.round(remainingMinutes * 0.03),
      percentage: 3,
      color: COLORS.distractions,
      label: "Distractions",
    },
    {
      id: "essentials",
      minutes: Math.round(remainingMinutes * 0.12),
      percentage: 12,
      color: COLORS.essentials,
      label: "Essentials",
    },
    {
      id: "social",
      minutes: Math.round(remainingMinutes * 0.28),
      percentage: 28,
      color: COLORS.social,
      label: "Social",
    },
    {
      id: "growth",
      minutes: Math.round(remainingMinutes * 0.25),
      percentage: 25,
      color: COLORS.growth,
      label: "Growth",
    },
    {
      id: "hobbies",
      minutes: Math.round(remainingMinutes * 0.25),
      percentage: 25,
      color: COLORS.hobbies,
      label: "Hobbies",
    },
  ];
};

// Get interpolated time breakdown based on progress (0-100)
export const getInterpolatedBreakdown = (remainingMinutes, progress) => {
  const initialBreakdown = getRemainingTimeBreakdown(remainingMinutes);
  const finalBreakdown = getTransformedTimeBreakdown(remainingMinutes);

  // For each category, interpolate between initial and final values
  const result = [];

  // Add screen time (decreasing)
  const screenTimeInitial = initialBreakdown.find(
    (item) => item.id === "screenTime"
  );
  const screenTimeFinal = finalBreakdown.find(
    (item) => item.id === "screenTime"
  );
  const screenTimePercentage =
    screenTimeInitial.percentage -
    (screenTimeInitial.percentage - screenTimeFinal.percentage) *
      (progress / 100);

  result.push({
    id: "screenTime",
    minutes: Math.round(remainingMinutes * (screenTimePercentage / 100)),
    percentage: screenTimePercentage,
    color: COLORS.screenTime,
    label: "Screen",
  });

  // Add distractions (decreasing)
  const distractionsInitial = initialBreakdown.find(
    (item) => item.id === "distractions"
  );
  const distractionsFinal = finalBreakdown.find(
    (item) => item.id === "distractions"
  );
  const distractionsPercentage =
    distractionsInitial.percentage -
    (distractionsInitial.percentage - distractionsFinal.percentage) *
      (progress / 100);

  result.push({
    id: "distractions",
    minutes: Math.round(remainingMinutes * (distractionsPercentage / 100)),
    percentage: distractionsPercentage,
    color: COLORS.distractions,
    label: "Distractions",
  });

  // Add remaining categories with interpolated values
  const categories = [
    { id: "essentials", initial: "essentials", final: "essentials" },
    { id: "social", initial: "social", final: "social" },
    { id: "growth", initial: "growth", final: "growth" },
  ];

  categories.forEach((cat) => {
    const initialItem = initialBreakdown.find(
      (item) => item.id === cat.initial
    );
    const finalItem = finalBreakdown.find((item) => item.id === cat.final);

    if (initialItem && finalItem) {
      const percentage =
        initialItem.percentage +
        (finalItem.percentage - initialItem.percentage) * (progress / 100);

      result.push({
        id: cat.id,
        minutes: Math.round(remainingMinutes * (percentage / 100)),
        percentage: percentage,
        color: finalItem.color,
        label: finalItem.label,
      });
    }
  });

  // Add hobbies (only appear in final - gradually introduce)
  if (progress > 0) {
    // Start showing hobbies from the beginning
    const hobbiesFinal = finalBreakdown.find((item) => item.id === "hobbies");
    if (hobbiesFinal) {
      const hobbiesPercentage = (hobbiesFinal.percentage * progress) / 100; // Linear progression

      result.push({
        id: "hobbies",
        minutes: Math.round(remainingMinutes * (hobbiesPercentage / 100)),
        percentage: hobbiesPercentage,
        color: COLORS.hobbies,
        label: "Hobbies",
      });
    }
  }

  // Normalize percentages to ensure they add up to 100
  const totalPercentage = result.reduce(
    (sum, item) => sum + item.percentage,
    0
  );
  return result.map((item) => ({
    ...item,
    percentage: (item.percentage / totalPercentage) * 100,
  }));
};
