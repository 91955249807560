import React, { useState, useEffect } from "react";

const ToggleVoice = () => {
  const [isVoiceEnabled, setIsVoiceEnabled] = useState(false);

  useEffect(() => {
    const voiceSetting = JSON.parse(localStorage.getItem("isVoiceEnabled"));
    if (voiceSetting !== null) {
      setIsVoiceEnabled(voiceSetting);
    }
  }, []);

  const handleToggleVoice = () => {
    const newVoiceSetting = !isVoiceEnabled;
    setIsVoiceEnabled(newVoiceSetting);
    localStorage.setItem("isVoiceEnabled", JSON.stringify(newVoiceSetting));
  };

  const buttonStyle = isVoiceEnabled
    ? "bg-lightBlue hover:bg-darkBlue text-white border-transparent"
    : "bg-transparent hover:bg-slate-100 dark:hover:bg-slate-700 text-black dark:text-white border border-gray-500";
  const iconSrc = isVoiceEnabled
    ? "/images/voice-on.png"
    : "/images/voice-off.png";
  const voiceStatus = isVoiceEnabled ? "Voice On" : "Voice Off";
  const imageClass = isVoiceEnabled
    ? "h-5 w-5 mr-2 brightness-100 dark:brightness-100"
    : "h-5 w-5 mr-2 brightness-50 dark:brightness-50";
  return (
    <button
      onClick={handleToggleVoice}
      className={`flex items-center h-10 px-3 rounded transition duration-300 ${buttonStyle}`}
    >
      <img src={iconSrc} alt="Voice Toggle" className={imageClass} />
      <span className="text-sm">{voiceStatus}</span>
    </button>
  );
};

export default ToggleVoice;
