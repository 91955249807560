import React, { useState } from "react";

const Topic = ({ topic, onUpdate }) => {
  const [name, setName] = useState(topic.name);
  const [note, setNote] = useState(topic.note);

  // Call onUpdate whenever there's a change
  const handleChange = () => {
    onUpdate({ ...topic, name, note });
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md hover:shadow-lg transition-shadow duration-300">
      <input
        className="text-sm font-semibold text-gray-700 mb-2 block w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        value={name}
        onChange={(e) => setName(e.target.value)}
        onBlur={handleChange}
        placeholder="Topic Name"
      />
      <textarea
        className="text-sm text-gray-700 block w-full px-3 py-2 rounded-md border border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        value={note}
        onChange={(e) => setNote(e.target.value)}
        onBlur={handleChange}
        placeholder="Topic Description"
      />
      <button
        className="mt-3 bg-red-200 text-red-600 font-medium py-2 text-sm px-4 rounded focus:outline-none focus:shadow-outline"
        onClick={() => onUpdate(null)}
      >
        Delete
      </button>
    </div>
  );
};

export default Topic;
