import React, { useState, useEffect } from "react";
import ChatBox from "../chat/ChatBox";
import Navbar from "../header/Navbar";
import AdminSyncPhone from "./AdminSyncPhone";
import Dashboard from "../chat/Dashboard";
import axios from "axios";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import Subscribe from "../Subscribe";

function AdminCoach() {
  const [userInput, setUserInput] = useState("");
  const [conversation, setConversation] = useState([]);
  const [isTyping, setIsTyping] = useState(false);
  const [showSyncPhone, setShowSyncPhone] = useState(false);
  const [view, setView] = useState("Dashboard");
  const [user, setUser] = useState({});
  const { phone } = useParams();
  const [apiKeyVerified, setApiKeyVerified] = useState(false);
  const [apiKey, setApiKey] = useState("");
  const [apiError, setApiError] = useState("");

  const [darkTheme, setDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("darkTheme");
    return savedTheme !== null ? JSON.parse(savedTheme) : true;
  });

  const mobile1440Id = Cookies.get("1440_mobile_id");
  const sub1440Id = Cookies.get("1440_web_id");
  const accessToken = Cookies.get("1440_access_token");

  useEffect(() => {
    const searchParams = new URLSearchParams(window.location.search);
    const viewParam = searchParams.get("view");
    if (viewParam) {
      setView(viewParam);
    }
  }, []);

  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
    if (sub1440Id && accessToken && apiKeyVerified) {
      loadPreviousMessages();
    }
  }, [darkTheme, sub1440Id, accessToken, apiKeyVerified]);

  useEffect(() => {
    if (!apiKeyVerified) {
      setShowSyncPhone(false);
    } else if (!sub1440Id || !accessToken) {
      setShowSyncPhone(true);
    } else {
      fetchUser();
    }
  }, [sub1440Id, accessToken, apiKeyVerified]);

  const verifyApiKey = async () => {
    try {
      const response = await axios.get("/api/coach/verify_api_key", {
        headers: { "Api-Key": apiKey },
      });
      setApiKeyVerified(true);
      setApiError("");
    } catch (error) {
      setApiError("Invalid API key. Please try again.");
      setApiKeyVerified(false);
    }
  };

  const loadPreviousMessages = async () => {
    if (!sub1440Id) return;
    try {
      const response = await axios.post(
        "/api/get_previous_session",
        {
          uid: sub1440Id,
        },
        {
          headers: { "Api-Key": apiKey },
        }
      );

      if (response.status !== "false") {
        setConversation(response.data);
      }
    } catch (error) {
      console.log("Error loading previous messages:", error);
    }
  };

  const toggleView = (newView) => {
    setView(newView);
  };

  const sendMessage = async (message) => {
    setConversation((prevConversation) => [
      ...prevConversation,
      { role: "user", content: message },
    ]);
    showTypingIndicator();

    try {
      const response = await axios.post(
        "/api/coach",
        {
          body: message,
          uid: sub1440Id,
        },
        {
          headers: { "Api-Key": apiKey },
        }
      );

      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: response.data.answer },
      ]);
    } catch (error) {
      setConversation((prevConversation) => [
        ...prevConversation,
        { role: "assistant", content: "An error occurred. Please try again." },
      ]);
    } finally {
      hideTypingIndicator();
    }
  };

  const fetchUser = async () => {
    try {
      const response = await axios.get(`/users/${sub1440Id}/`, {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setUser(response.data);
    } catch (error) {
      console.error("Error fetching user:", error);

      // Remove the relevant cookies
      Cookies.remove("1440_access_token");
      Cookies.remove("1440_web_id");
      Cookies.remove("1440_mobile_id");
      // Reload the page to reflect the changes
      // window.location.reload();
    }
  };

  const showTypingIndicator = () => {
    setIsTyping(true);
  };

  const hideTypingIndicator = () => {
    setIsTyping(false);
  };

  const handlePhoneSync = (phoneNumber) => {
    setShowSyncPhone(false);
    window.location.reload();
  };

  const closeSyncPhone = () => {
    setShowSyncPhone(false);
  };

  if (!apiKeyVerified) {
    return (
      <div className="bg-black text-white min-h-screen flex">
        <div className="flex flex-col items-center justify-center w-full max-w-md mx-auto space-y-6">
          <div className="w-full">
            <label
              htmlFor="apiKey"
              className="block text-sm font-medium text-gray-400 mb-2"
            >
              API Key
            </label>
            <input
              id="apiKey"
              type="text"
              value={apiKey}
              onChange={(e) => setApiKey(e.target.value)}
              className="w-full px-3 py-2 bg-slate-800 border border-gray-700 rounded-md text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
              placeholder="Enter your API key"
            />
          </div>
          <button
            onClick={verifyApiKey}
            className="w-full px-4 py-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-gray-900 text-white font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-200 ease-in-out"
          >
            Verify API Key
          </button>
          {apiError && (
            <div className="w-full px-4 py-3 bg-red-500 bg-opacity-10 border border-red-400 rounded-md">
              <p className="text-sm text-red-400">{apiError}</p>
            </div>
          )}
        </div>
      </div>
    );
  }

  return (
    <div
      className={`App${
        darkTheme ? " dark" : ""
      } flex flex-col min-h-screen bg-white dark:bg-black text-black dark:text-white`}
    >
      <header className="bg-white dark:bg-black px-6">
        <Navbar
          userId={sub1440Id}
          onToggleView={(newView) => toggleView(newView)}
          view={view}
        />
      </header>
      {showSyncPhone ? (
        <AdminSyncPhone
          onSync={handlePhoneSync}
          onCloseSync={closeSyncPhone}
          starterPhone={phone ? phone : false}
          apiKey={apiKey}
        />
      ) : view === "Dashboard" ? (
        <Dashboard user={user} />
      ) : view === "Chat" ? (
        <main className="flex-1 overflow-y-auto px-6 sm:px-10 md:px-12 bg-white dark:bg-black text-black dark:text-white">
          <ChatBox
            conversation={conversation}
            sendMessage={sendMessage}
            isTyping={isTyping}
            userInput={userInput}
            setUserInput={setUserInput}
          />
        </main>
      ) : view === "Subscribe" ? (
        <Subscribe setView={setView} />
      ) : (
        <div className="flex-1 flex items-center justify-center bg-white dark:bg-black text-black dark:text-white">
          <p className="text-xl">This page is empty.</p>
        </div>
      )}
    </div>
  );
}

export default AdminCoach;
