import React from "react";
import { motion } from "framer-motion";
import { primary, text, border } from "../theme/variables";
import "../theme/theme.css";

const InfoScreen = ({ onUpdate, content }) => {
  const container = {
    hidden: { opacity: 0 },
    show: {
      opacity: 1,
      transition: {
        staggerChildren: 0.1,
        delayChildren: 0.3,
      },
    },
  };

  const item = {
    hidden: { opacity: 0, y: 10 },
    show: { opacity: 1, y: 0, transition: { duration: 0.4 } },
  };

  return (
    <motion.div
      className="flex flex-col"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.4 }}
    >
      <motion.h2
        className="text-2xl font-bold mb-6 tracking-tight"
        style={{ color: primary[50] }}
        initial={{ opacity: 0, y: -10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5 }}
      >
        {content.title}
      </motion.h2>

      <motion.div
        className="space-y-6 leading-relaxed"
        style={{ color: primary[200] }}
        variants={container}
        initial="hidden"
        animate="show"
      >
        {content.paragraphs &&
          content.paragraphs.map((paragraph, index) => (
            <motion.p
              key={index}
              variants={item}
              style={
                paragraph.highlight
                  ? { color: primary[100], fontWeight: 500 }
                  : {}
              }
            >
              {paragraph.text || paragraph}
            </motion.p>
          ))}

        {content.stats && (
          <motion.div
            className="mt-8 space-y-4 p-5 rounded-lg"
            style={{
              backgroundColor: `${primary[900]}4D`, // 30% opacity
              borderWidth: "1px",
              borderColor: primary[800],
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            {content.stats.map((stat, index) => (
              <motion.div
                key={index}
                className="flex items-start"
                initial={{ opacity: 0, x: -10 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ delay: 0.7 + index * 0.1, duration: 0.4 }}
              >
                <div
                  className="w-1.5 h-1.5 rounded-full mt-2 mr-3"
                  style={{ backgroundColor: primary[500] }}
                ></div>
                <div className="flex-1">
                  <p style={{ color: primary[100] }}>{stat}</p>
                </div>
              </motion.div>
            ))}
          </motion.div>
        )}

        {content.chart && (
          <motion.div
            className="mt-6 p-4 rounded-lg"
            style={{
              backgroundColor: `${primary[900]}33`, // 20% opacity
              borderWidth: "1px",
              borderColor: primary[800],
            }}
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ delay: 0.6, duration: 0.5 }}
          >
            <h3
              className="text-lg font-medium mb-4"
              style={{ color: primary[100] }}
            >
              {content.chart.title}
            </h3>
            <div className="space-y-3">
              {content.chart.data.map((item, index) => (
                <div key={index} className="space-y-1">
                  <div className="flex justify-between text-sm">
                    <span style={{ color: primary[300] }}>{item.label}</span>
                    <span style={{ color: primary[200] }}>{item.value}</span>
                  </div>
                  <div
                    className="w-full rounded-full h-2"
                    style={{ backgroundColor: `${primary[800]}4D` }} // 30% opacity
                  >
                    <motion.div
                      className="h-2 rounded-full"
                      style={{ backgroundColor: primary[600], width: "0%" }}
                      animate={{ width: `${item.percentage}%` }}
                      transition={{
                        delay: 0.8 + index * 0.2,
                        duration: 0.8,
                        ease: "easeOut",
                      }}
                    ></motion.div>
                  </div>
                </div>
              ))}
            </div>
          </motion.div>
        )}

        {content.callout && (
          <motion.div
            className="mt-6 p-4 rounded-lg italic"
            style={{
              backgroundColor: `${primary[600]}33`, // 20% opacity
              borderWidth: "1px",
              borderColor: `${primary[600]}66`, // 40% opacity
              color: primary[50],
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.7, duration: 0.5 }}
          >
            {content.callout}
          </motion.div>
        )}
      </motion.div>

      {content.action && (
        <motion.div
          className="mt-8 flex justify-center"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ delay: 0.9, duration: 0.5 }}
        >
          <button
            onClick={() => onUpdate(content.action.value || {})}
            className="font-medium py-3 px-8 rounded-md shadow-md transition-colors duration-200"
            style={{
              backgroundColor: primary[600],
              color: text.white,
            }}
          >
            {content.action.text}
          </button>
        </motion.div>
      )}
    </motion.div>
  );
};

export default InfoScreen;
