import React from "react";
import JsonEditor from "react-json-editor-ui";
import "react-json-editor-ui/dist/react-json-editor-ui.cjs.development.css";

function ComplexFieldsEditor({ data, onChange }) {
  const complexFields = [
    "core_memory",
    "structured_memory",
    "summaries",
    "agents",
    "assets",
  ];

  const updateField = (field, value) => {
    onChange({ ...data, [field]: value });
  };

  return (
    <div className="space-y-6">
      {complexFields.map((field) => {
        if (!(field in data)) return null;

        return (
          <div key={field} className="mb-6">
            <h4 className="text-gray-300 text-sm font-medium mb-2">
              {field
                .split("_")
                .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
                .join(" ")}
            </h4>
            <JsonEditor
              data={data[field]}
              onChange={(newData) => updateField(field, newData)}
              theme="dark"
              colors={{
                background: "#1e293b",
                default: "#ffffff",
                string: "#a5d6ff",
                number: "#79c0ff",
                boolean: "#79c0ff",
                null: "#79c0ff",
                key: "#d2a8ff",
                brackets: "#8b949e",
              }}
            />
          </div>
        );
      })}
    </div>
  );
}

export default ComplexFieldsEditor;
