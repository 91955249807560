import { chart } from "../../theme/variables";

// Animation sequences
export const SEQUENCES = {
  INTRO: 0,
  WORK_SEGMENT: 1,
  SLEEP_SEGMENT: 2,
  EXERCISE_SEGMENT: 3,
  REMAINING_INTRO: 4,
  REMAINING_SHOW: 5,
  ACTIVITIES_BREAKDOWN: 6,
  TRANSFORMATION: 7,
  CONCLUSION: 8,
  COMPLETE: 9,
};

// Modern color palette with blues and oranges
export const COLORS = {
  // Basic colors
  white: "#ffffff",
  black: "#000000",
  gray: "#9B9B9B",
  lightGray: "#F5F5F5",
  green: "#4CD964",
  red: "#FF3B30",
  blue: "#007AFF",
  yellow: "#FFCC00",
  purple: "#5856D6",
  pink: "#FF2D55",
  orange: "#FF9500",
  // Activity categories - use chart colors from theme
  screenTime: chart.screenTime,
  distractions: chart.distractions,
  essentials: chart.essentials,
  growth: chart.growth,
  social: chart.social,
  hobbies: chart.hobbies,
  sleep: chart.sleep,
  exercise: chart.exercise,
  work: chart.work,
  remaining: chart.remaining,
  remainingTime: chart.remaining,
};

// Labels for activity categories
export const LABELS = {
  screenTime: "Screen Time",
  distractions: "Distractions",
  essentials: "Essentials",
  social: "Social",
  growth: "Growth",
  hobbies: "Hobbies",
  sleep: "Sleep",
};
