import React, { useState, useEffect } from "react";

export default function GroundScreen1({ onUpdate }) {
  const [deviceType, setDeviceType] = useState("");

  useEffect(() => {
    const detectedDeviceType = getDeviceType();
    setDeviceType(detectedDeviceType);
    onUpdate(detectedDeviceType);
  }, []); // Removed onUpdate from the dependency array

  const getDeviceType = () => {
    const ua = navigator.userAgent;
    if (/Android/i.test(ua)) {
      return "Android";
    } else if (/iPhone/i.test(ua)) {
      return "iPhone";
    } else if (/iPad/i.test(ua)) {
      return "iPad";
    } else if (/Windows/i.test(ua)) {
      return "Windows PC";
    } else if (/Mac OS/i.test(ua)) {
      return "Mac";
    } else {
      return "None of the above";
    }
  };

  const deviceOptions = [
    "Android",
    "iPhone",
    "iPad",
    "Windows PC",
    "Mac",
    "None of the above",
  ];

  const handleDeviceSelection = (option) => {
    setDeviceType(option);
    onUpdate(option);
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        Please select your device
      </p>
      <p className="text-gray-400 text-lg mb-6">Select one</p>
      <div className="flex flex-col  w-full">
        {deviceOptions.map((option) => (
          <button
            key={option}
            onClick={() => handleDeviceSelection(option)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              deviceType === option
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: deviceType === option ? "0 0 2px #FF8C22" : "",
            }}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}
