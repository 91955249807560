import React from "react";
import {
  TrashIcon,
  ChatBubbleLeftRightIcon,
  ListBulletIcon,
} from "@heroicons/react/24/outline";

const ModulePromptEditor = ({
  promptType,
  promptData,
  options = {},
  onChange,
}) => {
  const handleTypeChange = (newType) => {
    onChange({
      type: newType,
      data: newType === "string" ? "" : [],
      options:
        newType === "chain"
          ? {
              include_previous_step: false,
              include_next_step: false,
            }
          : {},
    });
  };

  const handleChainItemChange = (index, field, value) => {
    const updatedChain = [...promptData];
    updatedChain[index] = { ...updatedChain[index], [field]: value };
    onChange({
      type: promptType,
      data: updatedChain,
      options,
    });
  };

  const addChainStep = () => {
    onChange({
      type: promptType,
      data: [
        ...(promptData || []),
        {
          prompt: "",
          acceptance_criteria: "",
          transition_to: "",
          transition_from: "",
          estimated_length: 5,
        },
      ],
      options,
    });
  };

  return (
    <div className="space-y-6">
      {/* Type Selector */}
      <div className="grid grid-cols-2 gap-3">
        <button
          onClick={() => handleTypeChange("string")}
          className={`flex items-center p-3 border-2 rounded-lg transition-all ${
            promptType === "string"
              ? "border-indigo-500 bg-indigo-50/50"
              : "border-gray-200 hover:border-gray-300"
          }`}
        >
          <div className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center">
            <ChatBubbleLeftRightIcon
              className={`w-5 h-5 ${
                promptType === "string" ? "text-indigo-600" : "text-gray-500"
              }`}
            />
          </div>
          <div className="ml-3 text-left">
            <div className="font-medium text-gray-900">Single Text</div>
            <div className="text-sm text-gray-500">
              Simple interaction with one text prompt
            </div>
          </div>
        </button>

        <button
          onClick={() => handleTypeChange("chain")}
          className={`flex items-center p-3 border-2 rounded-lg transition-all ${
            promptType === "chain"
              ? "border-indigo-500 bg-indigo-50/50"
              : "border-gray-200 hover:border-gray-300"
          }`}
        >
          <div className="w-10 h-10 rounded-lg bg-gray-100 flex items-center justify-center">
            <ListBulletIcon
              className={`w-5 h-5 ${
                promptType === "chain" ? "text-indigo-600" : "text-gray-500"
              }`}
            />
          </div>
          <div className="ml-3 text-left">
            <div className="font-medium text-gray-900">Prompt Chain</div>
            <div className="text-sm text-gray-500">
              Multi-step conversation flow
            </div>
          </div>
        </button>
      </div>

      {promptType === "string" ? (
        <div className="bg-white rounded-lg border border-gray-200">
          <div className="p-4">
            <textarea
              className="w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 transition-all"
              value={promptData || ""}
              onChange={(e) =>
                onChange({
                  type: "string",
                  data: e.target.value,
                  options: {},
                })
              }
              placeholder="Enter your prompt text here..."
              rows={4}
            />
          </div>
        </div>
      ) : (
        <div className="space-y-6">
          {/* Chain Options */}
          <div className="bg-gray-50 rounded-lg border border-gray-200 p-4">
            <div className="grid grid-cols-2 gap-4">
              <label className="flex items-start space-x-3 p-3 bg-white rounded-lg border border-gray-200 cursor-pointer hover:border-gray-300 transition-colors">
                <input
                  type="checkbox"
                  className="mt-0.5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={options.include_previous_step}
                  onChange={(e) =>
                    onChange({
                      type: promptType,
                      data: promptData,
                      options: {
                        ...options,
                        include_previous_step: e.target.checked,
                      },
                    })
                  }
                />
                <div>
                  <div className="font-medium text-gray-900 mb-0">
                    Include Previous Step
                  </div>
                  <div className="text-sm text-gray-500">
                    Show content from the previous step during a step
                  </div>
                </div>
              </label>
              <label className="flex items-start space-x-3 p-3 bg-white rounded-lg border border-gray-200 cursor-pointer hover:border-gray-300 transition-colors">
                <input
                  type="checkbox"
                  className="mt-0.5 rounded border-gray-300 text-indigo-600 focus:ring-indigo-500"
                  checked={options.include_next_step}
                  onChange={(e) =>
                    onChange({
                      type: promptType,
                      data: promptData,
                      options: {
                        ...options,
                        include_next_step: e.target.checked,
                      },
                    })
                  }
                />
                <div>
                  <div className="font-medium text-gray-900 mb-0">
                    Include Next Step
                  </div>
                  <div className="text-sm text-gray-500">
                    Show content from the next step during a step
                  </div>
                </div>
              </label>
            </div>
          </div>

          {/* Steps */}
          <div className="space-y-4">
            {Array.isArray(promptData) &&
              promptData.map((step, index) => (
                <div
                  key={index}
                  className="bg-white rounded-lg border border-gray-200 overflow-hidden hover:border-gray-300 transition-all"
                >
                  <div className="px-4 py-3 bg-gray-50 border-b border-gray-200 flex justify-between items-center">
                    <div className="flex items-center space-x-3">
                      <div className="w-8 h-8 rounded-lg bg-indigo-100 flex items-center justify-center font-medium text-indigo-600">
                        {index + 1}
                      </div>
                      <h3 className="font-medium text-lg text-gray-900 mb-0">
                        {step.prompt ? step.prompt.slice(0, 30) + "..." : ""}
                      </h3>
                    </div>
                    <button
                      onClick={() => {
                        const newChain = [...promptData];
                        newChain.splice(index, 1);
                        onChange({
                          type: promptType,
                          data: newChain,
                          options,
                        });
                      }}
                      className="text-gray-400 hover:text-red-500 p-1 rounded-lg transition-colors"
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>

                  <div className="p-4 space-y-4">
                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Prompt
                      </label>
                      <textarea
                        className="w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 transition-all"
                        value={step.prompt}
                        onChange={(e) =>
                          handleChainItemChange(index, "prompt", e.target.value)
                        }
                        placeholder="What should the AI ask or say?"
                        rows={3}
                      />
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Acceptance Criteria
                      </label>
                      <textarea
                        className="w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 transition-all"
                        value={step.acceptance_criteria}
                        onChange={(e) =>
                          handleChainItemChange(
                            index,
                            "acceptance_criteria",
                            e.target.value
                          )
                        }
                        placeholder="What conditions need to be met to proceed? Preferably a list of bullet points."
                        rows={2}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Transition To Next
                        </label>
                        <textarea
                          className="w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 transition-all"
                          value={step.transition_to}
                          onChange={(e) =>
                            handleChainItemChange(
                              index,
                              "transition_to",
                              e.target.value
                            )
                          }
                          placeholder="How should we transition to the next step?"
                          rows={2}
                        />
                      </div>
                      <div>
                        <label className="block text-sm font-medium text-gray-700 mb-2">
                          Transition From Previous
                        </label>
                        <textarea
                          className="w-full rounded-lg border border-gray-200 bg-gray-50 px-4 py-3 placeholder-gray-400 focus:border-indigo-500 focus:ring-indigo-500 transition-all"
                          value={step.transition_from}
                          onChange={(e) =>
                            handleChainItemChange(
                              index,
                              "transition_from",
                              e.target.value
                            )
                          }
                          placeholder="How should we transition from the previous step?"
                          rows={2}
                        />
                      </div>
                    </div>

                    <div>
                      <label className="block text-sm font-medium text-gray-700 mb-2">
                        Estimated Length
                      </label>
                      <div className="flex items-center space-x-2">
                        <input
                          type="number"
                          className="w-24 rounded-lg border border-gray-200 bg-gray-50 px-4 py-2 focus:border-indigo-500 focus:ring-indigo-500 transition-all"
                          value={step.estimated_length}
                          onChange={(e) =>
                            handleChainItemChange(
                              index,
                              "estimated_length",
                              parseInt(e.target.value)
                            )
                          }
                          min="1"
                        />
                        <span className="text-sm text-gray-500">messages</span>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          <button
            onClick={addChainStep}
            className="w-full py-4 px-6 border-2 border-dashed border-gray-200 rounded-lg text-sm font-medium text-gray-600 hover:border-indigo-500 hover:text-indigo-600 hover:bg-indigo-50/50 transition-all flex items-center justify-center space-x-2"
          >
            <svg
              className="w-5 h-5"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
              />
            </svg>
            <span>Add New Step</span>
          </button>
        </div>
      )}
    </div>
  );
};

export default ModulePromptEditor;
