import React, { useState, useEffect } from "react";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function UserDetails({ userId, onBack }) {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayLimit, setDisplayLimit] = useState(20);
  const [showAllLogs, setShowAllLogs] = useState(false);
  const [creatingScorecard, setCreatingScorecard] = useState(false);

  useEffect(() => {
    const fetchUser = async () => {
      try {
        const apiKey = localStorage.getItem("adminApiKey");
        const response = await axios.get(`/api/admin/user/${userId}`, {
          headers: { "Api-Key": apiKey },
        });
        setUser(response.data);
      } catch (error) {
        console.error("Error fetching user details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUser();
  }, [userId]);

  async function turnOffShutdownMode() {
    try {
      const apiKey = localStorage.getItem("adminApiKey");
      await axios.put(
        `/api/admin/user/${userId}/shutdown_mode`,
        {},
        {
          headers: { "Api-Key": apiKey },
        }
      );
      setUser((prevUser) => ({
        ...prevUser,
        shutdown_mode: false,
      }));
    } catch (error) {
      console.error("Error turning off shutdown mode:", error);
    }
  }

  async function createScorecard() {
    try {
      setCreatingScorecard(true);
      const apiKey = localStorage.getItem("adminApiKey");
      const response = await axios.post(
        `/api/admin/scorecard/${user.web_id}`,
        {},
        {
          headers: { "Api-Key": apiKey },
        }
      );
      alert("Scorecard created successfully!");
    } catch (error) {
      console.error("Error creating scorecard:", error);
      alert("Failed to create scorecard");
    } finally {
      setCreatingScorecard(false);
    }
  }

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <button
          onClick={onBack}
          className="text-gray-400 hover:text-white mr-4 flex items-center"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Back
        </button>
        <h1 className="text-2xl mb-0 font-bold text-white">User Details</h1>
      </div>

      {user && (
        <>
          {user.shutdown_mode && (
            <div className="bg-red-900/50 border border-red-500 rounded-lg p-6 space-y-4">
              <h2 className="text-xl font-bold text-red-400">
                Shutdown Mode Active
              </h2>
              <p className="text-red-300">
                This user has been placed in temporary shutdown mode by the 1440
                system due to a high risk assessment. The user's access to the
                1440 platform and its features has been suspended.
              </p>
              <button
                onClick={turnOffShutdownMode}
                className="bg-red-500 hover:bg-red-600 text-white font-medium py-2 px-4 rounded"
              >
                Turn off shutdown mode
              </button>
            </div>
          )}

          <div className="bg-slate-800 rounded-lg p-6">
            <div className="space-y-4">
              {user.email && (
                <div>
                  <label className="text-gray-400">Email</label>
                  <div className="text-white">{user.email}</div>
                </div>
              )}
              {user.name && (
                <div>
                  <label className="text-gray-400">Name</label>
                  <div className="text-white">{user.name}</div>
                </div>
              )}
              <div>
                <label className="text-gray-400">User ID</label>
                <div className="text-white">{user.web_id}</div>
              </div>
              <div>
                <button
                  onClick={createScorecard}
                  disabled={creatingScorecard}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded disabled:opacity-50"
                >
                  {creatingScorecard
                    ? "Creating Scorecard..."
                    : "Create Scorecard"}
                </button>
              </div>
              {user.core_memory && (
                <div>
                  <label className="text-gray-400">Core Memory</label>
                  <table className="text-white w-full mt-2">
                    <tbody>
                      {Object.entries(user.core_memory).map(
                        ([key, value]) =>
                          key &&
                          value && (
                            <tr key={key} className="border-b border-gray-700">
                              <td className="text-gray-400 py-2 pr-4">{key}</td>
                              <td className="py-2">{value}</td>
                            </tr>
                          )
                      )}
                    </tbody>
                  </table>
                </div>
              )}
            </div>
          </div>

          {user.headline_summary && (
            <div className="bg-slate-800 rounded-lg p-6">
              <label className="text-gray-400 block mb-2">User Overview</label>
              <div className="text-white">{user.headline_summary}</div>
            </div>
          )}

          {user.log && user.log.length > 0 && (
            <div className="bg-slate-800 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-white">
                  Conversation History
                </h2>
                <button
                  onClick={() => setShowAllLogs(!showAllLogs)}
                  className="text-blue-400 hover:text-blue-300 text-sm"
                >
                  {showAllLogs ? "Show Less" : "Show All"}
                </button>
              </div>
              <div className="space-y-4">
                {user.log
                  .slice(0, showAllLogs ? user.log.length : displayLimit)
                  .map((logEntry, index, logArray) => {
                    const showVia =
                      index === 0 || logEntry.via !== logArray[index - 1].via;
                    const isUser = logEntry.role.toLowerCase() === "user";
                    return (
                      <div key={index}>
                        {showVia && (
                          <div className="text-gray-200 uppercase text-center my-8 px-2 py-1">
                            <span className="border rounded border-gray-200 py-4 px-6">
                              {logEntry.via}
                            </span>
                          </div>
                        )}
                        <div
                          className={`flex ${
                            isUser ? "justify-end" : "justify-start"
                          }`}
                        >
                          <div
                            className={`max-w-[80%] rounded-2xl px-4 py-2 ${
                              isUser
                                ? "bg-blue-600 text-white"
                                : "bg-slate-700 text-gray-100"
                            }`}
                          >
                            <div className="text-sm">{logEntry.content}</div>
                            <div
                              className={`text-xs mt-1 ${
                                isUser ? "text-blue-200" : "text-gray-400"
                              }`}
                            >
                              {logEntry.time}
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
              {!showAllLogs && user.log.length > displayLimit && (
                <button
                  onClick={() => setDisplayLimit((prev) => prev + 20)}
                  className="mt-6 bg-slate-700 hover:bg-slate-600 transition-colors text-white px-4 py-2 rounded-lg w-full text-sm"
                >
                  Load More
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
