import React, { useState, useEffect, useRef } from "react";
import axios from "axios";

export default function AIConversations({ onSelectConversation }) {
  const [testingUsers, setTestingUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [currentConversation, setCurrentConversation] = useState([]);
  const [theme, setTheme] = useState("");
  const [numMessages, setNumMessages] = useState(20);
  const messagesEndRef = useRef(null);
  const [currentConversationId, setCurrentConversationId] = useState(null);
  const [eventSource, setEventSource] = useState(null);

  useEffect(() => {
    fetchTestingUsers();
    return () => {
      // Cleanup EventSource on component unmount
      if (eventSource) {
        eventSource.close();
      }
    };
  }, []);

  const scrollToBottom = () => {};

  useEffect(() => {
    scrollToBottom();
  }, [currentConversation]);

  const fetchTestingUsers = async () => {
    try {
      setLoading(true);
      const response = await axios.get(
        "https://api.1440.ai/api/admin/testing-conversations",
        {
          headers: {
            "Api-Key":
              "secret_1440_internal_key_do_not_share_with_anyone_1gr2u5htdtwg5iEGNeqt4i",
          },
        }
      );
      setTestingUsers(response.data);
    } catch (error) {
      console.error("Error fetching testing users:", error);
    } finally {
      setLoading(false);
    }
  };

  const startNewConversation = async () => {
    // Close any existing EventSource
    if (eventSource) {
      eventSource.close();
    }

    setIsGenerating(true);
    setCurrentConversation([]);

    try {
      const response = await axios.post(
        "/api/admin/start-conversation",
        {
          theme: theme || undefined,
          num_messages: numMessages || undefined,
        },
        {
          headers: {
            "Api-Key":
              "secret_1440_internal_key_do_not_share_with_anyone_1gr2u5htdtwg5iEGNeqt4i",
          },
        }
      );

      const data = response.data;
      setCurrentConversationId(data.web_id);

      // Create new EventSource
      const newEventSource = new EventSource(
        `https://api.1440.ai/api/admin/conversation-stream/${data.web_id}/secret_1440_internal_key_do_not_share_with_anyone_1gr2u5htdtwg5iEGNeqt4i`
      );

      newEventSource.onmessage = (event) => {
        const message = JSON.parse(event.data);
        setCurrentConversation((prev) => [...prev, message]);
      };

      newEventSource.onerror = (error) => {
        console.error("EventSource error:", error);
        newEventSource.close();
        setIsGenerating(false);
        setEventSource(null);
      };

      setEventSource(newEventSource);
      fetchTestingUsers(); // Refresh the list after creating new conversation
    } catch (error) {
      console.error("Error starting conversation:", error);
      setIsGenerating(false);
    }
  };

  return (
    <div className="space-y-4">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-white">AI Conversations</h1>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
        {/* Left Column - Conversation List */}
        <div className="space-y-4">
          <div className="bg-slate-800 rounded-lg p-4">
            <h2 className="text-xl font-bold text-white mb-4">
              Generate New AI Conversation
            </h2>
            <div className="space-y-4">
              <div>
                <label className="block text-gray-300 mb-2">
                  Theme (Optional)
                </label>
                <input
                  type="text"
                  value={theme}
                  onChange={(e) => setTheme(e.target.value)}
                  placeholder="Enter conversation theme..."
                  className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isGenerating}
                />
              </div>
              <div>
                <label className="block text-gray-300 mb-2">
                  Number of Messages
                </label>
                <input
                  type="number"
                  value={numMessages}
                  onChange={(e) => setNumMessages(parseInt(e.target.value))}
                  min="1"
                  max="50"
                  className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
                  disabled={isGenerating}
                />
              </div>
              <button
                onClick={startNewConversation}
                disabled={isGenerating}
                className={`w-full px-4 py-2 rounded-lg ${
                  isGenerating
                    ? "bg-gray-500 cursor-not-allowed"
                    : "bg-blue-600 hover:bg-blue-700"
                } text-white`}
              >
                {isGenerating
                  ? "Generating..."
                  : "Generate New Test Conversation"}
              </button>
            </div>
          </div>

          <div className="bg-slate-800 rounded-lg p-4">
            <h2 className="text-xl font-bold text-white mb-4">
              All AI Conversations
            </h2>
            {loading ? (
              <div className="text-gray-400 text-center py-4">
                Loading AI conversations...
              </div>
            ) : (
              <div className="space-y-2">
                {testingUsers.map((user) => (
                  <div
                    key={user.web_id}
                    onClick={() => onSelectConversation(user.web_id)}
                    className="bg-slate-700 p-4 rounded-lg hover:bg-slate-600 cursor-pointer"
                  >
                    <div className="text-white font-medium">
                      ID: {user.web_id}
                    </div>
                    <div className="text-gray-300 text-sm">
                      Messages: {user.log?.length || 0}
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Current Conversation */}
        <div className="space-y-4">
          <div className="bg-slate-800 rounded-lg p-4 h-[600px] overflow-y-auto">
            {currentConversation.length === 0 ? (
              <div className="text-gray-400 text-center mt-4">
                No conversation selected or generated yet.
              </div>
            ) : (
              currentConversation.map((message, index) => (
                <div
                  key={index}
                  className={`mb-4 ${
                    message.role === "assistant"
                      ? "text-blue-400"
                      : "text-green-400"
                  }`}
                >
                  <span className="font-bold">
                    {message.role === "assistant" ? "Coach: " : "User: "}
                  </span>
                  <span className="text-white">{message.content}</span>
                </div>
              ))
            )}
            <div ref={messagesEndRef} />

            {isGenerating && (
              <div className="text-gray-400 animate-pulse">
                Generating conversation...
              </div>
            )}
          </div>

          {currentConversationId && (
            <div className="bg-slate-800 rounded-lg p-4">
              <h3 className="text-white font-bold">Current Conversation ID:</h3>
              <p className="text-gray-300">{currentConversationId}</p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
