import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import PieChart from "./PieChart";
import MessageDisplay from "./MessageDisplay";
import Timeline from "./Timeline";
import { SEQUENCES, COLORS } from "./timeConstants";
import {
  calculateTimeBreakdown,
  getRemainingTimeBreakdown,
  getTransformedTimeBreakdown,
  getInterpolatedBreakdown,
} from "./timeCalculations";

// Simplified messages for the animation
const messages = {
  introduction: {
    message: "What do you want to do with your time?",
    subMessage: "Tap to continue",
  },
  screenTime: {
    message:
      "People like you spend 80% of their remaining time on screens and distractions",
    subMessage: "Imagine what you could do with that time instead",
  },
  remainder: {
    message: "They only leave limited time for activities they truly value",
    subMessage: "like family, personal growth and hobbies",
  },
  transform: {
    message: "1440 empowers you to spend your time intentionally",
    subMessage: "See how your time transforms over the course of a year",
  },
  conclusion: {
    message: "So you can have more time for what matters",
    subMessage:
      "More social connections, personal growth, and hobbies you love. Less screens and distractions.",
  },
};

const TimeAnimation = ({ results, content, onSequenceChange }) => {
  const { title } = content || { title: "Your Daily 1440 Minutes" };
  const [sequence, setSequence] = useState(SEQUENCES.INTRO);
  const [message, setMessage] = useState("");
  const [subMessage, setSubMessage] = useState("");
  const [pieData, setPieData] = useState([]);
  const [focusedSegment, setFocusedSegment] = useState(null);
  const [expandedSegment, setExpandedSegment] = useState(null);

  // Simplified state for transformation
  const [timeProgress, setTimeProgress] = useState(0);
  const [transformationComplete, setTransformationComplete] = useState(false);

  // Update parent component when sequence changes
  useEffect(() => {
    if (onSequenceChange) {
      onSequenceChange(sequence);
    }
  }, [sequence, onSequenceChange]);

  // Run animation sequence
  useEffect(() => {
    const timeData = calculateTimeBreakdown(results);
    const timers = [];

    // INTRO sequence
    if (sequence === SEQUENCES.INTRO) {
      setMessage("Your life is made of 1440 minutes every day");
      setTimeProgress(0);

      // Initialize with empty pie data to ensure component mounts
      setPieData([]);

      timers.push(
        setTimeout(() => {
          setMessage("Here's how you're currently spending them");
          setSequence(SEQUENCES.WORK_SEGMENT);
        }, 3000)
      );
    }

    // WORK_SEGMENT sequence
    if (sequence === SEQUENCES.WORK_SEGMENT) {
      setPieData([timeData[0]]);
      setFocusedSegment("work");
      setExpandedSegment(null);

      const workHours = Math.floor(timeData[0].minutes / 60);
      const workMinutes = timeData[0].minutes % 60;
      const workText =
        workMinutes > 0
          ? `You work ${timeData[0].minutes} minutes per day (${workHours} hrs ${workMinutes} min)`
          : `You work ${timeData[0].minutes} minutes per day (${workHours} hrs)`;

      setMessage(workText);

      timers.push(
        setTimeout(() => {
          setSequence(SEQUENCES.SLEEP_SEGMENT);
        }, 4000)
      );
    }

    // SLEEP_SEGMENT sequence
    if (sequence === SEQUENCES.SLEEP_SEGMENT) {
      setPieData(timeData.slice(0, 2));
      setFocusedSegment("sleep");
      setExpandedSegment(null);

      const sleepHours = Math.floor(timeData[1].minutes / 60);
      const sleepMinutes = timeData[1].minutes % 60;
      const sleepText =
        sleepMinutes > 0
          ? `You sleep ${timeData[1].minutes} minutes per day (${sleepHours} hrs ${sleepMinutes} min)`
          : `You sleep ${timeData[1].minutes} minutes per day (${sleepHours} hrs)`;

      setMessage(sleepText);

      timers.push(
        setTimeout(() => {
          setSequence(SEQUENCES.EXERCISE_SEGMENT);
        }, 4000)
      );
    }

    // EXERCISE_SEGMENT sequence
    if (sequence === SEQUENCES.EXERCISE_SEGMENT) {
      setPieData(timeData.slice(0, 3));
      setFocusedSegment("exercise");
      setExpandedSegment(null);

      setMessage(`You exercise ${timeData[2].minutes} minutes per day`);

      timers.push(
        setTimeout(() => {
          setSequence(SEQUENCES.REMAINING_INTRO);
        }, 4000)
      );
    }

    // REMAINING_INTRO sequence
    if (sequence === SEQUENCES.REMAINING_INTRO) {
      setPieData(timeData);
      setFocusedSegment("remaining");
      setExpandedSegment(null);

      setMessage(`What about your remaining ${timeData[3].minutes} minutes?`);

      timers.push(
        setTimeout(() => {
          setSequence(SEQUENCES.REMAINING_SHOW);
        }, 4000)
      );
    }

    // REMAINING_SHOW sequence
    if (sequence === SEQUENCES.REMAINING_SHOW) {
      // Create personalized message based on user data
      const age = results.screen4 || ""; // Age from screen 4
      const gender = results.screen3 || ""; // Gender from screen 3

      let personalizedMessage = `This is how the average person spends their remaining ${timeData[3].minutes} minutes`;

      // If we have age and gender, make the message more personalized
      if (gender && age) {
        personalizedMessage = `This is how the average ${age} year old ${
          gender.toLowerCase() === "male"
            ? "man"
            : gender.toLowerCase() === "female"
            ? "woman"
            : gender.toLowerCase()
        } spends their remaining ${timeData[3].minutes} minutes`;
      } else if (age) {
        personalizedMessage = `This is how the average ${age} year old spends their remaining ${timeData[3].minutes} minutes`;
      } else if (gender) {
        personalizedMessage = `This is how the average ${gender.toLowerCase()} spends their remaining ${
          timeData[3].minutes
        } minutes`;
      }

      console.log("REMAINING_SHOW data:", {
        age,
        gender,
        remainingMinutes: timeData[3].minutes,
      });

      setMessage(personalizedMessage);

      // Use a fixed screen time percentage (70% is the default for the average person)
      const screenTimePercentage = 70;

      // Create the remaining time data with the fixed screen time percentage
      const remainingData = getRemainingTimeBreakdown(
        timeData[3].minutes,
        screenTimePercentage
      );

      // Set a special property on the first segment to indicate total remaining minutes
      remainingData[0].totalRemainingMinutes = timeData[3].minutes;

      setPieData(remainingData);
      setFocusedSegment("remaining");

      // Use a timeout to ensure expandedSegment is set after data is loaded
      setTimeout(() => {
        setExpandedSegment("remaining");
      }, 50);

      timers.push(
        setTimeout(() => {
          setSequence(SEQUENCES.ACTIVITIES_BREAKDOWN);
        }, 5000)
      );
    }

    // ACTIVITIES_BREAKDOWN sequence
    if (sequence === SEQUENCES.ACTIVITIES_BREAKDOWN) {
      // Use a fixed screen time percentage (70% is the default for the average person)
      const screenTimePercentage = 70;

      const remainingTimeActivities = getRemainingTimeBreakdown(
        timeData[3].minutes,
        screenTimePercentage
      );

      // Preserve the total remaining minutes in the data
      if (remainingTimeActivities.length > 0) {
        remainingTimeActivities[0].totalRemainingMinutes = timeData[3].minutes;
      }

      setPieData(remainingTimeActivities);
      setFocusedSegment(null);
      setExpandedSegment(null);

      // Calculate combined screen and distraction percentage
      const screenTimeMinutes = remainingTimeActivities[0]?.minutes || 0;
      const distractionsMinutes = remainingTimeActivities[1]?.minutes || 0;
      const totalRemainingMinutes = timeData[3].minutes;
      const combinedPercentage = Math.round(
        ((screenTimeMinutes + distractionsMinutes) / totalRemainingMinutes) *
          100
      );

      setMessage(
        messages.screenTime.message.replace("80%", `${combinedPercentage}%`)
      );
      setSubMessage(messages.screenTime.subMessage);

      timers.push(
        setTimeout(() => {
          setFocusedSegment("screenTime");
        }, 2000)
      );

      timers.push(
        setTimeout(() => {
          setFocusedSegment("distractions");
        }, 4000)
      );

      timers.push(
        setTimeout(() => {
          setFocusedSegment(null);
          setMessage(messages.remainder.message);
          setSubMessage(messages.remainder.subMessage);
        }, 7000)
      );

      timers.push(
        setTimeout(() => {
          // Cycle through valuable categories
          setFocusedSegment("social");
          timers.push(
            setTimeout(() => {
              setFocusedSegment("growth");
              timers.push(
                setTimeout(() => {
                  setFocusedSegment("hobbies");
                }, 2000)
              );
            }, 2000)
          );
        }, 9000)
      );

      timers.push(
        setTimeout(() => {
          setFocusedSegment(null);
          setMessage(messages.transform.message);
          setSubMessage(messages.transform.subMessage);
        }, 15000)
      );

      timers.push(
        setTimeout(() => {
          setSequence(SEQUENCES.TRANSFORMATION);
        }, 18000)
      );
    }

    // TRANSFORMATION sequence - COMPLETELY REWRITTEN
    if (sequence === SEQUENCES.TRANSFORMATION) {
      // Create personalized message

      let personalizedMessage = "Shift your time to what matters with 1440";

      setMessage(personalizedMessage);

      // Reset states
      setTimeProgress(0);
      setTransformationComplete(false);

      // Set initial pie data - ensure segment labels are visible by using expandedSegment
      const initialData = getRemainingTimeBreakdown(timeData[3].minutes);

      // Preserve the total remaining minutes in the data
      if (initialData.length > 0) {
        initialData[0].totalRemainingMinutes = timeData[3].minutes;
      }

      setPieData(initialData);

      // Make sure we're not focusing on any specific segment so all labels show
      setFocusedSegment(null);
      setExpandedSegment(null);

      // Set initial submessage
      setSubMessage("Assessing lifestyle patterns");

      // Create a closure for the timer IDs to ensure proper cleanup
      const timerIds = [];

      // We'll use a fixed 15-second duration for the transformation
      const transformationDuration = 7; // 15 seconds total
      const transformationSteps = 200; // Update every 100ms
      const progressIncrement = 40 / transformationSteps;

      // Start a controlled interval to smoothly update progress
      const progressInterval = setInterval(() => {
        setTimeProgress((prevProgress) => {
          // Calculate new progress
          let newProgress = prevProgress + progressIncrement;

          // Apply smoothing to ensure we reach exactly 100% at the end
          if (newProgress > 99 && newProgress < 100) {
            newProgress = 100;
          }

          // Update pie chart data based on current progress
          if (newProgress <= 100) {
            const interpolatedData = getInterpolatedBreakdown(
              timeData[3].minutes,
              newProgress
            );

            // Preserve the total remaining minutes in interpolated data
            if (interpolatedData.length > 0) {
              interpolatedData[0].totalRemainingMinutes = timeData[3].minutes;
            }

            setPieData(interpolatedData);
          }

          // Apply milestone messages at key points
          if (newProgress >= 33 && prevProgress < 33) {
            setSubMessage("Optimizing your daily schedule");
          } else if (newProgress >= 66 && prevProgress < 66) {
            setSubMessage("Addressing digital distractions");
          } else if (newProgress >= 100 && prevProgress < 100) {
            setSubMessage("Building meaningful habits");
            setTransformationComplete(true);

            // Schedule end of transformation after a delay
            const finalTimer = setTimeout(() => {
              // Show final message
              setMessage("After one year with 1440");
              setSubMessage("This is how your remaining time could look");

              // Schedule transition to conclusion
              const conclusionTimer = setTimeout(() => {
                setSequence(SEQUENCES.CONCLUSION);
              }, 5000);

              timerIds.push(conclusionTimer);
            }, 2000);

            timerIds.push(finalTimer);
            clearInterval(progressInterval);
          }

          return newProgress;
        });
      }, 20);

      // Add interval to cleanup
      timers.push(() => {
        clearInterval(progressInterval);
        timerIds.forEach((id) => clearTimeout(id));
      });
    }

    // CONCLUSION sequence
    if (sequence === SEQUENCES.CONCLUSION) {
      const transformedBreakdown = getTransformedTimeBreakdown(
        timeData[3].minutes
      );

      // Preserve the total remaining minutes
      if (transformedBreakdown.length > 0) {
        transformedBreakdown[0].totalRemainingMinutes = timeData[3].minutes;
      }

      setPieData(transformedBreakdown);
      setExpandedSegment(null);

      setMessage(messages.conclusion.message);
      setSubMessage(messages.conclusion.subMessage);

      timers.push(
        setTimeout(() => {
          setFocusedSegment(null);
          setMessage("Ready to reclaim your 1440 minutes each day?");
          setSubMessage("Let's create your personalized time plan");

          timers.push(
            setTimeout(() => {
              // Before advancing to COMPLETE, save the final state
              // This prevents animation resetting
              const finalState = {
                pieData: transformedBreakdown,
                message: "Ready to reclaim your 1440 minutes each day?",
                subMessage: "Let's create your personalized time plan",
              };

              // Move to COMPLETE sequence but preserve current visual state
              setSequence(SEQUENCES.COMPLETE);

              // Make sure we don't reset visual state when changing sequence
              setTimeout(() => {
                setPieData(finalState.pieData);
                setMessage(finalState.message);
                setSubMessage(finalState.subMessage);
              }, 50);
            }, 5000)
          );
        }, 5000)
      );
    }

    // COMPLETE sequence - add this sequence to handle final state properly
    if (sequence === SEQUENCES.COMPLETE) {
      // In the COMPLETE state, we don't schedule any more animations
      // We just maintain the current visual state
      // This ensures we don't jump back to the beginning

      // Use the transformed breakdown data for the final state
      const transformedBreakdown = getTransformedTimeBreakdown(
        timeData[3].minutes
      );

      // Preserve the total remaining minutes
      if (transformedBreakdown.length > 0) {
        transformedBreakdown[0].totalRemainingMinutes = timeData[3].minutes;
      }

      // Only set these if they're not already set (to prevent flashing)
      if (pieData.length === 0) {
        setPieData(transformedBreakdown);
        setMessage("Ready to reclaim your 1440 minutes each day?");
        setSubMessage("Let's create your personalized time plan");
        setFocusedSegment(null);
        setExpandedSegment(null);
      }
    }

    return () => {
      // Clean up timers - handle both functions and timeouts
      timers.forEach((timer) => {
        if (typeof timer === "function") {
          timer();
        } else {
          clearTimeout(timer);
        }
      });
    };
  }, [sequence, results]);

  return (
    <div className="flex flex-col items-center w-full h-full justify-between">
      {/* Message display at top */}
      <MessageDisplay message={message} subMessage={subMessage} title={title} />

      {/* Center-aligned pie chart */}
      <div className="flex justify-center items-center w-full h-72">
        <div className="w-72 h-72 relative">
          <PieChart
            data={pieData}
            focusedSegment={focusedSegment}
            expandedSegment={expandedSegment}
            isTransforming={false} // Always false to prevent the shifting effect
          />
        </div>
      </div>

      {/* Timeline at bottom - only show during transformation */}
      <div className="w-full">
        {sequence >= SEQUENCES.TRANSFORMATION && (
          <Timeline progress={timeProgress} />
        )}
      </div>
    </div>
  );
};

export default TimeAnimation;
