import React from "react";
import { motion } from "framer-motion";
import { primary } from "../../theme/variables";
import "../../theme/theme.css";

const Timeline = ({ progress }) => {
  // Milestones evenly spaced at thirds
  const milestones = [
    { label: "Week 1 using 1440", value: 33 },
    { label: "Month 1 using 1440", value: 66 },
    { label: "Year 1 using 1440", value: 100 },
  ];

  // Determine current milestone text to display
  const getCurrentMilestoneText = () => {
    if (progress >= 66) return "Year 1 using 1440";
    if (progress >= 33) return "Month 1 using 1440";
    return "Week 1 using 1440";
  };

  return (
    <div className="w-full px-6 py-6 mt-8 mb-4 rounded-lg">
      {/* Progress bar with improved styling */}
      <div
        className="w-full h-2 rounded-full overflow-hidden mb-6 relative shadow-inner"
        style={{ backgroundColor: "rgba(30, 41, 59, 0.8)" }} // slate-800 at 80% opacity
      >
        {/* Gradient background for progress bar */}
        <motion.div
          className="h-full rounded-full"
          style={{
            background: `linear-gradient(90deg, ${primary[700]} 0%, ${primary[500]} 100%)`,
            boxShadow: `0 0 5px ${primary[500]}66`, // 40% opacity
          }}
          initial={{ width: "0%" }}
          animate={{ width: `${progress}%` }}
          transition={{ duration: 0.2 }}
        />

        {/* Milestone ticks without dots */}
        {milestones.map((milestone) => (
          <div
            key={milestone.label}
            className="absolute top-0 transform -translate-x-1/2"
            style={{ left: `${milestone.value}%` }}
          >
            <div
              className="w-0.5 h-3 mt-3"
              style={{
                backgroundColor:
                  progress >= milestone.value ? primary[400] : "#475569", // slate-600
              }}
            />
            <div
              className="text-xs font-medium mt-1.5 transform -translate-x-1/2"
              style={{
                position: "absolute",
                left: "50%",
                width: "max-content",
                color: progress >= milestone.value ? primary[400] : "#475569", // slate-600
              }}
            >
              {milestone.label}
            </div>
          </div>
        ))}
      </div>

      {/* Single centered milestone text that changes */}
      <motion.div
        className="text-center text-sm font-medium mt-8"
        style={{ color: primary[300] }}
        key={getCurrentMilestoneText()} // Key changes trigger animation
        initial={{ opacity: 0, y: -5 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.3 }}
      >
        {getCurrentMilestoneText()}
      </motion.div>
    </div>
  );
};

export default Timeline;
