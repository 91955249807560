import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { primary, text, border, gradient } from "../../theme/variables";
import "../../theme/theme.css";

const PieChart = ({
  data,
  focusedSegment,
  expandedSegment,
  isTransforming,
}) => {
  // Track segments that need to be animated
  const [animatingSegments, setAnimatingSegments] = useState([]);
  const [prevData, setPrevData] = useState([]);
  const [transformProgress, setTransformProgress] = useState(0);

  // Track when we're showing remaining time to animate the number
  const [displayValue, setDisplayValue] = useState(1440);

  // Track when we've shown the remaining time view to prevent going back to 1440
  const [hasShownRemainingTime, setHasShownRemainingTime] = useState(false);

  // Track the total remaining minutes so we have a consistent value to display
  const [totalRemainingMinutes, setTotalRemainingMinutes] = useState(null);

  // Effect to handle animations when data changes
  useEffect(() => {
    if (data.length > 0) {
      setPrevData(data);
      setAnimatingSegments(data.map((item) => item.id));

      // Log data changes when expanded segment is "remaining"
      if (expandedSegment === "remaining") {
        console.log("Data changed with remaining segment:", data[0]?.minutes);
      }

      // Remove animation flag after segments are done animating
      const timer = setTimeout(() => {
        setAnimatingSegments([]);
      }, 1000);

      return () => clearTimeout(timer);
    }
  }, [data, expandedSegment]);

  // Effect to animate transformation
  useEffect(() => {
    if (isTransforming) {
      setTransformProgress(0);
      const timer = setInterval(() => {
        setTransformProgress((prev) => {
          const newValue = prev + 0.05;
          if (newValue >= 1) {
            clearInterval(timer);
            return 1;
          }
          return newValue;
        });
      }, 50);
      return () => clearInterval(timer);
    } else {
      setTransformProgress(0);
    }
  }, [isTransforming]);

  // Effect to smoothly animate the value change
  useEffect(() => {
    // Only update if we're showing remaining time breakdown (more reliable condition)
    if (expandedSegment === "remaining" && data.length > 0) {
      // Set flag to prevent going back to 1440
      if (!hasShownRemainingTime) {
        setHasShownRemainingTime(true);
      }

      const targetValue = data[0]?.minutes || 0;
      // Don't animate if already showing the right number
      if (displayValue === targetValue) return;

      const startValue = 1440;
      const duration = 1000; // 1 second animation
      const fps = 60;
      const frames = duration / (1000 / fps);
      const increment = (startValue - targetValue) / frames;

      let currentValue = startValue;
      let frame = 0;

      const timer = setInterval(() => {
        frame++;
        currentValue -= increment;

        if (frame >= frames) {
          clearInterval(timer);
          setDisplayValue(targetValue);
        } else {
          setDisplayValue(Math.round(currentValue));
        }
      }, 1000 / fps);

      return () => clearInterval(timer);
    } else if (!hasShownRemainingTime) {
      // Only set to 1440 if we haven't shown the remaining time yet
      setDisplayValue(1440);
    }
  }, [expandedSegment, data, hasShownRemainingTime, displayValue]);

  // Effect for expanded segment changes
  useEffect(() => {
    if (expandedSegment === "remaining" && data.length > 0) {
      console.log(
        "Expanded segment changed to remaining. Minutes:",
        data[0]?.minutes
      );
    }
  }, [expandedSegment, data]);

  // Effect to capture the total remaining minutes when first set
  useEffect(() => {
    if (
      expandedSegment === "remaining" &&
      data.length > 0 &&
      data[0].totalRemainingMinutes &&
      !totalRemainingMinutes
    ) {
      console.log(
        "Setting total remaining minutes:",
        data[0].totalRemainingMinutes
      );
      setTotalRemainingMinutes(data[0].totalRemainingMinutes);
    }
  }, [expandedSegment, data, totalRemainingMinutes]);

  // Calculate segment positioning for pie chart
  const calculateSegments = () => {
    let cumulativePercentage = 0;
    return data.map((segment) => {
      const startPercentage = cumulativePercentage;
      const endPercentage = startPercentage + segment.percentage;
      cumulativePercentage = endPercentage;

      // Convert percentages to angles (0% = 0deg, 100% = 360deg)
      const startAngle = (startPercentage / 100) * 360 - 90;
      const endAngle = (endPercentage / 100) * 360 - 90;

      // Convert angles to radians
      const startRad = (startAngle * Math.PI) / 180;
      const endRad = (endAngle * Math.PI) / 180;

      // Calculate points for SVG arc (using inner and outer radius for doughnut)
      const innerRadius = 20; // Inner radius for doughnut hole
      const outerRadius = 45; // Outer radius (same as before)

      const outerX1 = outerRadius * Math.cos(startRad);
      const outerY1 = outerRadius * Math.sin(startRad);
      const outerX2 = outerRadius * Math.cos(endRad);
      const outerY2 = outerRadius * Math.sin(endRad);

      const innerX1 = innerRadius * Math.cos(endRad);
      const innerY1 = innerRadius * Math.sin(endRad);
      const innerX2 = innerRadius * Math.cos(startRad);
      const innerY2 = innerRadius * Math.sin(startRad);

      // Determine which arc to use
      const largeArcFlag = endAngle - startAngle <= 180 ? "0" : "1";

      // Create SVG path for doughnut segment
      const path = `
        M ${outerX1} ${outerY1}
        A ${outerRadius} ${outerRadius} 0 ${largeArcFlag} 1 ${outerX2} ${outerY2}
        L ${innerX1} ${innerY1}
        A ${innerRadius} ${innerRadius} 0 ${largeArcFlag} 0 ${innerX2} ${innerY2}
        Z
      `;

      return {
        ...segment,
        startAngle,
        endAngle,
        path,
        isAnimating: animatingSegments.includes(segment.id),
        isFocused: focusedSegment === segment.id,
        isExpanding: expandedSegment === segment.id,
      };
    });
  };

  // Calculate label positions for segments
  const getLabelPosition = (segment) => {
    const angle =
      (((segment.startAngle + segment.endAngle) / 2) * Math.PI) / 180;

    // Base distance is determined by segment size
    const segmentSize = segment.endAngle - segment.startAngle;
    let distance = 32; // Increased default distance for doughnut

    // Adjust distance based on segment size and focus
    if (segmentSize < 30) {
      distance = 30; // Smaller segments get closer labels
    } else if (segmentSize > 120) {
      distance = 35; // Larger segments get further labels
    }

    // Adjust for focus/expanding state
    if (segment.isFocused) {
      distance += 3;
    } else if (segment.isExpanding) {
      distance += 5;
    }

    return {
      x: distance * Math.cos(angle),
      y: distance * Math.sin(angle),
    };
  };

  // Get outer label position (for showing full activity name)
  const getOuterLabelPosition = (segment) => {
    const angle =
      (((segment.startAngle + segment.endAngle) / 2) * Math.PI) / 180;

    // Adjust distance based on segment size - make labels closer to pie chart
    const segmentSize = segment.endAngle - segment.startAngle;
    let distance = 56; // Increased distance for doughnut

    if (segmentSize < 45) {
      distance = 53;
    } else if (segmentSize > 90) {
      distance = 58;
    }

    // Always keep text upright, no rotation
    return {
      x: distance * Math.cos(angle),
      y: distance * Math.sin(angle),
      rotation: 0,
    };
  };

  // Simplify label text with clean category names
  const shortenLabel = (label) => {
    // Map original labels to clean, simple category names
    const categoryMap = {
      Exercise: "Exercise",
      "Working Out": "Exercise",
      Workout: "Exercise",
      "Physical Activity": "Exercise",

      "Remaining Time": "Remaining",
      "Free Time": "Remaining",
      "Extra Time": "Remaining",

      "Personal Growth": "Growth",
      Learning: "Growth",
      Development: "Growth",
      Studying: "Growth",
      Education: "Growth",
      "Professional Development": "Growth",

      "Family Time": "Social",
      Relationships: "Social",
      Social: "Social",
      Friends: "Social",

      "Screen Time": "Screen",
      Technology: "Screen",
      Entertainment: "Screen",
      TV: "Screen",
      "Social Media": "Screen",

      Work: "Work",
      Working: "Work",
      Professional: "Work",
      Job: "Work",

      Sleep: "Sleep",
      Rest: "Sleep",

      Health: "Health",
      Wellness: "Health",

      Leisure: "Hobbies",
      Hobbies: "Hobbies",
      Recreation: "Hobbies",
    };

    // Look for match in the category map
    for (const [original, simple] of Object.entries(categoryMap)) {
      if (label.includes(original)) {
        return simple;
      }
    }

    // If no match found, return the original label
    // If the label is too long (>10 chars), just return the first word
    if (label.length > 10) {
      const firstWord = label.split(" ")[0];
      return firstWord;
    }

    return label;
  };

  // Build segments
  const segments = calculateSegments();

  // Should we show an empty circle (for intro animation)
  const showEmptyCircle = data.length === 0;

  // Are we in expanded mode (showing just the remaining time)
  const isExpanded = expandedSegment !== null;

  // Only show outer labels when there are multiple segments
  const showOuterLabels = data.length > 0 && !isTransforming;

  // Disable scaling that causes overlapping
  const getScaleForSegment = (segment) => {
    // No scaling at all to prevent overlapping
    return 1;
  };

  // No expanded scale either
  const getExpandedScale = (segment) => {
    // No scaling for expanded segments
    return 1;
  };

  return (
    <div
      className="relative"
      style={{ width: "140%", height: "140%", left: "-20%", top: "-20%" }}
    >
      <svg viewBox="0 0 140 140" width="100%" height="100%">
        {/* Subtle tech background */}
        <rect width="140" height="140" fill="transparent" />

        {/* Subtle grid pattern */}
        <pattern
          id="techGrid"
          width="5"
          height="5"
          patternUnits="userSpaceOnUse"
        >
          <path
            d="M 5 0 L 0 0 0 5"
            fill="none"
            stroke="#1E293B"
            strokeWidth="0.2"
          />
        </pattern>
        <rect width="140" height="140" fill="url(#techGrid)" opacity="0.15" />

        {/* Center the pie chart in the expanded viewBox */}
        <g transform="translate(70,70)">
          {/* Empty circle for initial state */}
          {showEmptyCircle && (
            <motion.circle
              cx="0"
              cy="0"
              r="45"
              fill="transparent"
              stroke="#334155"
              strokeWidth="1"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ duration: 0.5 }}
            />
          )}

          {/* Guidelines for labels (subtle lines from center) */}
          {segments.length > 0 && !isTransforming && (
            <g className="label-guidelines">
              {segments.map((segment) => {
                // Don't show guidelines for tiny segments
                if (segment.percentage < 3) return null;

                // Calculate midpoint of segment
                const midAngle = (segment.startAngle + segment.endAngle) / 2;
                const midRad = (midAngle * Math.PI) / 180;

                // Line from inner radius to outer edge (for doughnut)
                const innerRadius = 20; // Match inner radius of doughnut
                const innerX = innerRadius * Math.cos(midRad);
                const innerY = innerRadius * Math.sin(midRad);
                const outerX = 65 * Math.cos(midRad);
                const outerY = 65 * Math.sin(midRad);

                return (
                  <motion.line
                    key={`guideline-${segment.id}`}
                    x1={innerX}
                    y1={innerY}
                    x2={outerX}
                    y2={outerY}
                    stroke="rgba(148,163,184,0.1)"
                    strokeWidth="0.3"
                    strokeDasharray="0.8,1.2"
                    initial={{ opacity: 0 }}
                    animate={{
                      opacity: segment.isFocused ? 0.6 : 0.2,
                    }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 0.5 }}
                  />
                );
              })}
            </g>
          )}

          {/* Transformation transition overlay */}
          {isTransforming && (
            <motion.g>
              {/* Flow lines between changing segments */}
              {prevData.length > 0 && data.length > 0 && (
                <>
                  {/* Tech-style data flow lines */}
                  <motion.path
                    d="M-20,0 Q0,-20 20,0"
                    fill="none"
                    stroke="rgba(148,163,184,0.5)"
                    strokeWidth="0.5"
                    strokeDasharray="1,2"
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 1, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1.5 }}
                  />
                  <motion.path
                    d="M-20,0 Q0,20 20,0"
                    fill="none"
                    stroke="rgba(148,163,184,0.5)"
                    strokeWidth="0.5"
                    strokeDasharray="1,2"
                    initial={{ pathLength: 0, opacity: 0 }}
                    animate={{ pathLength: 1, opacity: 1 }}
                    exit={{ opacity: 0 }}
                    transition={{ duration: 1.5, delay: 0.3 }}
                  />
                </>
              )}

              {/* Tech-style data particles */}
              {Array.from({ length: 12 }).map((_, i) => (
                <motion.circle
                  key={`particle-${i}`}
                  cx="0"
                  cy="0"
                  r={0.2 + Math.random() * 0.4}
                  fill="#94A3B8"
                  initial={{
                    x: Math.random() * 30 - 15,
                    y: Math.random() * 30 - 15,
                    opacity: 0,
                  }}
                  animate={{
                    x: Math.random() * 60 - 30,
                    y: Math.random() * 60 - 30,
                    opacity: [0, 0.8, 0],
                    scale: [0, 1, 0],
                  }}
                  transition={{
                    duration: 1.5 + Math.random() * 0.5,
                    delay: Math.random() * 0.8,
                    repeat: 1,
                    repeatType: "reverse",
                  }}
                />
              ))}

              {/* Tech-style central message */}
              <motion.rect
                x="-18"
                y="-4"
                width="36"
                height="8"
                rx="1"
                fill="#0F172A00"
                stroke="rgba(148,163,184,0.3)"
                strokeWidth="0.5"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.4 }}
              />
              <motion.text
                x="0"
                y="0"
                textAnchor="middle"
                alignmentBaseline="middle"
                fill="white"
                fontSize="3"
                fontWeight="normal"
                initial={{ opacity: 0, scale: 0.9 }}
                animate={{ opacity: 1, scale: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: 0.5 }}
              >
                Shifting time
              </motion.text>
            </motion.g>
          )}

          {/* Pie segments - render these before labels */}
          <AnimatePresence>
            {segments.map((segment) => {
              // Determine if we should show this segment in expanded mode
              const showInExpandedMode =
                !isExpanded || segment.id === expandedSegment;

              if (!showInExpandedMode) return null;

              // Scale segment if it's focused or expanding
              const scale = getScaleForSegment(segment);

              // Apply zooming effect for expanded segment
              const expandedScale = getExpandedScale(segment);

              return (
                <g key={segment.id}>
                  {/* Main segment path */}
                  <motion.path
                    d={segment.path}
                    fill={segment.color}
                    opacity={
                      segment.isFocused
                        ? 1
                        : isTransforming || focusedSegment
                        ? 0.85
                        : 0.95
                    }
                    initial={{
                      scale: 0.8,
                      opacity: 0,
                      transformOrigin: "center",
                    }}
                    animate={{
                      scale: segment.isExpanding ? expandedScale : scale,
                      opacity: segment.isFocused
                        ? 1
                        : isTransforming || focusedSegment
                        ? 0.85
                        : 0.95,
                      transformOrigin: "center",
                    }}
                    exit={{
                      scale: 0.8,
                      opacity: 0,
                      transformOrigin: "center",
                    }}
                    transition={{
                      type: "spring",
                      stiffness: 200,
                      damping: 25,
                      duration: 0.7,
                    }}
                    stroke="#00000000"
                    strokeWidth="0.5"
                  />
                </g>
              );
            })}
          </AnimatePresence>

          {/* Center doughnut hole with minutes text - render after segments */}
          {!showEmptyCircle && (
            <motion.g>
              {/* Subtle glow effect behind center circle */}
              <motion.circle
                cx="0"
                cy="0"
                r="22"
                fill="rgba(56, 189, 248, 0.1)"
                initial={{ opacity: 0 }}
                animate={{
                  opacity: [0.1, 0.2, 0.1],
                  scale: [1, 1.05, 1],
                }}
                transition={{
                  duration: 3,
                  repeat: Infinity,
                  repeatType: "reverse",
                }}
              />

              <motion.circle
                cx="0"
                cy="0"
                r="20"
                fill="#0F172A00"
                stroke="#334155"
                strokeWidth="0.5"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  boxShadow: [
                    "0px 0px 0px rgba(56, 189, 248, 0)",
                    "0px 0px 4px rgba(56, 189, 248, 0.3)",
                    "0px 0px 0px rgba(56, 189, 248, 0)",
                  ],
                }}
                transition={{
                  duration: 0.5,
                  boxShadow: {
                    duration: 2,
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                }}
              />

              {/* Minutes text in center */}
              <motion.text
                x="0"
                y="-2"
                textAnchor="middle"
                alignmentBaseline="middle"
                fill="white"
                fontSize="7"
                fontWeight="bold"
                initial={{ opacity: 0, scale: 0.8 }}
                animate={{
                  opacity: 1,
                  scale: 1,
                  textShadow: [
                    "0px 0px 0px rgba(255,255,255,0)",
                    "0px 0px 8px rgba(255,255,255,0.3)",
                    "0px 0px 0px rgba(255,255,255,0)",
                  ],
                }}
                transition={{
                  duration: 0.5,
                  delay: 0.2,
                  textShadow: {
                    duration: 2.5,
                    repeat: Infinity,
                    repeatType: "reverse",
                  },
                }}
              >
                {/* Use the saved totalRemainingMinutes if available */}
                {totalRemainingMinutes ? totalRemainingMinutes : 1440}
              </motion.text>

              {/* Minutes label */}
              <motion.text
                x="0"
                y="4"
                textAnchor="middle"
                alignmentBaseline="middle"
                fill="white"
                fontSize="3.5"
                fontWeight="500"
                initial={{ opacity: 0 }}
                animate={{ opacity: 0.9 }}
                transition={{ duration: 0.5, delay: 0.3 }}
              >
                {totalRemainingMinutes ? "remaining" : "minutes"}
              </motion.text>
            </motion.g>
          )}

          {/* Labels - rendered last to ensure they're on top */}
          <g className="labels-layer">
            <AnimatePresence>
              {segments.map((segment) => {
                // Determine if we should show this segment in expanded mode
                const showInExpandedMode =
                  !isExpanded || segment.id === expandedSegment;

                if (!showInExpandedMode) return null;
                if (segment.percentage < 0.5) return null;

                return (
                  <g key={`label-${segment.id}`}>
                    {/* Percentage label */}
                    <motion.text
                      x={getLabelPosition(segment).x}
                      y={getLabelPosition(segment).y}
                      textAnchor="middle"
                      alignmentBaseline="middle"
                      fill="white"
                      fontSize={
                        segment.percentage < 5
                          ? "3"
                          : segment.isFocused
                          ? "3.5"
                          : "3.2"
                      }
                      fontWeight={segment.isFocused ? "bold" : "600"}
                      stroke="#00000000"
                      strokeWidth="0.3"
                      paintOrder="stroke"
                      initial={{ opacity: 0 }}
                      animate={{
                        opacity: 1,
                        fontSize:
                          segment.percentage < 5
                            ? "3"
                            : segment.isFocused
                            ? "3.5"
                            : "3.2",
                      }}
                      exit={{ opacity: 0 }}
                      transition={{ duration: 0.4 }}
                    >
                      {Math.round(segment.percentage)}%
                    </motion.text>

                    {/* Show outer labels for segments */}
                    {segment.percentage >= 1 && showOuterLabels && (
                      <motion.g
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.4, delay: 0.2 }}
                      >
                        {/* Tech background for labels - smaller box with cooler styling */}
                        <motion.rect
                          x={getOuterLabelPosition(segment).x - 14}
                          y={getOuterLabelPosition(segment).y - 6}
                          width="28"
                          height="12"
                          rx="3"
                          fill="rgba(15,23,42,0.85)"
                          stroke={segment.color}
                          strokeWidth="0.8"
                          strokeOpacity="0.9"
                          style={{
                            transformOrigin: `${
                              getOuterLabelPosition(segment).x
                            }px ${getOuterLabelPosition(segment).y}px`,
                            transform: `rotate(${
                              getOuterLabelPosition(segment).rotation
                            }deg)`,
                          }}
                        />

                        {/* Subtle inner border glow */}
                        <motion.rect
                          x={getOuterLabelPosition(segment).x - 13.2}
                          y={getOuterLabelPosition(segment).y - 5.2}
                          width="26.4"
                          height="10.4"
                          rx="2.2"
                          fill="transparent"
                          stroke="rgba(255,255,255,0.15)"
                          strokeWidth="0.4"
                        />

                        {/* Label text - always upright and shortened */}
                        <motion.text
                          x={getOuterLabelPosition(segment).x}
                          y={getOuterLabelPosition(segment).y}
                          textAnchor="middle"
                          alignmentBaseline="middle"
                          fill="white"
                          fontSize="3.5"
                          fontWeight="600"
                          stroke="#00000000"
                          strokeWidth="0.2"
                          paintOrder="stroke"
                        >
                          {shortenLabel(segment.label)}
                        </motion.text>
                      </motion.g>
                    )}

                    {/* Minutes label (appears below percentage for segments) */}
                    {segment.percentage >= 1 && segment.minutes && (
                      <motion.text
                        x={getLabelPosition(segment).x}
                        y={getLabelPosition(segment).y + 4}
                        textAnchor="middle"
                        alignmentBaseline="middle"
                        fill="white"
                        fontSize="3"
                        fontWeight="500"
                        stroke="#00000000"
                        strokeWidth="0.2"
                        paintOrder="stroke"
                        opacity="0.9"
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 0.9 }}
                        exit={{ opacity: 0 }}
                        transition={{ duration: 0.3, delay: 0.3 }}
                      >
                        {segment.minutes}m
                      </motion.text>
                    )}
                  </g>
                );
              })}
            </AnimatePresence>
          </g>
        </g>
      </svg>
    </div>
  );
};

export default PieChart;
