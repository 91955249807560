import React, { useState, useEffect } from "react";
import axios from "axios";

export default function UserList({ onSelectUser }) {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(true);
  const [searchTerm, setSearchTerm] = useState("");
  const [sortField, setSortField] = useState("last_updated"); // "last_updated" or "messages_count"
  const [sortOrder, setSortOrder] = useState("desc"); // "asc" or "desc"
  const [displayLimit, setDisplayLimit] = useState(10);

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const apiKey = localStorage.getItem("adminApiKey");
        const response = await axios.get("/api/admin/users/summary", {
          headers: { "Api-Key": apiKey },
        });
        setUsers(response.data);
      } catch (error) {
        console.error("Error fetching users:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchUsers();
  }, []);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    const month = date.toLocaleString("default", { month: "short" });
    return `${month} ${date.getDate()}`;
  };

  const handleSort = (field) => {
    if (sortField === field) {
      setSortOrder(sortOrder === "desc" ? "asc" : "desc");
    } else {
      setSortField(field);
      setSortOrder("desc");
    }
  };

  const filteredAndSortedUsers = users
    .filter((user) =>
      user.web_id.toLowerCase().includes(searchTerm.toLowerCase())
    )
    .sort((a, b) => {
      if (sortField === "last_updated") {
        const dateA = new Date(a.last_updated);
        const dateB = new Date(b.last_updated);
        return sortOrder === "desc" ? dateB - dateA : dateA - dateB;
      } else {
        return sortOrder === "desc"
          ? b.messages_count - a.messages_count
          : a.messages_count - b.messages_count;
      }
    })
    .slice(0, displayLimit);

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="bg-slate-800 rounded-lg p-4">
      <div className="mb-4 flex items-center gap-4">
        <input
          type="text"
          placeholder="Search by user ID..."
          className="bg-slate-700 text-white px-4 py-2 rounded-lg flex-grow"
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
        />
        <button
          className={`bg-slate-700 text-white px-4 py-2 rounded-lg ${
            sortField === "last_updated" ? "bg-slate-600" : ""
          }`}
          onClick={() => handleSort("last_updated")}
        >
          Date{" "}
          {sortField === "last_updated" && (sortOrder === "desc" ? "↓" : "↑")}
        </button>
        <button
          className={`bg-slate-700 text-white px-4 py-2 rounded-lg ${
            sortField === "messages_count" ? "bg-slate-600" : ""
          }`}
          onClick={() => handleSort("messages_count")}
        >
          Messages{" "}
          {sortField === "messages_count" && (sortOrder === "desc" ? "↓" : "↑")}
        </button>
      </div>
      <div className="divide-y divide-gray-700">
        {filteredAndSortedUsers.map((user) => (
          <div
            key={user.web_id}
            className="p-4 hover:bg-slate-700 cursor-pointer flex items-center justify-between"
            onClick={() => onSelectUser(user.web_id)}
          >
            <div className="text-white font-medium">{user.web_id}</div>
            <div className="flex items-center gap-4">
              <div className="text-gray-400 text-sm">
                {formatDate(user.last_updated)}
              </div>
              <div className="text-white font-bold">
                {user.messages_count} msgs
              </div>
            </div>
          </div>
        ))}
      </div>
      {users.length > displayLimit && (
        <button
          className="mt-4 bg-slate-700 text-white px-4 py-2 rounded-lg w-full"
          onClick={() => setDisplayLimit((prev) => prev + 10)}
        >
          Load More
        </button>
      )}
    </div>
  );
}
