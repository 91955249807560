import React, { useState } from "react";
import { format } from "date-fns";

const SummaryCard = ({ summary, summary_id, onSummaryOpen }) => {
  const [selectedSummary, setSelectedSummary] = useState(null);

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return format(date, "dd MMM yyyy, HH:mm");
  };

  const handleCardClick = () => {
    setSelectedSummary(summary);
    onSummaryOpen(summary_id);
  };

  const handleCloseModal = (e) => {
    if (e.target.id === "backdrop") {
      setSelectedSummary(null);
    }
  };

  return (
    <>
      <div
        className={`border border-slate-200 rounded-lg mb-0 cursor-pointer hover:shadow-lg transition-shadow duration-200 ${
          summary.read ? "bg-slate-50" : "bg-slate-50"
        }`}
        onClick={handleCardClick}
      >
        <p
          className={`text-sm p-4 pb-0 line-clamp-2 ${
            summary.read ? "text-slate-600" : "text-slate-600"
          }`}
        >
          {summary.results.split(" - ").join("\n")}
        </p>
        <div
          className={`border-t px-4 rounded-b-lg border-slate-200 py-3 flex justify-between items-center ${
            summary.read ? "bg-green-50" : "bg-red-50"
          }`}
        >
          <p
            className={`text-sm mb-0 font-medium ${
              summary.read ? "text-slate-600" : "text-slate-700"
            }`}
          >
            {summary.name}
          </p>
          <p className="text-xs text-slate-500 mb-0">
            {formatDate(summary.date)}
          </p>
        </div>
      </div>

      {selectedSummary && (
        <div
          id="backdrop"
          className="fixed inset-0 flex items-center justify-center z-50 bg-black bg-opacity-50"
          onClick={handleCloseModal}
        >
          <div className="bg-white p-8 rounded-lg shadow-lg w-full md:w-1/2 overflow-auto">
            <h2 className="text-2xl font-bold mb-4">{selectedSummary.name}</h2>
            <p className="text-slate-600 text-sm whitespace-pre-wrap">
              {selectedSummary.results.split(" - ").join("\n")}
            </p>
          </div>
        </div>
      )}
    </>
  );
};
export default SummaryCard;
