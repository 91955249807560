/**
 * Theme variables for onboarding flow
 * This file centralizes all color values used in the onboarding components
 */

// Primary colors
export const THEME = {
  // Main accent color (currently lime/neon) and its variations
  primary: {
    50: "#F9FFE6",
    100: "#F5FFCC",
    200: "#EEFF99",
    300: "#E7FF66",
    400: "#E1FF33",
    500: "#DCFF02", // Main primary color
    600: "#B3CC02",
    700: "#899901",
    800: "#606C01",
    900: "#363F00",
  },

  // Background colors (dark mode)
  background: {
    black: "#000000",
    darkest: "#0A0A0A",
    darker: "#121212",
    dark: "#1A1A1A",
    light: "#2A2A2A",
    overlay: "rgba(0, 0, 0, 0.6)",
    overlayLight: "rgba(0, 0, 0, 0.3)",
    overlayDark: "rgba(0, 0, 0, 0.8)",
    gradient: {
      dark: "linear-gradient(to bottom, rgba(0, 0, 0, 0.8), transparent)",
      darkReverse:
        "linear-gradient(to top, rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.6), transparent)",
    },
  },

  // Text colors
  text: {
    white: "#FFFFFF",
    lightGray: "#F5F5F5",
    gray: "#9B9B9B",
    primary: "#DCFF02", // Same as primary.500
  },

  // Border colors
  border: {
    primary: "rgba(220, 255, 2, 0.3)", // primary.500 at 30% opacity
    primaryHover: "rgba(220, 255, 2, 0.5)", // primary.500 at 50% opacity
    dark: "rgba(30, 41, 59, 0.8)", // slate-800 at 80% opacity
    light: "rgba(255, 255, 255, 0.1)",
    lightHover: "rgba(255, 255, 255, 0.2)",
  },

  // Data visualization colors
  chart: {
    screenTime: "#2A2A2A",
    distractions: "#6B2A2A",
    essentials: "#3A4A3A",
    growth: "#2A5E2A",
    social: "#2A2A6B",
    hobbies: "#4A2A6D",
    sleep: "#2A3A6D",
    exercise: "#2A6D2A",
    work: "#2A2A6B",
    remaining: "#3A3A4A",
  },

  // Gradient colors
  gradient: {
    primary: "linear-gradient(to right, #B3CC02, #DCFF02)", // primary.600 to primary.500
    primaryHover: "linear-gradient(to right, #899901, #B3CC02)", // primary.700 to primary.600
    testimonial1: "linear-gradient(to right, #DCFF02, #D4E60A)", // primary.500 to a slightly different shade
    testimonial2: "linear-gradient(to right, #D4E60A, #C8CC15)", // transitioning to more yellow
    testimonial3: "linear-gradient(to right, #C8CC15, #BDB320)", // transitioning to more gold
    testimonial4: "linear-gradient(to right, #BDB320, #B29A2B)", // transitioning to more amber
    testimonial5: "linear-gradient(to right, #B29A2B, #A78136)", // transitioning to more bronze
    testimonial6: "linear-gradient(to right, #9CC802, #DCFF02)", // slightly different green to primary.500
    sectionTransition:
      "linear-gradient(to right, rgba(220, 255, 2, 0.2), rgba(179, 204, 2, 0.2))", // primary.500/20 to primary.600/20
  },

  // Utility colors
  util: {
    success: "#4CD964",
    error: "#FF3B30",
    warning: "#FFCC00",
    info: "#DCFF02", // Changed to primary color
  },
};

// Export individual color groups for convenience
export const { primary, background, text, border, chart, gradient, util } =
  THEME;
