import React from "react";
import Layout from "./Layout";

const Calendar = () => {
  return (
    <Layout title="Calendar">
      <div className="">
        Calendar is under development. Schedule and manage your coaching
        sessions.
      </div>
    </Layout>
  );
};

export default Calendar;
