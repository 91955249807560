import React from "react";
import { Card } from "../../../components/ui/Card";
import {
  AlertTriangle,
  BatteryLow,
  HeartCrack,
  Clock,
  Zap,
  BrainCircuit,
} from "lucide-react";

const RealLifeProblems = () => {
  const problems = [
    {
      icon: HeartCrack,
      title: "Missing family milestones",
      description:
        "You keep missing your kids' games or date nights with your partner because work always seems more urgent.",
      color: "text-red-400",
    },
    {
      icon: Clock,
      title: "Always busy, never fulfilled",
      description:
        "You're constantly working but rarely feel like you're making progress on what truly matters to you.",
      color: "text-yellow-400",
    },
    {
      icon: BatteryLow,
      title: "Trapped in the scroll",
      description:
        "You spend hours mindlessly scrolling social media, then feel guilty about all the important things you didn't do.",
      color: "text-orange-400",
    },
    {
      icon: BrainCircuit,
      title: "Always 'on call'",
      description:
        "Your evenings and weekends are constantly interrupted by work emails and messages that could wait until tomorrow.",
      color: "text-blue-400",
    },
    {
      icon: Zap,
      title: "Health keeps slipping",
      description:
        "You keep canceling gym sessions and grabbing fast food because you're 'too busy' for anything else.",
      color: "text-green-400",
    },
    {
      icon: AlertTriangle,
      title: "Living for the weekend",
      description:
        "You spend 5 days enduring work you don't love, just to get 2 days of rushing through what matters to you.",
      color: "text-purple-400",
    },
  ];

  return (
    <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-6">
      {problems.map((problem, index) => (
        <Card
          key={index}
          className="glass-card p-6 border border-white/10 hover:border-primary/50 transition-all duration-300"
        >
          <div className="flex items-start gap-4 h-full">
            <div className={`rounded-full p-2.5 ${problem.color} bg-white/5`}>
              <problem.icon className="h-6 w-6" />
            </div>
            <div>
              <h3 className="font-semibold text-lg mb-2 text-white">
                {problem.title}
              </h3>
              <p className="text-gray-400 mb-0">{problem.description}</p>
            </div>
          </div>
        </Card>
      ))}
    </div>
  );
};

export default RealLifeProblems;
