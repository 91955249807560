import React from 'react';

const TypingIndicator = () => {
  return (
      <img 
        src="/images/loader3.gif" 
        alt="AI is typing..." 
        className="w-10 mt-4"
      />
  );
};

export default TypingIndicator;
