import React, { useState } from "react";
import "react-phone-number-input/style.css";
import PhoneInput from "react-phone-number-input";
import { PhoneIcon } from "@heroicons/react/24/outline";
import axios from "axios";
import Cookies from "js-cookie";

const AdminSyncPhone = ({ onSync, onCloseSync, starterPhone }) => {
  const [phoneNumber, setPhoneNumber] = useState(starterPhone || "");
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handlePhoneNumberSubmission = async () => {
    setLoading(true);
    try {
      const response = await axios.post("/api/auth/admin/login", {
        phone_number: phoneNumber,
      });

      if (response.status === 200) {
        const { access_token, web_id } = response.data;
        // Save the access token in a cookie
        Cookies.set("1440_access_token", access_token, { expires: 14 });
        Cookies.set("1440_web_id", web_id, { expires: 14 });
        Cookies.set("1440_mobile_id", phoneNumber, { expires: 14 });

        onSync(phoneNumber);
      }
    } catch (error) {
      console.error("Error logging in:", error);
      setError("Failed to log in. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === "Enter" && phoneNumber) {
      handlePhoneNumberSubmission();
    }
  };

  return (
    <div className="fixed inset-0 flex flex-col md:flex-row items-center justify-center bg-white">
      <div className="relative w-full md:w-1/2 text-center max-w-md md:max-w-lg mx-auto px-4 lg:px-16">
        <div className="absolute top-0 left-1/2 transform -translate-x-1/2 mt-4">
          <img src="/images/logo.png" alt="Logo" className="h-10 md:h-8" />
        </div>
        <div className="border shadow flex justify-center items-center mx-auto mb-4 h-20 w-20 md:h-16 md:w-16 rounded-xl mt-20">
          <PhoneIcon className="h-12 w-12 md:h-8 md:w-8 text-slate-700" />
        </div>

        <h1 className="text-3xl md:text-4xl font-bold mb-4">
          Admin Login with Phone Number
        </h1>
        <p className="text-lg md:text-lg mb-6">
          Enter the user's phone number to access their account.
        </p>
        <div className="flex flex-col justify-center items-center mb-4">
          <PhoneInput
            international
            defaultCountry="US"
            value={phoneNumber}
            onChange={setPhoneNumber}
            onKeyPress={handleKeyPress}
            placeholder="Enter phone number"
            className="form-input w-full px-4 py-3 md:py-2 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-darkBlue focus:border-transparent"
            inputClassName="w-full"
          />
          <button
            onClick={handlePhoneNumberSubmission}
            className={`bg-darkBlue hover:bg-blue-600 text-white font-bold py-3 md:py-2 px-6 md:px-4 rounded-lg w-full mt-4 flex justify-center items-center ${
              loading ? "cursor-not-allowed" : ""
            }`}
            disabled={loading}
          >
            {loading ? (
              <svg
                className="animate-spin h-5 w-5 text-white"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
              >
                <circle
                  className="opacity-25"
                  cx="12"
                  cy="12"
                  r="10"
                  stroke="currentColor"
                  strokeWidth="4"
                ></circle>
                <path
                  className="opacity-75"
                  fill="currentColor"
                  d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                ></path>
              </svg>
            ) : (
              "Login as User"
            )}
          </button>
          {error && <p className="text-red-500 mt-2">{error}</p>}
        </div>
        <p className="text-gray-400 text-xs">
          Admin access only. This bypasses normal authentication for debugging
          purposes.
        </p>
      </div>
      <div className="hidden md:flex md:w-1/2 lg:w-3/5 xl:w-3/4 h-screen items-center bg-slate-300 rounded-3xl">
        <img
          src="https://1440.ai/images/coach-cover.png"
          alt="Placeholder"
          className="object-cover w-full ml-12"
          style={{ height: "90vh", objectPosition: "left center" }}
        />
      </div>
    </div>
  );
};

export default AdminSyncPhone;
