import React from "react";
import { motion } from "framer-motion"; // For animations
import "./Introduction.css"; // Assuming custom CSS in addition to Tailwind
const Introduction = ({ onStart }) => {
  // Animation variants can be defined here
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { delay: 0.5, duration: 1 } },
  };

  return (
    <motion.div
      className="intro-container"
      variants={containerVariants}
      initial="hidden"
      animate="visible"
    >
      <div className="flex flex-col items-center justify-center h-screen">
        <motion.h1
          className="text-4xl md:text-6xl font-bold text-center mb-6"
          initial={{ y: -250 }}
          animate={{ y: -10 }}
          transition={{ delay: 0.2, type: "spring", stiffness: 120 }}
        >
          Discover Your Purpose
        </motion.h1>
        <motion.p
          className="text-md md:text-lg text-center text-gray-300 mb-8"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.8, duration: 1.5 }}
        >
          Take our quick assessment to uncover your unique purpose persona.
        </motion.p>
        <motion.button
          className="px-6 py-3 bg-accent text-white rounded hover:bg-lightBlue transition ease-in-out duration-300"
          onClick={onStart}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.9 }}
        >
          Start Assessment
        </motion.button>
      </div>
    </motion.div>
  );
};

export default Introduction;
