import React from "react";

const Testimonial = () => {
  return (
    <div className="p-4 my-8 mx-auto max-w-md bg-black rounded-lg">
      <div className="flex flex-col items-start">
        <div className="text-white text-2xl font-semibold mb-2">
          Invaluable Insights
        </div>
        <div className="text-accent text-2xl my-3 font-bold">★★★★★</div>
        <p className="text-white text-2xl font-medium leading-snug mt-2">
          "My girlfriend got this for me, because I didn't want to do
          traditional therapy. And let me tell you, it's transformed our
          relationship and even my friendships too. We don't bicker like we used
          to, when we have a problem we can't solve by talking it out, we go to
          1440 who remembers our previous disagreements and can help us get to
          the heart of the matter. I just want to thank the development team."
        </p>
        <div className="text-accent mt-3">August Frank</div>
        <div className="text-gray-400">Apr 10, 2023</div>
      </div>
    </div>
  );
};

export default Testimonial;
