export default function AdminTopBar({ activeView, setActiveView }) {
  return (
    <div className="bg-slate-900 border-b border-slate-800 p-6 flex items-center justify-between">
      <div className="flex items-center justify-center space-x-4">
        <img
          src="/images/logo-white.png"
          alt="1440 Logo"
          className="h-6 w-auto object-contain"
        />
        <h1 className="text-sm mb-0 py-2 px-3 rounded font-medium transition-all  text-indigo-400 ring-1 ring-indigo-500/30">
          Admin
        </h1>
      </div>
      <div className="flex space-x-2">
        <button
          className={`px-4 py-2 rounded-md text-sm font-medium transition-all ${
            activeView === "coach"
              ? "bg-indigo-500/10 text-indigo-400 ring-1 ring-indigo-500/30"
              : "text-slate-400 hover:bg-slate-800 hover:text-slate-200"
          }`}
          onClick={() => setActiveView("coach")}
        >
          Coaches
        </button>
        <button
          className={`px-4 py-2 rounded-md text-sm font-medium transition-all ${
            activeView === "user"
              ? "bg-indigo-500/10 text-indigo-400 ring-1 ring-indigo-500/30"
              : "text-slate-400 hover:bg-slate-800 hover:text-slate-200"
          }`}
          onClick={() => setActiveView("user")}
        >
          Users
        </button>
      </div>
    </div>
  );
}
