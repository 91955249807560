import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GoogleCalendarButton from "./header/GoogleCalendarButton";
import { HeartIcon, ChevronRightIcon } from "@heroicons/react/24/outline";
import axios from "axios";

const Connect = ({ userId }) => {
  const [healthLink, setHealthLink] = useState(userId);
  const [isSynced, setIsSynced] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  // Moved checkSyncStatus function from GoogleCalendarButton
  const checkSyncStatus = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(`/check_sync/${userId}/`);
      setIsSynced(response.data.status);
    } catch (e) {
      console.error("Error checking sync status:", e);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (userId) {
      checkSyncStatus(); // Call the function to check sync status
      // Fetch the health integration link

      axios
        .get(`/token/${userId}`)
        .then((response) => {
          setHealthLink(response.data.link);
        })
        .catch((error) => {
          console.error("Error fetching health link:", error);
        });
    }
  }, [userId]);

  return (
    <div className="">
      <h3 className="text-xl text-slate-400 font-bold text-left mb-4">
        Connect your data
      </h3>
      <div className="flex flex-col justify-start gap-4">
        <GoogleCalendarButton
          userId={userId}
          isSynced={isSynced}
          isLoading={isLoading}
        />
        <a
          href={healthLink}
          className="relative inline-flex items-center justify-between w-full lg:w-auto px-4 py-3 rounded bg-slate-800 text-sm font-medium text-white shadow-sm hover:bg-slate-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <div className="flex">
            <HeartIcon className="h-5 w-5 mr-2" />
            <span>Connect Health Devices</span>
          </div>
          <ChevronRightIcon className="h-5 w-5 ml-2" />
        </a>
      </div>
      <p className="text-slate-600 text-md mb-6 mt-3">
        By connecting your data, you agree to our terms. Standard message and
        data rates may apply. The frequency of data updates may vary. You can
        disconnect your data at any time. For detailed information on data use
        and protection, please see our
        <a href="/privacy-policy" className="text-blue-600 hover:text-blue-800">
          {" "}
          Privacy Policy
        </a>
        .
      </p>
    </div>
  );
};

export default Connect;
