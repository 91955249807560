import React, { useState, useEffect } from "react";
import axios from "axios";
import AdminCoachData from "./AdminCoachData";
import AdminUserData from "./user/AdminUserData";
import AdminTopBar from "./AdminTopBar";

export default function AdminData() {
  const [activeView, setActiveView] = useState("user");
  const [isValidated, setIsValidated] = useState(false);
  const [error, setError] = useState("");
  const [apiKey, setApiKey] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const validateApiKey = async () => {
      const storedApiKey = localStorage.getItem("adminApiKey");
      if (!storedApiKey) {
        setError("No API key found");
        return;
      }

      try {
        await axios.get("/api/admin/validate_api_key", {
          headers: { "Api-Key": storedApiKey },
        });
        setIsValidated(true);
        setError("");
      } catch (err) {
        setError("Invalid API key");
        localStorage.removeItem("adminApiKey");
      }
    };

    validateApiKey();
  }, []);

  const handleSubmitApiKey = async () => {
    setLoading(true);
    try {
      await axios.get("/api/admin/validate_api_key", {
        headers: { "Api-Key": apiKey },
      });
      localStorage.setItem("adminApiKey", apiKey);
      setIsValidated(true);
      setError("");
    } catch (err) {
      setError("Invalid API key");
    } finally {
      setLoading(false);
    }
  };

  if (!isValidated) {
    return (
      <div className="bg-black text-white min-h-screen flex items-center justify-center">
        <div className="bg-slate-900 p-8 rounded-lg max-w-md w-full">
          <h1 className="text-2xl font-bold mb-4">Admin Access Required</h1>
          <div className="space-y-4">
            <div>
              <label
                htmlFor="apiKey"
                className="block text-sm font-medium text-gray-400 mb-2"
              >
                API Key
              </label>
              <input
                id="apiKey"
                type="text"
                value={apiKey}
                onChange={(e) => setApiKey(e.target.value)}
                className="w-full px-3 py-2 bg-slate-800 border border-gray-700 rounded-md text-white placeholder-gray-500 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent transition duration-200 ease-in-out"
                placeholder="Enter your API key"
              />
            </div>
            <button
              onClick={handleSubmitApiKey}
              disabled={loading}
              className="w-full px-4 py-2 bg-blue-600 hover:bg-blue-700 focus:ring-blue-500 focus:ring-offset-gray-900 text-white font-medium rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-offset-2 transition duration-200 ease-in-out disabled:opacity-50"
            >
              {loading ? (
                <svg
                  className="animate-spin h-5 w-5 mx-auto"
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <circle
                    className="opacity-25"
                    cx="12"
                    cy="12"
                    r="10"
                    stroke="currentColor"
                    strokeWidth="4"
                  ></circle>
                  <path
                    className="opacity-75"
                    fill="currentColor"
                    d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"
                  ></path>
                </svg>
              ) : (
                "Submit"
              )}
            </button>
            {error && (
              <div className="bg-red-500 bg-opacity-10 border border-red-400 rounded p-3">
                <p className="text-red-400 text-sm">{error}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-black text-white min-h-screen flex flex-col">
      <AdminTopBar activeView={activeView} setActiveView={setActiveView} />
      <div className="flex-1">
        {activeView === "coach" ? <AdminCoachData /> : <AdminUserData />}
      </div>
    </div>
  );
}
