import React, { useState, useEffect } from "react";
import TimeAnimation from "./TimeAnimation";
import { SEQUENCES } from "./timeConstants";
import ContinueButton from "../../components/ContinueButton";
import { primary } from "../../theme/variables";

const TimeAssessmentScreen = ({ results, onUpdate, content }) => {
  const [isComplete, setIsComplete] = useState(false);
  const [currentSequence, setCurrentSequence] = useState(SEQUENCES.INTRO);
  const [showContinueButton, setShowContinueButton] = useState(false);

  // Log received data for debugging
  useEffect(() => {
    console.log("TimeAssessmentScreen received results:", {
      gender: results.screen3,
      age: results.screen4,
      occupation: results.screen8,
      workHours: results.screen9,
      sleepHours: results.screen10,
      exercise: results.screen11,
    });
  }, [results]);

  // Track sequence changes for more precise control
  const handleSequenceChange = (sequence) => {
    setCurrentSequence(sequence);

    // When we reach the COMPLETE sequence, mark as complete
    if (sequence === SEQUENCES.COMPLETE) {
      setIsComplete(true);
      // Show continue button after animation completes
      setTimeout(() => {
        setShowContinueButton(true);
      }, 4000); // Show button after 4 seconds of viewing final state
    }
  };

  // Handle continue button click
  const handleContinue = () => {
    onUpdate({
      timeAssessmentComplete: true,
      continueClicked: true,
    });
  };

  // Safety fallback to ensure we don't get stuck (increased to 60 seconds)
  useEffect(() => {
    const fallbackTimer = setTimeout(() => {
      if (!isComplete) {
        setShowContinueButton(true);
      }
    }, 60000);

    return () => clearTimeout(fallbackTimer);
  }, [isComplete]);

  return (
    <div className="flex flex-col items-center justify-center w-full relative">
      <TimeAnimation
        results={results}
        content={content}
        onSequenceChange={handleSequenceChange}
      />

      {/* Continue button that appears after animation completes */}
      {showContinueButton && (
        <ContinueButton isLastStep={false} handleContinue={handleContinue} />
      )}
    </div>
  );
};

export default TimeAssessmentScreen;
