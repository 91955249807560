// This file contains all the screens data for the onboarding flow

// Define screen configurations as a function that takes in the setShowSectionTitle function
export const createOnboardingScreens = (setShowSectionTitle) => [
  // Page 1: Welcome & Context
  {
    type: "intro",
    content: {
      title: "1440 minutes each day",
      description:
        "Complete this 2-minute quiz to create a personalized plan focused on what truly matters",
      buttonText: "I'M READY",
    },
    hideDefaultButton: true,
    section: -1, // Special value to indicate no section yet
  },

  // Page 2: Introduction (Section Transition)
  {
    type: "sectionTransition",
    content: {
      title: "Part 1: Purpose & Priorities",
      description: "Discover what matters most",
    },
    autoAdvance: true,
    hideDefaultButton: true,
    delay: 1800,
    section: 0,
    onAppear: () => setShowSectionTitle(true),
  },

  // Page 3: Gender
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question: "What's your gender?",
      options: ["Male", "Female", "Non-binary", "Prefer not to say"],
    },
    hideDefaultButton: true,
    section: 0,
  },

  // Page 4: Age (moved from Page 7 to Page 4)
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question: "What's your age?",
      options: [
        "18-24",
        "25-34",
        "35-44",
        "45-54",
        "55-64",
        "65+",
        "Prefer not to say",
      ],
    },
    hideDefaultButton: true,
    section: 0,
  },

  // Page 5: Focus Areas
  {
    type: "multiSelect",
    content: {
      question: "What areas of your life need the most attention?",
      options: [
        "Family",
        "Health & Fitness",
        "Friendships",
        "Personal Growth",
        "Career & Finances",
        "Recreation & Hobbies",
        "Spirituality & Religion",
      ],
    },
    hideDefaultButton: false,
    section: 0,
  },

  // Page 6: Motivation
  {
    type: "multiSelect",
    content: {
      question:
        "What would matter most to you about having better control of your time?",
      options: [
        "Being more present",
        "Spending your time in line with your values",
        "Creating space for passions and growth",
        "Building deeper connections with people",
        "Investing more in my health",
        "Building a sense of purpose",
        "Increase productivity",
        "Reduce resting stress levels",
      ],
    },
    hideDefaultButton: false,
    section: 0,
  },

  // Page 7: Section Transition
  {
    type: "sectionTransition",
    content: {
      title: "Part 2: Your Current Lifestyle",
      description: "Understand your daily patterns",
    },
    hideDefaultButton: true,
    autoAdvance: true,
    delay: 1800,
    section: 1,
  },

  // Page 8: Occupation (previously Page 8)
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question: "What best describes your current occupation?",
      options: [
        "Full-time employee",
        "Part-time employee",
        "Self-employed/Freelancer",
        "Business owner",
        "Student",
        "Homemaker",
        "Retired",
        "Other",
      ],
    },
    hideDefaultButton: true,
    section: 1,
  },

  // Page 9: Current Time Allocation (previously Page 9)
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question:
        "How many hours do you typically spend on work/career/study each week?",
      options: [
        "Less than 10 hours per week",
        "10-20 hours",
        "21-34 hours",
        "35-50 hours",
        "51-65 hours",
        "More than 65 hours per week",
      ],
    },
    hideDefaultButton: true,
    section: 1,
  },

  // Page 10: Sleep Habits (previously Page 10)
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question:
        "Including naps, how many hours do you typically sleep per day?",
      options: [
        "Less than 5 hours",
        "5-6.5 hours",
        "7-8.5 hours",
        "9-10.5 hours",
        "More than 11 hours per day",
      ],
    },
    hideDefaultButton: true,
    section: 1,
  },

  // Page 11: Exercise Habits (previously Page 11)
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question: "How many hours per week do you typically spend exercising?",
      options: [
        "More than 7 hours",
        "5-7 hours",
        "3-5 hours",
        "1-3 hours",
        "Less than 1 hour",
        "None",
      ],
    },
    hideDefaultButton: true,
    section: 1,
  },

  // Page 12: Time Assessment (previously Page 12)
  {
    type: "timeAssessment",
    content: {
      title: "Your Daily 1440 Minutes",
      description: "Here's how you're currently spending your time:",
      timeBreakdown: [
        {
          activity: "work",
          minutes: 480,
          description: "Work (8 hours)",
          color: "#4F86C6",
          percentage: 33.3,
        },
        {
          activity: "sleep",
          minutes: 420,
          description: "Sleep (7 hours)",
          color: "#7764E4",
          percentage: 29.2,
        },
        {
          activity: "social",
          minutes: 180,
          description: "Social media & entertainment",
          color: "#FF6B6B",
          percentage: 12.5,
        },
        {
          activity: "exercise",
          minutes: 30,
          description: "Exercise",
          color: "#65D6AD",
          percentage: 2.1,
        },
        {
          activity: "other",
          minutes: 330,
          description: "Everything else that matters",
          color: "#FFBC42",
          percentage: 22.9,
        },
      ],
      conclusion:
        "With 1440, we'll help you reclaim time from low-value activities and intentionally design more of your minutes for what truly matters to you.",
    },
    hideDefaultButton: true,
    autoAdvance: true,
    section: 1,
  },

  // Page 13: Section Transition
  {
    type: "sectionTransition",
    content: {
      title: "Part 3: Alignment Assessment",
      description: "Understand how you manage your time",
    },
    hideDefaultButton: true,
    autoAdvance: true,
    delay: 1800,
    section: 2,
  },

  // Page 14: Time Management
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question: "How do you currently manage your time?",
      options: [
        "Digital calendar with scheduling",
        "To-do lists",
        "Keeping it all in my head",
        "Remaining adaptable",
        "I don't actively manage my time",
      ],
    },
    hideDefaultButton: true,
    section: 2,
  },

  // Page 15: Current Challenges
  {
    type: "multiSelect",
    content: {
      question: "What are your biggest challenges with time management?",
      options: [
        "Too many interruptions and distractions",
        "Difficulty saying no to requests",
        "Procrastination",
        "Unclear priorities",
        "Overcommitting myself",
        "Digital addiction",
        "Low energy at key times of day",
        "Perfectionism",
        "Difficulty starting or switching to new tasks",
        "Decision fatigue",
      ],
    },
    hideDefaultButton: false,
    section: 2,
  },

  // Page 16: Alignment Question
  {
    type: "selection",
    autoAdvance: true,
    content: {
      question:
        "How often do you end your day feeling satisfied with how you spent your time?",
      options: [
        "Almost always",
        "Most days",
        "Sometimes",
        "Rarely",
        "Almost never",
      ],
    },
    hideDefaultButton: true,
    section: 2,
  },

  // Page 17: Value Proposition
  {
    type: "valueProposition",
    content: {
      title: "Let's make your days reflect what truly matters to you:",
      approachTitle: "The 1440 Approach:",
      approachPoints: [
        "AI-enhanced coaching that learns and evolves with you, identifying patterns and suggesting personalized improvements throughout your day.",
        "Human coaching sessions when you need deeper support, providing expert guidance to overcome complex challenges and roadblocks.",
        "Peer group accountability and motivation, connecting you with others who share similar goals and challenges.",
        "Health data integration to optimize your energy, helping you schedule important tasks when you're at your cognitive and physical peak.",
        "Regular check-ins that prevent backsliding, keeping you on track with gentle reminders and supportive interventions.",
        "Calendar and habit integration for seamless support, automatically blocking time for what matters and reducing decision fatigue.",
      ],
      highlight:
        "Did you know? In a recent study, our coaching system was rated 8.3% more effective than master-certified human coaches across key metrics of conversation quality.",
    },
    section: 2,
  },

  // Page 19: Testimonials
  {
    type: "testimonial",
    content: {
      title: "How 1440 helped others",
      testimonials: [
        {
          quote:
            "1440 has been great for me inside of work and out. During the day, it helps me be productive and start the morning with clarity and drive. Outside of work, it's helped me accomplish my goals fitness and my hobbies. I've had a couple friends join so far too and we're taking on group challenges every month.",
          name: "David Brown",
          avatar: "/images/testimonials/david.jpg",
        },
        {
          quote:
            "I integrated my Garmin and did marathon planning and have check-ins which has been great. Then the other day I missed a long run, and it actually called me and asked if it was intentional. It now sends me these morning voicemails with positive affirmations before my long runs and I've kept up the training since",
          name: "Noah Lloyd",
          avatar: "/images/testimonials/noah.jpg",
        },
        {
          quote:
            "I have this late night habit where I keep unconsciously falling back into scrolling on my phone whenever my mind gets tired or bored after work or dinner. Lately, instead of wasting another couple of hours, I've just been checking in with 1440 to talk through my thoughts, and why and how my habit persists. Result is that I haven't doomscrolled in the last 3 months - couldn't be happier.",
          name: "Mike Hansen",
          avatar: "/images/testimonials/mike.jpg",
        },
      ],
    },
    section: 3,
  },

  // Page 20: Future Self
  {
    type: "multiSelect",
    content: {
      question:
        "When your time is aligned with what matters most, how do you envision your days?",
      description: "select all that apply",
      options: [
        "Starting each day with clarity and purpose",
        "Ending each day with a sense of meaningful accomplishment",
        "Being fully present during family time without distractions",
        "Having energy for both work and personal pursuits",
        "Feeling in control rather than constantly reactive",
        "Making consistent progress on long-term goals and dreams",
      ],
    },
    hideDefaultButton: false,
    section: 3,
  },

  // Page 21: Your Why
  {
    type: "multiSelect",
    content: {
      question:
        "What would change most in your life if you could live according to your purpose and values?",
      description: "select all that apply",
      options: [
        "Deeper connections with the people I love",
        "Making meaningful progress toward my biggest dreams",
        "Improved health and wellbeing",
        "Greater sense of purpose and fulfillment",
        "Reduced stress and increased joy",
        "Being the person I want to be, not just doing what I have to do",
      ],
    },
    hideDefaultButton: false,
    section: 3,
  },

  // Page 22: Plan Creation Animation
  {
    type: "animation",
    content: {
      title: "Your coach is creating your personalized time alignment plan",
      steps: [
        { text: "Analyzing your current patterns", duration: 3000 },
        { text: "Identifying key misalignments", duration: 3000 },
        { text: "Designing custom interventions", duration: 3000 },
        { text: "Creating accountability framework", duration: 3000 },
        {
          text: "Building support system",
          duration: 3000,
        },
      ],
    },
    autoAdvance: true,
    delay: 9000, // Total duration (5 steps * 3000ms * 0.6 speed factor)
    section: 3,
    hideDefaultButton: true,
  },

  // Page 23: Plan Preview
  {
    type: "planPreview",
    content: {
      title: "Time Alignment Strategy",
      planFeatures: [
        {
          title: "Daily check-ins",
          description:
            "Brief but powerful moments to reset your focus and stay aligned with your priorities",
          selected: true,
        },
        {
          title: "Health device integration",
          description:
            "Connect your wearables to optimize your schedule based on your energy patterns",
          selected: true,
        },
        {
          title: "Calendar integration",
          description:
            "Seamlessly block time for what truly matters and reduce decision fatigue",
          selected: true,
        },
        {
          title: "Personal human coach",
          description:
            "Intro and regular calls with an expert coach to help you stay on track",
          selected: true,
        },
        {
          title: "Peer accountability group",
          description:
            "Connect with others who share similar goals for mutual support and motivation",
          selected: true,
        },
      ],
      buttonText: "GET MY PLAN",
    },
    section: 3,
  },

  // Page 24: Subscription
  {
    type: "subscription",
    content: {
      title: "Get your personalized 1440 time alignment system:",
      features: [
        "24/7 AI coach that learns with you",
        "Quarterly human coaching sessions",
        "Smart calendar integration",
        "Health and habit tracking",
        "Custom intervention strategies",
        "Peer accountability group",
        "Ongoing support and adaptation",
      ],
      buttonText: "Get Started Now: $XX/month",
    },
    section: 4,
  },
];
