import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import axios from "axios";
import { Auth0Provider } from "@auth0/auth0-react";
import Cookies from "js-cookie";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";

axios.defaults.baseURL = "https://api.1440.ai/";
axios.interceptors.request.use(
  (config) => {
    const token = Cookies.get("1440_access_token");

    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const onRedirectCallback = (appState) => {
  // Use the router's history.push method to replace the url
  window.history.replaceState(
    {},
    document.title,
    appState?.returnTo || window.location.pathname
  );
};

// Keep the original options structure
const options = {
  api_host: "https://us.i.posthog.com",
};

// Initialize PostHog with minimal options needed for toolbar
posthog.init("phc_SBLL0dhBjE0Rv0oFEFvZ0dTcJwudnMAxqoZPiAwCkWY", {
  ...options,
  loaded: (posthog) => {
    window.posthog = posthog;
  },
});

// Also explicitly attach to window object to ensure it's available
window.posthog = posthog;

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <PostHogProvider
    apiKey="phc_SBLL0dhBjE0Rv0oFEFvZ0dTcJwudnMAxqoZPiAwCkWY"
    options={options}
    client={posthog}
  >
    <Auth0Provider
      domain="dev-loxpthsb4ptsnqfz.us.auth0.com"
      clientId="rR8dqiA7yClypyIg8BZwwX9g3CpGFQeQ"
      redirectUri={window.location.origin + "/coach/dashboard"}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage" // This tells Auth0 to use local storage for auth state
    >
      <App />
    </Auth0Provider>
  </PostHogProvider>
);
