import React, { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { primary, text, border, gradient } from "../theme/variables";
import "../theme/theme.css";

const TestimonialScreen = ({ onUpdate, content }) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [direction, setDirection] = useState(0);
  const [autoplayEnabled, setAutoplayEnabled] = useState(true);

  // Autoplay functionality
  useEffect(() => {
    if (!autoplayEnabled) return;

    const interval = setInterval(() => {
      nextTestimonial();
    }, 10000); // Change testimonial every 5 seconds

    return () => clearInterval(interval);
  }, [currentIndex, autoplayEnabled]);

  // Pause autoplay when user interacts
  const pauseAutoplay = () => {
    setAutoplayEnabled(false);
    // Resume after 30 seconds of inactivity
    setTimeout(() => setAutoplayEnabled(true), 30000);
  };

  const nextTestimonial = () => {
    setDirection(1);
    setCurrentIndex((prevIndex) =>
      prevIndex === content.testimonials.length - 1 ? 0 : prevIndex + 1
    );
  };

  const prevTestimonial = () => {
    setDirection(-1);
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? content.testimonials.length - 1 : prevIndex - 1
    );
  };

  // Generate a vibrant but on-brand color for each testimonial
  const getAccentGradient = (index) => {
    const gradients = [
      gradient.testimonial1,
      gradient.testimonial2,
      gradient.testimonial3,
      gradient.testimonial4,
      gradient.testimonial5,
      gradient.testimonial6,
    ];
    return gradients[index % gradients.length];
  };

  // Get initials for a name
  const getInitials = (name) => {
    return name
      .split(" ")
      .map((n) => n[0])
      .join("")
      .toUpperCase();
  };

  // Animation variants
  const variants = {
    enter: (direction) => ({
      x: direction > 0 ? "100%" : "-100%",
      scale: 0.95,
      opacity: 0,
    }),
    center: {
      x: 0,
      scale: 1,
      opacity: 1,
    },
    exit: (direction) => ({
      x: direction < 0 ? "100%" : "-100%",
      scale: 0.95,
      opacity: 0,
    }),
  };

  // Handle swipe gestures
  const handleDragEnd = (e, { offset, velocity }) => {
    pauseAutoplay();

    const swipeThreshold = 50;
    if (offset.x > swipeThreshold) {
      prevTestimonial();
    } else if (offset.x < -swipeThreshold) {
      nextTestimonial();
    }
  };

  return (
    <motion.div
      className="flex flex-col h-full w-full px-8 py-10"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      {/* Testimonial carousel container - increased padding and adjusted height */}
      <div className="relative overflow-visible w-full flex-grow flex items-center justify-center">
        <AnimatePresence initial={false} custom={direction} mode="wait">
          <motion.div
            key={currentIndex}
            custom={direction}
            variants={variants}
            initial="enter"
            animate="center"
            exit="exit"
            transition={{
              x: { type: "spring", stiffness: 300, damping: 30 },
              opacity: { duration: 0.3 },
              scale: { type: "spring", stiffness: 200, damping: 25 },
            }}
            className="w-full max-w-md mx-auto"
            drag="x"
            dragConstraints={{ left: 0, right: 0 }}
            dragElastic={0.1}
            onDragEnd={handleDragEnd}
          >
            {/* Testimonial card - increased height and padding */}
            <div
              className="backdrop-blur-sm rounded-2xl p-8 shadow-xl relative min-h-[280px] flex flex-col justify-between"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderWidth: "1px",
                borderColor: "rgba(255, 255, 255, 0.1)",
              }}
            >
              {/* Quote text with quote mark */}
              <div className="flex-grow relative">
                {/* Quote mark */}
                <div className="absolute top-0 left-0 opacity-15">
                  <svg
                    width="40"
                    height="30"
                    viewBox="0 0 40 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M10 8H6c-1.1 0-2 .9-2 2v10l4-4h2V10c0-1.1-.9-2-2-2zm12 0h-4c-1.1 0-2 .9-2 2v10l4-4h2V10c0-1.1-.9-2-2-2z"
                      fill="white"
                    />
                  </svg>
                </div>

                {/* Quote text - reduced bottom margin */}
                <p
                  className="mt-8 text-lg leading-relaxed pl-0 mb-4"
                  style={{ color: text.white }}
                >
                  {content.testimonials[currentIndex].quote}
                </p>
              </div>

              {/* Attribution section with initials */}
              <div className="flex items-center mb-0">
                <div
                  className="w-10 h-10 rounded-full flex items-center justify-center flex-shrink-0"
                  style={{ background: getAccentGradient(currentIndex) }}
                >
                  <span
                    className="font-bold text-sm"
                    style={{ color: text.white }}
                  >
                    {getInitials(content.testimonials[currentIndex].name)}
                  </span>
                </div>
                <div className="ml-3">
                  <h3
                    className="font-semibold mb-0"
                    style={{ color: text.white }}
                  >
                    {content.testimonials[currentIndex].name}
                  </h3>
                  {content.testimonials[currentIndex].role && (
                    <p className="text-sm mb-0" style={{ color: primary[200] }}>
                      {content.testimonials[currentIndex].role}
                    </p>
                  )}
                </div>
              </div>
            </div>
          </motion.div>
        </AnimatePresence>

        {/* Navigation buttons */}
        <button
          onClick={() => {
            pauseAutoplay();
            prevTestimonial();
          }}
          className="absolute left-0 top-1/2 -translate-y-1/2 transform -translate-x-5 w-10 h-10 rounded-full backdrop-blur-sm flex items-center justify-center transition-colors"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            "--hover-bg-color": "rgba(255, 255, 255, 0.2)",
          }}
          aria-label="Previous testimonial"
        >
          <svg
            className="w-5 h-5"
            style={{ color: text.white }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M15 19l-7-7 7-7"
            />
          </svg>
        </button>

        <button
          onClick={() => {
            pauseAutoplay();
            nextTestimonial();
          }}
          className="absolute right-0 top-1/2 -translate-y-1/2 transform translate-x-5 w-10 h-10 rounded-full backdrop-blur-sm flex items-center justify-center transition-colors"
          style={{
            backgroundColor: "rgba(255, 255, 255, 0.1)",
            "--hover-bg-color": "rgba(255, 255, 255, 0.2)",
          }}
          aria-label="Next testimonial"
        >
          <svg
            className="w-5 h-5"
            style={{ color: text.white }}
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              strokeWidth={2}
              d="M9 5l7 7-7 7"
            />
          </svg>
        </button>
      </div>

      {/* Progress indicator dots */}
      <div className="mt-8 flex justify-center">
        <div className="flex space-x-2">
          {content.testimonials.map((_, index) => (
            <button
              key={index}
              onClick={() => {
                pauseAutoplay();
                setDirection(index > currentIndex ? 1 : -1);
                setCurrentIndex(index);
              }}
              className={`h-2 rounded-full transition-all duration-300 ${
                index === currentIndex ? "w-6" : "w-2"
              }`}
              style={{
                backgroundColor:
                  index === currentIndex
                    ? primary[500]
                    : "rgba(255, 255, 255, 0.3)",
                "--hover-bg-color": "rgba(255, 255, 255, 0.5)",
              }}
              aria-label={`Go to testimonial ${index + 1}`}
            />
          ))}
        </div>
      </div>
    </motion.div>
  );
};

export default TestimonialScreen;
