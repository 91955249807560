import React from "react";

function BasicFieldsEditor({ data, onChange }) {
  const updateField = (field, value) => {
    onChange({ ...data, [field]: value });
  };

  return (
    <div className="space-y-6">
      <div className="mb-6">
        <h4 className="text-gray-300 text-sm font-medium mb-2">
          Messages Count
        </h4>
        <input
          type="number"
          value={data.messages_count || 0}
          onChange={(e) =>
            updateField("messages_count", parseInt(e.target.value) || 0)
          }
          className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
      </div>

      <div className="mb-6">
        <h4 className="text-gray-300 text-sm font-medium mb-2">Is Paying</h4>
        <select
          value={data.is_paying?.toString()}
          onChange={(e) => updateField("is_paying", e.target.value === "true")}
          className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>

      <div className="mb-6">
        <h4 className="text-gray-300 text-sm font-medium mb-2">
          Shutdown Mode
        </h4>
        <select
          value={data.shutdown_mode?.toString()}
          onChange={(e) =>
            updateField("shutdown_mode", e.target.value === "true")
          }
          className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
        >
          <option value="true">Yes</option>
          <option value="false">No</option>
        </select>
      </div>
    </div>
  );
}

export default BasicFieldsEditor;
