import React, { useEffect } from "react";
import { motion } from "framer-motion";
import { primary, text, border, gradient } from "../theme/variables";
import "../theme/theme.css"; // Import CSS variables for classes

const SectionTransition = ({ content, onUpdate }) => {
  // Automatically advance after delay
  useEffect(() => {
    const timer = setTimeout(() => {
      onUpdate({});
    }, 0);

    return () => clearTimeout(timer);
  }, [onUpdate]);

  // Extract the section name without the "Part X: " prefix
  const sectionName = content.title.replace(/Part \d+: /g, "");

  return (
    <motion.div
      className="flex items-center justify-center h-full overflow-hidden"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
      transition={{ duration: 0.6 }}
    >
      <motion.div
        className="relative z-10 max-w-md text-center px-6"
        initial={{ y: 20, opacity: 0 }}
        animate={{ y: 0, opacity: 1 }}
        transition={{ duration: 0.5 }}
      >
        {/* Minimalist background element */}
        <div className="absolute -z-10 inset-0 overflow-hidden">
          <motion.div
            className="absolute w-[400px] h-[400px] rounded-full -translate-x-1/2 -translate-y-1/2 left-1/2 top-1/2"
            style={{
              borderWidth: "1px",
              borderColor: `${primary[500]}0D`, // 5% opacity
            }}
            initial={{ scale: 0.9, opacity: 0 }}
            animate={{
              scale: 1,
              opacity: 0.15,
              rotate: 360,
            }}
            transition={{
              duration: 30,
              ease: "linear",
              repeat: Infinity,
              opacity: { duration: 1.2 },
            }}
          />
        </div>

        {/* Section indicator pill */}
        <motion.div
          className="inline-block mb-8"
          initial={{ y: -10, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.1 }}
        >
          <div
            className="text-sm font-medium backdrop-blur-sm px-5 py-2 rounded-full"
            style={{
              background: gradient.sectionTransition,
              color: `${primary[300]}`,
              borderWidth: "1px",
              borderColor: `${primary[400]}1A`, // 10% opacity
            }}
          >
            {content.title.split(":")[0]}
          </div>
        </motion.div>

        {/* Section title with elegant typography */}
        <motion.h2
          className="text-5xl font-bold mb-6 tracking-tight"
          style={{ color: text.white }}
          initial={{ y: 15, opacity: 0 }}
          animate={{ y: 0, opacity: 1 }}
          transition={{ duration: 0.6, delay: 0.2 }}
        >
          {sectionName.length > 25
            ? `${sectionName.substring(0, 25)}...`
            : sectionName}
        </motion.h2>

        {/* Minimal elegant separator */}
        <motion.div
          className="h-px w-16 mx-auto mb-8"
          style={{
            background: `linear-gradient(to right, transparent, ${primary[400]}, transparent)`,
          }}
          initial={{ width: 0, opacity: 0 }}
          animate={{ width: 64, opacity: 0.7 }}
          transition={{ duration: 0.8, delay: 0.3 }}
        />

        {/* Description with refined typography */}
        <motion.p
          className="mb-12 mx-auto font-light text-lg leading-relaxed max-w-sm"
          style={{ color: `${primary[50]}` }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.7, delay: 0.4 }}
        >
          {content.description}
        </motion.p>

        {/* Subtle progress indicator */}
        <motion.div
          className="flex justify-center gap-3"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.5, delay: 0.6 }}
        >
          {[0, 1, 2].map((i) => (
            <motion.div
              key={i}
              className="w-1.5 h-1.5 rounded-full"
              style={{ backgroundColor: `${primary[300]}80` }} // 50% opacity
              animate={{
                opacity: [0.3, 0.8, 0.3],
                scale: [1, 1.1, 1],
              }}
              transition={{
                duration: 2,
                repeat: Infinity,
                delay: i * 0.4,
                ease: "easeInOut",
              }}
            />
          ))}
        </motion.div>
      </motion.div>

      {/* Subtle ambient light effect */}
      <motion.div
        className="absolute w-full h-full top-0 left-0 opacity-20 pointer-events-none"
        style={{
          background: `radial-gradient(circle at 50% 50%, ${primary[500]}1A 0%, transparent 70%)`,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 0.2 }}
        transition={{ duration: 2 }}
      />
    </motion.div>
  );
};

export default SectionTransition;
