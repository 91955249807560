import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { primary, text, background, border } from "../theme/variables";
import "../theme/theme.css";

const MultiSelectScreen = ({ onUpdate, content, hideDefaultButton = true }) => {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [hasSubmitted, setHasSubmitted] = useState(false);

  const toggleOption = (option) => {
    if (selectedOptions.includes(option)) {
      setSelectedOptions(selectedOptions.filter((item) => item !== option));
    } else {
      setSelectedOptions([...selectedOptions, option]);
    }
  };

  const handleContinue = () => {
    if (selectedOptions.length > 0) {
      setHasSubmitted(true);
      onUpdate(selectedOptions);
    }
  };

  // This effect triggers if the component needs to update without showing its own continue button
  useEffect(() => {
    if (selectedOptions.length > 0 && !hasSubmitted && !hideDefaultButton) {
      // Don't call update again if we've already submitted
      // This prevents loops if the parent component keeps this mounted
      const timer = setTimeout(() => {
        onUpdate(selectedOptions);
      }, 300);
      return () => clearTimeout(timer);
    }
  }, [selectedOptions, hasSubmitted, onUpdate, hideDefaultButton]);

  return (
    <motion.div
      className="flex flex-col py-4" // Reduced padding
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <motion.h2
        className="text-2xl font-bold mb-4"
        style={{ color: text.white }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        {content.question}
      </motion.h2>

      {content.description && (
        <motion.p
          className="mb-4 text-sm"
          style={{ color: primary[300] }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: 0.2, duration: 0.5 }}
        >
          {content.description}
        </motion.p>
      )}

      <div className="grid grid-cols-1 gap-2 mb-4">
        {" "}
        {/* Reduced gap and margin */}
        {content.options.map((option, index) => (
          <motion.button
            key={index}
            className="w-full text-left py-3 px-4 rounded-lg transition-all duration-200 flex items-center relative z-10"
            style={{
              backgroundColor: selectedOptions.includes(option)
                ? primary[600]
                : `${primary[900]}33`, // 20% opacity
              color: text.white,
              borderWidth: "1px",
              borderColor: selectedOptions.includes(option)
                ? primary[500]
                : `${primary[800]}4D`, // 30% opacity
              boxShadow: selectedOptions.includes(option)
                ? `0 4px 6px -1px ${primary[900]}33`
                : "none",
            }}
            onClick={() => toggleOption(option)}
            initial={{ opacity: 0, x: -20 }}
            animate={{ opacity: 1, x: 0 }}
            transition={{ delay: 0.1 + index * 0.05, duration: 0.5 }}
            whileHover={{ scale: 1.02 }}
            whileTap={{ scale: 0.98 }}
          >
            <div
              className="w-5 h-5 rounded mr-3 flex-shrink-0 flex items-center justify-center"
              style={{
                borderWidth: "2px",
                borderColor: selectedOptions.includes(option)
                  ? text.white
                  : primary[500],
                backgroundColor: selectedOptions.includes(option)
                  ? text.white
                  : "transparent",
              }}
            >
              {selectedOptions.includes(option) && (
                <motion.svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-3 w-3"
                  style={{ color: primary[600] }}
                  viewBox="0 0 20 20"
                  fill="currentColor"
                  initial={{ scale: 0 }}
                  animate={{ scale: 1 }}
                  transition={{ duration: 0.2 }}
                >
                  <path
                    fillRule="evenodd"
                    d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                    clipRule="evenodd"
                  />
                </motion.svg>
              )}
            </div>
            <span>{option}</span>
          </motion.button>
        ))}
      </div>

      {/* We'll display this button conditionally only if we need to handle our own continue */}
      {hideDefaultButton && (
        <div className="mt-auto">
          <motion.button
            onClick={handleContinue}
            disabled={selectedOptions.length === 0}
            className="w-full py-3.5 rounded-md font-medium transition-colors duration-200 shine-effect relative z-10"
            style={{
              backgroundColor:
                selectedOptions.length > 0 ? primary[600] : `${primary[900]}66`, // 40% opacity
              color: selectedOptions.length > 0 ? text.white : primary[300],
              boxShadow:
                selectedOptions.length > 0
                  ? "0 4px 6px -1px rgba(0, 0, 0, 0.1)"
                  : "none",
              cursor: selectedOptions.length > 0 ? "pointer" : "not-allowed",
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.3, duration: 0.5 }}
          >
            Continue
          </motion.button>
        </div>
      )}

      {selectedOptions.length > 0 && (
        <motion.p
          className="text-center mt-2 text-sm"
          style={{ color: primary[300] }}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ duration: 0.3 }}
        >
          {selectedOptions.length} option
          {selectedOptions.length !== 1 ? "s" : ""} selected
        </motion.p>
      )}
    </motion.div>
  );
};

export default MultiSelectScreen;
