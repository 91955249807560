// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.intro-container {
  background: linear-gradient(to right, rgb(40, 21, 2), #3a1f04, #1d0f01);
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 20px;
}

@media (max-width: 600px) {
  .text-6xl {
    font-size: 3.5rem;
  }
}
`, "",{"version":3,"sources":["webpack://./src/purpose-assessment/Introduction.css"],"names":[],"mappings":"AAAA;EACE,uEAAuE;EACvE,aAAa;EACb,aAAa;EACb,mBAAmB;EACnB,uBAAuB;EACvB,aAAa;AACf;;AAEA;EACE;IACE,iBAAiB;EACnB;AACF","sourcesContent":[".intro-container {\n  background: linear-gradient(to right, rgb(40, 21, 2), #3a1f04, #1d0f01);\n  height: 100vh;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  padding: 20px;\n}\n\n@media (max-width: 600px) {\n  .text-6xl {\n    font-size: 3.5rem;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
