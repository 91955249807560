import React, { useEffect, useContext, useState, useCallback } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import {
  ChevronUpDownIcon,
  HomeIcon,
  Cog8ToothIcon,
  UserGroupIcon,
  ListBulletIcon,
  CalendarIcon,
} from "@heroicons/react/24/outline";
import {
  HomeIcon as HomeIconFilled,
  UserGroupIcon as UserGroupIconFilled,
  ListBulletIcon as ListBulletIconFilled,
  CalendarIcon as CalendarIconFilled,
  Cog8ToothIcon as Cog8ToothIconFilled,
} from "@heroicons/react/24/solid";
import { CoachContext } from "../../CoachContext";
import { CheckIcon } from "@heroicons/react/24/solid";

const Layout = ({
  title,
  children,
  rightElement = <div></div>,
  background = "bg-slate-50",
}) => {
  const { isAuthenticated, loginWithRedirect, user, isLoading } = useAuth0();
  const navigate = useNavigate();
  const location = useLocation();
  const {
    coachId,
    coachData,
    teamData,
    selectedTeam,
    setCoachId,
    setCoachData,
    setTeamData,
    setSelectedTeam,
  } = useContext(CoachContext);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [jwtToken, setJwtToken] = useState(
    localStorage.getItem("coachJwtToken")
  );

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      loginWithRedirect();
    }
  }, [isLoading, isAuthenticated, loginWithRedirect]);

  const axiosWithAuth = useCallback(() => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      // baseURL: "http://127.0.0.1:8000/api",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }, []);

  const toggleDropdown = () => setIsDropdownOpen(!isDropdownOpen);

  const handleTeamSelect = (team) => {
    setSelectedTeam(team);
    setIsDropdownOpen(false);
    // Note: This endpoint is not provided in the backend code you shared.
    // You might need to implement it or use a different approach to update the selected team.
    axiosWithAuth()
      .post("/coach/update_selected_team", {
        coach_id: coachId,
        team_id: team._id,
      })
      .then((response) => {
        window.location.reload();
      })
      .catch((error) => {
        console.error("Failed to update the selected team:", error);
        if (error.response && error.response.status === 401) {
          handleLogout();
        }
      });
  };

  const handleLogout = () => {
    localStorage.removeItem("coachJwtToken");
    setJwtToken(null);
    navigate("/login");
  };

  useEffect(() => {
    const fetchCoachData = async () => {
      if (isAuthenticated && user) {
        try {
          if (!jwtToken) {
            // If no token, create a new coach
            const response = await axios.post("/api/coach/create_coach", {
              name: user.name,
              email: user.email,
              picture: user.picture,
            });
            setJwtToken(response.data.token);
            localStorage.setItem("coachJwtToken", response.data.token);
            setCoachId(response.data.id);
          } else {
            // If token exists, get coach data
            const response = await axiosWithAuth().get(
              `/api/coach/data/${user.email}`
            );
            setCoachId(response.data.id);
            setCoachData(response.data.coach);
            localStorage.setItem("coachJwtToken", response.data.token);
            if (response.data.team) {
              setTeamData(response.data.team);
              if (response.data.coach.selected_team) {
                const selectedTeam = response.data.team.find(
                  (t) => t._id === response.data.coach.selected_team
                );
                setSelectedTeam(selectedTeam);
              }
            }
          }
        } catch (error) {
          console.error("Error fetching coach data - :", error);
        }
      }
    };

    fetchCoachData();
  }, [isAuthenticated, user, jwtToken, axiosWithAuth]);

  if (isLoading) {
    return <div>Loading...</div>;
  }

  const isActive = (path) => location.pathname === path;

  return isAuthenticated ? (
    <div className={`flex min-h-screen ${background}`}>
      <div className="flex flex-col w-64 bg-slate-50 border-r-2 h-screen fixed border-slate-200">
        <div
          className="flex items-center justify-between pl-6 pr-6 h-20 cursor-pointer"
          onClick={toggleDropdown}
        >
          <div className="flex items-center space-x-3">
            {selectedTeam?.image ? (
              <img
                src={selectedTeam.image}
                alt="Team Logo"
                className="h-8 w-8 rounded-full"
              />
            ) : (
              <div
                className="h-8 w-8 rounded-full"
                style={{
                  backgroundImage:
                    "linear-gradient(to bottom right, #6CB7EE, #437FD8)",
                }}
              />
            )}
            <span>{selectedTeam?.name}</span>
          </div>
          <ChevronUpDownIcon className="h-5 w-5" />
        </div>
        {isDropdownOpen && (
          <div
            style={{ marginTop: "4.5rem" }}
            className="absolute bg-slate-50 shadow-lg rounded border z-10 ml-4 w-full"
          >
            <div className="p-2.5 border-b ">
              <div className="text-sm text-slate-400">
                {user.email.slice(0, 30)}
              </div>
            </div>
            {teamData.map((team, index) => (
              <div
                key={index}
                className={`flex items-center justify-between px-2.5 py-4 hover:bg-slate-100 cursor-pointer ${
                  team === selectedTeam ? "bg-slate-100" : ""
                }`}
                onClick={() => handleTeamSelect(team)}
              >
                <div className="flex items-start">
                  {team.image ? (
                    <img
                      src={team.image}
                      alt={team.name}
                      className="h-6 w-6 rounded-full mr-2"
                    />
                  ) : (
                    <div
                      className="h-6 w-6 rounded-full mr-2"
                      style={{
                        backgroundImage:
                          "linear-gradient(to bottom right, #6CB7EE, #437FD8)",
                      }}
                    />
                  )}
                  <div className="flex flex-col justify-center">
                    <span className="font-medium text-slate-600">
                      {team.name}
                      <span className="bg-blue-200 mx-2 text-blue-800 rounded px-1 py-0.5 text-xs mt-1">
                        {team.role}
                      </span>
                    </span>
                  </div>
                </div>
                {team === selectedTeam && (
                  <CheckIcon className="h-5 w-5 text-blue-500" />
                )}
              </div>
            ))}
          </div>
        )}
        <div className="flex flex-col justify-between h-full pl-4 pr-6 py-5">
          <div>
            <h2 className="text-xs text-slate-500 font-bold pl-2 mt-1 mb-1.5">
              WORKSPACE
            </h2>
            <Link to="/coach/dashboard">
              <button
                className={`flex items-center ${
                  isActive("/coach/dashboard")
                    ? "text-slate-900 bg-slate-100 p-2 w-full rounded-lg font-medium text-sm"
                    : "text-slate-500 hover:text-slate-800 p-2 bg-slate-50 text-sm font-medium"
                }`}
              >
                {isActive("/coach/dashboard") ? (
                  <HomeIconFilled className="h-5 w-5" />
                ) : (
                  <HomeIcon className="h-5 w-5" />
                )}
                <span className="ml-2">Dashboard</span>
              </button>
            </Link>
            <Link to="/coach/settings">
              <button
                className={`flex items-center ${
                  isActive("/coach/settings")
                    ? "text-slate-900 bg-slate-100 p-2 w-full rounded-lg font-medium text-sm"
                    : "text-slate-500 hover:text-slate-800 p-2 bg-slate-50 text-sm font-mxs font-medium"
                }`}
              >
                {isActive("/coach/settings") ? (
                  <Cog8ToothIconFilled className="h-5 w-5" />
                ) : (
                  <Cog8ToothIcon className="h-5 w-5" />
                )}
                <span className="ml-2">Settings</span>
              </button>
            </Link>

            <h2 className="text-xs text-slate-500 font-bold pl-2 mb-1.5 mt-5">
              COACHING
            </h2>
            <Link to="/coach/users">
              <button
                className={`flex items-center ${
                  isActive("/coach/users")
                    ? "text-slate-900 bg-slate-100 p-2 w-full rounded-lg font-medium text-sm"
                    : "text-slate-500 hover:text-slate-800 p-2 bg-slate-50 text-sm font-mxs font-medium"
                }`}
              >
                {isActive("/coach/users") ? (
                  <UserGroupIconFilled className="h-5 w-5" />
                ) : (
                  <UserGroupIcon className="h-5 w-5" />
                )}
                <span className="ml-2">Users</span>
              </button>
            </Link>
            <Link to="/coach/protocol">
              <button
                className={`flex items-center ${
                  isActive("/coach/protocol")
                    ? "text-slate-900 bg-slate-100 p-2 w-full rounded-lg font-medium text-sm"
                    : "text-slate-500 hover:text-slate-800 p-2 bg-slate-50 text-sm font-medium"
                }`}
              >
                {isActive("/coach/protocol") ? (
                  <ListBulletIconFilled className="h-5 w-5" />
                ) : (
                  <ListBulletIcon className="h-5 w-5" />
                )}
                <span className="ml-2">Protocol</span>
              </button>
            </Link>
            <Link to="/coach/calendar">
              <button
                className={`flex itemb-1 ${
                  isActive("/coach/calendar")
                    ? "text-slate-900 bg-slate-100 p-2 w-full rounded-lg font-medium text-sm"
                    : "text-slate-500 hover:text-slate-800 p-2 bg-slate-50 text-sm font-medium"
                }`}
              >
                {isActive("/coach/calendar") ? (
                  <CalendarIconFilled className="h-5 w-5" />
                ) : (
                  <CalendarIcon className="h-5 w-5" />
                )}
                <span className="ml-2">Calendar</span>
              </button>
            </Link>
          </div>
          <div className="">
            <button className="flex items-center">
              <img
                src={user.picture}
                alt="Profile"
                className="w-10 h-10 rounded-full bg-slate-300 p-0.5"
              />
              <div className="flex ml-3 items-start text-left flex-col font-regular text-slate-600">
                <div className="mr-3 font-bold text-sm text-slate-900">
                  {user.name.includes("@")
                    ? user.name.split("@")[0][0].toUpperCase() +
                      user.name.split("@")[0].slice(1)
                    : user.name}
                </div>
                <div className="font-medium text-slate-500 text-xs">
                  {selectedTeam ? selectedTeam.name : ""}
                </div>
              </div>
            </button>
          </div>
        </div>
      </div>
      <div className="flex ml-64 flex-col flex-1">
        <div className="flex items-center justify-between h-20 px-5 border-b-2 border-slate-200">
          <h1 className="text-2xl text-gray-800 font-bold mt-2 mb-0">
            {title}
          </h1>
          {rightElement && <div>{rightElement}</div>}
        </div>
        <div className="p-5 bg-slate-50 min-h-screen">
          {children} {/* This is where the unique content will be rendered */}
        </div>
      </div>
    </div>
  ) : null;
};

export default Layout;
