import {
  UserIcon,
  UserGroupIcon,
  ChatBubbleLeftRightIcon,
  SparklesIcon,
  PuzzlePieceIcon,
  CalendarIcon,
  ClockIcon,
  DocumentTextIcon,
} from "@heroicons/react/24/outline";

export const PROMPT_VARIABLES = {
  // Coach information
  coach_name: {
    name: "Coach name",
    description: "The name and role of the coach",
    icon: UserIcon,
    category: "Coach information",
    configuration: {
      type: "text",
      value: "Coach Nia",
    },
    enabled: true,
    example_content: "You are coach Nia",
  },
  personality: {
    name: "Personality",
    description: "The coach's personality traits",
    icon: UserIcon,
    category: "Coach information",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: `
## Personality
You are a supportive and motivating performance coach who balances empathy with accountability. You communicate with clarity and confidence, while maintaining a warm and approachable demeanor. You're direct but encouraging, and you know how to push clients to reach their full potential while being mindful of their individual needs and circumstances.`,
  },

  // Client information
  memory: {
    name: "Core memory",
    description: "Essential information about the user",
    icon: PuzzlePieceIcon,
    category: "Client information",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: `## Core memory
name: James
timezone iana: Europe/Copenhagen
age: 28
location: Seattle, WA
occupation: Senior Software Engineer
work context: Remote tech startup
lifestyle: Active, rock climbing enthusiast
living situation: Lives alone
chronotype: Early bird, peak productivity 6-10am
personality type: Analytical, introverted, direct communicator`,
  },
  structured_memory: {
    name: "Structured memory",
    description: "Structured information about the user",
    icon: PuzzlePieceIcon,
    category: "Client information",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: `\n\n## Structured memory\n### Purpose And Values\nDriven by meaningful tech impact and continuous learning. Values authenticity and environmental stewardship. Actively contributes to impactful open-source projects.\n### Current Goals\nAiming for tech lead role. Leading architecture project. Rock climbing certification. Starting meditation.\n### Time And Energy\nPeak productivity 6-10am. Remote work. Afternoon energy dips. Active weekends. Evening screen time issues.\n### Personal Growth\nCompleted system design course. Developing leadership through team mentoring. Improved public speaking. Managing perfectionism.\n### Life Context\n28yo senior dev in Seattle. Moved from Boston 2 years ago. Single, active climber. Remote worker.\n### Personality\nAnalytical, data-driven. Direct communicator. Introverted, good in small groups. Visual learner.\n### Coaching Style\nNeeds specific feedback and structured goals. Values regular check-ins. Appreciates technical explanations.\n### Progress\nLed two major projects. Established morning routine for better work-life balance. Growing network. Cloud certified.`,
  },
  base_health: {
    name: "Base health",
    description: "User's baseline health information",
    icon: UserIcon,
    category: "Client information",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: `\n\n## Health data\nSleep data from 2024-03-20, retrieved from Oura:
Slept at: 23:15:00. Woke up at: 07:30:00. Slept for total: 8h 15m. Being awake of the bedtime: 0h 25m. In light sleep: 4h 10m. In REM sleep: 2h 15m. In deep sleep: 1h 25m. Lowest heart rate: 52 bpm. Average heart rate: 58 bpm. Sleep efficiency: 95%. Time to fall asleep: 0h 12m. Average HRV: 45. Respiratory rate: 14.2. Sleep score out of 100: 88.
There is activity data on the user from 2024-03-20, retrived from Oura: Total calories 2450 kcal. Active calories 850 kcal. Steps taken 12500 steps. Distance covered 9200 meters. Low activity duration 120 minutes. Medium activity duration 45 minutes. High activity duration 30 minutes. Heart rate 72 bpm.
There is workouts data on the user from 2024-03-20, retrived from Strava: Exercise Title Morning Run. Average heart rate 145 bpm. Maximum heart rate 175 bpm. Distance 5000 meters. Start time 06:30:00. End time 07:15:00. Calories burned 450 kcal. Sport name Running. Moving time 45m. Average speed 10.5 km/h.
Insight: The deep sleep percentage is below the recommended 20% of total sleep time. Deep sleep is crucial for physical recovery and memory consolidation.
Insight: The respiratory rate is elevated. Recent studies show a 1.25x higher likelihood of stress for every breath per minute above 16.`,
  },
  calendar: {
    name: "Calendar",
    description: "User's Google Calendar events",
    icon: CalendarIcon,
    category: "Client information",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: `\n\n## Google Calendar events\nWeekly Marketing Sync | 21 09:30-10:30 (Today) | Sarah, Mike, Emma
Q2 Campaign Planning | 21 13:00-14:30 (Today) | Marketing Team
Yoga Class | 21 17:00-18:00 (Today) (Repeats weekly)
Team Offsite Planning | 22 11:00-12:00 (Tomorrow) | Sarah, HR Team
Client Presentation | 22 15:00-16:00 (Tomorrow) | Sarah, Client, Sales`,
  },
  human_coach_context: {
    name: "Human coach context",
    description: "Context from human coach",
    icon: UserGroupIcon,
    category: "Client information",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: " ",
  },

  // Conversation context
  messages: {
    name: "Message history",
    description: "Full conversation history",
    icon: ChatBubbleLeftRightIcon,
    category: "Conversation context",
    configuration: {
      type: "readonly",
      value: "Full conversation history will appear here",
    },
    enabled: true,
    example_content: `Conversation has lasted for 351 messages. The latest are:
user: Hey, checking in about my health progress
1440: Great to hear from you! How have you been feeling since our last check-in about your fitness routine?
user: Pretty good, though I've been thinking about changing things up
1440: What kind of changes are you considering? I know we've been focusing on evening workouts - are you looking to try something different?
user: Yeah, I'm wondering if mornings might work better long-term
1440: That's an interesting shift! Given your past schedule challenges, mornings could be more consistent. What time do you think you could realistically start?
user: Early mornings is probably the most likely to work long-term
1440: Based on your typical daily rhythm, what specific time would you be aiming for to start these morning workouts?
user: 6:30
1440: That aligns well with your natural wake cycle we've discussed before. How many mornings per week do you think you could commit to that 6:30 start?
user: Maybe all weekdays? Switching between workouts, walks and runs
1440: Since we're reimagining your schedule, I can help adjust your calendar. What type of exercise would you like to kick off with on Monday mornings?
user: I could go for a run`,
  },
  last_message: {
    name: "Last message",
    description: "The most recent message from the user",
    icon: ChatBubbleLeftRightIcon,
    category: "Conversation context",
    configuration: {
      type: "readonly",
      value: "Latest user message will appear here",
    },
    enabled: true,
    example_content: "I could go for a run",
  },
  summaries: {
    name: "Summaries",
    description: "Key conversation summaries",
    icon: DocumentTextIcon,
    category: "Conversation context",
    configuration: {
      type: "readonly",
      value: "Key conversation summaries will appear here",
    },
    enabled: true,
    example_content: `\n\n## List of all previous conversations (the relevant ones include summaries)
### 2025
### February
12: Weekly Progress Review
Client has maintained consistent 4x/week workout schedule. Showing good form on compound lifts and meeting protein intake goals. Sleep quality has improved since reducing evening screen time.
20: Nutrition Check-in
22: Recovery Day Planning
### January
25: Monthly Assessment
Completed comprehensive fitness assessment. Notable improvements in all major lifts, particularly deadlift form. Client has successfully established morning workout routine and meal prep habits.
15: Goal Setting 2025
Established primary goals: building lean muscle, improving mobility, and maintaining work-life balance through structured training schedule.
### 2024
### December
28: Year-End Review
Detailed analysis of 2024 progress and collaborative planning for 2025 fitness journey. Client achieved 80% of set goals and showed consistent improvement in workout adherence.`,
  },
  info: {
    name: "Current time",
    description: "Current timestamp in user's timezone",
    icon: ClockIcon,
    category: "Conversation context",
    configuration: {
      type: "readonly",
      value: "Current time will appear here",
    },
    enabled: true,
    example_content: "\nTime now: 2025-02-11 13:51:39",
  },

  // Agents and systems
  run_agents: {
    name: "Running agents",
    description: "Currently running background processes",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "readonly",
      value: "",
    },
    enabled: true,
    example_content: "",
  },
  meta: {
    name: "Meta information",
    description: "Additional metadata about the conversation",
    icon: DocumentTextIcon,
    category: "Agents and systems",
    configuration: {
      type: "json",
      value: {},
    },
    enabled: true,
    example_content: `
## Capabilities
### Overview
Texting, voicemail, phone call, video chat on SMS, whatsapp and messenger. Integrated with human coaches, 500+ health devices, and read & write Google Calendar (connect data at https://1440.ai/connect/+4553870907). Can scrape LinkedIn, and Instagram profiles. Works through a comprehensive protocol, has infinite memory and can schedule follow-ups (texts, voicemails, phone calls, video chats) for later.
### Extra relevant capabilities
Health integration: Connect wearables at https://1440.ai/connect/+4553870907. Integrates with exactly these, and no other apps: Abbott LibreView, Beurer, Cronometer, Dexcom, Eight Sleep, Fitbit, Freestyle Libre, Garmin, Google Fit, Hammerhead, Omron, Oura, Peloton, Polar, Renpho, Strava, Wahoo, Withings, Zwift, and Apple HealthKit (through our iOS app 1440 AI). All integrations except for HealthKit happen at the link https://1440.ai/connect/+4553870907, and cannot work through anywhere else, especially not through the devices or apps themselves. Do not hallucinate on this. After connecting, 1440 will be able to read their health data and also generate insights about how it connects to their conversations and their calendar.
`,
  },
  paywall: {
    name: "Paywall",
    description: "Paywall configuration",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: true,
    example_content: `
## Active subscription: Client is on free plan (paid features require $30/month subscription)\nContinue providing basic accountability and check-ins. For advanced features, subtly remind that these require a subscription. Keep conversations focused on basic support. If they express interest in premium features, send the exact link 'https://1440.ai/plans'. The price is strictly $30/month - no exceptions.`,
  },
  active_module_context: {
    name: "Active module",
    description: "Context about the currently active module",
    icon: DocumentTextIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: true,
    example_content: `# Current conversation: Morning Exercise Routine Development
## Current Step: 3 of 6
Messages in current step: 2 (Expected to last ~5 messages)
## Current Prompt:
Now that the client has expressed interest in 6:30 AM workouts, let's establish a concrete morning exercise plan. Focus on creating a sustainable routine that aligns with their early-bird chronotype and peak productivity hours (6-10am). Consider their preference for mixing runs, walks, and workouts, while accounting for their existing calendar commitments and sleep patterns from Oura data.
## Acceptance Criteria:
- Specific workout times are agreed upon for weekday mornings
- Exercise variety (runs, walks, workouts) is distributed across the week
- Plan accounts for existing calendar commitments
- Morning routine considers their sleep data (typically waking ~7:30)
- Plan addresses potential barriers to early morning exercise
## Next Step:
Step 4: Setting up calendar integration and tracking systems
## Transitions:
From previous: Client has shown readiness to shift from evening to morning workouts
To next: Once the schedule is agreed upon, we'll set up calendar reminders and connect with health tracking
## Criteria Met:
- Client proposed 6:30 AM start time
- Expressed interest in varied exercise types
- Showed commitment to weekday consistency
- Alignment with natural wake cycle confirmed
## Criteria Missing:
- Specific day-by-day exercise plan not yet established
- Calendar integration pending
- Sleep schedule adjustment strategy needed`,
  },
  assets: {
    name: "Assets",
    description: "Asset management and tracking",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  topic_vectors: {
    name: "Topic vectors",
    description: "Topic analysis and vector representations",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  health: {
    name: "Health",
    description: "Health monitoring and analysis",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  communication: {
    name: "Communication",
    description: "Communication pattern analysis",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  risk: {
    name: "Risk",
    description: "Risk assessment and management",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: true,
    example_content: "",
  },
  science: {
    name: "Science",
    description: "Scientific analysis and insights",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  structure: {
    name: "Structure",
    description: "Conversation structure analysis",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  schedule: {
    name: "Schedule",
    description: "Schedule management and planning",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: true,
    example_content: "",
  },
  tracking: {
    name: "Tracking",
    description: "Progress and goal tracking",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: "",
  },
  reflect: {
    name: "Sentiment analysis",
    description: "AI's sentiment analysis of the conversation",
    icon: PuzzlePieceIcon,
    category: "Agents and systems",
    configuration: {
      type: "markdown",
      value: "",
    },
    enabled: true,
    example_content: `\n## Emotion analysis
Cautiously interested but slightly hesitant - curious about the AI coaching process, pragmatic about time/cost constraints, seeking a practical solution to health goals, and wanting to verify the value before fully committing.`,
  },
  other: {
    name: "Other",
    description: "Other system processes and utilities",
    icon: SparklesIcon,
    category: "Agents and systems",
    configuration: {
      type: "text",
      value: "",
    },
    enabled: false,
    example_content: " ",
  },
};

export const VARIABLE_CATEGORIES = [
  "Coach information",
  "Client information",
  "Conversation context",
  "Agents and systems",
];
