import React, { useState } from "react";
import PhoneInput, { isValidPhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

const EditCommaSeparatedList = ({ value, onChange }) => {
  const safeValue = value || "";
  const items = safeValue === "" ? [] : safeValue.split(",").map(item => item.trim());
  const [editingIndex, setEditingIndex] = useState(null);
  const [isAdding, setIsAdding] = useState(false);
  const [newPhoneNumber, setNewPhoneNumber] = useState("");
  const [error, setError] = useState("");

  const validatePhoneNumber = (number) => {
    if (!number) return "Phone number is required";
    if (!isValidPhoneNumber(number)) return "Invalid phone number for selected country";
    return "";
  };

  const handleItemChange = (newValue, index) => {
    setError("");
    const validationError = validatePhoneNumber(newValue);
    if (!validationError) {
      const updatedItems = [...items];
      updatedItems[index] = newValue;
      onChange(updatedItems.join(","));
    } else {
      setError(validationError);
    }
  };

  const handleAddItem = () => {
    setIsAdding(true);
    setNewPhoneNumber("");
    setError("");
  };

  const handleSaveNew = () => {
    const validationError = validatePhoneNumber(newPhoneNumber);
    if (validationError) {
      setError(validationError);
      return;
    }
    
    const updatedItems = [...items, newPhoneNumber];
    onChange(updatedItems.join(","));
    setIsAdding(false);
    setNewPhoneNumber("");
    setError("");
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onChange(updatedItems.join(","));
    setError("");
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setError("");
  };

  const handleSaveEdit = (index) => {
    const validationError = validatePhoneNumber(items[index]);
    if (validationError) {
      setError(validationError);
      return;
    }
    setEditingIndex(null);
    setError("");
  };

  const inputStyle = "flex-grow border rounded-lg px-3 py-2 focus:ring-2 focus:ring-blue-500";

  return (
    <div className="space-y-4">
      {error && (
        <div className="text-red-500 text-sm bg-red-50 p-2 rounded-lg">
          {error}
        </div>
      )}
      
      {items.map((item, index) => (
        <div key={index} className="flex items-center gap-2">
          {editingIndex === index ? (
            <>
              <PhoneInput
                international
                defaultCountry="US"
                value={item}
                onChange={(newValue) => handleItemChange(newValue, index)}
                className={inputStyle}
                error={error && "Invalid phone number"}
              />
              <button
                onClick={() => handleSaveEdit(index)}
                className="bg-green-500 text-white px-3 py-1.5 rounded hover:bg-green-600"
              >
                Save
              </button>
            </>
          ) : (
            <>
              <div className={inputStyle + " bg-gray-50"}>
                {item}
              </div>
              <button
                onClick={() => handleEdit(index)}
                className="bg-blue-500 text-white px-3 py-1.5 rounded hover:bg-blue-600"
              >
                Edit
              </button>
            </>
          )}
          <button
            onClick={() => handleRemoveItem(index)}
            className="bg-red-500 text-white px-3 py-1.5 rounded hover:bg-red-600"
          >
            Remove
          </button>
        </div>
      ))}

      {isAdding ? (
        <div className="flex items-center gap-2 mt-4">
          <PhoneInput
            international
            defaultCountry="US"
            value={newPhoneNumber}
            onChange={setNewPhoneNumber}
            className={inputStyle}
            error={error && "Invalid phone number"}
          />
          <button
            onClick={handleSaveNew}
            className="bg-green-500 text-white px-3 py-1.5 rounded hover:bg-green-600"
          >
            Save
          </button>
          <button
            onClick={() => {
              setIsAdding(false);
              setError("");
            }}
            className="bg-gray-500 text-white px-3 py-1.5 rounded hover:bg-gray-600"
          >
            Cancel
          </button>
        </div>
      ) : (
        <button
          onClick={handleAddItem}
          className="mt-2 bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          Add Phone Number
        </button>
      )}
    </div>
  );
};

export default EditCommaSeparatedList; 