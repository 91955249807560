import React from "react";
import { Card } from "../../../components/ui/Card";
import {
  Activity,
  ArrowRight,
  BarChart4,
  Brain,
  Calendar,
  CheckCircle,
  Mic,
  MessageSquare,
  PhoneCall,
  Users,
} from "lucide-react";

const HowWeHelp = () => {
  const solutions = [
    {
      icon: MessageSquare,
      title: "24/7 Text & Call Support",
      description:
        "Text or call with 1440 anytime on WhatsApp, SMS, or Messenger. Get guidance when you need it most, whether it's early morning or late at night.",
      action: "Start chatting",
    },
    {
      icon: Calendar,
      title: "Smart Calendar Management",
      description:
        "1440 actively manages your calendar to create space for what matters, blocking time for deep work and scheduling calls when you're most receptive.",
      action: "See how it works",
    },
    {
      icon: Activity,
      title: "Biomarker-Driven Support",
      description:
        "We monitor your sleep, activity, and social media use patterns to reach out at optimal times - like a gentle reminder when screen time spikes or stress levels rise.",
      action: "Learn more",
    },
    {
      icon: BarChart4,
      title: "Real-Time Insights",
      description:
        "See exactly where your time goes versus your priorities, with concrete steps to reduce digital distractions and increase time on what truly matters.",
      action: "View example",
    },
    {
      icon: Brain,
      title: "AI-Enhanced Coaching",
      description:
        "1440 and our experts analyze your patterns and provide insights to make every interaction more impactful and personalized to your needs.",
      action: "How it works",
    },
    {
      icon: Users,
      title: "Community Connection",
      description:
        "Connect with peers and your personal human coach in guided group sessions that provide perspective and mutual accountability for lasting change.",
      action: "Meet the community",
    },
  ];

  const timelineEvents = [
    {
      time: "7:00 AM",
      icon: MessageSquare,
      title: "Morning message",
      description:
        "1440 texts a brief focus question based on your stated priorities for the week",
    },
    {
      time: "9:30 AM",
      icon: PhoneCall,
      title: "Pre-meeting call",
      description:
        "A 3-minute coaching call helps you clarify your intentions before an important meeting",
    },
    {
      time: "1:15 PM",
      icon: Activity,
      title: "Biomarker alert",
      description:
        "Stress levels rising—a breathing exercise and priority reminder is texted to you",
    },
    {
      time: "4:00 PM",
      icon: CheckCircle,
      title: "Quick check-in",
      description:
        "A text to help you reflect on today's focus and adjust as needed",
    },
    {
      time: "8:30 PM",
      icon: Mic,
      title: "Evening voicemail",
      description:
        "1440 shares a short voice message to help you process the day and set intentions for tomorrow",
    },
  ];

  return (
    <div>
      <div className="mb-24 max-w-6xl mx-auto">
        <div className="relative">
          <h2 className="text-3xl md:text-4xl font-bold text-white mb-12 text-center">
            A day with <span className="text-primary">1440</span>'s support
          </h2>

          <div className="relative pl-8 md:pl-10">
            {/* Vertical timeline line */}
            <div className="absolute left-0 top-0 bottom-0 w-1 bg-gradient-to-b from-primary/80 via-primary/50 to-primary/20"></div>

            <div className="space-y-12">
              {timelineEvents.map((event, index) => (
                <div key={index} className="group">
                  <div className="flex items-center mb-3">
                    {/* Circle marker - perfectly centered on the line */}
                    <div className="absolute left-[-2.2px] w-[9px] h-[9px] rounded-full bg-primary group-hover:scale-125 transition-transform"></div>
                    {/* Time */}
                    <div className="text-primary font-medium text-base md:text-lg ml-6">
                      {event.time}
                    </div>
                  </div>

                  <div className="glass-card p-4 md:p-6 rounded-xl border border-white/10 group-hover:border-primary/30 transition-colors">
                    <div className="flex items-center gap-3 mb-2">
                      <event.icon className="h-5 w-5 text-primary" />
                      <span className="text-white font-medium">
                        {event.title}
                      </span>
                    </div>
                    <p className="text-gray-300 text-base mb-0 md:text-lg">
                      {event.description}
                    </p>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>

      <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-8">
        {solutions.map((solution, index) => (
          <Card
            key={index}
            className="glass-card p-8 border border-white/10 hover:border-primary/30 transition-all duration-300"
          >
            <div className="flex items-start gap-6">
              <div className="bg-primary/20 p-4 rounded-xl">
                <solution.icon className="h-7 w-7 text-primary" />
              </div>
              <div className="flex-1">
                <h3 className="font-semibold text-xl mb-3 text-white">
                  {solution.title}
                </h3>
                <p className="text-gray-300 mb-0 leading-relaxed">
                  {solution.description}
                </p>
              </div>
            </div>
          </Card>
        ))}
      </div>
    </div>
  );
};

export default HowWeHelp;
