// SalesLanding.js after correctly removing invite code and waitlist signup logic
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Mixpanel from "mixpanel-browser";
import SalesChat from "./SalesChat";
import WaitlistSignup from "../UI/WaitlistSignup"; // Import WaitlistSignup component

function SalesLanding({ userId, setUserId, onReferrerUpdate }) {
  Mixpanel.init("d724d0d0045430023cc58dd17086256b", { track_pageview: true });
  if (userId) Mixpanel.identify(userId);
  Mixpanel.track("Page View", { page: "welcome" });

  const [isLoading, setIsLoading] = useState(false);

  if (isLoading) {
    return (
      <div className="flex justify-center items-center h-screen bg-black">
        <h1 className="text-white text-3xl">Loading...</h1>
      </div>
    );
  }

  return (
    <div className="bg-black">
      <div className="bg-black px-8 py-16 sm:px-16 md:px-32 lg:px-32 xl:px-48">
        <div className="flex justify-center lg:justify-between items-center mb-16 lg:mb-24">
          <div className="flex items-center">
            <img
              src="/images/logo-white.png"
              alt="1440 logo"
              className="w-28 brightness-100 mr-3"
            />
          </div>
          <div className="hidden lg:flex lg:items-center lg:relative lg:px-6 lg:py-2 lg:rounded-full lg:border lg:border-white lg:border-opacity-40">
            <div className="flex justify-center items-center">
              <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-lightBlue opacity-50"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-accent"></span>
            </div>
            <span className="ml-2 text-white text-sm opacity-60">
              alpha version live
            </span>
          </div>
        </div>
        {/* Alpha version indicator for mobile */}
        <div className="lg:hidden flex justify-center my-12">
          <div className="relative px-6 py-2 rounded-full border border-white border-opacity-40 flex items-center">
            <div className="flex justify-center items-center">
              <span className="animate-ping absolute inline-flex h-3 w-3 rounded-full bg-lightBlue opacity-50"></span>
              <span className="relative inline-flex rounded-full h-2 w-2 bg-accent"></span>
            </div>
            <span className="ml-2 text-white text-sm opacity-60">
              alpha version live
            </span>
          </div>
        </div>
        {/* Main content and Waitlist Signup Component */}
        <div className="flex text-center lg:text-left items-center flex-col lg:flex-row lg:justify-between">
          <div className="flex w-full mb-8 lg:mb-0 lg:mr-5 flex-col justify-center items-center lg:items-start text-white">
            <h2 className="text-5xl lg:text-7xl 2xl:text-8xl mb-2 lg:mb-6 lg:mt-0 leading-tight lg:leading-tight opacity-90">
              <span className="text-transparent font-medium bg-clip-text bg-gradient-to-r from-accent to-darkBlue">
                Your Time<br></br>On Purpose
              </span>
            </h2>
            <p className="text-2xl 2xl:text-3xl lg:w-3/4 opacity-60 m-0">
              24/7 hyperpersonal AI coaching, empowering you to live your best
              life
            </p>
          </div>
          <div className="w-full lg:w-3/4">
            <WaitlistSignup />
          </div>
        </div>
      </div>
      {/* SalesChat Component */}
      <div className="border-t border-opacity-50 border-accent lg:border-none shadow-inner px-8 sm:px-16 md:px-32 lg:px-32 xl:px-48">
        <SalesChat userId={userId} setUserId={setUserId} />
      </div>
    </div>
  );
}

export default React.memo(SalesLanding);
