import React, { useState } from "react";
import { PROMPT_VARIABLES } from "./PromptVariables";
import PromptVariableSidebar from "./PromptVariableSidebar";
import { CATEGORY_COLORS } from "./constants";
import {
  DocumentTextIcon,
  DocumentMagnifyingGlassIcon,
} from "@heroicons/react/24/outline";

const VARIABLE_REGEX = /\{([^}]+)\}/g;

const renderText = (text, useExampleContent = false) => {
  const parts = [];
  let lastIndex = 0;
  const matches = text.matchAll(VARIABLE_REGEX);

  for (const match of matches) {
    if (match.index > lastIndex) {
      parts.push(text.slice(lastIndex, match.index));
    }

    const variable = PROMPT_VARIABLES[match[1]];
    if (variable) {
      const colors = CATEGORY_COLORS[variable.category];
      if (useExampleContent && variable.enabled) {
        // In preview mode, show example content with hover tooltip
        const content = variable.example_content || `[${variable.name}]`;
        parts.push(
          `<span class="group relative">${content}<span class="pointer-events-none fixed opacity-0 transition-opacity group-hover:opacity-100 ${colors.bg} border ${colors.text} text-xs rounded-lg shadow-sm whitespace-nowrap z-10 px-2 py-1" style="top: calc(var(--mouse-y, 0) * 1px - 30px); left: calc(var(--mouse-x, 0) * 1px + 10px);">${variable.name}</span></span>`
        );
      } else {
        // In edit mode, show highlighted variable
        parts.push(
          `<span class="${colors.bg} ${colors.text} rounded">${match[0]}</span>`
        );
      }
    } else {
      parts.push(`<span class="text-gray-400">${match[0]}</span>`);
    }
    lastIndex = match.index + match[0].length;
  }

  if (lastIndex < text.length) {
    parts.push(text.slice(lastIndex));
  }

  return parts.join("");
};

export default function PromptEditor({ value, onChange }) {
  const [variables, setVariables] = useState(PROMPT_VARIABLES);
  const [activeTab, setActiveTab] = useState("edit"); // "edit" | "preview"

  // Track mouse position
  const handleMouseMove = (e) => {
    document.documentElement.style.setProperty("--mouse-x", e.clientX);
    document.documentElement.style.setProperty("--mouse-y", e.clientY);
  };

  // Sync scroll between textarea and preview
  const handleScroll = (e) => {
    requestAnimationFrame(() => {
      const preview = document.getElementById("preview");
      if (preview) {
        preview.scrollTop = e.target.scrollTop;
        preview.scrollLeft = e.target.scrollLeft;
      }
    });
  };

  const handleConfigureVariable = (key, newValue, newEnabled) => {
    const updatedVariables = {
      ...variables,
      [key]: {
        ...variables[key],
        configuration: {
          ...variables[key].configuration,
          value: newValue,
        },
        enabled: newEnabled,
      },
    };
    setVariables(updatedVariables);
  };

  return (
    <div className="flex h-full" onMouseMove={handleMouseMove}>
      <PromptVariableSidebar
        onInsert={(variableKey) => {
          const el = document.getElementById("editor");
          const start = el.selectionStart;
          const newValue =
            value.slice(0, start) + `{${variableKey}}` + value.slice(start);
          onChange(newValue);
          requestAnimationFrame(() => {
            el.focus();
          });
        }}
        onConfigure={handleConfigureVariable}
      />
      <div className="flex-1 flex flex-col overflow-hidden">
        <div className="border-b border-gray-200 bg-slate-50 px-4 flex-shrink-0">
          <div className="flex gap-2">
            <button
              onClick={() => setActiveTab("edit")}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 ${
                activeTab === "edit"
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              <DocumentTextIcon className="w-4 h-4" />
              Editor
            </button>
            <button
              onClick={() => setActiveTab("preview")}
              className={`px-4 py-2 text-sm font-medium flex items-center gap-2 ${
                activeTab === "preview"
                  ? "text-blue-600 border-b-2 border-blue-600"
                  : "text-gray-500 hover:text-gray-700"
              }`}
            >
              <DocumentMagnifyingGlassIcon className="w-4 h-4" />
              Preview
            </button>
          </div>
        </div>
        <div className="flex-1 relative">
          {activeTab === "edit" ? (
            <div className="absolute inset-0">
              <textarea
                id="editor"
                value={value}
                onChange={(e) => onChange(e.target.value)}
                onScroll={handleScroll}
                className="absolute inset-0 w-full h-full resize-none bg-transparent p-4 focus:outline-none z-10"
                style={{
                  color: "transparent",
                  caretColor: "black",
                  lineHeight: "24px",
                  fontSize: "14px",
                }}
              />
              <div
                id="preview"
                className="absolute inset-0 whitespace-pre-wrap text-gray-900 p-4 pointer-events-none overflow-hidden"
                style={{
                  lineHeight: "24px",
                  fontSize: "14px",
                }}
                dangerouslySetInnerHTML={{ __html: renderText(value, false) }}
              />
            </div>
          ) : (
            <div className="absolute inset-0 overflow-auto">
              <div className="p-4 whitespace-pre-wrap text-gray-900">
                <div
                  dangerouslySetInnerHTML={{ __html: renderText(value, true) }}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
