// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.toggle {
    width: 38px;
    margin-right: 8px;
    height: 16px;
    border: 2px solid #FF8C22;
    border-radius: 12.5px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 2px;
  }
  
  .toggle.dark .circle {
    transform: translateX(200%);
  }
  
  .toggle.light .circle {
    transform: translateX(0);
  }
  
  .circle {
    width: 10px;
    height: 10px;
    background-color: #FF8C22;
    border-radius: 50%;
    transition: transform 0.3s ease;
  }

  .progress-bar-container {}
  .progress-bar {
    height: 2px;
  }`, "",{"version":3,"sources":["webpack://./src/components/footer/Footer.css"],"names":[],"mappings":"AAAA;IACI,WAAW;IACX,iBAAiB;IACjB,YAAY;IACZ,yBAAyB;IACzB,qBAAqB;IACrB,eAAe;IACf,aAAa;IACb,mBAAmB;IACnB,8BAA8B;IAC9B,YAAY;EACd;;EAEA;IACE,2BAA2B;EAC7B;;EAEA;IACE,wBAAwB;EAC1B;;EAEA;IACE,WAAW;IACX,YAAY;IACZ,yBAAyB;IACzB,kBAAkB;IAClB,+BAA+B;EACjC;;EAEA,yBAAyB;EACzB;IACE,WAAW;EACb","sourcesContent":[".toggle {\n    width: 38px;\n    margin-right: 8px;\n    height: 16px;\n    border: 2px solid #FF8C22;\n    border-radius: 12.5px;\n    cursor: pointer;\n    display: flex;\n    align-items: center;\n    justify-content: space-between;\n    padding: 2px;\n  }\n  \n  .toggle.dark .circle {\n    transform: translateX(200%);\n  }\n  \n  .toggle.light .circle {\n    transform: translateX(0);\n  }\n  \n  .circle {\n    width: 10px;\n    height: 10px;\n    background-color: #FF8C22;\n    border-radius: 50%;\n    transition: transform 0.3s ease;\n  }\n\n  .progress-bar-container {}\n  .progress-bar {\n    height: 2px;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
