import React from "react";
import { motion } from "framer-motion";
import {
  MessageSquare,
  Activity,
  Brain,
  Calendar,
  CheckCircle,
  Users,
  PhoneCall,
  Mic,
} from "lucide-react";
import { primary, text, border } from "../theme/variables";
import "../theme/theme.css";

const ValuePropositionScreen = ({ onUpdate, content }) => {
  // Use the exact same content as HowWeHelp
  const timelineEvents = [
    {
      time: "7:00 AM",
      icon: MessageSquare,
      title: "Morning message",
      description:
        "1440 texts a brief focus question based on your stated priorities for the week",
    },
    {
      time: "9:30 AM",
      icon: PhoneCall,
      title: "Pre-meeting call",
      description:
        "A 3-minute coaching call helps you clarify your intentions before an important meeting",
    },
    {
      time: "1:15 PM",
      icon: Activity,
      title: "Biomarker alert",
      description:
        "Stress levels rising—a breathing exercise and priority reminder is texted to you",
    },
    {
      time: "4:00 PM",
      icon: CheckCircle,
      title: "Quick check-in",
      description:
        "A text to help you reflect on today's focus and adjust as needed",
    },
    {
      time: "8:30 PM",
      icon: Mic,
      title: "Evening voicemail",
      description:
        "1440 shares a short voice message to help you process the day and set intentions for tomorrow",
    },
  ];

  return (
    <motion.div
      className="flex flex-col h-full"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <motion.h2
        className="text-2xl md:text-3xl font-bold mb-3 text-center"
        style={{ color: text.white }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        How We Approach <span className="text-primary">Change</span>
      </motion.h2>

      <div className="flex-grow flex flex-col justify-start pt-4">
        {timelineEvents.map((event, index) => (
          <motion.div
            key={index}
            className="group"
            initial={{ opacity: 0, y: 10 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.2 + index * 0.1, duration: 0.5 }}
          >
            <div
              className="px-4 py-3 rounded-xl mb-3 transition-colors"
              style={{
                backgroundColor: "rgba(255, 255, 255, 0.05)",
                borderWidth: "1px",
                borderColor: "rgba(255, 255, 255, 0.1)",
                "--hover-border-color": `${primary[500]}4D`, // 30% opacity
              }}
            >
              <div className="flex justify-between mb-1.5 items-center">
                <div className="flex items-center gap-2">
                  <event.icon className="h-4 w-4 text-primary" />
                  <span
                    style={{ color: text.white }}
                    className="font-medium text-sm"
                  >
                    {event.title}
                  </span>
                </div>
                <div className="text-primary font-medium text-xs">
                  {event.time}
                </div>
              </div>
              <p
                className="text-xs mb-0 mt-0.5"
                style={{ color: "rgb(209, 213, 219)" }} // gray-300
              >
                {event.description}
              </p>
            </div>
          </motion.div>
        ))}
      </div>
    </motion.div>
  );
};

export default ValuePropositionScreen;
