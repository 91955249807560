import React from "react";

const GroundScreenMotivation = () => {
  const motivationMessage = `
    1440 <span class="text-accent">integrates</span> your calendar and health data for insights that sculpt a <span class="text-accent">balanced</span>, well-informed lifestyle.<br><br>Experience <span class="text-accent">tailored support</span> with our unique combination of AI and human coaching that can take in <span class="text-accent">more information</span> and more context to break free from cookie cutter solutions and give you <span class="text-accent">science backed</span> plans customized for you.<br><br>1440 is designed to fit neatly into <span class="text-accent">almost any lifestyle</span>. Because we can be available <span class="text-accent">24/7</span> and sessions can be as long and as short as you need.
  `;

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p
        className="text-white text-left text-3xl font-bold mb-6"
        dangerouslySetInnerHTML={{ __html: motivationMessage }}
      ></p>
    </div>
  );
};

export default GroundScreenMotivation;
