import React, { useState, useEffect } from "react";
import ToggleVoice from "../header/ToggleVoice";

const SalesNavBar = () => {
  return (
    <div className="mt-10 lg:mt-0">
      <nav className="flex justify-between items-center bg-light pb-4 z-20 text-black dark:text-white dark:bg-dark transition-all duration-500">
        <div className="flex items-center text-lg lg:text-xl flex-col gap-4 lg:flex-row">
          <img
            src="/images/logo-white.png"
            alt="Logo"
            className="h-7 w-auto brightness-0 dark:brightness-100 "
          />
          <p className="text-lg hidden lg:flex mb-0 font-bold opacity-80">
            Chat with 1440 about how we can help you
          </p>
        </div>

        {/* Desktop Menu Items */}
        <div className="lg:flex items-center space-x-4">
          <ToggleVoice />
        </div>
      </nav>
      <hr className="bg-white h-0 w-full mb-6 lg:mb-32 opacity-50" />
    </div>
  );
};

export default SalesNavBar;
