import React from "react";

const Button = ({ children }) => {
  const onClick = () => {
    // redirect to WhatsApp
    window.location.href = "https://wa.me/+16203372653?text=Hello";
  };
  return (
    <button onClick={onClick} className="text-[#DCFF02] font-light text-base">
      {children}
    </button>
  );
};

export default Button;
