import React, { useState, useEffect } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [selectedGoals, setSelectedGoals] = useState([]);

  const goals = [
    "Find my life's purpose",
    "Work on my mental health",
    "Improve my mindset",
    "Set and pursue personal goals",
    "Improve my career trajectory",
    "Build emotional intelligence",
    "Manage stress",
    "Boost my sense of self",
    "Improve one or more relationships",
    "Improve overall well-being",
    "I'm not sure yet, I need guidance.",
  ];

  const handleGoalSelection = (goal) => {
    setSelectedGoals((prevGoals) => {
      if (prevGoals.includes(goal)) {
        return prevGoals.filter((g) => g !== goal);
      } else {
        return [...prevGoals, goal];
      }
    });
  };

  useEffect(() => {
    onUpdate(selectedGoals);
  }, [selectedGoals, onUpdate]);

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        What are your primary goals as they relate to purpose, mental health,
        and self-actualization?
      </p>
      <div className="flex flex-col w-full">
        {goals.map((goal) => (
          <button
            key={goal}
            onClick={() => handleGoalSelection(goal)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              selectedGoals.includes(goal)
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: selectedGoals.includes(goal) ? "0 0 2px #FF8C22" : "",
            }}
          >
            {goal}
          </button>
        ))}
      </div>
    </div>
  );
}
