import React, { useState, useEffect } from 'react';
import ToggleVoice from '../header/ToggleVoice';

const NewYearsCountdown = () => {
  const calculateTimeLeft = () => {
    const newYear = new Date(`January 1 ${new Date().getFullYear() + 1} 00:00:00`);
    const currentTime = new Date();
    const difference = newYear - currentTime;

    let timeLeft = {};

    if (difference > 0) {
      timeLeft = {
        days: Math.floor(difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((difference / 1000 / 60) % 60),
        seconds: Math.floor((difference / 1000) % 60)
      };
    }

    return timeLeft;
  };

  const [timeLeft, setTimeLeft] = useState(calculateTimeLeft());

  useEffect(() => {
    const timer = setTimeout(() => {
      setTimeLeft(calculateTimeLeft());
    }, 1000);

    return () => clearTimeout(timer);
  });

  const countdown = "🎉 " + timeLeft.days + "d " + 
                    timeLeft.hours + "h " + 
                    timeLeft.minutes + "m " + 
                    timeLeft.seconds + "s until new years 🎉";

  return (
    <div className="countdown">
      {countdown}
    </div>
  );
};

const NewYearsNavbar = () => {

  return (
    <div>
      <nav className="flex justify-center md:justify-between items-center bg-light pb-4 z-20 text-black dark:text-white dark:bg-dark transition-all duration-500">
        <div className="flex items-center text-base md:text-lg flex-col gap-4 md:flex-row">
        <img src="/images/logo-white.png" alt="Logo" className="h-7 w-auto brightness-0 dark:brightness-100 mr-8" />
        
        <NewYearsCountdown />
        </div>
        
        {/* Desktop Menu Items */}
        <div className="hidden md:flex items-center space-x-4">
          <ToggleVoice />
        </div>
      </nav>

    </div>
  );
};

export default NewYearsNavbar;
