import React, { useState } from "react";
import {
  ChevronUpDownIcon,
  PencilSquareIcon,
  TrashIcon,
  ChevronDoubleDownIcon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@heroicons/react/24/outline";
import ModuleEditor from "./ModuleEditor";

// Module type structure
export const PRIORITY_LEVELS = {
  MUST_DO: "MUST_DO",
  CLIENT_CHOICE: "CLIENT_CHOICE",
  OPTIONAL: "OPTIONAL",
};

export const PRIORITY_LABELS = {
  MUST_DO: "Must do",
  CLIENT_CHOICE: "If client wants to",
  OPTIONAL: "Optional",
};

export const DEFAULT_MODULE = {
  module_id: "",
  title: "",
  priority: PRIORITY_LEVELS.MUST_DO,
  activations: [],
  prompt_type: "string", // or "chain"
  options: {
    include_previous_step: false,
    include_next_step: false,
  },
  prompt_data: "", // string for text type, array for chain type
};

const ACTIVATION_TYPES = {
  ACCOUNT_CREATION: {
    id: "account_creation",
    label: "Account creation",
    format: "Account creation",
    type: "simple",
  },
  PAYMENT_RECEIVED: {
    id: "payment_received",
    label: "Payment received",
    format: "Payment received",
    type: "simple",
  },
  MESSAGE_COUNT: {
    id: "message_count",
    label: "After x messages",
    format: "After {count} messages",
    type: "number",
    param: "count",
  },
  WEEKLY_RECURRING: {
    id: "weekly_recurring",
    label: "Recurring weekly",
    format: "Recurring weekly on {day} starting after {time} days",
    type: "weekly",
    params: ["day", "time"],
  },
  MONTHLY_RECURRING: {
    id: "monthly_recurring",
    label: "Recurring monthly",
    format: "Recurring monthly on day {day} starting after {time} days",
    type: "monthly",
    params: ["day", "time"],
  },
  TIME_AFTER: {
    id: "time_after",
    label: "After time period",
    format: "After {count} {unit}",
    type: "time",
    params: ["count", "unit"],
  },
  QUARTERLY_RECURRING: {
    id: "quarterly_recurring",
    label: "Recurring quarterly",
    format: "Recurring quarterly on day {day} starting after {time} days",
    type: "quarterly",
    params: ["day", "time"],
  },
  CALENDAR_CONNECTED: {
    id: "calendar_connected",
    label: "Calendar connected",
    format: "Calendar connected",
    type: "simple",
  },
  HEALTH_CONNECTED: {
    id: "health_connected",
    label: "Health device connected",
    format: "Health device connected",
    type: "simple",
  },
};

const DAYS_OF_WEEK = [
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
  "Sunday",
];

const TIME_UNITS = ["hours", "days", "weeks", "months"];

const parseActivation = (activationString) => {
  // Try to match against each activation type's format
  for (const type of Object.values(ACTIVATION_TYPES)) {
    if (activationString === type.format) {
      return { type: type.id };
    }

    // For parameterized types, extract the values
    if (type.type !== "simple") {
      const regex = type.format
        .replace(/\{(\w+)\}/g, "(.+)")
        .replace(/[()]/g, "\\$&");
      const match = activationString.match(new RegExp(`^${regex}$`));
      if (match) {
        const values = match.slice(1);
        const params = type.params || [type.param];
        return {
          type: type.id,
          params: Object.fromEntries(
            params.map((param, i) => [param, values[i]])
          ),
        };
      }
    }
  }
  return null;
};

const formatActivation = (type, params = {}) => {
  const activationType = ACTIVATION_TYPES[type];
  if (!activationType) return "";

  let result = activationType.format;
  if (activationType.type === "simple") return result;

  const paramList = activationType.params || [activationType.param];
  paramList.forEach((param) => {
    result = result.replace(`{${param}}`, params[param] || "");
  });
  return result;
};

const ActivationEditor = ({ value, onChange, onRemove }) => {
  const activation = parseActivation(value) || { type: "", params: {} };
  const activationType = ACTIVATION_TYPES[activation.type];

  const handleTypeChange = (newType) => {
    const type = ACTIVATION_TYPES[newType];
    if (type.type === "simple") {
      onChange(type.format);
    } else {
      const params = {};
      (type.params || [type.param]).forEach((param) => {
        params[param] = "";
      });
      onChange(formatActivation(newType, params));
    }
  };

  const handleParamChange = (param, value) => {
    const params = { ...activation.params, [param]: value };
    onChange(formatActivation(activation.type, params));
  };

  return (
    <div className="flex items-start space-x-2">
      <div className="flex-grow space-y-2">
        <select
          className="w-full border rounded p-2"
          value={activation.type}
          onChange={(e) => handleTypeChange(e.target.value)}
        >
          <option value="">Select activation type</option>
          {Object.entries(ACTIVATION_TYPES).map(([key, type]) => (
            <option key={key} value={key}>
              {type.label}
            </option>
          ))}
        </select>

        {activationType && activationType.type !== "simple" && (
          <div className="flex items-center space-x-2">
            {activationType.type === "weekly" && (
              <>
                <select
                  className="border rounded p-2"
                  value={activation.params?.day || ""}
                  onChange={(e) => handleParamChange("day", e.target.value)}
                >
                  <option value="">Select day</option>
                  {DAYS_OF_WEEK.map((day) => (
                    <option key={day} value={day}>
                      {day}
                    </option>
                  ))}
                </select>
                <span>starting after</span>
                <input
                  type="number"
                  className="border rounded p-2 w-20"
                  value={activation.params?.time || ""}
                  onChange={(e) => handleParamChange("time", e.target.value)}
                  min="0"
                />
                <span>days</span>
              </>
            )}

            {(activationType.type === "monthly" ||
              activationType.type === "quarterly") && (
              <>
                <span>on day</span>
                <input
                  type="number"
                  className="border rounded p-2 w-20"
                  value={activation.params?.day || ""}
                  onChange={(e) => handleParamChange("day", e.target.value)}
                  min="1"
                  max="31"
                />
                <span>starting after</span>
                <input
                  type="number"
                  className="border rounded p-2 w-20"
                  value={activation.params?.time || ""}
                  onChange={(e) => handleParamChange("time", e.target.value)}
                  min="0"
                />
                <span>days</span>
              </>
            )}

            {activationType.type === "time" && (
              <>
                <span>After</span>
                <input
                  type="number"
                  className="border rounded p-2 w-20"
                  value={activation.params?.count || ""}
                  onChange={(e) => handleParamChange("count", e.target.value)}
                  min="1"
                />
                <select
                  className="border rounded p-2"
                  value={activation.params?.unit || ""}
                  onChange={(e) => handleParamChange("unit", e.target.value)}
                >
                  <option value="">Select unit</option>
                  {TIME_UNITS.map((unit) => (
                    <option key={unit} value={unit}>
                      {unit}
                    </option>
                  ))}
                </select>
              </>
            )}

            {activationType.type === "number" && (
              <>
                <span>After</span>
                <input
                  type="number"
                  className="border rounded p-2 w-20"
                  value={activation.params?.count || ""}
                  onChange={(e) => handleParamChange("count", e.target.value)}
                  min="1"
                />
                <span>messages</span>
              </>
            )}
          </div>
        )}
      </div>

      <button
        onClick={onRemove}
        className="p-2 text-red-500 hover:bg-red-50 rounded"
      >
        <TrashIcon className="h-4 w-4" />
      </button>
    </div>
  );
};

const ModulePromptEditor = ({
  promptType,
  promptData,
  options = {},
  onChange,
}) => {
  const handleTypeChange = (newType) => {
    onChange({
      type: newType,
      data: newType === "string" ? "" : [],
      options:
        newType === "chain"
          ? {
              include_previous_step: false,
              include_next_step: false,
            }
          : {},
    });
  };

  const handleChainItemChange = (index, field, value) => {
    const updatedChain = [...promptData];
    updatedChain[index] = { ...updatedChain[index], [field]: value };
    onChange({
      type: promptType,
      data: updatedChain,
      options,
    });
  };

  const addChainStep = () => {
    onChange({
      type: promptType,
      data: [
        ...(promptData || []),
        {
          prompt: "",
          acceptance_criteria: "",
          transition_to: "",
          transition_from: "",
          estimated_length: 5,
        },
      ],
      options,
    });
  };

  return (
    <div className="space-y-4">
      <div className="flex items-center space-x-4">
        <label className="text-sm font-medium text-gray-700">
          Prompt Type:
        </label>
        <select
          className="border rounded p-2"
          value={promptType}
          onChange={(e) => handleTypeChange(e.target.value)}
        >
          <option value="string">Single Text</option>
          <option value="chain">Prompt Chain</option>
        </select>
      </div>

      {promptType === "string" ? (
        <textarea
          className="w-full border rounded p-2"
          value={promptData || ""}
          onChange={(e) =>
            onChange({
              type: "string",
              data: e.target.value,
              options: {},
            })
          }
          placeholder="Enter prompt text..."
          rows={4}
        />
      ) : (
        <div className="space-y-4">
          {promptType === "chain" && (
            <div className="flex items-center space-x-4 ml-4">
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={options.include_previous_step}
                  onChange={(e) =>
                    onChange({
                      type: promptType,
                      data: promptData,
                      options: {
                        ...options,
                        include_previous_step: e.target.checked,
                      },
                    })
                  }
                />
                <span className="ml-2">
                  Include content from previous step during this step
                </span>
              </label>
              <label className="inline-flex items-center">
                <input
                  type="checkbox"
                  className="form-checkbox"
                  checked={options.include_next_step}
                  onChange={(e) =>
                    onChange({
                      type: promptType,
                      data: promptData,
                      options: {
                        ...options,
                        include_next_step: e.target.checked,
                      },
                    })
                  }
                />
                <span className="ml-2">
                  Include content from next step during this step
                </span>
              </label>
            </div>
          )}

          {Array.isArray(promptData) &&
            promptData.map((step, index) => (
              <div key={index} className="border rounded p-4 space-y-4">
                <div className="flex justify-between items-center">
                  <h4 className="font-medium">Step {index + 1}</h4>
                  <button
                    onClick={() => {
                      const newChain = [...promptData];
                      newChain.splice(index, 1);
                      onChange({
                        type: promptType,
                        data: newChain,
                        options,
                      });
                    }}
                    className="text-red-500"
                  >
                    <TrashIcon className="h-4 w-4" />
                  </button>
                </div>
                <div className="space-y-2">
                  <textarea
                    className="w-full border rounded p-2"
                    value={step.prompt}
                    onChange={(e) =>
                      handleChainItemChange(index, "prompt", e.target.value)
                    }
                    placeholder="Enter step prompt..."
                    rows={3}
                  />
                  <textarea
                    className="w-full border rounded p-2"
                    value={step.acceptance_criteria}
                    onChange={(e) =>
                      handleChainItemChange(
                        index,
                        "acceptance_criteria",
                        e.target.value
                      )
                    }
                    placeholder="Enter acceptance criteria..."
                    rows={2}
                  />
                  <div className="grid grid-cols-2 gap-2">
                    <textarea
                      className="w-full border rounded p-2"
                      value={step.transition_to}
                      onChange={(e) =>
                        handleChainItemChange(
                          index,
                          "transition_to",
                          e.target.value
                        )
                      }
                      placeholder="Enter transition to..."
                      rows={2}
                    />
                    <textarea
                      className="w-full border rounded p-2"
                      value={step.transition_from}
                      onChange={(e) =>
                        handleChainItemChange(
                          index,
                          "transition_from",
                          e.target.value
                        )
                      }
                      placeholder="Enter transition from..."
                      rows={2}
                    />
                  </div>
                  <div className="flex items-center space-x-2">
                    <label className="text-sm text-gray-600">
                      Estimated Length:
                    </label>
                    <input
                      type="number"
                      className="w-20 border rounded p-1"
                      value={step.estimated_length}
                      onChange={(e) =>
                        handleChainItemChange(
                          index,
                          "estimated_length",
                          parseInt(e.target.value)
                        )
                      }
                      min="1"
                    />
                  </div>
                </div>
              </div>
            ))}
          <button
            onClick={addChainStep}
            className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            + Add Step
          </button>
        </div>
      )}
    </div>
  );
};

const ProtocolRoadmap = ({ initialModules, onUpdate }) => {
  const [modules, setModules] = useState(initialModules || []);
  const [editingIndex, setEditingIndex] = useState(null);
  const [visibleModulesCount, setVisibleModulesCount] = useState(10);
  const [draggedIndex, setDraggedIndex] = useState(null);
  const [hasChanges, setHasChanges] = useState(false);

  const handleAdd = () => {
    setEditingIndex(-1);
    setHasChanges(true);
  };

  const handleDelete = (index, event) => {
    event.stopPropagation();
    const updatedModules = modules.filter((_, i) => i !== index);
    setModules(updatedModules);
    setHasChanges(true);
  };

  const handleLoadMore = () => {
    setVisibleModulesCount(modules.length);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
  };

  const handleDragStart = (index, e) => {
    setDraggedIndex(index);
    e.dataTransfer.effectAllowed = "move";
  };

  const handleDragOver = (index, e) => {
    e.preventDefault();
    if (draggedIndex === null) return;
    if (draggedIndex === index) return;

    const updatedModules = [...modules];
    const [draggedModule] = updatedModules.splice(draggedIndex, 1);
    updatedModules.splice(index, 0, draggedModule);

    setModules(updatedModules);
    setDraggedIndex(index);
    setHasChanges(true);
  };

  const handleDragEnd = () => {
    if (hasChanges) {
      onUpdate(modules);
    }
    setDraggedIndex(null);
  };

  const handleSave = (index, updatedModule) => {
    let updatedModules;
    if (index === -1) {
      // Adding new module
      updatedModules = [
        ...modules,
        {
          ...DEFAULT_MODULE,
          ...updatedModule,
          module_id: Date.now().toString(),
        },
      ];
    } else {
      // Updating existing module
      updatedModules = modules.map((module, i) =>
        i === index ? updatedModule : module
      );
    }
    setModules(updatedModules);
    setHasChanges(true);
    setEditingIndex(null);
    onUpdate(updatedModules);
  };

  return (
    <div className="bg-slate-100 rounded shadow divide-y divide-slate-200">
      <div className="p-4 bg-white rounded-t-lg">
        <button
          onClick={handleAdd}
          className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
        >
          + Add New Module
        </button>
      </div>

      {editingIndex === -1 && (
        <div className="bg-slate-50">
          <ModuleEditor
            module={DEFAULT_MODULE}
            onSave={(newModule) => handleSave(-1, newModule)}
            onCancel={() => setEditingIndex(null)}
          />
        </div>
      )}

      {modules.slice(0, visibleModulesCount).map((module, index) => (
        <div
          key={module.module_id || index}
          className={`bg-slate-50 ${
            draggedIndex === index ? "opacity-50" : ""
          }`}
          draggable={editingIndex !== index}
          onDragStart={(e) => handleDragStart(index, e)}
          onDragOver={(e) => handleDragOver(index, e)}
          onDragEnd={handleDragEnd}
        >
          {editingIndex === index ? (
            <ModuleEditor
              module={module}
              onSave={(updatedModule) => handleSave(index, updatedModule)}
              onCancel={() => setEditingIndex(null)}
            />
          ) : (
            <div
              className="p-4 flex items-center justify-between cursor-pointer hover:bg-slate-100"
              onClick={() => handleEdit(index)}
            >
              <div className="flex items-center space-x-4">
                <ChevronUpDownIcon className="h-5 w-5 text-gray-400 cursor-move" />
                <span className="font-medium text-slate-700">
                  {index + 1}. {module.title}
                </span>
                <span className="text-sm text-gray-500">
                  {PRIORITY_LABELS[module.priority]}
                </span>
                {module.activations.length > 0 && (
                  <span className="text-sm text-blue-500">
                    {module.activations.length} activation
                    {module.activations.length !== 1 ? "s" : ""}
                  </span>
                )}
              </div>
              <div className="flex items-center space-x-2">
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEdit(index);
                  }}
                  className="p-1 hover:bg-gray-100 rounded"
                >
                  <PencilSquareIcon className="h-4 w-4" />
                </button>
                <button
                  onClick={(e) => handleDelete(index, e)}
                  className="p-1 hover:bg-gray-100 rounded text-red-500"
                >
                  <TrashIcon className="h-4 w-4" />
                </button>
              </div>
            </div>
          )}
        </div>
      ))}

      {modules.length > visibleModulesCount && (
        <div className="p-4 bg-white">
          <button
            onClick={handleLoadMore}
            className="w-full py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 bg-white hover:bg-gray-50"
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default ProtocolRoadmap;
