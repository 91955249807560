import React, { useState } from "react";
import { motion } from "framer-motion";
import { primary, text, border, gradient } from "../theme/variables";
import "../theme/theme.css";

const PersonalizedInsightScreen = ({ onUpdate, content, results }) => {
  const [answer, setAnswer] = useState("");

  const handleSubmit = () => {
    onUpdate({ personalInsight: answer });
  };

  // Dynamically replace placeholders with actual user data
  const formatInsight = (text) => {
    // Examples of replacements - these would be extracted from the results object
    // In a production app, this would need more sophisticated logic to find the right answers
    let formattedText = text;

    // Look for specific work hours pattern if available
    const workHoursQuestion = results?.screen10;
    if (workHoursQuestion) {
      formattedText = formattedText.replace(
        "[specific answer from earlier question]",
        typeof workHoursQuestion === "string"
          ? workHoursQuestion
          : "your work schedule"
      );
    } else {
      formattedText = formattedText.replace(
        "[specific answer from earlier question]",
        "your current schedule"
      );
    }

    // Look for focus areas if available
    const focusAreasQuestion = results?.screen5;
    if (
      focusAreasQuestion &&
      Array.isArray(focusAreasQuestion) &&
      focusAreasQuestion.length > 0
    ) {
      formattedText = formattedText.replace(
        "[priority area selected earlier]",
        focusAreasQuestion[0]
      );
    } else {
      formattedText = formattedText.replace(
        "[priority area selected earlier]",
        "your priorities"
      );
    }

    return formattedText;
  };

  return (
    <motion.div
      className="flex flex-col h-full py-8"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.6 }}
    >
      <motion.h2
        className="text-2xl font-bold mb-6"
        style={{ color: text.white }}
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.1, duration: 0.5 }}
      >
        {content.title}
      </motion.h2>

      <motion.div
        className="backdrop-blur-sm rounded-lg p-5 mb-8"
        style={{
          background: gradient.insightCard,
          borderWidth: "1px",
          borderColor: `${border.translucent}`,
        }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.2, duration: 0.5 }}
      >
        <p style={{ color: text.white }}>{formatInsight(content.insight)}</p>
      </motion.div>

      <motion.p
        className="text-lg font-medium mb-4"
        style={{ color: primary[300] }}
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.3, duration: 0.5 }}
      >
        {content.question}
      </motion.p>

      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.4, duration: 0.5 }}
        className="w-full"
      >
        <textarea
          className="w-full backdrop-blur-sm rounded-md p-4 min-h-[120px] focus:outline-none focus:ring-2 placeholder-opacity-50"
          style={{
            backgroundColor: `${primary[900]}33`, // 20% opacity
            borderWidth: "1px",
            borderColor: `${border.translucent}`,
            color: text.white,
            caretColor: primary[400],
          }}
          placeholder="Share your thoughts..."
          value={answer}
          onChange={(e) => setAnswer(e.target.value)}
        />
        <div className="flex justify-end mt-4">
          <button
            onClick={handleSubmit}
            className="font-medium py-2 px-6 rounded-md transition-colors duration-200"
            style={{
              color: text.white,
              backgroundColor: primary[600],
            }}
          >
            Submit
          </button>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PersonalizedInsightScreen;
