import React, { useState, useEffect } from "react";
import axios from "axios";
import MonacoEditor from "@monaco-editor/react";

export default function ScorecardTemplateEditor() {
  const [scorecardTemplate, setScorecardTemplate] = useState("");
  const [promptTemplate, setPromptTemplate] = useState("");
  const [saveStatus, setSaveStatus] = useState("");
  const [activeTab, setActiveTab] = useState("scorecard"); // 'scorecard' or 'prompt'

  useEffect(() => {
    fetchTemplates();
  }, []);

  const fetchTemplates = async () => {
    try {
      const apiKey = localStorage.getItem("adminApiKey");
      const headers = { "Api-Key": apiKey };

      const [scorecardRes, promptRes] = await Promise.all([
        axios.get("/api/admin/scorecard-template", { headers }),
        axios.get("/api/admin/scoring-prompt-template", { headers }),
      ]);

      setScorecardTemplate(JSON.stringify(scorecardRes.data, null, 2));
      setPromptTemplate(JSON.stringify(promptRes.data, null, 2));
    } catch (error) {
      console.error("Error fetching templates:", error);
      setSaveStatus("Error loading templates");
    }
  };

  const handleSave = async () => {
    try {
      const apiKey = localStorage.getItem("adminApiKey");
      const headers = { "Api-Key": apiKey };

      if (activeTab === "scorecard") {
        const parsedScorecard = JSON.parse(scorecardTemplate);
        await axios.put("/api/admin/scorecard-template", parsedScorecard, {
          headers,
        });
      } else {
        const parsedPrompt = JSON.parse(promptTemplate);
        await axios.put("/api/admin/scoring-prompt-template", parsedPrompt, {
          headers,
        });
      }

      setSaveStatus("Saved successfully!");
      setTimeout(() => setSaveStatus(""), 3000);
    } catch (error) {
      console.error("Error saving template:", error);
      setSaveStatus(error.message || "Error saving template");
    }
  };

  return (
    <div className="bg-slate-800 rounded-lg p-4">
      <div className="flex justify-between items-center mb-4">
        <div className="flex space-x-4">
          <button
            className={`px-4 py-2 rounded-lg ${
              activeTab === "scorecard"
                ? "bg-blue-500 text-white"
                : "bg-slate-700 text-gray-300"
            }`}
            onClick={() => setActiveTab("scorecard")}
          >
            Scorecard Template
          </button>
          <button
            className={`px-4 py-2 rounded-lg ${
              activeTab === "prompt"
                ? "bg-blue-500 text-white"
                : "bg-slate-700 text-gray-300"
            }`}
            onClick={() => setActiveTab("prompt")}
          >
            Prompt Template
          </button>
        </div>
        <div className="flex items-center space-x-4">
          <span className="text-gray-300">{saveStatus}</span>
          <button
            className="bg-green-500 text-white px-4 py-2 rounded-lg"
            onClick={handleSave}
          >
            Save
          </button>
        </div>
      </div>
      <div className="h-[calc(100vh-200px)]">
        <MonacoEditor
          height="100%"
          language="json"
          theme="vs-dark"
          value={activeTab === "scorecard" ? scorecardTemplate : promptTemplate}
          onChange={
            activeTab === "scorecard" ? setScorecardTemplate : setPromptTemplate
          }
          options={{
            minimap: { enabled: false },
            formatOnPaste: true,
            formatOnType: true,
            scrollBeyondLastLine: false,
          }}
        />
      </div>
    </div>
  );
}
