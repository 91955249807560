// WaitlistSignup.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const WaitlistSignup = ({
  text1 = "Don't have an invite code yet?",
  text2 = "Sign up...",
  text3 = "1440's alpha version is free with an invite code",
}) => {
  const [email, setEmail] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const handleWaitlistSignup = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch("https://api.1440.ai/api/signup", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ email }),
      });
      const data = await response.json();
      alert(data.message || "You have been added to the waitlist!");
    } catch (error) {
      alert("Failed to join the waitlist. Please try again.");
      setError(error.toString());
    }
    setIsSubmitting(false);
  };

  return (
    <div className="w-full">
      <div className="flex w-full justify-center items-center overflow-hidden">
        <div className="bg-gradient-to-br w-full from-white/10 to-black border border-white border-opacity-40 p-6">
          <p className="text-white text-lg font-light mt-6 mb-1">{text1}</p>
          <p className="text-white text-3xl font-light mb-6">{text2}</p>
          <form onSubmit={handleWaitlistSignup} className="mb-4">
            <input
              type="email"
              placeholder="Enter your email"
              className="bg-transparent border-b-2 border-white border-opacity-40 p-2 pl-0 mb-6 w-full text-white placeholder-gray-500 focus:outline-none"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
              disabled={isSubmitting}
            />
            <button
              type="submit"
              className="w-full border-white font-bold bg-gradient-to-r from-accent to-lightBlue border border-opacity-20 text-white py-4 uppercase tracking-wider hover:bg-accent transition duration-300"
              disabled={isSubmitting}
            >
              {isSubmitting ? "Submitting..." : "Join waitlist"}
            </button>
            {error && (
              <p className="text-red-300 text-sm text-center mt-2">{error}</p>
            )}
            <p className="text-white text-md font-light mt-4 mb-0">{text3}</p>
          </form>
        </div>
      </div>
    </div>
  );
};

export default WaitlistSignup;
