import React from "react";
import { TrashIcon } from "@heroicons/react/24/outline";
import ActivationTypeSelector from "./ActivationTypeSelector";

export const ACTIVATION_TYPES = {
  // Onboarding/Setup activations
  ACCOUNT_CREATION: {
    id: "ACCOUNT_CREATION",
    label: "Account creation",
    description: "Trigger when a user first creates their account",
    format: "Account creation",
    type: "simple",
    category: "onboarding",
  },
  CALENDAR_CONNECTED: {
    id: "CALENDAR_CONNECTED",
    label: "Calendar connected",
    description: "Trigger when a user connects their calendar",
    format: "Calendar connected",
    type: "simple",
    category: "onboarding",
  },
  HEALTH_CONNECTED: {
    id: "HEALTH_CONNECTED",
    label: "Health device connected",
    description: "Trigger when a user connects their health device",
    format: "Health device connected",
    type: "simple",
    category: "onboarding",
  },
  PAYMENT_RECEIVED: {
    id: "PAYMENT_RECEIVED",
    label: "Payment received",
    description: "Trigger when a payment is successfully processed",
    format: "Payment received",
    type: "simple",
    category: "onboarding",
  },

  // Time-based activations
  TIME_AFTER: {
    id: "TIME_AFTER",
    label: "After time period",
    description: "Trigger after a specific amount of time has passed",
    format: "After {count} {unit}",
    type: "time",
    params: ["count", "unit"],
    category: "time",
  },
  WEEKLY_RECURRING: {
    id: "WEEKLY_RECURRING",
    label: "Recurring weekly",
    description: "Trigger once every week",
    format: "Recurring weekly",
    type: "simple",
    category: "time",
  },
  MONTHLY_RECURRING: {
    id: "MONTHLY_RECURRING",
    label: "Recurring monthly",
    description: "Trigger once every month",
    format: "Recurring monthly",
    type: "simple",
    category: "time",
  },
  QUARTERLY_RECURRING: {
    id: "QUARTERLY_RECURRING",
    label: "Recurring quarterly",
    description: "Trigger once every three months",
    format: "Recurring quarterly",
    type: "simple",
    category: "time",
  },

  // Usage-based activations
  MESSAGE_COUNT: {
    id: "MESSAGE_COUNT",
    label: "After x messages",
    description: "Trigger after a specific number of messages are exchanged",
    format: "After {count} messages",
    type: "number",
    param: "count",
    category: "usage",
  },
};

const CATEGORY_LABELS = {
  onboarding: "Onboarding & Setup",
  time: "Time-based",
  usage: "Usage-based",
};

export const TIME_UNITS = ["hours", "days", "weeks", "months"];

export const parseActivation = (activationString) => {
  if (!activationString) return null;

  // Try to match against each activation type's format
  for (const [typeKey, type] of Object.entries(ACTIVATION_TYPES)) {
    // For simple types, just check exact match
    if (type.type === "simple" && activationString === type.format) {
      return { type: typeKey, params: {} };
    }

    // For parameterized types, extract the values
    if (type.type !== "simple") {
      const regex = type.format
        .replace(/\{(\w+)\}/g, "(.+?)") // Non-greedy match
        .replace(/[()]/g, "\\$&");
      const match = activationString.match(new RegExp(`^${regex}$`));

      if (match) {
        const values = match.slice(1);
        const params = type.params || [type.param];
        return {
          type: typeKey,
          params: Object.fromEntries(
            params.map((param, i) => [param, values[i]])
          ),
        };
      }
    }
  }
  return null;
};

export const formatActivation = (type, params = {}) => {
  const activationType = ACTIVATION_TYPES[type];
  if (!activationType) return "";

  let result = activationType.format;
  if (activationType.type === "simple") return result;

  const paramList = activationType.params || [activationType.param];
  paramList.forEach((param) => {
    result = result.replace(`{${param}}`, params[param] || "");
  });
  return result;
};

const ActivationEditor = ({ value, onChange, onRemove }) => {
  const activation = parseActivation(value) || { type: "", params: {} };
  const activationType = ACTIVATION_TYPES[activation.type];

  const handleTypeChange = (newType) => {
    const type = ACTIVATION_TYPES[newType];
    if (!type) return;

    if (type.type === "simple") {
      onChange(type.format);
      return;
    }

    const params = {};
    (type.params || [type.param]).forEach((param) => {
      params[param] = "";
    });
    const formattedValue = formatActivation(newType, params);
    onChange(formattedValue);
  };

  const handleParamChange = (param, paramValue) => {
    if (!activation.type) return;

    const newParams = { ...activation.params, [param]: paramValue };
    const formattedValue = formatActivation(activation.type, newParams);
    onChange(formattedValue);
  };

  return (
    <div className="flex items-start space-x-2">
      <div className="flex-grow space-y-2">
        <div className="flex space-x-2">
          <div className="flex-grow">
            <ActivationTypeSelector
              value={activation.type}
              onChange={handleTypeChange}
              activationTypes={ACTIVATION_TYPES}
              categoryLabels={CATEGORY_LABELS}
            />
          </div>
          <button
            onClick={onRemove}
            className="text-gray-400 hover:text-red-500 bg-gray-50 hover:bg-red-50 rounded-lg transition-colors duration-200 flex items-center justify-center px-3"
          >
            <TrashIcon className="h-6 w-6" />
          </button>
        </div>

        {activationType && activationType.type !== "simple" && (
          <div className="flex items-center space-x-2 mt-2 p-3 bg-white border border-gray-200 rounded-lg">
            {activationType.type === "number" && (
              <>
                <span className="text-gray-600">After</span>
                <input
                  type="number"
                  className="border rounded-lg p-2 w-24 focus:ring-2 focus:ring-blue-100 focus:border-blue-500 transition-all duration-200"
                  value={activation.params?.count || ""}
                  onChange={(e) => handleParamChange("count", e.target.value)}
                  min="1"
                />
                <span className="text-gray-600">messages</span>
              </>
            )}

            {activationType.type === "time" && (
              <>
                <span className="text-gray-600">After</span>
                <input
                  type="number"
                  className="border rounded-lg p-2 w-24 focus:ring-2 focus:ring-blue-100 focus:border-blue-500 transition-all duration-200"
                  value={activation.params?.count || ""}
                  onChange={(e) => handleParamChange("count", e.target.value)}
                  min="1"
                />
                <select
                  className="border rounded-lg p-2 focus:ring-2 focus:ring-blue-100 focus:border-blue-500 transition-all duration-200"
                  value={activation.params?.unit || ""}
                  onChange={(e) => handleParamChange("unit", e.target.value)}
                >
                  <option value="">Select unit</option>
                  {TIME_UNITS.map((unit) => (
                    <option key={unit} value={unit}>
                      {unit}
                    </option>
                  ))}
                </select>
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ActivationEditor;
