// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ReactMarkdown > * {
  font-size: 1.35rem;
  opacity: 90%;
}

.coach-message .ReactMarkdown > * {
  font-weight: 500;
}

.coach-message:nth-last-of-type(2) .ReactMarkdown > * {
  font-size: 1.9rem !important;
  opacity: 100;
}

@media (max-width: 768px) {
  .ReactMarkdown > * {
    font-size: 1.2rem;
  }
  .coach-message:nth-last-of-type(2) .ReactMarkdown > * {
    font-size: 1.5rem !important;
    opacity: 100;
  }
}

/* Existing styles */

/* Add this new style */
.last-coach-message .ReactMarkdown > * {
  font-size: 1.9rem !important; /* Adjust the size as needed */
  opacity: 100;
}

@media (max-width: 768px) {
  /* Existing media query styles */

  .last-coach-message .ReactMarkdown > * {
    font-size: 1.5rem !important; /* Adjust for smaller screens */
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/chat/MessageList.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,YAAY;AACd;;AAEA;EACE,gBAAgB;AAClB;;AAEA;EACE,4BAA4B;EAC5B,YAAY;AACd;;AAEA;EACE;IACE,iBAAiB;EACnB;EACA;IACE,4BAA4B;IAC5B,YAAY;EACd;AACF;;AAEA,oBAAoB;;AAEpB,uBAAuB;AACvB;EACE,4BAA4B,EAAE,8BAA8B;EAC5D,YAAY;AACd;;AAEA;EACE,gCAAgC;;EAEhC;IACE,4BAA4B,EAAE,+BAA+B;EAC/D;AACF","sourcesContent":[".ReactMarkdown > * {\n  font-size: 1.35rem;\n  opacity: 90%;\n}\n\n.coach-message .ReactMarkdown > * {\n  font-weight: 500;\n}\n\n.coach-message:nth-last-of-type(2) .ReactMarkdown > * {\n  font-size: 1.9rem !important;\n  opacity: 100;\n}\n\n@media (max-width: 768px) {\n  .ReactMarkdown > * {\n    font-size: 1.2rem;\n  }\n  .coach-message:nth-last-of-type(2) .ReactMarkdown > * {\n    font-size: 1.5rem !important;\n    opacity: 100;\n  }\n}\n\n/* Existing styles */\n\n/* Add this new style */\n.last-coach-message .ReactMarkdown > * {\n  font-size: 1.9rem !important; /* Adjust the size as needed */\n  opacity: 100;\n}\n\n@media (max-width: 768px) {\n  /* Existing media query styles */\n\n  .last-coach-message .ReactMarkdown > * {\n    font-size: 1.5rem !important; /* Adjust for smaller screens */\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
