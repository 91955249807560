import React from "react";
import { Card } from "../../../components/ui/Card";
import { Star } from "lucide-react";

const TestimonialSection = () => {
  const testimonials = [
    {
      quote:
        "1440 has been great for me inside of work and out. During the day, it helps me be productive and start the morning with clarity and drive. Outside of work, it's helped me accomplish my goals fitness and my hobbies. I've had a couple friends join so far too and we're taking on group challenges every month.",
      name: "David Brown",
      role: "Consultant",
      stars: 5,
    },
    {
      quote:
        "I integrated my Garmin and did marathon planning and have check-ins which has been great. Then the other day I missed a long run, and it actually called me and asked if it was intentional. It now sends me these morning voicemails with positive affirmations before my long runs and I've kept up the training since",
      name: "Noah Lloyd",
      role: "Student",
      stars: 5,
    },
    {
      quote:
        "I have this late night habit where I keep unconsciously falling back into scrolling on my phone whenever my mind gets tired or bored after work or dinner. Lately, instead of wasting another couple of hours, I've just been checking in with 1440 to talk through my thoughts, and why and how my habit persists. Result is that I haven't doomscrolled in the last 3 months - couldn't be happier.",
      name: "Mike Hansen",
      role: "Software Engineer",
      stars: 5,
    },
  ];

  return (
    <section className="py-24 px-6 relative">
      <div className="container mx-auto">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-white">
            Real <span className="text-gradient">Transformation</span> Stories
          </h2>
          <p className="text-xl text-gray-300">
            Hear from people who have bridged the gap between their time and
            priorities with 1440.
          </p>
        </div>

        <div className="grid md:grid-cols-3 gap-8 max-w-5xl mx-auto">
          {testimonials.map((testimonial, index) => (
            <Card
              key={index}
              className="glass-card p-6 border border-white/10 hover:border-primary/50 transition-all duration-300 animate-fade-up"
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <div className="flex space-x-1 mb-0">
                {Array(testimonial.stars)
                  .fill(0)
                  .map((_, i) => (
                    <Star
                      key={i}
                      className="h-5 w-5 fill-primary text-primary"
                    />
                  ))}
              </div>
              <p className=" text-gray-300 mt-4 mb-6 italic">
                "{testimonial.quote}"
              </p>
              <div>
                <p className=" mb-0 font-semibold text-white">
                  {testimonial.name}
                </p>
                <p className=" mb-0 text-sm text-gray-400">
                  {testimonial.role}
                </p>
              </div>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TestimonialSection;
