import React from "react";
import "./index.css";
import { Button } from "../../ui/Button";
import TimelineSection from "./TimelineSection";
import TestimonialSection from "./TestimonialSection";
import ContactForm from "./ContactForm";
import MessagingPlatforms from "./MessagingPlatforms";
import RealLifeProblems from "./RealLifeProblems";
import HowWeHelp from "./HowWeHelp";

const Index = () => {
  return (
    <div className="min-h-screen bg-dark text-white overflow-x-hidden">
      {/* Navigation */}
      <nav className="fixed w-full bg-dark/80 backdrop-blur-sm z-50 border-b border-white/10">
        <div className="container mx-auto px-6 py-4 flex items-center justify-between">
          <a href="/">
            <img src="/images/logo-white.png" alt="1440 Logo" className="h-6" />
          </a>
          <div className="hidden md:flex items-center gap-8">
            <a
              href="https://wa.me/+16203372653?text=Hello"
              target="_blank"
              rel="noopener noreferrer"
              className="text-gray-300 hover:text-primary transition-colors"
            >
              WHATSAPP
            </a>
            <a
              href="sms:+16203372653"
              className="text-gray-300 hover:text-primary transition-colors"
            >
              SMS
            </a>
            <a
              href="/coach/dashboard"
              className="text-gray-300 hover:text-primary transition-colors"
            >
              COACH SIGN-IN
            </a>
            <Button
              variant="outline"
              className="ml-4 border-primary text-primary hover:bg-primary hover:text-white"
              as="a"
              href="https://wa.me/+16203372653?text=Hello"
              target="_blank"
              rel="noopener noreferrer"
            >
              Start Now
            </Button>
          </div>
        </div>
      </nav>

      {/* Hero Section with Storytelling Approach */}
      <section className="relative pt-32 pb-24 px-6 overflow-hidden">
        <div className="absolute inset-0 bg-gradient-to-b from-accent-purple/20 to-transparent"></div>
        <div className="absolute top-20 right-0 w-96 h-96 bg-accent-blue/30 rounded-full filter blur-3xl opacity-70"></div>
        <div className="absolute bottom-0 left-0 w-96 h-96 bg-accent-teal/30 rounded-full filter blur-3xl opacity-70"></div>

        <div className="container mx-auto relative">
          <div className="max-w-3xl mx-auto text-center">
            <div className="inline-block">
              <span className="bg-primary/10 text-primary px-4 py-1.5 rounded-full text-sm font-medium">
                1,440 minutes daily — make each one count.
              </span>
            </div>

            <h1 className="mt-8 text-5xl md:text-6xl font-bold leading-tight animate-fade-up text-white">
              Your time should reflect{" "}
              <span className="text-gradient">what matters most</span>
            </h1>

            <p
              className="mt-6 text-xl text-gray-300 max-w-2xl mx-auto animate-fade-up"
              style={{ animationDelay: "200ms", animationFillMode: "forwards" }}
            >
              Most of us are caught in a daily struggle between what we do and
              what we love. Enter your phone below to get a call from the 1440
              coach.
            </p>

            <div
              className="mt-10 space-y-6 animate-fade-up"
              style={{ animationDelay: "400ms", animationFillMode: "forwards" }}
            >
              <ContactForm />

              <div className="text-center">
                <p className="text-gray-400 mb-3">
                  Or reach out to the 1440 coach directly:
                </p>
                <MessagingPlatforms />
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Real Life Problems Section */}
      <section id="problems" className="py-24 px-6 relative">
        <div className="absolute inset-0 bg-gradient-to-b from-accent-blue/10 to-transparent opacity-70"></div>
        <div className="container mx-auto relative">
          <div className="max-w-3xl mx-auto text-center mb-16">
            <h2 className="text-4xl font-bold mb-6 text-white">
              Sound <span className="text-gradient">familiar?</span>
            </h2>
            <p className="text-xl text-gray-300 mb-6">
              If you're like most people, you're experiencing at least a few of
              these daily struggles:
            </p>
          </div>

          <RealLifeProblems />

          <div className="mt-16 text-center">
            <p className="text-xl text-gray-300 mb-0 max-w-3xl mx-auto">
              These aren't just annoyances—they're symptoms of a deeper problem:{" "}
              <span className="font-semibold text-white">
                a fundamental misalignment between how you spend your time and
                what you truly value.
              </span>
            </p>
          </div>
        </div>
      </section>

      {/* Solution and How We Help Section (Combined) */}
      <section id="solution" className="py-24 px-6 relative bg-dark-lighter">
        <div className="absolute inset-0 bg-gradient-to-b from-dark to-transparent h-32"></div>
        <div className="absolute top-1/4 right-0 w-96 h-96 bg-accent-purple/20 rounded-full filter blur-3xl opacity-60"></div>
        <div className="container mx-auto relative">
          <HowWeHelp />
        </div>
      </section>

      {/* Transformation Timeline */}
      <TimelineSection />

      {/* Testimonials */}
      <TestimonialSection />

      {/* CTA Section */}
      <section className="py-24 px-6 relative overflow-hidden">
        <div className="absolute inset-0 bg-gradient-radial from-accent-purple/30 via-dark to-dark opacity-80"></div>
        <div className="absolute top-1/3 left-1/4 w-96 h-96 bg-accent-blue/20 rounded-full filter blur-3xl opacity-60"></div>
        <div className="container mx-auto relative">
          <div className="max-w-3xl mx-auto glass-card rounded-3xl p-12 border border-white/10">
            <div className="text-center">
              <h2 className="text-3xl md:text-4xl font-bold mb-6 text-white">
                Ready to align your time with{" "}
                <span className="text-gradient">what truly matters?</span>
              </h2>
              <p className="text-xl mb-8 text-gray-300">
                Enter your phone number below and 1440 will call you to get
                started.
              </p>

              <div className="mb-8">
                <ContactForm />
              </div>

              <p className="text-sm text-gray-400 mb-6">
                Or reach out to the 1440 coach directly:
              </p>

              <MessagingPlatforms />
            </div>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-dark-lighter border-t border-white/10">
        <div className="container mx-auto px-6 py-12">
          <div className="grid md:grid-cols-2 gap-8">
            <div>
              <img
                src="/images/logo-white.png"
                alt="1440"
                className="h-8 mb-4"
              />
              <p className="text-gray-400">
                Your complete support system for time alignment
              </p>
            </div>
            <div className="text-right">
              <h3 className="font-semibold mb-4 text-white">Legal</h3>
              <ul className="space-y-2 text-gray-400">
                <li>
                  <a
                    href="/privacy-policy"
                    className="hover:text-primary transition-colors"
                  >
                    Privacy
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className="mt-12 pt-8 border-t border-white/10 text-center text-gray-400"></div>
        </div>
      </footer>
    </div>
  );
};

export default Index;
