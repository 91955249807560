import React, { useState, useEffect } from "react";
import axios from "axios";

function ProtocolSelector({ selectedProtocolId, onChange }) {
  const [protocols, setProtocols] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchProtocols = async () => {
      try {
        const response = await axios.get(
          "https://api.1440.ai/api/admin/protocol_names",
          {
            headers: {
              "Api-Key": localStorage.getItem("adminApiKey"),
            },
          }
        );
        setProtocols(response.data);
      } catch (error) {
        console.error("Error fetching protocols:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchProtocols();
  }, []);

  if (loading) {
    return <div className="text-gray-300">Loading protocols...</div>;
  }

  return (
    <div className="space-y-4">
      <h4 className="text-gray-300 text-sm font-medium mb-2">
        Select Protocol
      </h4>
      <select
        value={selectedProtocolId || ""}
        onChange={(e) => onChange(e.target.value)}
        className="w-full px-3 py-2 bg-slate-700 text-white rounded-lg focus:outline-none focus:ring-2 focus:ring-blue-500"
      >
        <option value="">No Protocol Selected</option>
        {protocols.map((protocol) => (
          <option key={protocol._id} value={protocol._id}>
            {protocol.title}
          </option>
        ))}
      </select>
      <p className="text-sm text-gray-400">
        Select a protocol to link to this persona. The persona will use this
        protocol's configuration for conversations.
      </p>
    </div>
  );
}

export default ProtocolSelector;
