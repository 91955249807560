import React from "react";

const GroundScreenCommunity = ({ userActivityLevel, userPrimaryGoal }) => {
  const getCommunityMessage = () => {
    let activityMessagePart = "";
    let goalMessagePart = "";

    switch (userActivityLevel) {
      case "Not active at all":
        activityMessagePart = "just starting out";
        break;
      case "Slightly active":
        activityMessagePart = "taking small steps";
        break;
      case "Moderately active":
        activityMessagePart = "making steady progress";
        break;
      case "Very active":
        activityMessagePart = "highly dedicated";
        break;
      case "Extremely active":
        activityMessagePart = "at the peak of activity";
        break;
      default:
        activityMessagePart = "on their own unique path";
    }

    if (
      userPrimaryGoal.length > 0 &&
      userPrimaryGoal !== "I'm not sure yet, I need guidance."
    ) {
      goalMessagePart = `striving towards goals like to <span class="text-accent">${userPrimaryGoal[0]
        .toLowerCase()
        .replace("my", "their")}</span>`;
    } else if (userPrimaryGoal === "I'm not sure yet, I need guidance.") {
      goalMessagePart = "seeking guidance, which is a brave first step";
    } else {
      goalMessagePart =
        "exploring their potential and seeking self-improvement";
    }

    return `You're one of us!<br><br>You are <span class="text-accent">not alone</span> in this journey; many in our community are <span class="text-accent">${activityMessagePart}</span> and are ${goalMessagePart}.<br/><br/>Together, we <span class="text-accent">grow stronger</span> and support each other  <span class="text-accent">every step</span> of the way.`;
  };

  const communityMessage = getCommunityMessage();

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p
        className="text-white text-left text-3xl font-bold mb-6"
        dangerouslySetInnerHTML={{ __html: communityMessage }}
      ></p>
    </div>
  );
};

export default GroundScreenCommunity;
