import React, { useState } from "react";
import { motion } from "framer-motion";
import { CheckIcon } from "@heroicons/react/24/solid";
import ContinueButton from "../components/ContinueButton";
import { primary, text, border } from "../theme/variables";
import "../theme/theme.css";

const PlanPreviewScreen = ({ content, onUpdate }) => {
  const { title, planFeatures, buttonText } = content;

  // Initialize selected features based on the default 'selected' property
  const [selectedFeatures, setSelectedFeatures] = useState(
    planFeatures.map((feature) => feature.selected)
  );

  const toggleFeature = (index) => {
    setSelectedFeatures((prev) => {
      const newSelected = [...prev];
      newSelected[index] = !newSelected[index];
      return newSelected;
    });
  };

  const handleClick = () => {
    onUpdate({
      planSelected: true,
      selectedFeatures: planFeatures
        .filter((_, index) => selectedFeatures[index])
        .map((feature) => feature.title),
    });
  };

  return (
    <div className="flex flex-col">
      {/* Title with plan styling */}
      <motion.div
        className="border-b pb-3 mt-6 mb-6"
        style={{ borderColor: `${primary[500]}4D` }} // 30% opacity
        initial={{ opacity: 0, y: -20 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.5, ease: "easeOut" }}
      >
        <h2
          className="text-2xl font-bold text-left"
          style={{ color: text.white }}
        >
          {title}
        </h2>
        <p className="text-sm mt-1 mb-0" style={{ color: primary[400] }}>
          Customize your features
        </p>
      </motion.div>

      {/* Plan features */}
      <div className="mb-8 space-y-4">
        {planFeatures.map((feature, index) => (
          <motion.div
            key={index}
            className="p-4 rounded-lg border cursor-pointer transition-all duration-200"
            style={{
              backgroundColor: selectedFeatures[index]
                ? `${primary[900]}33` // 20% opacity
                : "rgba(15, 23, 42, 0.5)", // slate-900 with 50% opacity
              borderColor: selectedFeatures[index]
                ? `${primary[500]}80` // 50% opacity
                : "rgb(30, 41, 59)", // slate-800
            }}
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{
              delay: index * 0.1 + 0.2,
              duration: 0.5,
              ease: "easeOut",
            }}
            onClick={() => toggleFeature(index)}
          >
            <div className="flex items-start">
              <div
                className="flex-shrink-0 w-5 h-5 mr-3 mt-0.5 rounded border flex items-center justify-center"
                style={{
                  backgroundColor: selectedFeatures[index]
                    ? primary[500]
                    : "transparent",
                  borderColor: selectedFeatures[index]
                    ? primary[600]
                    : "#475569", // slate-600
                }}
              >
                {selectedFeatures[index] && (
                  <CheckIcon
                    className="w-3.5 h-3.5"
                    style={{ color: text.black }}
                  />
                )}
              </div>
              <div>
                <h3
                  className="font-medium text-base"
                  style={{ color: text.white }}
                >
                  {feature.title}
                </h3>
                <p
                  className="text-sm mb-0"
                  style={{ color: "rgb(203, 213, 225)" }} // slate-300
                >
                  {feature.description}
                </p>
              </div>
            </div>
          </motion.div>
        ))}
      </div>
    </div>
  );
};

export default PlanPreviewScreen;
