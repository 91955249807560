import React, { useState, useEffect } from "react";
import axios from "axios";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";

export default function AIConversationDetails({ conversationId, onBack }) {
  const [conversation, setConversation] = useState(null);
  const [loading, setLoading] = useState(true);
  const [displayLimit, setDisplayLimit] = useState(20);
  const [showAllLogs, setShowAllLogs] = useState(false);
  const [creatingScorecard, setCreatingScorecard] = useState(false);

  useEffect(() => {
    const fetchConversation = async () => {
      try {
        const apiKey = localStorage.getItem("adminApiKey");
        const response = await axios.get(`/api/admin/user/${conversationId}`, {
          headers: { "Api-Key": apiKey },
        });
        setConversation(response.data);
      } catch (error) {
        console.error("Error fetching conversation details:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchConversation();
  }, [conversationId]);

  async function createScorecard() {
    try {
      setCreatingScorecard(true);
      const apiKey = localStorage.getItem("adminApiKey");
      const response = await axios.post(
        `/api/admin/scorecard/${conversationId}`,
        {},
        {
          headers: { "Api-Key": apiKey },
        }
      );
      alert("Scorecard created successfully!");
    } catch (error) {
      console.error("Error creating scorecard:", error);
      alert("Failed to create scorecard");
    } finally {
      setCreatingScorecard(false);
    }
  }

  if (loading) {
    return <div className="text-white">Loading...</div>;
  }

  return (
    <div className="space-y-6">
      <div className="flex items-center">
        <button
          onClick={onBack}
          className="text-gray-400 hover:text-white mr-4 flex items-center"
        >
          <ArrowLeftIcon className="h-5 w-5 mr-2" />
          Back
        </button>
        <h1 className="text-2xl mb-0 font-bold text-white">
          AI Conversation Details
        </h1>
      </div>

      {conversation && (
        <>
          <div className="bg-slate-800 rounded-lg p-6">
            <div className="space-y-4">
              <div>
                <label className="text-gray-400">Conversation ID</label>
                <div className="text-white">{conversation._id}</div>
              </div>
              {conversation.config?.theme && (
                <div>
                  <label className="text-gray-400">Theme</label>
                  <div className="text-white">{conversation.config.theme}</div>
                </div>
              )}
              <div>
                <label className="text-gray-400">Created At</label>
                <div className="text-white">
                  {new Date(conversation.created_at).toLocaleString()}
                </div>
              </div>
              <div>
                <button
                  onClick={createScorecard}
                  disabled={creatingScorecard}
                  className="bg-blue-500 hover:bg-blue-600 text-white font-medium py-2 px-4 rounded disabled:opacity-50"
                >
                  {creatingScorecard
                    ? "Creating Scorecard..."
                    : "Create Scorecard"}
                </button>
              </div>
            </div>
          </div>

          {conversation.log && conversation.log.length > 0 && (
            <div className="bg-slate-800 rounded-lg p-6">
              <div className="flex justify-between items-center mb-4">
                <h2 className="text-lg font-medium text-white">
                  Conversation History
                </h2>
                <button
                  onClick={() => setShowAllLogs(!showAllLogs)}
                  className="text-blue-400 hover:text-blue-300 text-sm"
                >
                  {showAllLogs ? "Show Less" : "Show All"}
                </button>
              </div>
              <div className="space-y-4">
                {conversation.log
                  .slice(
                    0,
                    showAllLogs ? conversation.log.length : displayLimit
                  )
                  .map((logEntry, index) => (
                    <div key={index}>
                      <div
                        className={`flex ${
                          logEntry.role === "user"
                            ? "justify-end"
                            : "justify-start"
                        }`}
                      >
                        <div
                          className={`max-w-[80%] rounded-2xl px-4 py-2 ${
                            logEntry.role === "user"
                              ? "bg-blue-600 text-white"
                              : "bg-slate-700 text-gray-100"
                          }`}
                        >
                          <div className="text-sm">{logEntry.content}</div>
                          <div
                            className={`text-xs mt-1 ${
                              logEntry.role === "user"
                                ? "text-blue-200"
                                : "text-gray-400"
                            }`}
                          >
                            {new Date(logEntry.timestamp).toLocaleString()}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
              {!showAllLogs && conversation.log.length > displayLimit && (
                <button
                  onClick={() => setDisplayLimit((prev) => prev + 20)}
                  className="mt-6 bg-slate-700 hover:bg-slate-600 transition-colors text-white px-4 py-2 rounded-lg w-full text-sm"
                >
                  Load More
                </button>
              )}
            </div>
          )}
        </>
      )}
    </div>
  );
}
