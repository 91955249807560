import React, { useState } from "react";
import AdminSidebar from "./Sidebar";
import Dashboard from "./Dashboard";
import Users from "./Users";
import UserDetails from "./UserDetails";
import AIConversations from "./AIConversations";
import AIConversationDetails from "./AIConversationDetails";

import Scorecards from "./Scorecards";
import ScorecardDetails from "./ScorecardDetails";

function AdminUserData() {
  const [activeView, setActiveView] = useState("dashboard");
  const [selectedId, setSelectedId] = useState(null);

  const renderContent = () => {
    switch (activeView) {
      case "dashboard":
        return <Dashboard />;
      case "users":
        return selectedId ? (
          <UserDetails userId={selectedId} onBack={() => setSelectedId(null)} />
        ) : (
          <Users onSelectUser={(id) => setSelectedId(id)} />
        );
      case "scorecards":
        return selectedId ? (
          <ScorecardDetails
            scorecardId={selectedId}
            onBack={() => setSelectedId(null)}
          />
        ) : (
          <Scorecards onSelectScorecard={(id) => setSelectedId(id)} />
        );
      case "ai-conversations":
        return selectedId ? (
          <AIConversationDetails
            conversationId={selectedId}
            onBack={() => setSelectedId(null)}
          />
        ) : (
          <AIConversations onSelectConversation={(id) => setSelectedId(id)} />
        );
      default:
        return <Dashboard />;
    }
  };

  return (
    <div className="flex min-h-screen bg-slate-900">
      <AdminSidebar activeView={activeView} onViewChange={setActiveView} />
      <div className="flex-1">
        <div className="p-8">{renderContent()}</div>
      </div>
    </div>
  );
}

export default AdminUserData;
