import React from "react";
import { motion } from "framer-motion";
import { primary, background, text } from "../theme/variables";

const HeaderBar = ({
  sections,
  currentSection,
  showSectionTitle = true,
  sectionProgress = 0,
  onLogoClick,
}) => {
  return (
    <>
      {/* Header with logo */}
      <div
        className="pt-6 pb-5 flex justify-center sticky top-0 z-50 backdrop-blur-sm"
        style={{ backgroundColor: background.overlay }}
      >
        <div
          className="cursor-pointer"
          onClick={onLogoClick}
          role="button"
          aria-label="Go back"
        >
          <img src="/images/logo-white.png" alt="Logo" className="h-6 w-auto" />
        </div>
      </div>

      {/* Segmented progress bar */}
      <div
        className="w-full flex justify-center sticky top-14 z-50 pb-3"
        style={{
          background:
            "linear-gradient(to bottom, rgba(0, 0, 0, 0.6), transparent)",
        }}
      >
        <div className="w-full max-w-md px-5">
          <div className="flex items-center">
            {sections.map((section, index) => (
              <React.Fragment key={index}>
                {/* Step node */}
                <div className="flex flex-col items-center relative z-10">
                  {index === currentSection ? (
                    <motion.div
                      className="w-3 h-3 rounded-full border-2 flex items-center justify-center"
                      style={{
                        borderColor: primary[500],
                        backgroundColor: primary[500],
                      }}
                      initial={{ scale: 1 }}
                      animate={{
                        scale: [1, 1.15, 1],
                        boxShadow: [
                          `0 0 0 0 ${primary[500]}00`,
                          `0 0 0 2px ${primary[500]}33`,
                          `0 0 0 0 ${primary[500]}00`,
                        ],
                      }}
                      transition={{
                        duration: 1.5,
                        times: [0, 0.5, 1],
                        repeat: Infinity,
                        repeatDelay: 3,
                      }}
                      whileInView={{
                        scale: [1, 1.8, 1],
                        boxShadow: [
                          `0 0 0 0 ${primary[500]}00`,
                          `0 0 0 8px ${primary[500]}66`,
                          `0 0 0 0 ${primary[500]}00`,
                        ],
                        transition: {
                          duration: 0.8,
                          times: [0, 0.5, 1],
                          repeat: 0,
                        },
                      }}
                    />
                  ) : (
                    <div
                      className="w-3 h-3 rounded-full border-2 flex items-center justify-center"
                      style={{
                        borderColor:
                          index < currentSection ? primary[500] : "#4B5563",
                        backgroundColor:
                          index < currentSection ? primary[500] : "transparent",
                      }}
                    >
                      {index < currentSection && (
                        <motion.svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-2 w-2 text-black"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                          initial={{ scale: 0 }}
                          animate={{ scale: 1 }}
                          transition={{ duration: 0.3 }}
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </motion.svg>
                      )}
                    </div>
                  )}
                </div>

                {/* Connecting line between nodes (except after the last one) */}
                {index < sections.length - 1 && (
                  <div className="flex-1 h-[1.5px] relative -top-[0.5px] mx-0.5">
                    {/* Previous sections are fully filled */}
                    {index < currentSection ? (
                      <div
                        className="h-[1.5px]"
                        style={{ backgroundColor: primary[500] }}
                      ></div>
                    ) : /* Current section shows partial progress */
                    index === currentSection ? (
                      <div
                        className="h-[1.5px] w-full relative"
                        style={{ backgroundColor: "#4B5563" }}
                      >
                        <motion.div
                          className="h-[1.5px] absolute top-0 left-0"
                          style={{ backgroundColor: primary[500] }}
                          initial={{ width: 0 }}
                          animate={{ width: `${sectionProgress * 100}%` }}
                          transition={{ duration: 0.5, ease: "easeOut" }}
                        ></motion.div>
                      </div>
                    ) : (
                      /* Future sections are unfilled */
                      <div
                        className="h-[1.5px]"
                        style={{ backgroundColor: "#4B5563" }}
                      ></div>
                    )}
                  </div>
                )}
              </React.Fragment>
            ))}
          </div>

          {/* Current section title - only shown if showSectionTitle is true */}
          {showSectionTitle && (
            <motion.div
              className="text-center mt-2.5"
              initial={{ opacity: 0, y: -5 }}
              animate={{ opacity: 1, y: 0 }}
              transition={{ duration: 0.5 }}
            >
              <p
                className="text-sm font-medium"
                style={{ color: text.primary }}
              >
                {sections[currentSection]}
              </p>
            </motion.div>
          )}
        </div>
      </div>
    </>
  );
};

export default HeaderBar;
