import React from "react";

const GroundScreenInspiration = () => {
  const inspirationMessage = `1440 <span class="text-accent">brings</span> coaching to you! Our tech-enabled offering has been rated as <span class="text-accent">more effective</span> than some coaches even with the highest levels of certifications.<br></br>We're able to bring all of this to you <span class="text-accent">24/7</span>, more <span class="text-accent">affordably</span>, and packed full of features and functions <span class="text-accent">customized</span> to you and your needs.`;

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p
        className="text-white text-left text-3xl font-bold mb-6"
        dangerouslySetInnerHTML={{ __html: inspirationMessage }}
      ></p>
    </div>
  );
};

export default GroundScreenInspiration;
