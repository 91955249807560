const SuggestedMessages = ({ sendMessage, suggestions }) => {
  return (
    <div className="flex flex-wrap justify-left mt-7 mb-5 ml-0">
      {suggestions.map((suggestion, index) => (
        <button
          key={index}
          onClick={() => sendMessage(suggestion)}
          className="bg-transparent text-black dark:text-white border border-black dark:border-white border-opacity-20 dark:border-opacity-80 text-opacity-70 dark:text-opacity-70 text-sm md: text-md mb-2 hover:bg-black hover:text-white dark:hover:bg-white dark:hover:text-black hover:border-transparent rounded-full px-4 py-2 mr-2 transition duration-300 ease-in-out"
        >
          {suggestion}
        </button>
      ))}
    </div>
  );
};

export default SuggestedMessages;
