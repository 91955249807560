import React from "react";
import { motion } from "framer-motion";

const PersonalizedPlanSummary = ({ results }) => {
  // Process user responses
  const getUserData = () => {
    try {
      // Extract relevant data from responses
      const focusAreas = results?.screen5 || [];
      const challenges = results?.screen15 || [];
      const primaryMotivation = (results?.screen6 || [])[0] || "";
      const workHours = results?.screen9 || "";
      const sleepHours = results?.screen10 || "";

      return {
        focusAreas: focusAreas.slice(0, 2), // Only use top 2 priorities
        challenges: challenges.slice(0, 2), // Only use top 2 challenges
        primaryMotivation,
        workHours,
        sleepHours,
      };
    } catch (error) {
      console.error("Error processing user data:", error);
      return {
        focusAreas: [],
        challenges: [],
        primaryMotivation: "",
        workHours: "",
        sleepHours: "",
      };
    }
  };

  const userData = getUserData();

  // Build a simple focus description based on user data
  const getFocusDescription = () => {
    if (userData.focusAreas.length === 0 && userData.challenges.length === 0) {
      return "Your personalized system will help you align your time with what matters most.";
    }

    let description = "";

    // Add focus area if available
    if (userData.focusAreas.length > 0) {
      if (userData.focusAreas.length === 1) {
        description = `Your system will help you prioritize ${userData.focusAreas[0].toLowerCase()}`;
      } else {
        description = `Your system will help you balance ${userData.focusAreas[0].toLowerCase()} and ${userData.focusAreas[1].toLowerCase()}`;
      }
    }

    // Add challenges if available
    if (userData.challenges.length > 0) {
      if (description) {
        description += ` while addressing ${userData.challenges[0].toLowerCase()}`;
      } else {
        description = `Your system will help you overcome ${userData.challenges[0].toLowerCase()}`;
      }
    }

    // Add motivation if available
    if (userData.primaryMotivation) {
      const motivation = userData.primaryMotivation.toLowerCase();
      description += `, ultimately helping you ${
        motivation.startsWith("being") ||
        motivation.startsWith("spending") ||
        motivation.startsWith("creating") ||
        motivation.startsWith("building") ||
        motivation.startsWith("reducing")
          ? motivation
          : "achieve " + motivation
      }`;
    }

    description += ".";
    return description;
  };

  // Estimate current time distribution based on responses
  const getTimeDistribution = () => {
    // Default estimates if no data available
    const defaultDistribution = {
      productive: 25,
      wasted: 40,
      rest: 30,
      personal: 5,
    };

    // Projected improvement after 3 months
    const improvedDistribution = {
      productive: 38,
      wasted: 10,
      rest: 35,
      personal: 17,
    };

    return {
      current: defaultDistribution,
      improved: improvedDistribution,
    };
  };

  // Generate personalized insights based on user data
  const getPersonalizedInsights = () => {
    const insights = [];

    // Screen time reduction
    insights.push({
      icon: "📱",
      text: "-90 min daily screen time",
      color: "rgb(139, 92, 246)", // Purple
    });

    // Add insights based on focus areas
    if (userData.focusAreas.includes("Career")) {
      insights.push({
        icon: "💼",
        text: "+5 hrs weekly deep work",
        color: "rgb(59, 130, 246)", // Blue
      });
    } else if (userData.focusAreas.includes("Relationships")) {
      insights.push({
        icon: "💞",
        text: "+2 quality conversations daily",
        color: "rgb(236, 72, 153)", // Pink
      });
    } else if (userData.focusAreas.includes("Health")) {
      insights.push({
        icon: "🏃",
        text: "+3 workouts weekly",
        color: "rgb(16, 185, 129)", // Green
      });
    }

    // Add insight based on challenges
    if (userData.challenges.includes("Procrastination")) {
      insights.push({
        icon: "⏰",
        text: "-40% task completion time",
        color: "rgb(245, 158, 11)", // Amber
      });
    } else if (userData.challenges.includes("Distractions")) {
      insights.push({
        icon: "🧠",
        text: "+45 min daily deep focus",
        color: "rgb(239, 68, 68)", // Red
      });
    } else if (userData.challenges.includes("Overwhelm")) {
      insights.push({
        icon: "🧘",
        text: "-60% feeling overwhelmed",
        color: "rgb(16, 185, 129)", // Green
      });
    }

    // Fill with generic insights if needed
    if (insights.length < 3) {
      const genericInsights = [
        {
          icon: "✅",
          text: "+20% daily productivity",
          color: "rgb(139, 92, 246)", // Purple
        },
        {
          icon: "💪",
          text: "+30% goal completion",
          color: "rgb(59, 130, 246)", // Blue
        },
        {
          icon: "🌱",
          text: "-45% time wasted daily",
          color: "rgb(16, 185, 129)", // Green
        },
      ];

      // Add generic insights until we have at least 3
      while (insights.length < 3 && genericInsights.length > 0) {
        insights.push(genericInsights.shift());
      }
    }

    return insights.slice(0, 3); // Return up to 3 insights
  };

  const timeDistribution = getTimeDistribution();
  const personalizedInsights = getPersonalizedInsights();

  return (
    <motion.div
      className="w-full mb-8 "
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5, delay: 0.2 }}
    >
      {/* Main card */}
      <motion.div
        className="border border-zinc-800 rounded-lg p-5 mt-4 bg-zinc-900/50 shadow-sm mb-6"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.3, duration: 0.4 }}
      >
        <div className="mb-4 flex items-center">
          <div className="w-10 h-10 rounded-full bg-zinc-800 flex items-center justify-center mr-3">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 24 24"
              fill="none"
              stroke="currentColor"
              strokeWidth="2"
              strokeLinecap="round"
              strokeLinejoin="round"
              className="text-zinc-400"
            >
              <circle cx="12" cy="12" r="10"></circle>
              <polyline points="12 6 12 12 16 14"></polyline>
            </svg>
          </div>
          <h3 className="text-lg font-medium text-white mb-0">Focus areas</h3>
        </div>

        <p className="text-zinc-300 mb-0 text-sm leading-relaxed">
          {getFocusDescription()}
        </p>

        <div className="pt-4 mt-6 border-t border-zinc-800">
          <div className="flex justify-between items-center">
            <div className="flex items-center">
              <div className="w-8 h-8 p-2 rounded-full bg-zinc-800 flex items-center justify-center mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="text-zinc-400"
                >
                  <path d="M20 11.08V8l-6-6H6a2 2 0 0 0-2 2v16c0 1.1.9 2 2 2h12a2 2 0 0 0 2-2v-3.08"></path>
                  <path d="M14 3v5h5"></path>
                  <rect x="8" y="12" width="8" height="2"></rect>
                  <rect x="8" y="16" width="8" height="2"></rect>
                </svg>
              </div>
              <span className="text-zinc-300 text-sm">
                Daily guidance with accountability
              </span>
            </div>
            <div className="flex items-center">
              <div className="w-8 h-8 p-2 rounded-full bg-zinc-800 flex items-center justify-center mr-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  strokeWidth="2"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  className="text-zinc-400"
                >
                  <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2"></path>
                  <circle cx="9" cy="7" r="4"></circle>
                  <path d="M22 21v-2a4 4 0 0 0-3-3.87"></path>
                  <path d="M16 3.13a4 4 0 0 1 0 7.75"></path>
                </svg>
              </div>
              <span className="text-zinc-300 text-sm">
                Regular human coaching
              </span>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Time transformation chart - REDESIGNED CURVE */}
      <motion.div
        className="border border-zinc-800 rounded-xl p-6 bg-zinc-900/80 shadow-xl"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.5, duration: 0.4 }}
      >
        {/* Graph container */}
        <div className="relative h-72 w-full mb-12">
          {/* Grid lines */}
          <div className="absolute inset-0 grid grid-rows-6 gap-0 opacity-15">
            <div className="border-b border-zinc-500"></div>
            <div className="border-b border-zinc-500"></div>
            <div className="border-b border-zinc-500"></div>
            <div className="border-b border-zinc-500"></div>
            <div className="border-b border-zinc-500"></div>
            <div className="border-b border-zinc-500"></div>
          </div>

          {/* Background gradient effect */}
          <div className="absolute inset-0 bg-gradient-to-br from-indigo-900/5 to-blue-900/5 rounded-lg"></div>

          {/* Time points */}
          <div className="absolute bottom-[-28px] left-[10px] text-center">
            <div className="text-zinc-400 text-xs">Today</div>
          </div>

          <div className="absolute bottom-[-28px] right-[10px] text-center">
            <div className="text-zinc-400 text-xs">3 months</div>
          </div>

          {/* Productivity curve */}
          <motion.div
            className="absolute bottom-0 left-0 w-full h-full"
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            transition={{ duration: 0.5, delay: 0.8 }}
          >
            <svg
              viewBox="0 0 100 100"
              className="absolute bottom-0 left-0 w-full h-full overflow-visible"
              preserveAspectRatio="none"
            >
              {/* Gradient definitions */}
              <defs>
                <linearGradient
                  id="lineGradient"
                  x1="0%"
                  y1="100%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="#3b82f6" />
                  <stop offset="100%" stopColor="#DCFF02" />
                </linearGradient>
                <linearGradient
                  id="areaGradient"
                  x1="0%"
                  y1="100%"
                  x2="100%"
                  y2="0%"
                >
                  <stop offset="0%" stopColor="#3b82f6" stopOpacity="0.2" />
                  <stop offset="100%" stopColor="#DCFF02" stopOpacity="0.1" />
                </linearGradient>
                <filter id="glow" x="-5%" y="-5%" width="110%" height="110%">
                  <feGaussianBlur stdDeviation="0.5" result="blur" />
                  <feComposite in="SourceGraphic" in2="blur" operator="over" />
                </filter>
              </defs>

              {/* Extended path to fix gradient transition - constrained within box */}
              <path
                d="M0,100 L0,85 Q30,80 50,50 T100,15 L100,100 Z"
                fill="url(#areaGradient)"
                opacity="0.4"
              />

              {/* The curve line itself - smoother bezier curve */}
              <motion.path
                d="M0,85 Q30,80 50,50 T100,15"
                fill="none"
                stroke="url(#lineGradient)"
                strokeWidth="2.5"
                strokeLinecap="round"
                filter="url(#glow)"
                strokeDasharray="300"
                strokeDashoffset="300"
                initial={{ strokeDashoffset: 300 }}
                animate={{ strokeDashoffset: 0 }}
                transition={{ duration: 2, delay: 0.5, ease: "easeOut" }}
              />

              {/* Starting point marker - perfectly round */}
              <circle
                cx="0"
                cy="85"
                r="3.5"
                fill="#3b82f6"
                opacity="1"
                shapeRendering="geometricPrecision"
              />

              {/* Ending point marker - perfectly round */}
              <circle
                cx="100"
                cy="15"
                r="3.5"
                fill="#DCFF02"
                opacity="1"
                shapeRendering="geometricPrecision"
              />
            </svg>
          </motion.div>

          {/* Now label */}
          <div className="absolute left-4 bottom-[85px]">
            <div
              className="rounded-lg bg-zinc-800 px-4 py-1.5 text-sm font-medium text-white 
                          shadow-sm border border-zinc-700"
            >
              <div className="flex items-center">
                <div className="w-2.5 h-2.5 rounded-full bg-blue-500 mr-2"></div>
                Now
              </div>
            </div>
          </div>

          {/* New you label */}
          <div className="absolute right-4 top-[10px]">
            <div
              className="rounded-lg bg-[#DCFF02] px-4 py-1.5 text-sm font-medium text-zinc-900 
                          shadow-sm border border-[#DCFF02]/70"
            >
              <div className="flex items-center">
                <div className="w-2.5 h-2.5 rounded-full bg-zinc-900 mr-2"></div>
                New you
              </div>
            </div>
          </div>
        </div>

        {/* Personalized insights - Simplified cards */}
        <div className="grid grid-cols-3 gap-4">
          {/* Screen Time Card */}
          <div className="bg-zinc-800/60 rounded-xl overflow-hidden">
            <div className="p-5 flex flex-col items-center">
              <div className="w-12 h-12 rounded-full bg-blue-900/30 flex items-center justify-center mb-3 border border-blue-700/30">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-blue-400"
                >
                  <rect
                    x="5"
                    y="2"
                    width="14"
                    height="20"
                    rx="2"
                    ry="2"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <line
                    x1="12"
                    y1="18"
                    x2="12"
                    y2="18.01"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                  />
                </svg>
              </div>
              <div className="text-red-400 font-semibold text-xl">-90m</div>
              <div className="text-zinc-400 text-sm mt-1">wasted</div>
            </div>
          </div>

          {/* Productivity Card */}
          <div className="bg-zinc-800/60 rounded-xl overflow-hidden">
            <div className="p-5 flex flex-col items-center">
              <div className="w-12 h-12 rounded-full bg-sky-900/30 flex items-center justify-center mb-3 border border-sky-700/30">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-sky-400"
                >
                  <rect
                    x="3"
                    y="3"
                    width="18"
                    height="18"
                    rx="2"
                    stroke="currentColor"
                    strokeWidth="1.5"
                  />
                  <path
                    d="M9 12L11 14L15 10"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div className="text-sky-400 font-semibold text-xl">+20%</div>
              <div className="text-zinc-400 text-sm mt-1">productivity</div>
            </div>
          </div>

          {/* Focus Duration Card */}
          <div className="bg-zinc-800/60 rounded-xl overflow-hidden">
            <div className="p-5 flex flex-col items-center">
              <div className="w-12 h-12 rounded-full bg-green-900/30 flex items-center justify-center mb-3 border border-green-700/30">
                <svg
                  width="22"
                  height="22"
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="text-green-400"
                >
                  <path
                    d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm0 18c-4.41 0-8-3.59-8-8s3.59-8 8-8 8 3.59 8 8-3.59 8-8 8zm-1-13h2v6h-2zm0 8h2v2h-2z"
                    fill="currentColor"
                  />
                </svg>
              </div>
              <div className="text-green-400 font-semibold text-xl">+25%</div>
              <div className="text-zinc-400 text-sm mt-1">growth</div>
            </div>
          </div>
        </div>
      </motion.div>

      {/* Payment and guarantee section - Minimalist Design */}
      <motion.div
        className="border border-zinc-800 rounded-xl p-6 bg-zinc-900/80 shadow-xl mb-8 mt-6"
        initial={{ opacity: 0, y: 10 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ delay: 0.7, duration: 0.4 }}
      >
        <div className="flex flex-col gap-8">
          {/* Pricing offer */}
          <div className="flex-1">
            <div className="flex items-center space-x-2 mb-4">
              <div className="h-8 w-1.5 bg-[#DCFF02] rounded-full mb-0"></div>
              <h3 className="text-lg font-medium mb-0 text-white">
                Limited offer
              </h3>
            </div>

            <div className="mb-6">
              <div className="flex items-baseline mb-2">
                <span className="text-3xl font-bold text-white">$10</span>
                <span className="text-zinc-400 ml-2 text-sm">/month</span>
                <span className="text-zinc-500 ml-3 text-xs line-through">
                  $29/month
                </span>
              </div>
              <p className="text-zinc-400 text-sm">
                AI Performance Coaching subscription
              </p>
            </div>

            <a
              href="https://buy.stripe.com/5kAeVsbGrdcE3Di6oo"
              className="block w-full bg-[#DCFF02] hover:bg-[#DCFF02]/90 text-black py-3 px-4 rounded-lg transition-colors duration-200 text-center font-bold leading-none "
            >
              Get Started
            </a>

            <div className="mt-4 flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-4 h-4 text-[#DCFF02] mr-2"
              >
                <path
                  fillRule="evenodd"
                  d="M12.516 2.17a.75.75 0 00-1.032 0 11.209 11.209 0 01-7.877 3.08.75.75 0 00-.722.515A12.74 12.74 0 002.25 9.75c0 5.942 4.064 10.933 9.563 12.348a.75.75 0 00.674 0c5.499-1.415 9.563-6.406 9.563-12.348 0-1.39-.223-2.73-.635-3.985a.75.75 0 00-.722-.516l-.143.001c-2.996 0-5.717-1.17-7.734-3.08zm3.094 8.016a.75.75 0 10-1.22-.872l-3.236 4.53L9.53 12.22a.75.75 0 00-1.06 1.06l2.25 2.25a.75.75 0 001.14-.094l3.75-5.25z"
                  clipRule="evenodd"
                />
              </svg>
              <span className="text-zinc-400 text-xs">
                30-day money-back guarantee
              </span>
            </div>
          </div>

          {/* Payment methods */}
          <div className="flex-1">
            <div className="mb-4">
              <h4 className="text-zinc-400 text-sm font-medium mb-3">
                Secure checkout with
              </h4>
              <div className="grid grid-cols-3 gap-2">
                {/* Visa */}
                <div className="bg-zinc-800/50 rounded p-2 flex items-center justify-center h-10">
                  <img
                    src="/images/visa.svg"
                    alt="Visa"
                    className="h-6 rounded-xs"
                  />
                </div>

                {/* Mastercard */}
                <div className="bg-zinc-800/50 rounded p-2 flex items-center justify-center h-10">
                  <img
                    src="/images/mastercard.svg"
                    alt="Mastercard"
                    className="h-6 rounded-xs"
                  />
                </div>

                {/* Apple Pay */}
                <div className="bg-zinc-800/50 rounded p-2 flex items-center justify-center h-10">
                  <img
                    src="/images/apple-pay.svg"
                    alt="Apple Pay"
                    className="h-6 rounded-xs"
                  />
                </div>

                {/* Google Pay */}
                <div className="bg-zinc-800/50 rounded p-2 flex items-center justify-center h-10">
                  <img
                    src="/images/google-pay.svg"
                    alt="Google Pay"
                    className="h-6 rounded-xs"
                  />
                </div>

                {/* Amazon Pay */}
                <div className="bg-zinc-800/50 rounded p-2 flex items-center justify-center h-10">
                  <img
                    src="/images/amazon-pay.svg"
                    alt="Amazon Pay"
                    className="h-6 rounded-xs"
                  />
                </div>

                {/* Klarna */}
                <div className="bg-zinc-800/50 rounded p-2 flex items-center justify-center h-10">
                  <img
                    src="/images/klarna.svg"
                    alt="Klarna"
                    className="h-6 rounded-xs"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default PersonalizedPlanSummary;
