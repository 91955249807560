import React, { useState } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [selectedModalities, setSelectedModalities] = useState([]);

  const modalities = [
    "Continuous text chat",
    "24/7 Voice communication",
    "Scheduled video calls",
    "Journaling exercises",
    "Guided assessments",
    "Meditation",
    "Diagnostics",
    "Visualization",
    "Coach pairing",
    "Executive coaching",
    "Career coaching",
    "Educational information",
    "Couples/relationship coaching",
  ];

  const handleModalitySelection = (modality) => {
    setSelectedModalities((prevModalities) => {
      if (prevModalities.includes(modality)) {
        return prevModalities.filter((item) => item !== modality);
      } else {
        return [...prevModalities, modality];
      }
    });
  };

  // Update the parent component whenever the selectedModalities change
  useState(() => {
    onUpdate(selectedModalities.join(", "));
  }, [selectedModalities, onUpdate]);

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        Which modalities have you used before?
      </p>
      <div className="flex flex-col w-full">
        {modalities.map((modality) => (
          <button
            key={modality}
            onClick={() => handleModalitySelection(modality)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              selectedModalities.includes(modality)
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: selectedModalities.includes(modality)
                ? "0 0 2px #FF8C22"
                : "",
            }}
          >
            {modality}
          </button>
        ))}
      </div>
    </div>
  );
}
