import React from "react";

const EditStructuredList = ({ items = [], onChange }) => {
  const handleItemChange = (index, field, value) => {
    const updatedItems = [...items];
    updatedItems[index] = {
      ...updatedItems[index],
      [field]: field === "length" ? parseInt(value) || 10 : value
    };
    onChange(updatedItems);
  };

  const handleAddItem = () => {
    onChange([
      ...items,
      {
        title: "",
        text: "",
        length: 10
      }
    ]);
  };

  const handleRemoveItem = (index) => {
    const updatedItems = items.filter((_, i) => i !== index);
    onChange(updatedItems);
  };

  return (
    <div className="space-y-6">
      {items.map((item, index) => (
        <div key={index} className="bg-white p-4 rounded-lg border border-slate-200 space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-sm font-medium text-slate-600">Section {index + 1}</span>
            <button
              onClick={() => handleRemoveItem(index)}
              className="text-red-500 hover:text-red-700 text-sm font-medium"
            >
              Remove
            </button>
          </div>
          
          <div className="space-y-4">
            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Title
              </label>
              <input
                type="text"
                value={item.title}
                onChange={(e) => handleItemChange(index, "title", e.target.value)}
                placeholder="Enter topic title..."
                className="w-full p-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
                Topic content for 1440 to discuss with user
              </label>
              <textarea
                value={item.text}
                onChange={(e) => handleItemChange(index, "text", e.target.value)}
                placeholder="Enter instructions for 1440..."
                rows={3}
                className="w-full p-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>

            <div>
              <label className="block text-sm font-medium text-slate-700 mb-1">
              Number of user responses needed before next section
              </label>
              <input
                type="number"
                min="1"
                value={item.length}
                onChange={(e) => handleItemChange(index, "length", e.target.value)}
                className="w-32 p-2 border border-slate-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
            </div>
          </div>
        </div>
      ))}
      
      <button
        onClick={handleAddItem}
        className="w-full py-3 px-4 border-2 border-dashed border-slate-300 rounded-lg text-slate-600 hover:border-blue-500 hover:text-blue-500 transition-colors"
      >
        + Add Message
      </button>
    </div>
  );
};

export default EditStructuredList; 