import React from "react";

const EditProtocolField = ({
  title,
  description,
  placeholder,
  value,
  onChange,
}) => {
  return (
    <div className="mb-6">
      <h4 className="text-base font-medium mb-1 text-gray-700">{title}</h4>
      <p className="text-sm text-gray-500 mb-2">{description}</p>
      <textarea
        value={value}
        onChange={onChange}
        placeholder={placeholder}
        className="w-full p-3 text-base text-gray-700 bg-white border border-gray-300 rounded-lg focus:outline-none focus:border-indigo-500"
      />
    </div>
  );
};

export default EditProtocolField;
