import React, { useState } from "react";
import {
  ChevronUpDownIcon,
  PencilSquareIcon,
  TrashIcon,
  ChevronDoubleDownIcon,
} from "@heroicons/react/24/outline";

const ProtocolOutline = ({ initialItems, onUpdate }) => {
  const [items, setItems] = useState(initialItems);
  const [editingIndex, setEditingIndex] = useState(null);
  const [newItem, setNewItem] = useState({ name: "", note: "" });
  const [showNoteInput, setShowNoteInput] = useState(false);
  const [showEditNoteInput, setShowEditNoteInput] = useState(false);
  const [visibleItemsCount, setVisibleItemsCount] = useState(10); // State to keep track of how many items to show
  const handleLoadMore = () => {
    setVisibleItemsCount(items.length); // Set visible items count to the total number of items
  };

  const handleAdd = () => {
    if (newItem.name) {
      const updatedItems = [...items, newItem];
      setItems(updatedItems);
      onUpdate(updatedItems);
      setNewItem({ name: "", note: "" });
      setShowNoteInput(false);
    }
  };

  const handleDelete = (index, event) => {
    event.stopPropagation();
    const updatedItems = items.filter((_, i) => i !== index);
    setItems(updatedItems);
    onUpdate(updatedItems);
  };

  const handleEdit = (index) => {
    setEditingIndex(index);
    setShowEditNoteInput(items[index].note !== "");
  };

  const handleSave = (index, updatedItem) => {
    const updatedItems = items.map((item, i) =>
      i === index ? updatedItem : item
    );
    setItems(updatedItems);
    onUpdate(updatedItems);
    setEditingIndex(null);
    setShowEditNoteInput(false);
  };

  const handleNameChange = (index, newName) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, name: newName } : item
    );
    setItems(updatedItems);
    onUpdate(updatedItems); // Call onUpdate "summary", to notify about the change
  };

  const handleNoteChange = (index, newNote) => {
    const updatedItems = items.map((item, i) =>
      i === index ? { ...item, note: newNote } : item
    );
    setItems(updatedItems);
    onUpdate(updatedItems); // Call onUpdate "summary", to notify about the change
  };

  const handleDragStart = (index, event) => {
    event.dataTransfer.setData("text/plain", index);
  };

  const handleDrop = (event) => {
    const fromIndex = parseInt(event.dataTransfer.getData("text/plain"), 10);
    const toIndex = parseInt(event.currentTarget.dataset.index, 10);
    if (fromIndex === toIndex) return;

    const itemToMove = items[fromIndex];
    let reorderedItems = [...items];
    reorderedItems.splice(fromIndex, 1); // Remove the item from its original position
    reorderedItems.splice(toIndex, 0, itemToMove); // Insert the item at the new position

    setItems(reorderedItems);
    onUpdate(reorderedItems);
  };

  const handleDragOver = (event) => {
    event.preventDefault(); // Necessary for the drop event to fire.
  };

  return (
    <div className="bg-slate-100 rounded shadow divide-y divide-slate-200">
      <div className="p-4 bg-white rounded-t-lg">
        <div className="flex items-center space-x-2">
          <input
            className="flex-grow border rounded p-2"
            value={newItem.name}
            placeholder="Add new item"
            onChange={(e) => setNewItem({ ...newItem, name: e.target.value })}
            onKeyPress={(event) => {
              if (event.key === "Enter") {
                handleAdd();
              }
            }}
          />
          <button
            className="bg-slate-200 text-slate-700 rounded p-2"
            onClick={handleAdd}
          >
            + Add
          </button>
        </div>
        {showNoteInput && (
          <textarea
            className="mt-2 w-full border rounded p-2"
            value={newItem.note}
            placeholder="Add note (optional)"
            onChange={(e) => setNewItem({ ...newItem, note: e.target.value })}
          />
        )}
        {!showNoteInput && (
          <button
            className="mt-2 bg-slate-200 text-slate-700 rounded p-2"
            onClick={() => setShowNoteInput(true)}
          >
            + Add note
          </button>
        )}
      </div>
      {Array.isArray(items) &&
        items.slice(0, visibleItemsCount).map((item, index) => (
          <div
            key={index}
            className="bg-slate-50"
            draggable
            onDragStart={(e) => handleDragStart(index, e)}
            onDrop={handleDrop}
            onDragOver={handleDragOver}
            data-index={index}
          >
            <div className="p-4 flex items-center justify-between">
              <span className="font-medium text-slate-700 ml-2">
                {index + 1}
              </span>
              <ChevronUpDownIcon className="h-6 w-6 mr-3 text-slate-700 cursor-move" />
              {editingIndex === index ? (
                <>
                  <div className="flex-grow">
                    <div className="flex justify-between mr-2">
                      <input
                        className="border rounded p-2 mr-2 w-full"
                        value={item.name}
                        onChange={(e) =>
                          handleNameChange(index, e.target.value)
                        }
                      />
                      <button
                        className="bg-red-200 text-red-700 rounded p-3"
                        onClick={(e) => handleDelete(index, e)}
                      >
                        <TrashIcon className="h-5 w-5" />
                      </button>
                    </div>
                    <div className="flex mt-2">
                      {showEditNoteInput && (
                        <textarea
                          className="flex-grow border rounded p-2 mr-2"
                          value={item.note}
                          onChange={(e) =>
                            handleNoteChange(index, e.target.value)
                          }
                        />
                      )}
                      {!showEditNoteInput && !item.note && (
                        <button
                          className="bg-slate-200 text-slate-700 rounded p-2 mr-2"
                          onClick={(e) => {
                            e.stopPropagation();
                            setShowEditNoteInput(true);
                          }}
                        >
                          + Add note
                        </button>
                      )}
                      <button
                        className="bg-slate-200 text-slate-700 rounded p-2"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSave(index, item);
                        }}
                      >
                        Save
                      </button>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <span className="flex-grow">{item.name}</span>
                  <div className="flex items-center space-x-2 mr-2">
                    <button
                      className="bg-slate-200 text-slate-700 rounded p-2"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEdit(index);
                      }}
                    >
                      <PencilSquareIcon className="h-5 w-5" />
                    </button>
                    <button
                      className="bg-red-200 text-red-700 rounded p-2"
                      onClick={(e) => handleDelete(index, e)}
                    >
                      <TrashIcon className="h-5 w-5" />
                    </button>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      {visibleItemsCount < items.length && ( // Only show the Load More button if there are more items to show
        <div className="p-4 text-center">
          <button
            className="text-slate-700 rounded flex items-center justify-center"
            onClick={handleLoadMore}
          >
            <ChevronDoubleDownIcon className="h-5 w-5 mx-2" />
            Load More
          </button>
        </div>
      )}
    </div>
  );
};

export default ProtocolOutline;
