import React, { useState, createContext } from "react";

export const CoachContext = createContext();

export const CoachProvider = ({ children }) => {
  const [coachId, setCoachId] = useState(null);
  const [coachData, setCoachData] = useState({});
  const [teamData, setTeamData] = useState([]);
  const [selectedTeam, setSelectedTeam] = useState(null); // Add state for selected team

  const value = {
    coachId,
    setCoachId,
    coachData,
    setCoachData,
    teamData,
    setTeamData,
    selectedTeam,
    setSelectedTeam,
  };

  return (
    <CoachContext.Provider value={value}>{children}</CoachContext.Provider>
  );
};
