import React from "react";

export default function GroundScreen19({ onUpdate }) {
  const handleCoachSelection = (coachName) => {
    onUpdate(coachName);
  };

  return (
    <div className="flex justify-center flex-col items-center bg-black py-12">
      <h2 className="text-4xl font-bold text-white text-center mb-10">
        Your Personal AI Coaching Team
      </h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div className="bg-dark overflow-hidden rounded-lg shadow-xl border-2 border-gray-900 transform transition-all hover:scale-105 relative">
          <img
            className="w-full h-64 object-cover filter blur-md"
            src="/images/coach3.png"
            alt="Coach Nia"
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <span className="text-white text-xl font-medium px-6 py-3  ">
              Coming Soon
            </span>
          </div>
          <div className="p-6 filter blur-md">
            <h3 className="text-xl font-semibold mb-4 text-white">Coach Nia</h3>
            <div className="text-gray-500 font-medium">
              <li className="list-none">Integrated with calendar and health</li>
              <li className="list-none">24/7 AI-driven coaching</li>
              <li className="list-none">Science-backed methodologies</li>
            </div>
            <button
              className="mt-4 bg-accent text-black py-3 px-6 font-medium w-full hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50"
              onClick={() => handleCoachSelection("CoachNia")}
            >
              Engage with Nia
            </button>
          </div>
        </div>

        <div className="bg-dark rounded-lg shadow-xl border-2 border-gray-900 overflow-hidden transform transition-all scale-105 hover:scale-110">
          <img
            className="w-full h-64 object-cover"
            src="/images/coach2.png"
            alt="Coach Nia"
          />
          <div className="p-6">
            <h3 className="text-xl font-semibold mb-4 text-white">Coach Nia</h3>
            <div className="text-gray-500 font-medium">
              <li className="list-none">Integrated with calendar and health</li>
              <li className="list-none">24/7 AI-driven coaching</li>
              <li className="list-none">Science-backed methodologies</li>
            </div>
            <button
              className="mt-4 bg-accent text-black py-3 px-6 font-medium w-full hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50"
              onClick={() => handleCoachSelection("CoachNia")}
            >
              Engage with Nia
            </button>
          </div>
        </div>

        <div className="bg-dark overflow-hidden rounded-lg shadow-xl border-2 border-gray-900 transform transition-all hover:scale-105 relative">
          <img
            className="w-full h-64 object-cover filter blur-md"
            src="/images/coach1.png"
            alt="Coach Nia"
          />
          <div className="absolute inset-0 flex justify-center items-center">
            <span className="text-white text-xl font-medium px-6 py-3  ">
              Coming Soon
            </span>
          </div>
          <div className="p-6 filter blur-md">
            <h3 className="text-xl font-semibold mb-4 text-white">Coach Nia</h3>
            <div className="text-gray-500 font-medium">
              <li className="list-none">Integrated with calendar and health</li>
              <li className="list-none">24/7 AI-driven coaching</li>
              <li className="list-none">Science-backed methodologies</li>
            </div>
            <button
              className="mt-4 bg-accent text-black py-3 px-6 font-medium w-full hover:bg-accent-dark focus:outline-none focus:ring-2 focus:ring-accent focus:ring-opacity-50"
              onClick={() => handleCoachSelection("CoachNia")}
            >
              Engage with Nia
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}
