import React, { useState, useEffect } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [selectedModalities, setSelectedModalities] = useState([]);

  const modalities = [
    "Text-based conversations",
    "Voice-based interactions",
    "Video-based interactions",
    "Virtual Reality experiences",
    "All of the above",
  ];

  const handleModalitySelection = (modality) => {
    setSelectedModalities((prevModalities) => {
      if (modality === "All of the above") {
        return modalities.slice(0, modalities.length - 1);
      } else {
        const newModalities = prevModalities.includes(modality)
          ? prevModalities.filter((item) => item !== modality)
          : [...prevModalities, modality];
        // Remove "All of the above" if any other modality is selected
        return newModalities.filter((item) => item !== "All of the above");
      }
    });
  };

  // Update the parent component whenever the selectedModalities change
  useEffect(() => {
    onUpdate(selectedModalities.join(", "));
  }, [selectedModalities, onUpdate]);

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        Which modalities excite you?
      </p>
      <div className="flex flex-col w-full">
        {modalities.map((modality) => (
          <button
            key={modality}
            onClick={() => handleModalitySelection(modality)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              selectedModalities.includes(modality)
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: selectedModalities.includes(modality)
                ? "0 0 2px #FF8C22"
                : "",
            }}
          >
            {modality}
          </button>
        ))}
      </div>
    </div>
  );
}
