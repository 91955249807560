import React from "react";

const TimelineSection = () => {
  const timelineSteps = [
    {
      title: "Assessment & Discovery",
      description:
        "We help you identify what you truly value and compare it with how you're actually spending your 1,440 minutes each day.",
      week: "Weeks 1-2",
    },
    {
      title: "Awareness Building",
      description:
        "You'll start recognizing in real-time when you're spending time on social media or tasks that don't align with your priorities.",
      week: "Weeks 3-4",
    },
    {
      title: "Pattern Disruption",
      description:
        "We'll help you break habits like checking your phone first thing in the morning or saying yes to commitments that drain you.",
      week: "Weeks 5-8",
    },
    {
      title: "New Alignment Creation",
      description:
        "We'll help restructure your calendar and daily routines to naturally prioritize what matters most to you.",
      week: "Weeks 9-12",
    },
    {
      title: "Integration & Mastery",
      description:
        "You'll develop an intuitive sense of how to protect your time for what matters, with our support as needed.",
      week: "Ongoing",
    },
  ];

  return (
    <section className="py-24 px-6 relative bg-dark-lighter">
      <div className="absolute -top-32 inset-x-0 bottom-0">
        <div className="absolute inset-0 bg-gradient-to-b from-transparent  to-dark/50 h-48"></div>
        <div className="absolute inset-y-48 inset-x-0 bg-gradient-to-b from-dark/50 to-transparent h-48"></div>
      </div>
      <div className="absolute bottom-0 left-0 w-96 h-96 bg-accent-teal/20 rounded-full filter blur-3xl opacity-50"></div>
      <div className="absolute top-1/2 right-0 w-96 h-96 bg-accent-blue/20 rounded-full filter blur-3xl opacity-50"></div>
      <div className="container mx-auto relative">
        <div className="max-w-3xl mx-auto text-center mb-16">
          <h2 className="text-4xl font-bold mb-6 text-white">
            Your <span className="text-gradient">Transformation</span> Journey
          </h2>
          <p className="text-xl text-gray-300">
            The path to time alignment follows a proven sequence, guided by our
            comprehensive support system.
          </p>
        </div>

        <div className="relative max-w-4xl mx-auto">
          {/* Timeline line */}
          <div className="absolute left-1/2 transform -translate-x-1/2 h-full w-1 bg-gradient-to-b from-primary/80 via-primary/50 to-primary/20 rounded-full"></div>

          {/* Timeline steps */}
          {timelineSteps.map((step, index) => (
            <div
              key={index}
              className={`relative z-10 flex items-center mb-20 animate-fade-up`}
              style={{ animationDelay: `${index * 200}ms` }}
            >
              <div
                className={`flex flex-col ${
                  index % 2 === 0 ? "md:flex-row" : "md:flex-row-reverse"
                } items-center w-full`}
              >
                {/* Card section - with consistent distance from center */}
                <div
                  className={`flex-1 ${
                    index % 2 === 0 ? "md:pr-16" : "md:pl-16"
                  }`}
                >
                  <div className="glass-card p-6 rounded-xl">
                    <h3 className="text-xl font-semibold mb-2 text-white">
                      {step.title}
                    </h3>
                    <p className="text-gray-300 mb-3">{step.description}</p>
                    <span className="text-primary mb-0 text-sm font-medium">
                      {step.week}
                    </span>
                  </div>
                </div>

                {/* Number square in the center */}
                <div className="my-6 md:my-0">
                  <div className="w-12 h-12 bg-primary border-2 border-primary flex items-center justify-center text-white font-bold rounded-md">
                    {index + 1}
                  </div>
                </div>

                {/* Empty space on the other side for alignment */}
                <div
                  className={`flex-1 ${
                    index % 2 === 0 ? "md:pl-16" : "md:pr-16"
                  } hidden md:block`}
                ></div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default TimelineSection;
