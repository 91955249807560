import React, { useState, useEffect } from "react";
import axios from "axios";

export default function UserStatsCard() {
  const [stats, setStats] = useState({
    totalUsers: 0,
    activeUsers: 0,
    newUsersToday: 0,
  });
  const [plots, setPlots] = useState({});
  const [timestamp, setTimestamp] = useState(null);
  const [loading, setLoading] = useState(true);
  const [updating, setUpdating] = useState(false);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const api_key = localStorage.getItem("adminApiKey");
        const response = await axios.get("/api/admin/user_stats", {
          headers: { "Api-Key": api_key },
        });

        if (response.data.status === "success") {
          setPlots(response.data.plots);
          setTimestamp(response.data.timestamp);
        }
      } catch (error) {
        console.error("Error fetching stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  const handleUpdatePlots = async () => {
    setUpdating(true);
    try {
      alert(
        "Please open an SSH connection and run the visualizations.py file to update the graphs"
      );
    } catch (error) {
      console.error("Error updating plots:", error);
    } finally {
      setUpdating(false);
    }
  };

  if (loading) {
    return (
      <div className="bg-slate-800 rounded-lg p-6 animate-pulse">
        <div className="h-4 bg-slate-700 rounded w-1/4 mb-4"></div>
        <div className="space-y-3">
          <div className="h-8 bg-slate-700 rounded"></div>
          <div className="h-8 bg-slate-700 rounded"></div>
          <div className="h-8 bg-slate-700 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="space-y-6">
      <div className="bg-slate-800 rounded-lg p-6">
        <h2 className="text-xl font-semibold text-white mb-4">
          User Statistics
        </h2>
        <div className="space-y-4">
          <div className="flex justify-between items-center">
            <span className="text-gray-400">Total Users</span>
            <span className="text-white text-xl font-bold">
              {stats.totalUsers}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-400">Active Users (7d)</span>
            <span className="text-white text-xl font-bold">
              {stats.activeUsers}
            </span>
          </div>
          <div className="flex justify-between items-center">
            <span className="text-gray-400">New Users Today</span>
            <span className="text-white text-xl font-bold">
              {stats.newUsersToday}
            </span>
          </div>
        </div>
      </div>

      {Object.keys(plots).length > 0 && (
        <div className="">
          <div className="flex justify-between items-center mb-6 mt-12 bg-slate-800 rounded-lg p-6">
            <h2 className="text-xl font-semibold text-white mb-0">
              Analytics Graphs
            </h2>
            <div className="flex items-center gap-4">
              {timestamp && (
                <span className="text-sm text-gray-400">
                  Updated: {new Date(timestamp).toLocaleString()}
                </span>
              )}
              <button
                onClick={handleUpdatePlots}
                disabled={updating}
                className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed"
              >
                {updating ? "Updating..." : "Update plots"}
              </button>
            </div>
          </div>
          <div className="grid grid-cols-1  gap-6">
            {Object.entries(plots).map(([name, url]) => (
              <div key={name} className="bg-slate-700 rounded-lg p-4">
                <h3 className="text-white text-lg font-medium mb-3 capitalize">
                  {name.replace(/_/g, " ")}
                </h3>
                <img
                  src={"https://api.1440.ai" + url}
                  alt={`${name} graph`}
                  className="w-full rounded-lg"
                  loading="lazy"
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
}
