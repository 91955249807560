import React from "react";

const GroundScreenMotivation = () => {
  const motivationMessage = `Our methods are based on the science of <span class="text-accent">purpose, time, happiness, and achievement</span>. If you know what you want to do, we can help you <span class="text-accent">achieve it</span>.<br><br>And if you're not sure yet, we can help you find the things you're <span class="text-accent">uniquely suited</span> for and craft custom plans to help you <span class="text-accent">accomplish your goals</span>. Whether it's a career change, mending a relationship, or just a place for <span class="text-accent">un-biased advice</span>, we're here for you without judgement.`;

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p
        className="text-white text-left text-3xl font-bold mb-6"
        dangerouslySetInnerHTML={{ __html: motivationMessage }}
      ></p>
    </div>
  );
};

export default GroundScreenMotivation;
