import React from "react";
import { motion } from "framer-motion";
import { primary, background } from "../theme/variables";
import "../theme/theme.css";

const GridBackground = () => {
  return (
    <div
      className="fixed inset-0 w-full h-full overflow-hidden"
      style={{ zIndex: -1, backgroundColor: background.black }}
    >
      {/* Grid pattern */}
      <div className="absolute inset-0 grid-pattern opacity-40"></div>

      {/* First gradient blob */}
      <motion.div
        className="absolute -top-40 -right-40 w-[600px] h-[600px] rounded-full blur-[120px]"
        style={{ backgroundColor: `${primary[500]}42` }} // 30% opacity
        animate={{
          scale: [1, 1.1, 1],
          opacity: [0.3, 0.5, 0.3],
        }}
        transition={{
          duration: 8,
          repeat: Infinity,
          repeatType: "reverse",
        }}
      />

      {/* Second gradient blob */}
      <motion.div
        className="absolute -bottom-40 -left-40 w-[500px] h-[500px] rounded-full blur-[100px]"
        style={{ backgroundColor: `${primary[700]}42` }} // 30% opacity
        animate={{
          scale: [1, 1.2, 1],
          opacity: [0.3, 0.6, 0.3],
        }}
        transition={{
          duration: 10,
          repeat: Infinity,
          repeatType: "reverse",
          delay: 1,
        }}
      />

      {/* Third gradient blob */}
      <motion.div
        className="absolute top-1/3 left-1/3 w-[400px] h-[400px] rounded-full blur-[90px]"
        style={{ backgroundColor: `${primary[800]}3D` }} // 25% opacity
        animate={{
          scale: [1, 1.15, 1],
          opacity: [0.2, 0.4, 0.2],
        }}
        transition={{
          duration: 12,
          repeat: Infinity,
          repeatType: "reverse",
          delay: 2,
        }}
      />

      {/* Dotted overlay */}
      <div className="absolute inset-0">
        <div className="glow-dots"></div>
      </div>
    </div>
  );
};

export default GridBackground;
