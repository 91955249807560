import React, { useState, useEffect } from "react";
import Introduction from "./Introduction";
import Question from "./Question";
import Results from "./Results";
import Footer from "../components/footer/Footer";
import Mixpanel from "mixpanel-browser";

const PurposeAssessment = ({ userId = null }) => {
  useEffect(() => {
    // Initialize Mixpanel with your project token
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", {
      track_pageview: true,
    });
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "about" });
  }, []);

  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [userResponses, setUserResponses] = useState({});
  const [selectedOptions, setSelectedOptions] = useState({});
  const [assessmentStarted, setAssessmentStarted] = useState(true);
  const [assessmentCompleted, setAssessmentCompleted] = useState(false);
  const [darkTheme, setDarkTheme] = useState(() => {
    const savedTheme = localStorage.getItem("darkTheme");
    return savedTheme !== null ? JSON.parse(savedTheme) : false;
  });

  useEffect(() => {
    localStorage.setItem("darkTheme", JSON.stringify(darkTheme));
    // Optionally track theme changes with Mixpanel
  }, [darkTheme]);
  const questions = [
    {
      text: "I am motivated by professional achievements and career advancement.",
    },
    {
      text: "Innovating or creating something new excites me more than following established paths.",
    },
    {
      text: "Making a positive impact in others’ lives is a primary goal for me.",
    },
    {
      text: "I am constantly seeking new learning opportunities and knowledge.",
    },
    {
      text: "Achieving financial stability is crucial to my sense of purpose.",
    },
    {
      text: "Maintaining a balance between my work and personal life is very important to me.",
    },
    {
      text: "Expressing myself artistically or creatively is a key part of who I am.",
    },
    {
      text: "Building and maintaining social connections is one of my top priorities.",
    },
    { text: "My physical and mental health is a primary focus in my life." },
    { text: "I am drawn to adventures and exploring new experiences." },
    {
      text: "Being actively involved in my community is a significant aspect of my life.",
    },
    { text: "Environmental issues and sustainability are important to me." },
    { text: "Spiritual or personal growth is a key part of my life journey." },
    {
      text: "I am driven to lead and influence others in my professional or personal life.",
    },
    {
      text: "Exploring and understanding different cultures is important to me.",
    },
  ];
  const handleStart = () => {
    setAssessmentStarted(true);
  };

  const handleResponse = (questionIndex, response) => {
    const newSelectedOptions = {
      ...selectedOptions,
      [questionIndex]: response,
    };
    setSelectedOptions(newSelectedOptions);

    const updatedUserResponses = {
      ...userResponses,
      [questionIndex]: response,
    };
    setUserResponses(updatedUserResponses);

    // Check if all questions have been answered
    const allAnswered = questions.every((_, index) =>
      updatedUserResponses.hasOwnProperty(index)
    );
    if (allAnswered) {
      // If all questions have been answered, mark the assessment as completed
      setAssessmentCompleted(true);
      Mixpanel.identify(userId);
      Mixpanel.track("Assessment Completed", {
        responses: updatedUserResponses,
      });
    } else {
      // Otherwise, move to the next question
      // Find the next unanswered question
      const nextUnansweredIndex = questions.findIndex(
        (_, index) => !updatedUserResponses.hasOwnProperty(index)
      );
      if (nextUnansweredIndex !== -1) {
        setCurrentQuestionIndex(nextUnansweredIndex);
      }
    }
  };

  const updateTheme = (theme) => {
    setDarkTheme(theme);
  };

  return (
    <div className={`${darkTheme ? "dark" : ""}`}>
      <div className=" bg-white w-full px-4 flex justify-center dark:bg-black  text-black dark:text-white">
        <div className="flex flex-col h-screen py-4">
          <div className="flex-1 overflow-y-auto">
            {!assessmentStarted ? (
              <Introduction onStart={handleStart} />
            ) : assessmentCompleted ? (
              <Results responses={userResponses} />
            ) : (
              <div className="w-full flex items-center justify-center">
                <div className="flex flex-col justify-center items-center">
                  {questions.map((question, index) => (
                    <Question
                      key={index}
                      question={question}
                      onResponse={(response) => handleResponse(index, response)}
                      selectedOption={selectedOptions[index]}
                    />
                  ))}
                </div>
              </div>
            )}
          </div>
          <div className="w-full">
            <Footer darkTheme={darkTheme} updateTheme={updateTheme} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PurposeAssessment;
