import React from "react";
import UserStatsCard from "./UserStats";
import ScorecardStatsCard from "./ScorecardStats";

export default function Dashboard() {
  return (
    <div className="p-6">
      <h1 className="text-2xl font-bold text-white mb-6">Dashboard</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <UserStatsCard />
        <ScorecardStatsCard />
      </div>
    </div>
  );
}
