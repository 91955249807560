// web/src/purpose-assessment/Results.js
import React from "react";

const categories = {
  Innovator: [1],
  Leader: [13],
  Creator: [6, 7],
  Contributor: [2, 10, 11],
  Explorer: [9, 14],
  // Add other categories and question indices as needed
};

const categoryStyles = {
  Innovator: "bg-blue-700",
  Leader: "bg-green-700",
  Creator: "bg-red-700",
  Contributor: "bg-yellow-700",
  Explorer: "bg-purple-700",
  // Add more styles for other categories
};

const Results = ({ responses }) => {
  const categories = {
    Innovator: {
      positive: [1, 3, 5],
      negative: [0, 2, 4],
    },
    Leader: {
      positive: [0, 2, 4],
      negative: [6, 8, 10],
    },
    Creator: {
      positive: [6, 8, 10],
      negative: [12, 14, 1],
    },
    Contributor: {
      positive: [12, 14, 1],
      negative: [3, 5, 7],
    },
    Explorer: {
      positive: [7, 9, 11],
      negative: [9, 11, 13],
    },
  };
  const calculateResult = () => {
    let scores = {
      Innovator: 0,
      Leader: 0,
      Creator: 0,
      Contributor: 0,
      Explorer: 0,
    };

    // Tally the scores for each category based on responses
    for (const [questionIndex, response] of Object.entries(responses)) {
      for (const [category, correlation] of Object.entries(categories)) {
        if (correlation.positive.includes(parseInt(questionIndex))) {
          scores[category] += response; // Add points for positive correlation
        }
        if (correlation.negative.includes(parseInt(questionIndex))) {
          scores[category] -= response; // Subtract points for negative correlation
        }
      }
    }

    // Determine the dominant category
    let maxScore = -Infinity;
    let dominantCategory = "";
    for (const [category, score] of Object.entries(scores)) {
      if (score > maxScore) {
        maxScore = score;
        dominantCategory = category;
      }
    }

    return dominantCategory;
  };

  const result = calculateResult();
  const resultStyle = categoryStyles[result];
  const shareResults = () => {
    const text = `My purpose persona is ${result}. Discover yours now!`;
    if (navigator.share) {
      navigator
        .share({
          title: "Purpose Assessment Results",
          text: text,
          url: window.location.href,
        })
        .then(() => console.log("Successful share"))
        .catch((error) => console.log("Error sharing:", error));
    } else {
      // Fallback for devices that don't support the Web Share API
      const dummy = document.createElement("input"),
        textArea = window.document.body.appendChild(dummy);
      textArea.value = text + " " + window.location.href;
      textArea.select();
      document.execCommand("copy");
      textArea.remove();
      alert("Results copied to clipboard!");
    }
  };

  return (
    <div
      className={`results ${resultStyle} min-h-screen flex items-center justify-center p-20`}
    >
      <div className="text-center text-white p-20 rounded-lg shadow-2xl max-w-2xl mx-auto">
        <h1 className="text-2xl font-normal">
          <div className="mb-4 opacity-80">Your purpose persona is...</div>
          <div className="text-6xl font-extrabold text-opacity-100">
            {result}
          </div>
        </h1>
        <div className="mb-8">
          <p className="text-2xl">
            {result === "Innovator" &&
              "You're a visionary who loves to explore new horizons and challenge the status quo."}
          </p>
          <p className="text-2xl">
            {result === "Leader" &&
              "You have a natural ability to inspire others and drive change."}
          </p>
          <p className="text-2xl">
            {result === "Creator" &&
              "Your creativity knows no bounds. You find joy in making things that resonate with others."}
          </p>
          <p className="text-2xl">
            {result === "Contributor" &&
              "You're happiest when you're helping others and contributing to a cause."}
          </p>
          <p className="text-2xl">
            {result === "Explorer" &&
              "Adventure calls to you. You're at your best when discovering the world."}
          </p>
          {/* Add more descriptions for other categories */}
        </div>
        <button
          onClick={shareResults}
          className="bg-white text-gray-800 font-bold rounded-full py-4 px-8 shadow-lg uppercase tracking-wider hover:bg-slate-100 transition ease-in-out duration-300"
        >
          SHARE YOUR RESULTS
        </button>
      </div>
    </div>
  );
};

export default Results;
