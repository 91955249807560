// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes fadeIn {
  from {
    background-color: transparent;
  }
  to {
    background-color: #ff8c22;
  }
}

.selected {
  animation: fadeIn 0.7s;
}

.checkmark {
  display: none;
  position: absolute;
  inset: 0;
  margin: auto; /* Centers the image */
}

input:checked + * .checkmark {
  display: block;
}
`, "",{"version":3,"sources":["webpack://./src/purpose-assessment/Question.css"],"names":[],"mappings":"AAAA;EACE;IACE,6BAA6B;EAC/B;EACA;IACE,yBAAyB;EAC3B;AACF;;AAEA;EACE,sBAAsB;AACxB;;AAEA;EACE,aAAa;EACb,kBAAkB;EAClB,QAAQ;EACR,YAAY,EAAE,sBAAsB;AACtC;;AAEA;EACE,cAAc;AAChB","sourcesContent":["@keyframes fadeIn {\n  from {\n    background-color: transparent;\n  }\n  to {\n    background-color: #ff8c22;\n  }\n}\n\n.selected {\n  animation: fadeIn 0.7s;\n}\n\n.checkmark {\n  display: none;\n  position: absolute;\n  inset: 0;\n  margin: auto; /* Centers the image */\n}\n\ninput:checked + * .checkmark {\n  display: block;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
