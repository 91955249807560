import React from "react";

export default function ScorecardDetails({ scorecard, onBack }) {
  const getAverageRating = (scorecard) => {
    const ratings = Object.values(scorecard.results).map((r) => r.rating);
    return ratings.reduce((a, b) => a + b, 0) / ratings.length;
  };

  const formatDate = (dateString) => {
    const date = new Date(dateString);
    return date.toLocaleString();
  };

  return (
    <div className="bg-slate-800 rounded-lg p-4">
      <div className="mb-4 flex relative items-center justify-between">
        <button
          onClick={onBack}
          className="bg-slate-700 text-white px-4 py-2 rounded-lg"
        >
          ← Back
        </button>
        <div className="flex-grow"></div>
        <div className="absolute right-4 top-4">
          <div className="text-white font-bold text-2xl">
            {Math.round(getAverageRating(scorecard))}%
          </div>
        </div>
      </div>

      <div className="mb-4">
        <div className="text-gray-400">User ID</div>
        <div className="text-white text-lg font-medium">
          {scorecard.user_id}
        </div>
      </div>

      <div className="mb-4">
        <div className="text-gray-400">Date</div>
        <div className="text-white">{formatDate(scorecard.timestamp)}</div>
      </div>

      <div className="space-y-6">
        {Object.entries(scorecard.results).map(([category, data]) => (
          <div key={category} className="bg-slate-700 rounded-lg p-4 relative">
            <div className="flex items-center justify-between mb-2">
              <div className="text-white font-medium">{category}</div>
              <div className="text-white font-bold text-xl">
                {Math.round(data.rating)}%
              </div>
            </div>
            <div className="space-y-4">
              {data.examples.map((example, index) => (
                <div
                  key={index}
                  className={`border-l-4 pl-4 ${
                    example.is_positive ? "border-green-500" : "border-red-500"
                  }`}
                >
                  <div className="text-gray-400">"{example.example}"</div>
                  <div className="text-white mt-1">{example.explanation}</div>
                  <div
                    className={`inline-block text-sm px-2 py-1 mt-2 rounded-full ${
                      example.is_positive
                        ? "bg-green-900 text-green-300"
                        : "bg-red-900 text-red-300"
                    }`}
                  >
                    {example.is_positive ? "Positive" : "Negative"}
                  </div>
                </div>
              ))}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}
