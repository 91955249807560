import React, { useState } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [gender, setGender] = useState("");

  const genders = [
    "Male",
    "Female",
    "Non-binary",
    "Prefer not to say",
    "Other",
  ];

  const handleGenderSelection = (selectedGender) => {
    setGender(selectedGender);
    onUpdate(selectedGender);
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        What is your gender?
      </p>
      <div className="flex flex-col w-full">
        {genders.map((genderOption) => (
          <button
            key={genderOption}
            onClick={() => handleGenderSelection(genderOption)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              gender === genderOption
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: gender === genderOption ? "0 0 2px #FF8C22" : "",
            }}
          >
            {genderOption}
          </button>
        ))}
      </div>
    </div>
  );
}
