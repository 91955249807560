import React, { useState, useEffect } from "react";
import axios from "axios";

function TouchpointHistory({ touchpointId, onClose }) {
  const [history, setHistory] = useState([]);
  const [touchpoint, setTouchpoint] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchHistory = async () => {
      try {
        setLoading(true);
        const response = await axios.get(
          `/api/touchpoints/${touchpointId}/history`
        );
        setHistory(response.data.history || []);
        setTouchpoint({
          type: response.data.type,
          modality: response.data.modality,
          status: response.data.status,
        });
        setLoading(false);
      } catch (err) {
        console.error("Error fetching touchpoint history:", err);
        setError("Failed to load history");
        setLoading(false);
      }
    };

    if (touchpointId) {
      fetchHistory();
    }
  }, [touchpointId]);

  // Format the modality for display
  const formatModality = (modality) => {
    switch (modality) {
      case "text":
        return "Text Message";
      case "voice_message":
        return "Voice Message";
      case "phone_call":
        return "Phone Call";
      case "video":
        return "Video Call";
      default:
        return modality?.replace("_", " ") || "";
    }
  };

  // Get icon for modality
  const getModalityIcon = (modality) => {
    switch (modality) {
      case "text":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path>
          </svg>
        );
      case "voice_message":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M12 1a3 3 0 0 0-3 3v8a3 3 0 0 0 6 0V4a3 3 0 0 0-3-3z"></path>
            <path d="M19 10v2a7 7 0 0 1-14 0v-2"></path>
            <line x1="12" y1="19" x2="12" y2="23"></line>
            <line x1="8" y1="23" x2="16" y2="23"></line>
          </svg>
        );
      case "phone_call":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <path d="M22 16.92v3a2 2 0 0 1-2.18 2 19.79 19.79 0 0 1-8.63-3.07 19.5 19.5 0 0 1-6-6 19.79 19.79 0 0 1-3.07-8.67A2 2 0 0 1 4.11 2h3a2 2 0 0 1 2 1.72 12.84 12.84 0 0 0 .7 2.81 2 2 0 0 1-.45 2.11L8.09 9.91a16 16 0 0 0 6 6l1.27-1.27a2 2 0 0 1 2.11-.45 12.84 12.84 0 0 0 2.81.7A2 2 0 0 1 22 16.92z"></path>
          </svg>
        );
      case "video":
        return (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="h-4 w-4"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <polygon points="23 7 16 12 23 17 23 7"></polygon>
            <rect x="1" y="5" width="15" height="14" rx="2" ry="2"></rect>
          </svg>
        );
      default:
        return null;
    }
  };

  // Get status badge
  const getStatusBadge = (status) => {
    const baseClasses = "text-xs px-2 py-0.5 rounded-full font-medium";
    if (status === "completed") {
      return `${baseClasses} bg-emerald-950 text-emerald-100 border border-emerald-700`;
    } else if (status === "cancelled") {
      return `${baseClasses} bg-zinc-800 text-zinc-300 border border-zinc-700`;
    } else {
      return `${baseClasses} bg-blue-950 text-blue-100 border border-blue-800`;
    }
  };

  if (loading) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-slate-900 rounded-lg max-w-2xl w-full p-6 flex items-center justify-center">
          <div className="animate-spin h-8 w-8 border-t-2 border-b-2 border-blue-500 rounded-full"></div>
        </div>
      </div>
    );
  }

  if (error) {
    return (
      <div className="fixed inset-0 bg-black bg-opacity-75 flex items-center justify-center z-50">
        <div className="bg-slate-900 rounded-lg max-w-2xl w-full p-6">
          <div className="flex justify-between items-center mb-4">
            <h3 className="text-xl font-semibold text-white">Error</h3>
            <button
              onClick={onClose}
              className="p-1 hover:bg-slate-800 rounded-full text-white"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M6 18L18 6M6 6l12 12"
                />
              </svg>
            </button>
          </div>
          <div className="p-4 border border-red-800 bg-red-950 rounded-lg text-red-100">
            <div className="flex items-center">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
                strokeLinecap="round"
                strokeLinejoin="round"
              >
                <circle cx="12" cy="12" r="10"></circle>
                <line x1="12" y1="8" x2="12" y2="12"></line>
                <line x1="12" y1="16" x2="12.01" y2="16"></line>
              </svg>
              {error}
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="fixed inset-0 bg-black bg-opacity-80 flex items-center justify-center z-50 backdrop-blur-sm">
      <div className="bg-slate-900 rounded-lg max-w-3xl w-full max-h-[85vh] overflow-hidden shadow-xl">
        <div className="border-b border-slate-800 px-6 py-4 flex justify-between items-center">
          <div className="flex items-center">
            <h3 className="text-xl font-medium text-white">
              Execution History
            </h3>
            {touchpoint && (
              <div className="flex items-center ml-3 space-x-2">
                <span className={getStatusBadge(touchpoint.status)}>
                  {touchpoint.status || "Active"}
                </span>
                {touchpoint.modality && (
                  <div className="flex items-center text-slate-400 text-xs px-2 py-0.5 rounded-full bg-slate-800 border border-slate-700">
                    {getModalityIcon(touchpoint.modality)}
                    <span className="ml-1.5">
                      {formatModality(touchpoint.modality)}
                    </span>
                  </div>
                )}
              </div>
            )}
          </div>
          <button
            onClick={onClose}
            className="p-1 hover:bg-slate-800 rounded-full text-white"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth={2}
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>

        <div className="p-6 overflow-y-auto max-h-[calc(85vh-4rem)]">
          {history.length === 0 ? (
            <div className="text-center py-8">
              <div className="inline-flex h-12 w-12 items-center justify-center rounded-full bg-slate-950 border border-slate-800 mb-4">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-6 w-6 text-slate-400"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={2}
                    d="M12 8v4m0 4h.01M21 12a9 9 0 11-18 0 9 9 0 0118 0z"
                  />
                </svg>
              </div>
              <h3 className="text-lg font-medium text-white mb-2">
                No execution history
              </h3>
              <p className="text-slate-400 max-w-md mx-auto">
                This touchpoint hasn't been executed yet or its history is not
                available.
              </p>
            </div>
          ) : (
            <div className="relative">
              {/* Timeline line */}
              <div className="absolute left-4 top-6 bottom-10 w-0.5 bg-slate-800"></div>

              <div className="space-y-6">
                {history.map((entry, index) => (
                  <div key={index} className="relative pl-12 pb-2">
                    {/* Timeline dot */}
                    <div
                      className={`absolute left-2 top-1.5 w-5 h-5 rounded-full border-2 ${
                        entry.success
                          ? "border-green-500 bg-green-900"
                          : "border-red-500 bg-red-900"
                      } z-10 flex items-center justify-center`}
                    >
                      {entry.success ? (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3 text-green-200"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M16.707 5.293a1 1 0 010 1.414l-8 8a1 1 0 01-1.414 0l-4-4a1 1 0 011.414-1.414L8 12.586l7.293-7.293a1 1 0 011.414 0z"
                            clipRule="evenodd"
                          />
                        </svg>
                      ) : (
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          className="h-3 w-3 text-red-200"
                          viewBox="0 0 20 20"
                          fill="currentColor"
                        >
                          <path
                            fillRule="evenodd"
                            d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                            clipRule="evenodd"
                          />
                        </svg>
                      )}
                    </div>

                    <div className="bg-slate-800 rounded-lg overflow-hidden">
                      <div className="px-4 py-3 bg-slate-850 border-b border-slate-700 flex justify-between items-center">
                        <span className="text-sm text-slate-300 font-medium">
                          {new Date(entry.executed_at).toLocaleString()}
                        </span>
                        <span
                          className={`text-xs px-2 py-0.5 rounded ${
                            entry.success
                              ? "bg-green-900 text-green-100"
                              : "bg-red-900 text-red-100"
                          }`}
                        >
                          {entry.success ? "Success" : "Failed"}
                        </span>
                      </div>

                      {entry.content && (
                        <div className="p-4 text-sm text-slate-300 bg-slate-850">
                          <div className="whitespace-pre-wrap">
                            {entry.content}
                          </div>
                        </div>
                      )}

                      {entry.executed_by && (
                        <div className="px-4 py-2 text-xs text-slate-400 bg-slate-900">
                          Executed by:{" "}
                          <span className="font-medium">
                            {entry.executed_by}
                          </span>
                        </div>
                      )}
                    </div>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="border-t border-slate-800 px-6 py-4 flex justify-end">
          <button
            onClick={onClose}
            className="px-4 py-2 border border-slate-700 text-white hover:bg-slate-800 rounded-md text-sm font-medium transition-colors"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  );
}

export default TouchpointHistory;
