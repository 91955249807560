import React, { useState } from "react";
import Cookies from "js-cookie";

const SignInButton = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const handleSignOutClick = () => {
    setIsModalOpen(true);
  };

  const handleSignOutConfirm = () => {
    // Remove the relevant cookies
    Cookies.remove("1440_access_token");
    Cookies.remove("1440_web_id");
    Cookies.remove("1440_mobile_id");
    // Reload the page to reflect the changes
    window.location.reload();
  };

  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleBackdropClick = (event) => {
    if (event.target.id === "modalBackdrop") {
      handleModalClose();
    }
  };

  return (
    <div>
      <div
        onClick={handleSignOutClick}
        className="flex items-center text-white py-2 px-3 border border-darkBlue rounded transition duration-300 hover:bg-slate-800 cursor-pointer"
      >
        <span className="text-md font-medium">Sign out</span>
        <img
          src="/images/signout.png"
          alt="Sign out"
          className="h-5 w-5 ml-2 cursor-pointer rotate-180 hover:text-white"
        />
      </div>
      {isModalOpen && (
        <div
          id="modalBackdrop"
          onClick={handleBackdropClick}
          className="fixed inset-0 z-50 bg-black bg-opacity-70 flex justify-center items-center"
        >
          <div
            className="bg-slate-800 rounded-lg shadow-xl p-8 max-w-md w-full"
            onClick={(e) => e.stopPropagation()}
          >
            <h2 className="text-2xl font-medium mb-6 text-slate-100">
              Are you sure you want to sign out?
            </h2>
            <div className="flex justify-end space-x-4">
              <button
                onClick={handleModalClose}
                className="px-4 py-2 text-sm font-medium text-slate-200 bg-slate-700 rounded-md hover:bg-slate-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-slate-500"
              >
                Cancel
              </button>
              <button
                onClick={handleSignOutConfirm}
                className="px-4 py-2 text-sm font-medium text-white bg-red-700 rounded-md hover:bg-red-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500"
              >
                Sign out
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default SignInButton;
