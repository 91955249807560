import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Footer.css";

const Toggle = ({ isDarkMode, onToggle }) => (
  <div onClick={onToggle} className={`toggle ${isDarkMode ? "dark" : "light"}`}>
    <div className="circle"></div>
  </div>
);

const Footer = ({ darkTheme, updateTheme }) => {
  const [timePassed, setTimePassed] = useState(0);

  useEffect(() => {
    const getTimePassed = () => {
      const now = new Date();
      const minutesPassed = now.getHours() * 60 + now.getMinutes();
      return minutesPassed / 1440;
    };

    const setNextTimeout = () => {
      const now = new Date();
      const nextMinute = new Date(now);
      nextMinute.setMinutes(now.getMinutes() + 1);
      nextMinute.setSeconds(0);
      nextMinute.setMilliseconds(0);
      const delay = nextMinute.getTime() - now.getTime();

      setTimeout(() => {
        const time = getTimePassed();
        setTimePassed(time);
        setNextTimeout();
      }, delay);
    };

    setNextTimeout();
    const time = getTimePassed();
    setTimePassed(time);
  }, []);

  const toggleTheme = () => {
    updateTheme(!darkTheme);
  };

  return (
    <footer className="text-left font-normal z-10">
      <div className="flex">
        <hr
          className="progress-bar border-t-2 border-accent mt-2 mb-3"
          style={{ width: `${timePassed * 100}%` }}
        />
        <hr
          className="progress-bar border-t-2 border-accent opacity-30 mt-2 mb-3"
          style={{ width: `${(1 - timePassed) * 100}%` }}
        />
      </div>
      <div className="flex flex-row justify-between text-xs">
        <nav className="">
          <ul className="flex mb-0 flex-row list-none p-0">
            <li className="mr-8 text-xs">
              <Link to="/about">about</Link>
            </li>
            <li className="mr-8 text-xs">
              <Link to="/science">science</Link>
            </li>
            <li className="mr-8 text-xs">
              <Link to="/privacy-policy">privacy</Link>
            </li>
            <li className="mr-8 text-xs hidden sm:block">
              <Link to="/purpose">discover your purpose</Link>
            </li>
          </ul>
        </nav>
        <li className="text-xs flex align-center">
          <Toggle isDarkMode={darkTheme} onToggle={toggleTheme} />
          <span>{darkTheme ? "dark mode" : "light mode"}</span>
        </li>
      </div>
    </footer>
  );
};

export default Footer;
