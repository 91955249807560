import React, { useState, useRef, useEffect } from "react";
import axios from "axios";
import Layout from "../Layout";
import ProtocolRoadmap from "./ProtocolRoadmap";
import { ArrowLeftIcon } from "@heroicons/react/24/outline";
import EditProtocolField from "./EditProtocolField";
import EditTask from "./EditTask";
import EditStringList from "./EditStringList"; // Import the new component
import EditCommaSeparatedList from "./EditCommaSeparatedList";
import "react-phone-number-input/style.css";
import PromptEditor from "./PromptEditor";

const DEFAULT_PROMPT_TEMPLATE = `# {coach_name}{personality}
{meta}

# The client
{memory}{structured_memory}{base_health}{calendar}{human_coach_context}{summaries}{reflect}

{active_module_context}

# {messages}{info}

# Your task is to respond to their latest message
## You will be rewarded for the following: 
1) Your response shows a deep understanding of what has been said so far. 2) You recognize their underlying emotions and react to their cues, no matter how subtle. 3) You adjusts formality and vocabulary to align with the user's style. 4) You sometimes use micro-commitments. 5) You recognize when to slow down, change the conversation topic or modify your approach if the user seems hesitant or disengaged. 6) Your response style and length matches the user's messages (you should often have short responses). 7) You end your message after a question mark without appending anything afterwards.
## You will be penalized for the following: 
1) You ask multiple questions. 2) Your sentence structure is the same as your previous messages. 3) You overuse the user's name. 4) You overuse emojis. 5) You either append example responses or explain why you asked AFTER your question instead of ending the message on the question. 7) You speak like an AI (overly affirmative, positively reinforcing, subservient and agreeable) rather than a personal coach (understanding, assertive and contextually aware).
{paywall}

## Now, respond to the latest message with no text before or after, in plain text, never markdown, '{last_message}':
`;

const EditProtocol = ({
  protocol,
  onClose,
  updateLocalProtocol,
  isUserProtocol = "",
}) => {
  // Initialize protocol data from props
  const [protocolData, setProtocolData] = useState(
    Array.isArray(protocol) ? protocol : [protocol]
  );
  const [selectedProtocolIndex, setSelectedProtocolIndex] = useState(0);
  const updateTimeoutId = useRef(null);
  const lastProtocolValue = useRef(null);

  // Remove empty useEffect and move functions outside
  const handleTaskUpdate = (updatedTask) => {
    const updatedtouchpoints = protocolData[
      selectedProtocolIndex
    ]?.touchpoints?.map((task) =>
      task.touchpoint_id === updatedTask.touchpoint_id ? updatedTask : task
    );
    handleProtocolChange("touchpoints", updatedtouchpoints);
  };

  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };

  const handleAddTask = () => {
    const newTask = {
      description: "",
      execute_at: new Date().toISOString(),
      type: "one-time",
      touchpoint_id: Date.now().toString(), // Simple unique ID generator for example purposes
    };
    const updatedtouchpoints = [
      ...protocolData[selectedProtocolIndex].touchpoints,
      newTask,
    ];
    handleProtocolChange("touchpoints", updatedtouchpoints);
  };

  // Function to remove a task by touchpoint_id
  const handleRemoveTask = (taskId) => {
    const updatedtouchpoints = protocolData[
      selectedProtocolIndex
    ]?.touchpoints?.filter((task) => task.touchpoint_id !== taskId);
    handleProtocolChange("touchpoints", updatedtouchpoints);
  };

  // This function now handles all updates to the protocol
  const handleProtocolChange = (key, value) => {
    const updatedProtocolData = [...protocolData];
    updatedProtocolData[selectedProtocolIndex] = {
      ...updatedProtocolData[selectedProtocolIndex],
      [key]: value,
    };
    setProtocolData(updatedProtocolData); // Update local state

    // Clear the previous timeout
    if (updateTimeoutId.current) {
      clearTimeout(updateTimeoutId.current);
    }

    // Set the last value to compare after the timeout
    lastProtocolValue.current = value;

    // Set a new timeout
    updateTimeoutId.current = setTimeout(() => {
      // Check if the value is still the same
      if (lastProtocolValue.current === value) {
        if (isUserProtocol) {
          updateUserProtocol(updatedProtocolData[selectedProtocolIndex]);
        } else {
          updateProtocol(updatedProtocolData[selectedProtocolIndex]);
        }
      }
    }, 1800); // 3 seconds timeout
  };

  // Debounced function to update the protocol on the server
  const updateUserProtocol = (updatedProtocol) => {
    axiosWithAuth()
      .post("/user_protocol/update/", {
        protocol_data: updatedProtocol,
        user_id: isUserProtocol,
      })
      .then((response) => {
        if (response.status === 200) {
          updateLocalProtocol(updatedProtocol); // This updates the protocol in the parent component's state
        }
      })
      .catch((error) => {
        console.error("Error updating protocol:", error);
      });
  };

  const updateProtocol = (updatedProtocol) => {
    axiosWithAuth()
      .post("/protocol/update", updatedProtocol)
      .then((response) => {
        if (response.status === 200) {
          updateLocalProtocol(updatedProtocol);
        }
      })
      .catch((error) => {
        console.error("Error updating protocol:", error);
      });
  };

  const renderProtocolEditor = (protocol, index) => {
    return (
      <div key={protocol._id || index}>
        <div className="mb-12">
          <h2 className="text-xl font-semibold text-slate-700 mb-2">
            Prompt Blueprint
          </h2>
          <p className="text-gray-500 text-sm w-2/3 mb-4">
            Design how your AI coach thinks and responds by crafting its
            conversation blueprint. Use dynamic variables from the sidebar to
            create context-aware, personalized interactions.
          </p>
          <div className="h-[600px] border border-gray-200 rounded-xl overflow-hidden shadow-sm">
            <PromptEditor
              value={
                protocolData[selectedProtocolIndex]?.prompt_blueprint ||
                DEFAULT_PROMPT_TEMPLATE
              }
              onChange={(value) =>
                handleProtocolChange("prompt_blueprint", value)
              }
            />
          </div>
        </div>

        <h2 className="text-xl font-medium mb-1 text-slate-700">Roadmap</h2>
        <p className="text-slate-400 text-sm w-2/3">
          The underlying journey, that will guide the AI in what conversations
          to have, at what stages. This is not completely linear, but an outline
          that will inform the types of things to bring up next.
        </p>
        <ProtocolRoadmap
          initialModules={protocol?.modules || []}
          onUpdate={(modules) => handleProtocolChange("modules", modules)}
        />
        <div className="mt-12">
          <h1 className="text-xl font-semibold text-slate-700 mb-6">
            {isUserProtocol
              ? "Edit user's protocol fields"
              : "Edit protocol fields"}
          </h1>
          {fields.map((field) => {
            if (field.render) {
              return (
                <div key={field.key} className="mb-6">
                  {field.render(field)}
                </div>
              );
            }
            if (field.key === "first_message") {
              return (
                <div key={field.key} className="mb-4">
                  <h2 className="text-lg font-medium text-slate-700 mb-2">
                    {field.title}
                  </h2>
                  <p className="text-sm text-gray-500 mb-2">
                    {field.description}
                  </p>
                  <EditStringList
                    items={field.value}
                    onChange={(newItems) =>
                      handleProtocolChange(field.key, newItems)
                    }
                  />
                </div>
              );
            }
            if (field.key === "phone") {
              return (
                <div key={field.key} className="mb-4">
                  <h2 className="text-lg font-medium text-slate-700 mb-2">
                    {field.title}
                  </h2>
                  <p className="text-sm text-gray-500 mb-2">
                    {field.description}
                  </p>
                  <EditCommaSeparatedList
                    value={field.value}
                    onChange={(value) => handleProtocolChange(field.key, value)}
                  />
                </div>
              );
            }
            return (
              <EditProtocolField
                key={field.key}
                title={field.title}
                description={field.description}
                placeholder={field.placeholder}
                value={field.value}
                onChange={(e) =>
                  handleProtocolChange(field.key, e.target.value)
                }
              />
            );
          })}
        </div>
        <div className="mt-12">
          <h1 className="text-lg font-medium text-slate-700 mb-2">
            {isUserProtocol ? "Edit touchpoints" : "Edit touchpoints"}
          </h1>
          <p className="text-slate-400 text-sm w-2/3">
            {isUserProtocol
              ? "If the user's phone number is synced, 1440 will proactively send messages based on the description and conversational context."
              : "Define touchpoints that the AI should perform at specific times."}
          </p>
          {protocol?.touchpoints &&
            protocol?.touchpoints?.map((task) => (
              <div key={task.touchpoint_id} className="mb-4">
                <EditTask
                  task={task}
                  onUpdate={handleTaskUpdate}
                  handleRemove={() => handleRemoveTask(task.touchpoint_id)}
                  isUserTask={isUserProtocol}
                />
              </div>
            ))}
          <button
            onClick={handleAddTask}
            className="mt-4 bg-slate-200 text-slate-500 py-2 px-4 rounded hover:bg-slate-300 mb-8"
          >
            + Add {isUserProtocol ? "touchpoint" : "touchpoint"}
          </button>
        </div>
      </div>
    );
  };

  const fields = [
    {
      key: "title",
      title: "Title",
      description: "Enter the title for the protocol.",
      placeholder: "Enter title here...",
      value: protocolData[selectedProtocolIndex]?.title || "",
    },
    {
      key: "phone",
      title: "Phone number",
      description:
        "The 1440-owned phone number that is associated with this protocol in the format of '+11234567890'.",
      placeholder: "Phone number",
      value: protocolData[selectedProtocolIndex]?.phone || "",
    },
    {
      key: "personality",
      title: "Personality",
      description: "Customize the personality for the conversation.",
      placeholder: "Describe the personality...",
      value: protocolData[selectedProtocolIndex]?.personality || "",
    },
    {
      key: "coach_name",
      title: "Coach name",
      description: "Customize the coaches name for the conversation.",
      placeholder: "Coach name...",
      value: protocolData[selectedProtocolIndex]?.coach_name || "",
    },
    {
      key: "topic_writing_guide",
      title: "Topic Writing Guide",
      description:
        "At every conversational shift of topic, we generate a guide based on context, and the new topic, about how the coach should approach this new topic. This is the prompt used to generate that guide.",
      placeholder: "How should the coach address each topic?",
      value: protocolData[selectedProtocolIndex]?.topic_writing_guide || "",
    },
    {
      key: "first_message",
      title: "First Message",
      description:
        "The first message(s) that the client will receive after writing to the coach for the first time.",
      placeholder: "Enter the first message(s)...",
      value: protocolData[selectedProtocolIndex]?.first_message || [],
    },
  ];

  if (isUserProtocol) {
    return (
      <div className="mt-4">
        <div className="flex gap-2 mb-4">
          {protocolData.map((protocol, index) => (
            <button
              key={protocol._id || index}
              className={`py-2 px-4 rounded-lg ${
                index === selectedProtocolIndex
                  ? "bg-blue-500 text-white"
                  : "bg-slate-200 text-slate-500"
              }`}
              onClick={() => setSelectedProtocolIndex(index)}
            >
              {protocol.title || `Protocol ${index + 1}`}
            </button>
          ))}
        </div>
        {protocolData.map((protocol, index) => {
          return (
            index === selectedProtocolIndex &&
            renderProtocolEditor(protocol, index)
          );
        })}
      </div>
    );
  } else {
    return (
      <Layout
        title={protocolData[selectedProtocolIndex]?.title}
        rightElement={
          <button
            onClick={onClose}
            className="flex items-center bg-slate-200 py-2 px-3 rounded-lg text-slate-600"
          >
            <ArrowLeftIcon className="h-5 w-5 mr-2" />
            All protocols
          </button>
        }
      >
        {protocolData.map((protocol, index) => {
          return (
            index === selectedProtocolIndex &&
            renderProtocolEditor(protocol, index)
          );
        })}
      </Layout>
    );
  }
};

export default EditProtocol;
