import React, { useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";

const CoachSignInButton = ({ buttonText }) => {
  const { loginWithRedirect } = useAuth0();
  const { isLoading } = useAuth0();

  if (isLoading) {
    return (
      <span className="text-base text-white cursor-pointer">Loading...</span>
    ); // Replace with your custom loading component
  }

  return (
    <span
      onClick={() => loginWithRedirect()}
      className="text-base text-white cursor-pointer hover:text-gray-400"
    >
      {buttonText}
    </span>
  );
};

export default CoachSignInButton;
