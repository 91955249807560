import React, { useState } from "react";
import { PROMPT_VARIABLES, VARIABLE_CATEGORIES } from "./PromptVariables";
import { CATEGORY_COLORS } from "./constants";
import {
  ChevronRightIcon,
  ChevronDownIcon,
  MagnifyingGlassIcon,
  Cog6ToothIcon,
} from "@heroicons/react/24/outline";

const ConfigurationModal = ({ variable, onClose, onSave }) => {
  const [value, setValue] = useState(variable.configuration.value);
  const [enabled, setEnabled] = useState(variable.enabled);

  return (
    <div className="fixed inset-0 bg-black/50 flex items-center justify-center z-50">
      <div className="bg-white rounded-xl shadow-xl w-[600px] max-h-[80vh] flex flex-col">
        <div className="p-6 border-b border-gray-200 flex items-center justify-between">
          <div>
            <h2 className="text-xl font-semibold text-gray-900">
              {variable.name}
            </h2>
            <p className="text-sm text-gray-500 mt-1">{variable.description}</p>
          </div>
          <div className="flex items-center gap-2">
            <span className="text-sm text-gray-600">Enabled</span>
            <button
              onClick={() => setEnabled(!enabled)}
              className={`relative inline-flex h-5 w-9 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none ${
                enabled ? "bg-blue-600" : "bg-gray-200"
              }`}
            >
              <span
                className={`inline-block h-4 w-4 transform rounded-full bg-white transition duration-200 ease-in-out ${
                  enabled ? "translate-x-4" : "translate-x-0"
                }`}
              />
            </button>
          </div>
        </div>

        <div className="p-6 flex-1 overflow-y-auto space-y-6">
          {variable.configuration.type !== "readonly" && (
            <div>
              <h3 className="text-sm font-medium text-gray-900 mb-2">
                Configuration
              </h3>
              {variable.configuration.type === "markdown" && (
                <textarea
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="w-full h-32 p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Enter markdown content..."
                />
              )}
              {variable.configuration.type === "text" && (
                <input
                  type="text"
                  value={value}
                  onChange={(e) => setValue(e.target.value)}
                  className="w-full p-3 border border-gray-200 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Enter text..."
                />
              )}
              {variable.configuration.type === "json" && (
                <textarea
                  value={
                    typeof value === "object"
                      ? JSON.stringify(value, null, 2)
                      : value
                  }
                  onChange={(e) => setValue(e.target.value)}
                  className="w-full h-32 p-3 border border-gray-200 rounded-lg font-mono focus:ring-2 focus:ring-blue-500 focus:border-transparent"
                  placeholder="Enter JSON..."
                />
              )}
            </div>
          )}

          <div>
            <div className="flex items-center justify-between mb-2">
              <h3 className="text-sm font-medium text-gray-900">
                Example Output
              </h3>
            </div>
            <div className="bg-gray-50 rounded-lg p-4 font-mono text-sm text-gray-600">
              {variable.example_content || "[No example content]"}
            </div>
          </div>
        </div>

        <div className="p-4 border-t border-gray-200 bg-gray-50 rounded-b-xl flex justify-end gap-2">
          <button
            onClick={onClose}
            className="px-4 py-2 text-sm font-medium text-gray-700 hover:text-gray-900 hover:bg-gray-100 rounded-lg transition-colors"
          >
            Cancel
          </button>
          <button
            onClick={() => {
              onSave(value, enabled);
              onClose();
            }}
            className="px-4 py-2 text-sm font-medium text-white bg-blue-600 hover:bg-blue-700 rounded-lg transition-colors"
          >
            Save Changes
          </button>
        </div>
      </div>
    </div>
  );
};

const VariableItem = ({ variableKey, variable, onInsert, onConfigure }) => {
  const colors = CATEGORY_COLORS[variable.category];
  const Icon = variable.icon;
  const [showModal, setShowModal] = useState(false);

  return (
    <>
      <div
        className={`group flex items-center gap-3 p-3 ${colors.hover} rounded-lg cursor-pointer`}
        onClick={() => onInsert(variableKey)}
      >
        <div
          className={`flex-shrink-0 w-10 h-10 rounded-xl ${colors.bg} flex items-center justify-center`}
        >
          <Icon className={`h-5 w-5 ${colors.text}`} />
        </div>
        <div className="flex-1 min-w-0">
          <div className="text-sm font-medium text-gray-900">
            {variable.name}
          </div>
          <div className="text-xs text-gray-500 truncate">
            {variable.description}
          </div>
        </div>
        {variable.configuration &&
          variable.configuration.type !== "readonly" && (
            <button
              onClick={(e) => {
                e.stopPropagation();
                setShowModal(true);
              }}
              className="opacity-0 group-hover:opacity-100 p-1.5 rounded-lg bg-white shadow-sm border border-gray-200 hover:bg-gray-50 transition-opacity"
            >
              <Cog6ToothIcon className="w-4 h-4 text-gray-400" />
            </button>
          )}
      </div>
      {showModal && (
        <ConfigurationModal
          variable={variable}
          onClose={() => setShowModal(false)}
          onSave={(value, enabled) => {
            onConfigure(variableKey, value, enabled);
          }}
        />
      )}
    </>
  );
};

const CategorySection = ({ category, variables, onInsert, onConfigure }) => {
  const [isExpanded, setIsExpanded] = useState(true);
  const categoryVariables = Object.entries(variables).filter(
    ([_, v]) => v.category === category
  );
  const colors = CATEGORY_COLORS[category];

  if (categoryVariables.length === 0) return null;

  return (
    <div className="mb-4">
      <div className="sticky top-0 px-4 py-2.5 bg-white/90 backdrop-blur-sm border-b">
        <div className="flex items-center gap-2">
          <button
            onClick={() => setIsExpanded(!isExpanded)}
            className={colors.text}
          >
            {isExpanded ? (
              <ChevronDownIcon className="h-4 w-4" />
            ) : (
              <ChevronRightIcon className="h-4 w-4" />
            )}
          </button>
          <h3
            className={`text-xs font-semibold mb-0 uppercase tracking-wider ${colors.text}`}
          >
            {category}
            <span className="ml-1 text-gray-400">
              ({categoryVariables.length})
            </span>
          </h3>
        </div>
      </div>

      {isExpanded && (
        <div className="space-y-1 mt-1">
          {categoryVariables.map(([key, variable]) => (
            <VariableItem
              key={key}
              variableKey={key}
              variable={variable}
              onInsert={onInsert}
              onConfigure={onConfigure}
            />
          ))}
        </div>
      )}
    </div>
  );
};

export default function PromptVariableSidebar({ onInsert, onConfigure }) {
  const [searchQuery, setSearchQuery] = useState("");

  const filteredVariables = searchQuery
    ? Object.fromEntries(
        Object.entries(PROMPT_VARIABLES).filter(
          ([key, variable]) =>
            variable.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
            variable.description
              .toLowerCase()
              .includes(searchQuery.toLowerCase()) ||
            key.toLowerCase().includes(searchQuery.toLowerCase())
        )
      )
    : PROMPT_VARIABLES;

  return (
    <div className="w-80 bg-white border-r border-gray-200 h-full flex flex-col">
      <div className="p-4 border-b border-gray-200">
        <div className="relative">
          <input
            type="text"
            placeholder="Search variables..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="w-full bg-gray-50 text-gray-900 placeholder-gray-400 rounded-lg pl-10 pr-4 py-2 text-sm border border-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          />
          <MagnifyingGlassIcon className="absolute left-3 top-2.5 h-4 w-4 text-gray-400" />
        </div>
      </div>

      <div className="flex-1 overflow-y-auto">
        {VARIABLE_CATEGORIES.map((category) => (
          <CategorySection
            key={category}
            category={category}
            variables={filteredVariables}
            onInsert={onInsert}
            onConfigure={onConfigure}
          />
        ))}
      </div>
    </div>
  );
}
