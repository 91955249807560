import React, { useState } from "react";
import Button from "./Button";
import { Bars3BottomRightIcon, XMarkIcon } from "@heroicons/react/24/outline";
import CoachSignInButton from "../CoachSignInButton";

const Header = () => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <header className="bg-black text-white">
      <div className="container mx-auto flex justify-between items-center">
        <img src="/images/logo-white.png" alt="Logo" className="h-8" />
        <nav className="hidden md:flex font-light space-x-6">
          <a
            href="https://wa.me/+16203372653?text=Hello"
            className="hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            WHATSAPP
          </a>
          <a
            href="sms:+16203372653"
            className="hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            SMS
          </a>
          <CoachSignInButton
            buttonText={"COACH SIGN-IN"}
            className="hover:text-gray-400"
          />
        </nav>
        <Button>TRY THE COACH LIVE</Button>
        <div className="md:hidden">
          <button onClick={() => setIsOpen(!isOpen)}>
            {isOpen ? (
              <XMarkIcon className="h-6 w-6" />
            ) : (
              <Bars3BottomRightIcon className="h-6 w-6" />
            )}
          </button>
        </div>
      </div>
      {isOpen && (
        <nav className="md:hidden bg-black text-white py-4">
          <a
            href="https://wa.me/+16203372653?text=Hello"
            className="block py-2 hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            WHATSAPP
          </a>
          <a
            href="sms:+16203372653"
            className="block py-2 hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            SMS
          </a>
          <a
            href="https://m.me/100093207430260?text=Hello"
            className="block py-2 hover:text-gray-400"
            target="_blank"
            rel="noopener noreferrer"
          >
            MESSENGER
          </a>
          <div className="mt-2"></div>
          <CoachSignInButton
            buttonText={"COACH SIGN-IN"}
            className="block mt-4 py-2"
          />
        </nav>
      )}
    </header>
  );
};

export default Header;
