import React, { useEffect } from "react";
import WaitlistSignup from "../ui/WaitlistSignup";
import Footer from "../footer/Footer";
import { useNavigate } from "react-router-dom";
import Mixpanel from "mixpanel-browser";

const StaticLanding = ({ userId = null }) => {
  useEffect(() => {
    // Initialize Mixpanel with your project token
    Mixpanel.init("d724d0d0045430023cc58dd17086256b", {
      track_pageview: true,
    });
    Mixpanel.identify(userId);
    Mixpanel.track("Page View", { page: "about" });
  }, []);

  const navigate = useNavigate();
  return (
    <div className="bg-slate-950 text-gray-300 min-h-screen px-8 flex flex-col items-center">
      <div className="container py-12 max-w-2xl">
        <button
          onClick={() => navigate("/")}
          className="flex items-center justify-center text-accent opacity-50 hover:opacity-100 hover:font-bold"
        >
          <img src="/images/submit.png" className="h-5 rotate-180 w-5 mr-2" />
          Back
        </button>
        <div className="flex justify-center items-center gap-16 flex-col">
          <div className="cursor-pointer" onClick={() => navigate("/")}>
            <img
              src="/images/logo-white.png"
              alt="1440 logo"
              className="w-28 brightness-100"
            />
          </div>
          <header className="mb-12">
            <h2 className="text-5xl text-center mb-2 leading-snug opacity-90">
              <span className="text-transparent bg-clip-text bg-gradient-to-r from-accent to-darkBlue">
                Your Time<br className="md:hidden"></br>
                <span className="hidden mx-2 md:inline-block">—</span>
                Reimagined
              </span>
            </h2>
          </header>
        </div>
        <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
          In 2020, we, a group of neuroscientists, AI experts, and serial
          entrepreneurs, started a special project. We dove into the science of
          time perception and purpose, aiming to develop a technology that would
          change how people perceive and spend their time. Our vision was to
          move away from the conventional calendar view and create something
          that inspires and brings purpose to every minute.
        </p>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            Our research led us to an important realization: living with purpose
            and intention requires practical elements like accountability,
            coaching, and expert guidance. This insight was pivotal in shaping
            our approach. It motivated us to build a world-class AI system,
            designed not just for managing schedules but for being a
            conversational partner that provides guidance and holds users
            accountable, helping them live each day with intention.
          </p>
        </section>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            The development of our app at 1440 was the result of this
            exploration. It's an embodiment of our understanding and research.
            The AI system at its heart assesses your purpose and works alongside
            a deep understanding of you, amplified by connectedness to health
            and calendar data. It guides you in reflecting and holds you
            accountable. We built it with the best science has to offer in
            fields like behavior change, chronobiology, and effective coaching
            and therapy, ensuring it is both innovative and deeply supportive.
          </p>
        </section>
        <section className="mb-8">
          <p className="text-lg md:text-2xl leading-relaxed md:leading-10 text-left">
            Joining 1440 means more than just using an app. It's about being
            part of a movement that values each of the 1,440 minutes in a day.
            It's a commitment to live differently, to control your time, and to
            infuse every moment with purpose and intention, with the support of
            our technology.
          </p>
        </section>
        <WaitlistSignup />
        <div className="mt-8">
          <Footer updateTheme={() => {}} />
        </div>
      </div>
    </div>
  );
};

export default StaticLanding;
