import React, { useState } from "react";
import GoogleCalendarButton from "./GoogleCalendarButton";
import CoachDropdown from "./CoachDropdown";
import MobileMenu from "./MobileMenu";
import ToggleVoice from "./ToggleVoice";
import SignInButton from "./SignInButton";
import {
  ChatBubbleLeftIcon,
  HomeIcon,
  ArrowPathIcon,
} from "@heroicons/react/24/outline";

const Navbar = ({ userId = null, onToggleView, view }) => {
  const [isDesktopMenuOpen, setIsDesktopMenuOpen] = useState(false);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  // Function to handle desktop menu toggle
  const toggleDesktopMenu = () => {
    setIsDesktopMenuOpen(!isDesktopMenuOpen);
  };

  // Function to handle mobile menu toggle
  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  // Function to handle view selection
  const handleViewSelection = (value) => {
    onToggleView(value);
    setIsDesktopMenuOpen(false);
    setIsMobileMenuOpen(false);
  };

  return (
    <>
      <nav className="max-w-4xl mx-auto flex justify-between items-center bg-light py-6 text-black dark:text-white dark:bg-dark">
        <div
          className="cursor-pointer"
          onClick={() => handleViewSelection("Dashboard")}
        >
          <img
            src="/images/logo-white.png"
            alt="Logo"
            className="h-8 w-auto brightness-0 dark:brightness-100"
          />
        </div>

        {/* Mobile menu */}
        <div className="md:hidden flex items-center space-x-4">
          <div
            className="relative"
            style={{ position: "relative", zIndex: 50 }}
          >
            <button
              onClick={toggleDesktopMenu}
              className="toggle-view-button border border-darkBlue hover:bg-slate-800 text-white font-medium py-2 px-3 rounded inline-flex items-center transition duration-300 ease-in-out"
              aria-haspopup="true"
              aria-expanded={isDesktopMenuOpen ? "true" : "false"}
              aria-label="Toggle desktop menu"
            >
              <ArrowPathIcon className="w-5 h-5 mr-2" />
              {view}
            </button>
            {isDesktopMenuOpen && (
              <div className="absolute z-50 right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5">
                <a
                  href="#"
                  className="block px-4 py-3 text-sm text-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 font-medium group"
                  onClick={() => handleViewSelection("Dashboard")}
                >
                  <HomeIcon className="inline-block w-5 h-5 mr-3 group-hover:hidden" />
                  <HomeIcon
                    className="inline-block w-5 h-5 mr-3 hidden group-hover:inline-block"
                    fill="currentColor"
                  />
                  Dashboard
                </a>
                <a
                  href="#"
                  className="block px-4 py-3 text-sm text-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 font-medium group"
                  onClick={() => handleViewSelection("Chat")}
                >
                  <ChatBubbleLeftIcon className="inline-block w-5 h-5 mr-3 group-hover:hidden" />
                  <ChatBubbleLeftIcon
                    className="w-5 h-5 mr-3 hidden group-hover:inline-block"
                    fill="currentColor"
                  />
                  Chat
                </a>
                <a
                  href="#"
                  className="block px-4 py-3 text-sm text-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 font-medium group"
                  onClick={() => handleViewSelection("Subscribe")}
                >
                  <ArrowPathIcon className="inline-block w-5 h-5 mr-3" />
                  Subscribe
                </a>
              </div>
            )}
          </div>
          <img
            src="/images/settings.png"
            alt="Menu"
            className="h-6 w-6 cursor-pointer brightness-0 dark:brightness-100"
            onClick={toggleMobileMenu}
          />
        </div>

        {/* Desktop menu */}
        <div className="hidden md:flex items-center space-x-4">
          <div
            className="relative"
            style={{ position: "relative", zIndex: 50 }}
          >
            <button
              onClick={toggleDesktopMenu}
              className="toggle-view-button border border-darkBlue hover:bg-slate-800 text-white font-medium py-2 px-3 rounded inline-flex items-center transition duration-300 ease-in-out"
              aria-haspopup="true"
              aria-expanded={isDesktopMenuOpen ? "true" : "false"}
              aria-label="Toggle desktop menu"
            >
              <ArrowPathIcon className="w-5 h-5 mr-2" />
              {view}
            </button>
            {isDesktopMenuOpen && (
              <div className="absolute right-0 mt-2 w-48 rounded-md shadow-lg bg-white dark:bg-slate-800 ring-1 ring-black ring-opacity-5">
                <a
                  href="#"
                  className="block px-4 py-3 text-sm text-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 font-medium group"
                  onClick={() => handleViewSelection("Dashboard")}
                >
                  <HomeIcon className="inline-block w-5 h-5 mr-3 group-hover:hidden" />
                  <HomeIcon
                    className="inline-block w-5 h-5 mr-3 hidden group-hover:inline-block"
                    fill="currentColor"
                  />
                  Dashboard
                </a>
                <a
                  href="#"
                  className="block px-4 py-3 text-sm text-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 font-medium group"
                  onClick={() => handleViewSelection("Chat")}
                >
                  <ChatBubbleLeftIcon className="inline-block w-5 h-5 mr-3 group-hover:hidden" />
                  <ChatBubbleLeftIcon
                    className="w-5 h-5 mr-3 hidden group-hover:inline-block"
                    fill="currentColor"
                  />
                  Chat
                </a>
                <a
                  href="#"
                  className="block px-4 py-3 text-sm text-slate-700 dark:text-slate-200 hover:bg-slate-100 dark:hover:bg-slate-700 font-medium group"
                  onClick={() => handleViewSelection("Subscribe")}
                >
                  <ArrowPathIcon className="inline-block w-5 h-5 mr-3" />
                  Subscribe
                </a>
              </div>
            )}
          </div>
          <SignInButton userId={userId} />
        </div>
      </nav>

      {/* Mobile menu content */}
      {isMobileMenuOpen && <MobileMenu onViewSelection={handleViewSelection} />}
    </>
  );
};

export default Navbar;
