import React, { useState } from "react";
import {
  ChevronUpIcon,
  ChevronDownIcon,
  UserPlusIcon,
  CalendarIcon,
  PlusIcon,
  HeartIcon,
  CreditCardIcon,
  ClockIcon,
  ArrowPathIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";

const ICONS = {
  ACCOUNT_CREATION: UserPlusIcon,
  CALENDAR_CONNECTED: CalendarIcon,
  HEALTH_CONNECTED: HeartIcon,
  PAYMENT_RECEIVED: CreditCardIcon,
  TIME_AFTER: ClockIcon,
  WEEKLY_RECURRING: ArrowPathIcon,
  MONTHLY_RECURRING: CalendarDaysIcon,
  QUARTERLY_RECURRING: ChartBarIcon,
  MESSAGE_COUNT: ChatBubbleLeftRightIcon,
};

const CATEGORY_COLORS = {
  onboarding: {
    bg: "bg-indigo-100",
    text: "text-indigo-600",
    hover: "hover:bg-indigo-50/50",
    selected: "bg-indigo-50",
    ring: "ring-indigo-100",
    border: "border-indigo-500",
  },
  time: {
    bg: "bg-emerald-100",
    text: "text-emerald-600",
    hover: "hover:bg-emerald-50/50",
    selected: "bg-emerald-50",
    ring: "ring-emerald-100",
    border: "border-emerald-500",
  },
  usage: {
    bg: "bg-amber-100",
    text: "text-amber-600",
    hover: "hover:bg-amber-50/50",
    selected: "bg-amber-50",
    ring: "ring-amber-100",
    border: "border-amber-500",
  },
};

const ActivationTypeSelector = ({
  value,
  onChange,
  activationTypes,
  categoryLabels,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const selectedType = activationTypes[value];

  const activationsByCategory = Object.entries(activationTypes).reduce(
    (acc, [key, type]) => {
      if (!acc[type.category]) {
        acc[type.category] = [];
      }
      acc[type.category].push({ key, ...type });
      return acc;
    },
    {}
  );

  const getColors = (category) =>
    CATEGORY_COLORS[category] || CATEGORY_COLORS.onboarding;

  const IconComponent = selectedType ? ICONS[selectedType.id] : null;
  const colors = selectedType ? getColors(selectedType.category) : null;

  return (
    <div className="relative">
      <button
        type="button"
        className={`w-full flex items-center justify-between border rounded-lg p-3 bg-white transition-all duration-200 ${
          isOpen ? "shadow-md ring-2" : "border-gray-300 hover:shadow-sm"
        } ${isOpen && colors ? colors.ring : ""} ${
          isOpen && colors ? colors.border : ""
        }`}
        onClick={() => setIsOpen(!isOpen)}
      >
        <div className="flex items-center space-x-3">
          {selectedType ? (
            <>
              <div
                className={`flex-shrink-0 w-10 h-10 rounded-xl ${colors.bg} flex items-center justify-center`}
              >
                <IconComponent className={`h-5 w-5 ${colors.text}`} />
              </div>
              <div className="flex flex-col items-start">
                <span className="text-gray-900 font-medium mb-0 leading-none">
                  {selectedType.label}
                </span>
                <span className="text-xs text-gray-500 mt-0.5">
                  {categoryLabels[selectedType.category]}
                </span>
              </div>
            </>
          ) : (
            <>
              <div className="flex-shrink-0 w-10 h-10 rounded-xl bg-gray-100 flex items-center justify-center">
                <PlusIcon className="h-5 w-5 text-gray-400" />
              </div>
              <span className="text-gray-500">Select activation type</span>
            </>
          )}
        </div>
        {isOpen ? (
          <ChevronUpIcon
            className={`h-5 w-5 ${colors ? colors.text : "text-gray-400"}`}
          />
        ) : (
          <ChevronDownIcon className="h-5 w-5 text-gray-400" />
        )}
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-2 bg-white border border-gray-200 rounded-lg shadow-lg max-h-[28rem] overflow-y-auto">
          {Object.entries(activationsByCategory).map(([category, types]) => {
            const categoryColors = getColors(category);
            return (
              <div key={category} className="border-b last:border-b-0">
                <div className="sticky top-0 px-4 py-2.5 bg-white/90 backdrop-blur-sm border-b">
                  <h3
                    className={`text-xs font-semibold mb-0 uppercase tracking-wider ${categoryColors.text}`}
                  >
                    {categoryLabels[category]}
                  </h3>
                </div>
                <div className="divide-y divide-gray-100">
                  {types.map((type) => {
                    const Icon = ICONS[type.id];
                    return (
                      <button
                        key={type.key}
                        className={`w-full text-left px-4 py-3 ${
                          categoryColors.hover
                        } focus:outline-none transition-colors duration-150 ${
                          value === type.key ? categoryColors.selected : ""
                        }`}
                        onClick={() => {
                          onChange(type.key);
                          setIsOpen(false);
                        }}
                      >
                        <div className="flex items-center space-x-3">
                          <div
                            className={`flex-shrink-0 w-10 h-10 rounded-xl ${categoryColors.bg} flex items-center justify-center`}
                          >
                            <Icon
                              className={`h-5 w-5 ${categoryColors.text}`}
                            />
                          </div>
                          <div className="flex-1">
                            <div className="font-medium text-gray-900">
                              {type.label}
                            </div>
                            <div className="text-sm text-gray-500 line-clamp-2">
                              {type.description}
                            </div>
                          </div>
                        </div>
                      </button>
                    );
                  })}
                </div>
              </div>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default ActivationTypeSelector;
