import React, { useState, useEffect } from "react";
import Connect from "../Connect";
function Dashboard({ user }) {
  return (
    <div className="flex justifty-center bg-black">
      <div className="max-w-4xl min-h-screen mx-auto bg-black text-white p-6 lg:px-0">
        <h1 className="text-2xl font-bold">
          Welcome
          {user?.core_memory?.name
            ? ", " + user?.core_memory?.name
            : " to 1440"}
        </h1>
        <div className="my-4 h-0.5 bg-slate-800 text-slate-800" />
        <Connect userId={user.web_id} />
      </div>
    </div>
  );
}

export default Dashboard;
