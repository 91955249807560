import React, { useState } from "react";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const ContactForm = () => {
  const [phoneNumber, setPhoneNumber] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const submitPhoneNumber = async (phone) => {
    try {
      const response = await fetch("https://api.1440.ai/api/contact", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ phoneNumber: phone }),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to submit phone number");
      }

      return await response.json();
    } catch (error) {
      console.error("Error submitting phone number:", error);
      throw error;
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (!phoneNumber || phoneNumber.length < 8) {
      alert("Please enter a valid phone number");
      return;
    }

    setIsLoading(true);

    try {
      await submitPhoneNumber(phoneNumber);
      console.log("Phone number submitted:", phoneNumber);
      alert("Thanks! We'll be in touch shortly.");
      setIsSubmitted(true);
      setTimeout(() => setIsSubmitted(false), 3000);
    } catch (error) {
      alert(error.message || "Failed to submit. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="flex w-full max-w-md mx-auto">
      <div className="flex flex-1 items-center glass-card rounded-l-lg border-r-0 h-10">
        <div className="w-full pl-3">
          <PhoneInput
            international
            value={phoneNumber}
            onChange={setPhoneNumber}
            defaultCountry="US"
            placeholder="Your phone number"
            className="w-full"
            numberInputProps={{
              className:
                "border-0 bg-transparent focus-visible:ring-0 focus-visible:ring-offset-0 h-10 rounded-none text-white",
            }}
          />
        </div>
      </div>
      <button
        type="submit"
        className="rounded-l-none h-10 bg-blue-600 hover:bg-blue-700 text-white px-4 py-2"
        disabled={isSubmitted || isLoading}
      >
        {isSubmitted ? "✓" : isLoading ? "Sending..." : "Call me"}
      </button>
    </form>
  );
};

export default ContactForm;
