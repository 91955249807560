import React, { useState, useEffect, ChangeEvent, FormEvent } from 'react';
import { Building2, Heart, Scale, AlertCircle } from 'lucide-react';

export function PanelLandingPage() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    license: ''
  });
  const [error, setError] = useState(null);
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Use useEffect to append the script when the component mounts
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://www.celli.ai/static/js/custom_widget.js?agentId=panel_1440';
    script.async = true;
    document.body.appendChild(script);

    // Cleanup function to remove the script when the component unmounts
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  const handleChange = (e ) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e ) => {
    e.preventDefault();
    setIsSubmitting(true);
    setError(null);

    try {
      const response = await fetch('https://os.share.vc/insert_leads/', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'API request failed');
      }

      console.log('Form submitted successfully');
      setFormData({ name: '', email: '', license: '' });
    } catch (err) {
      setError(err.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="min-h-screen bg-gray-50 text-gray-900">
      <header className="container mx-auto px-4 py-6 flex justify-between items-center">
        <div
          style={{
            fontSize: '24px',
            fontWeight: 'bold',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          P A N Ǝ L
        </div>
        <nav>
          <ul className="flex space-x-6">
            <li>
              <a href="#" className="text-sm text-gray-600 hover:text-gray-900">
                About
              </a>
            </li>
            <li>
              <a href="#" className="text-sm text-gray-600 hover:text-gray-900">
                Services
              </a>
            </li>
            <li>
              <a href="#" className="text-sm text-gray-600 hover:text-gray-900">
                Contact
              </a>
            </li>
          </ul>
        </nav>
      </header>

      <main>
        <section className="container mx-auto px-4 py-20 text-center">
          <h1 className="text-4xl font-bold mb-4">
            Precision Medicine
            <br />
            Meets Lifestyle Design
          </h1>
          <p className="mb-8 text-gray-600 max-w-2xl mx-auto">
            An integrated approach combining GLP-1 medication management with personalized training and
            nutritional counseling.
          </p>
          <button
            onClick={() =>
              (window.location.href = 'https://www.celli.ai/app/web-embed/panel_1440/')
            }
            className="bg-black text-white hover:bg-gray-800 px-4 py-2 rounded-lg"
          >
            Request Provider Access
          </button>
        </section>

        <section className="container mx-auto px-4 py-20">
          <div className="grid md:grid-cols-3 gap-8">
            <div className="text-center">
              <Building2 className="w-12 h-12 mx-auto mb-4 text-blue-500" />
              <h2 className="text-xl font-semibold mb-2">Medical Management</h2>
              <p className="text-gray-600">
                Supervised GLP-1 treatment with ongoing monitoring and adjustments.
              </p>
            </div>
            <div className="text-center">
              <Heart className="w-12 h-12 mx-auto mb-4 text-green-500" />
              <h2 className="text-xl font-semibold mb-2">Personal Training</h2>
              <p className="text-gray-600">
                Customized exercise programs designed for sustainable results.
              </p>
            </div>
            <div className="text-center">
              <Scale className="w-12 h-12 mx-auto mb-4 text-purple-500" />
              <h2 className="text-xl font-semibold mb-2">Nutritional Counseling</h2>
              <p className="text-gray-600">
                Expert dietary guidance aligned with medication therapy.
              </p>
            </div>
          </div>
        </section>

        <section className="container mx-auto px-4 py-20">
          <div className="grid md:grid-cols-3 gap-8 text-center">
            <div>
              <div className="text-4xl font-bold mb-2">94%</div>
              <div className="text-gray-600">Patient Satisfaction</div>
            </div>
            <div>
              <div className="text-4xl font-bold mb-2">12+</div>
              <div className="text-gray-600">Specialist Network</div>
            </div>
            <div>
              <div className="text-4xl font-bold mb-2">24/7</div>
              <div className="text-gray-600">Professional Support</div>
            </div>
          </div>
        </section>

        <section className="container mx-auto px-4 py-20">
          <h2 className="text-3xl font-bold text-center mb-8">Join Our Network</h2>
          <p className="text-center text-gray-600 mb-8 max-w-2xl mx-auto">
            Partner with PANEL to offer your patients a comprehensive weight management solution.
          </p>
          <form onSubmit={handleSubmit} className="max-w-md mx-auto space-y-4">
            <input
              type="text"
              name="name"
              placeholder="Full Name"
              value={formData.name}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            />
            <input
              type="email"
              name="email"
              placeholder="Email Address"
              value={formData.email}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            />
            <input
              type="text"
              name="license"
              placeholder="Medical License Number"
              value={formData.license}
              onChange={handleChange}
              required
              className="w-full px-4 py-2 border border-gray-300 rounded-lg"
            />
            <button
              type="submit"
              className="w-full bg-black text-white hover:bg-gray-800 px-4 py-2 rounded-lg"
              disabled={isSubmitting}
            >
              {isSubmitting ? 'Submitting...' : 'Request Information'}
            </button>
          </form>
          {error && (
            <div className="mt-4 p-4 bg-red-100 border border-red-400 text-red-700 rounded">
              <div className="flex items-center mb-2">
                <AlertCircle className="w-5 h-5 mr-2" />
                <span className="font-semibold">Error</span>
              </div>
              <p>{error}</p>
            </div>
          )}
        </section>
      </main>
    </div>
  );
}

export default PanelLandingPage;
