import React, { useState } from "react";

export default function GroundScreen3({ onUpdate }) {
  const [activityLevel, setActivityLevel] = useState("");

  const activityLevels = [
    "Not active at all",
    "Slightly active",
    "Moderately active",
    "Very active",
    "Extremely active",
  ];

  const handleActivityLevelSelection = (level) => {
    setActivityLevel(level);
    onUpdate(level);
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        How active are you in your current practices toward this goal?
      </p>
      <div className="flex flex-col w-full">
        {activityLevels.map((level) => (
          <button
            key={level}
            onClick={() => handleActivityLevelSelection(level)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              activityLevel === level
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: activityLevel === level ? "0 0 2px #FF8C22" : "",
            }}
          >
            {level}
          </button>
        ))}
      </div>
    </div>
  );
}
