import React from "react";
import { motion, AnimatePresence } from "framer-motion";
import { primary, text } from "../../theme/variables";
import "../../theme/theme.css";

const MessageDisplay = ({ message, subMessage, title }) => {
  return (
    <div className="w-full mb-4 pt-12">
      {/* Blue section title that was previously removed */}
      {title && title !== "Your Daily 1440 Minutes" && (
        <div
          className="p-3 rounded-lg mb-6 w-full"
          style={{
            backgroundColor: `${primary[900]}66`, // 40% opacity
            borderWidth: "1px",
            borderColor: `${primary[700]}66`, // 40% opacity
          }}
        >
          <h2
            className="text-center text-lg font-semibold"
            style={{ color: text.white }}
          >
            {title}
          </h2>
        </div>
      )}

      {/* Fixed height container for messages to prevent screen jumping */}
      <div className="h-32 flex flex-col justify-center w-full">
        <AnimatePresence mode="wait">
          {message && (
            <motion.div
              key={message}
              className="text-center overflow-hidden"
              initial={{ opacity: 0, y: -10, scale: 0.95 }}
              animate={{ opacity: 1, y: 0, scale: 1 }}
              exit={{ opacity: 0, y: 10, scale: 0.95 }}
              transition={{
                type: "spring",
                stiffness: 200,
                damping: 30,
                duration: 0.5,
              }}
            >
              <h3
                className="text-base font-medium mb-2"
                style={{ color: text.white }}
              >
                {message}
              </h3>

              {subMessage && (
                <motion.p
                  className="text-xs"
                  style={{ color: "#9CA3AF" }} // gray-400
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{ delay: 0.2, duration: 0.4 }}
                >
                  {subMessage}
                </motion.p>
              )}
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </div>
  );
};

export default MessageDisplay;
