import React, { useState } from "react";

export default function GroundScreen2({ onUpdate }) {
  const [sessionTime, setSessionTime] = useState("");

  const sessionOptions = [
    "Less than 5 minutes",
    "5-10 minutes",
    "10-20 minutes",
    "20-30 minutes",
    "More than 30 minutes",
    "One message at a time",
  ];

  const handleSessionTimeSelection = (option) => {
    setSessionTime(option);
    onUpdate(option);
  };

  return (
    <div className="flex flex-col items-center justify-start mt-8 w-full">
      <p className="text-white text-center text-2xl font-bold mb-2">
        How much time would you like to spend per session?
      </p>
      <div className="flex flex-col w-full">
        {sessionOptions.map((option) => (
          <button
            key={option}
            onClick={() => handleSessionTimeSelection(option)}
            className={`w-full bg-black text-white py-4 my-2 text-lg rounded-full border ${
              sessionTime === option
                ? "border-accent shadow-outline"
                : "border-gray-700"
            } focus:outline-none transition-all duration-150`}
            style={{
              boxShadow: sessionTime === option ? "0 0 2px #FF8C22" : "",
            }}
          >
            {option}
          </button>
        ))}
      </div>
    </div>
  );
}
