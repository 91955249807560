export const CATEGORY_COLORS = {
  "Coach information": {
    bg: "bg-indigo-50",
    text: "text-indigo-600",
    hover: "hover:bg-indigo-50/50",
    selected: "bg-indigo-100",
  },
  "Client information": {
    bg: "bg-emerald-50",
    text: "text-emerald-600",
    hover: "hover:bg-emerald-50/50",
    selected: "bg-emerald-100",
  },
  "Conversation context": {
    bg: "bg-amber-50",
    text: "text-amber-600",
    hover: "hover:bg-amber-50/50",
    selected: "bg-amber-100",
  },
  "Agents and systems": {
    bg: "bg-purple-50",
    text: "text-purple-600",
    hover: "hover:bg-purple-50/50",
    selected: "bg-purple-100",
  },
  Other: {
    bg: "bg-rose-50",
    text: "text-rose-600",
    hover: "hover:bg-rose-50/50",
    selected: "bg-rose-100",
  },
  Style: {
    bg: "bg-rose-50",
    text: "text-rose-600",
    hover: "hover:bg-rose-50/50",
    selected: "bg-rose-100",
  },
  System: {
    bg: "bg-sky-50",
    text: "text-sky-600",
    hover: "hover:bg-sky-50/50",
    selected: "bg-sky-100",
  },
  Stats: {
    bg: "bg-lime-50",
    text: "text-lime-600",
    hover: "hover:bg-lime-50/50",
    selected: "bg-lime-100",
  },
  Health: {
    bg: "bg-red-50",
    text: "text-red-600",
    hover: "hover:bg-red-50/50",
    selected: "bg-red-100",
  },
  Integration: {
    bg: "bg-blue-50",
    text: "text-blue-600",
    hover: "hover:bg-blue-50/50",
    selected: "bg-blue-100",
  },
  Resources: {
    bg: "bg-orange-50",
    text: "text-orange-600",
    hover: "hover:bg-orange-50/50",
    selected: "bg-orange-100",
  },
};
