import React, { useState, useEffect } from "react";
import axios from "axios";

export default function ScorecardStatsCard() {
  const [stats, setStats] = useState({
    totalScorecards: 0,
    averageScore: 0,
    scorecardsToday: 0,
  });
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchStats = async () => {
      try {
        const apiKey = localStorage.getItem("adminApiKey");
        const response = await axios.get("/api/admin/scorecards", {
          headers: { "Api-Key": apiKey },
        });

        const scorecards = response.data;
        const today = new Date().toISOString().split("T")[0];

        const totalScores = scorecards.reduce(
          (sum, card) => sum + card.score,
          0
        );

        setStats({
          totalScorecards: scorecards.length,
          averageScore: scorecards.length
            ? (totalScores / scorecards.length).toFixed(1)
            : 0,
          scorecardsToday: scorecards.filter((s) =>
            s.timestamp.startsWith(today)
          ).length,
        });
      } catch (error) {
        console.error("Error fetching scorecard stats:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchStats();
  }, []);

  if (loading) {
    return (
      <div className="bg-slate-800 rounded-lg p-6 animate-pulse">
        <div className="h-4 bg-slate-700 rounded w-1/4 mb-4"></div>
        <div className="space-y-3">
          <div className="h-8 bg-slate-700 rounded"></div>
          <div className="h-8 bg-slate-700 rounded"></div>
          <div className="h-8 bg-slate-700 rounded"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="bg-slate-800 rounded-lg p-6">
      <h2 className="text-xl font-semibold text-white mb-4">
        Scorecard Statistics
      </h2>
      <div className="space-y-4">
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Total Scorecards</span>
          <span className="text-white text-xl font-bold">
            {stats.totalScorecards}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Average Score</span>
          <span className="text-white text-xl font-bold">
            {stats.averageScore}
          </span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-400">Scorecards Today</span>
          <span className="text-white text-xl font-bold">
            {stats.scorecardsToday}
          </span>
        </div>
      </div>
    </div>
  );
}
