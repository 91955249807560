import React, { useEffect, useState } from "react";
import Layout from "../Layout";
import { CoachContext } from "../../../CoachContext";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import User from "./User";
import AddUserModal from "./AddUserModal";
import "./Users.css";
const Users = () => {
  const { coachId } = React.useContext(CoachContext);
  const [users, setUsers] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();
  const [sortOption, setSortOption] = useState("Most Recent");
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const axiosWithAuth = () => {
    const token = localStorage.getItem("coachJwtToken");
    return axios.create({
      baseURL: "https://api.1440.ai/",
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  };
  useEffect(() => {
    if (coachId) {
      setIsLoading(true);
      axiosWithAuth()
        .get(`/api/coach/users/${coachId}/basic`)
        .then((response) => {
          if (response.data.status === "success") {
            setUsers(response.data.users);
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
    }
  }, [coachId]);

  const sortUsers = (users, option) => {
    if (!users) return [];
    switch (option) {
      case "Most Recent":
        return [...users].sort(
          (a, b) =>
            new Date(b.log[b.log.length - 1]?.time) -
            new Date(a.log[a.log.length - 1]?.time)
        );
      case "Oldest":
        return [...users].sort(
          (a, b) =>
            new Date(a.log[a.log.length - 1]?.time) -
            new Date(b.log[b.log.length - 1]?.time)
        );
      default:
        return users;
    }
  };

  const handleAddUser = () => {
    setShowAddUserModal(true); // Show the modal when the button is clicked
  };

  const handleRowClick = (user) => {
    setSelectedUser(user.web_id);
  };

  if (selectedUser) {
    return (
      <User
        userId={selectedUser}
        onClose={() => setSelectedUser(null)}
        onCloseTitle="All users"
      />
    );
  }

  const sortOptions = (
    <div className="text-right">
      <label className="inline-flex items-center text-sm font-medium text-gray-700">
        Sort by
        <select
          className="ml-4 border-none bg-slate-200 text-base px-4 py-2.5 rounded-lg focus:ring-0"
          value={sortOption} // Set the value to the current sort option
          onChange={(e) => setSortOption(e.target.value)} // Update the sort option on change
        >
          <option className="px-3 mx-3">Most Recent</option>
          <option>Oldest</option>
        </select>
      </label>
    </div>
  );

  const renderTableBody = () => {
    if (isLoading) {
      return (
        <tr className="border-none">
          <td colSpan="6" className="text-center py-10">
            Loading users...
          </td>
        </tr>
      );
    } else if (users && users.length > 0) {
      return sortUsers(users, sortOption).map((user) => (
        <tr
          className={`cursor-pointer border-b-2 border-slate-200 ${
            user.shutdown_mode
              ? "bg-red-100 hover:bg-red-200"
              : "hover:bg-blue-100"
          }`}
          onClick={() => handleRowClick(user)}
          key={user.web_id}
        >
          <td
            className={`px-6 py-6 whitespace-nowrap flex items-center justify-start text-base ${
              user.shutdown_mode ? "text-red-800" : "text-slate-500"
            }`}
          >
            {user.core_memory.name ? user.core_memory.name : user.web_id}
            {user.shutdown_mode && (
              <span className="inline-block ml-2 relative overflow-visible">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  height={24}
                  width={24}
                  viewBox="0 0 24 24"
                  stroke-width="1.5"
                  fill="none"
                  stroke="#991B1B"
                >
                  <path
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    d="M12 9v3.75m9-.75a9 9 0 1 1-18 0 9 9 0 0 1 18 0Zm-9 3.75h.008v.008H12v-.008Z"
                  />
                </svg>
                <span className="tooltip-text absolute invisible bg-red-100 shadow text-red-800 text-xs rounded py-3 px-4 bottom-full left-1/2 transform -translate-x-1/2 -mb-2 opacity-0 transition-opacity duration-300">
                  This user is in shutdown mode.<br></br>You must review their
                  conversation<br></br>before 1440 will proceed.
                </span>
              </span>
            )}
          </td>
          <td
            className={`px-6 py-6 whitespace-nowrap text-base ${
              user.shutdown_mode ? "text-red-800" : "text-slate-500"
            }`}
          >
            {
              user.log.filter(
                (log) =>
                  new Date(log?.time) >
                  new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
              ).length
            }
          </td>
          <td
            className={`px-6 py-6 whitespace-nowrap text-base ${
              user.shutdown_mode ? "text-red-800" : "text-slate-500"
            }`}
          >
            {Math.floor(
              (Math.random() / 3) *
                user.log?.filter(
                  (log) =>
                    new Date(log.time) >
                    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                ).length
            )}
          </td>
          <td
            className={`px-6 py-6 whitespace-nowrap text-base ${
              user.shutdown_mode ? "text-red-800" : "text-slate-500"
            }`}
          >
            {Math.floor(
              (Math.random() / 3) *
                user.log?.filter(
                  (log) =>
                    new Date(log.time) >
                    new Date(Date.now() - 7 * 24 * 60 * 60 * 1000)
                ).length
            )}
          </td>
          <td
            className={`px-6 py-6 whitespace-nowrap text-base ${
              user.shutdown_mode ? "text-red-800" : "text-slate-500"
            }`}
          >
            {formatLastActive(user.log[user.log?.length - 1]?.time)}
          </td>
          <td
            className={`px-6 py-6 whitespace-nowrap text-right text-base ${
              user.shutdown_mode ? "text-red-800" : "text-slate-500"
            }`}
          >
            {user.unread_summaries}
          </td>
        </tr>
      ));
    } else {
      return (
        <tr className="border-none">
          <td colSpan="6" className="text-center py-10">
            No users found.
          </td>
        </tr>
      );
    }
  };

  return (
    <>
      {showAddUserModal && (
        <AddUserModal
          onClose={() => setShowAddUserModal(false)}
          onAddUser={(data) => {
            if (data && "user" in data) {
              setUsers([...users, data["user"]]); // Add the new user to the list
            }
            setShowAddUserModal(false); // Close the modal
          }}
        />
      )}
      <Layout
        title="All Users"
        rightElement={sortOptions}
        background="bg-slate-50"
      >
        <div className="flex bg-slate-50 flex-col">
          <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
            <div className="py-4 align-middle inline-block min-w-full sm:px-6 lg:px-8">
              <div className="border-b-2 border-slate-200">
                <table className="min-w-full divide-y divide-slate-200">
                  <thead className="border-none ">
                    <tr className="border-none">
                      <th className="px-6 py-3 text-left text-base  font-bold text-slate-700 uppercase tracking-wider">
                        Name
                      </th>
                      <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                        Messages
                        <div className="text-xs lowercase text-slate-500">
                          (this week)
                        </div>
                      </th>
                      <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                        Assessments
                        <div className="text-xs lowercase text-slate-500">
                          (this week)
                        </div>
                      </th>
                      <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                        Intervention
                        <div className="text-xs lowercase text-slate-500">
                          (this week)
                        </div>
                      </th>
                      <th className="px-6 py-3 text-base text-center text-slate-700 uppercase font-bold tracking-wider">
                        Last
                      </th>
                      <th className="px-6 py-3 text-base text-right font-bold text-slate-700 uppercase tracking-wider">
                        Unread summaries
                      </th>
                    </tr>
                  </thead>
                  <tbody className="divide-y divide-slate-200 text-black">
                    {renderTableBody()}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <button
            onClick={handleAddUser}
            className="bg-slate-50 border-2 w-32 hover:bg-slate-200 text-slate-500 hover:text-slate-800 mt-5 font-medium py-2 px-4 rounded "
          >
            + Add user
          </button>
        </div>
      </Layout>
    </>
  );
};

function formatLastActive(lastUpdated) {
  const last = new Date(lastUpdated);
  if (isNaN(last.getTime())) return "0 days ago"; // Check if the date is valid
  const now = new Date();
  const diff = now - last;
  const diffMinutes = Math.floor(diff / 60000);
  if (diffMinutes < 60) return `${diffMinutes} minutes ago`;
  const diffHours = Math.floor(diff / 3600000);
  if (diffHours < 24) return `${diffHours} hours ago`;
  const diffDays = Math.floor(diff / 86400000);
  return `${diffDays} days ago`;
}

export default Users;
