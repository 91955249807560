import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";
import { CheckIcon } from "@heroicons/react/24/outline";
import axios from "axios";

export default function Subscribe({ setView }) {
  const [loadingPlan, setLoadingPlan] = useState(null);
  const [userSubscription, setUserSubscription] = useState(null);

  const createStripeSession = async (planName) => {
    setLoadingPlan(planName);
    try {
      const response = await axios.post(`/api/billing/create_portal_session`, {
        user_id: Cookies.get("1440_web_id"),
        plan: planName === "AI performance coaching" ? "AI" : "human",
      });
      const { url } = response.data;
      window.location.href = url; // Redirect the user to the Stripe billing portal
    } catch (error) {
      console.error("Error creating Stripe session:", error);
      // Handle errors here, such as displaying a notification to the user
    } finally {
      setLoadingPlan(null);
    }
  };

  const checkSubscriptionStatus = async () => {
    try {
      const response = await axios.post(
        `/api/billing/check_subscription_status`,
        {
          user_id: Cookies.get("1440_web_id"), // Assuming this is how you get the user ID
        }
      );
      setUserSubscription(response.data);
    } catch (error) {
      console.error("Error checking subscription status:", error);
      // Handle errors here, such as displaying a notification to the user
    }
  };
  // Call checkSubscriptionStatus when the component mounts
  useEffect(() => {
    checkSubscriptionStatus();
  }, []);

  const accessToken = Cookies.get("1440_access_token");

  const pricingPlans = [
    {
      name: "Free coaching",
      price: "$0",
      description: "Ideal for those that are just starting their journey",
      features: [
        "Daily check-ins",
        "Accountability partner",
        "Coaching conversations",
      ],
      buttonLabel: "Try now",
      buttonVariant: "border border-slate-900 text-slate-300",
    },
    {
      name: "AI performance coaching",
      price: "$10",
      description:
        "For those looking to achieve more and make the most of their time",
      features: [
        "Everything in previous plans",
        "24/7 hyper-personal performance coaching",
        "Science-backed custom protocol",
        "Memory and connecting the dots",
        "Proactive texts, voicemails and phone calls",
        "Calendar context, editing and insights",
        "500+ health device integrations",
        "Multi-platform chat",
      ],
      buttonLabel: "Get started today",
      buttonVariant: "from-lightBlue to-darkBlue text-slate-950",
      popular: true,
    },
    {
      name: "AI/human synergy",
      price: "$90",
      description:
        "The ultimate combination of AI and human coaching for growth",
      features: [
        "Everything in previous plans",
        "24/7 human in the loop",
        "Bi-weekly human check-ins",
        "Access to peer support groups",
        "Access to industry experts",
        "Lab tests",
      ],
      buttonLabel: "Get started today",
      buttonVariant: "from-lightBlue to-darkBlue text-slate-950",
    },
  ];

  return (
    <div className="bg-black text-white min-h-screen flex flex-col items-center py-12">
      <div className="max-w-6xl bg-black pb-8 w-full px-6">
        {userSubscription && userSubscription.is_paying && (
          <div className="bg-slate-900 rounded-lg shadow-lg p-6 mb-8 max-w-md mx-auto">
            <div className="flex items-center mb-4">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-8 w-8 text-blue-400 mr-3"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"
                />
              </svg>
              <h2 className="text-2xl mb-0 font-semibold text-white">
                Active Subscription
              </h2>
            </div>
            <div className="bg-slate-800 rounded-md p-4 mb-6">
              <p className="text-slate-300 mb-0">
                <span className="font-medium">Expires on</span>{" "}
                {new Date(
                  userSubscription.subscriptions[0].current_period_end * 1000
                ).toLocaleDateString()}
              </p>
            </div>
            <button
              onClick={() => createStripeSession()}
              className="w-full bg-blue-500 hover:bg-blue-600 text-white font-bold py-3 px-4 rounded-md transition duration-300 ease-in-out flex items-center justify-center"
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5 mr-2"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M3 10h18M7 15h1m4 0h1m-7 4h12a3 3 0 003-3V8a3 3 0 00-3-3H6a3 3 0 00-3 3v8a3 3 0 003 3z"
                />
              </svg>
              Manage Subscription
            </button>
          </div>
        )}
        <h1 className="text-4xl font-bold mb-6 text-center text-white">
          10X Better, 100X Cheaper
        </h1>
        <p className="text-xl mb-10 text-center text-slate-400">
          Use the combination of AI and human coaching to unlock your full
          potential
        </p>

        <div className="md:col-start-2 md:col-span-1 justify-center mb-6 hidden md:flex">
          <button className="px-6 py-2 shadow shadow-darkBlue cursor-default rounded-full bg-gradient-to-r from-lightBlue to-darkBlue text-black font-bold">
            Most Popular
          </button>
        </div>
        <div className="grid md:grid-cols-3 gap-6">
          {pricingPlans.map((plan, index) => (
            <>
              {index === 1 && (
                <div className="md:hidden flex justify-center mt-4">
                  <button className="px-6 py-2 rounded-full shadow shadow-darkBlue bg-gradient-to-r from-lightBlue to-darkBlue text-black font-bold">
                    Most Popular
                  </button>
                </div>
              )}

              <div
                key={plan.name}
                className={`bg-slate-950 p-6 rounded-lg shadow-md relative ${
                  plan.popular
                    ? " shadow-lg shadow-darkBlue border border-darkBlue"
                    : " border border-slate-700"
                }`}
              >
                <h2 className="text-xl font-bold mb-3 text-white">
                  {plan.name}
                </h2>
                <p className="text-4xl font-bold mb-3">
                  {plan.price}
                  <span className="text-lg text-slate-400">/month</span>
                </p>
                <p className="mb-6 text-slate-400">{plan.description}</p>
                <button
                  className={`w-full px-6 py-2 rounded bg-gradient-to-r text-white ${plan.buttonVariant} font-bold mb-6 flex items-center justify-center`}
                  onClick={
                    plan.price !== "$0"
                      ? () => createStripeSession(plan.name)
                      : () => setView("Chat")
                  }
                  disabled={loadingPlan === plan.name}
                >
                  {loadingPlan === plan.name ? (
                    <svg
                      className="animate-spin h-5 w-5 text-white"
                      xmlns="http://www.w3.org/2000/svg"
                      fill="none"
                      viewBox="0 0 24 24"
                    >
                      <circle
                        className="opacity-25"
                        cx="12"
                        cy="12"
                        r="10"
                        stroke="currentColor"
                        strokeWidth="4"
                      ></circle>
                      <path
                        className="opacity-75"
                        fill="currentColor"
                        d="M4 12a8 8 0 018-8v4a4 4 0 100 8v4a8 8 0 01-8-8z"
                      ></path>
                    </svg>
                  ) : (
                    plan.buttonLabel
                  )}
                </button>
                <div className="flex items-center justify-center mb-3">
                  <div className="border-t h-4 border-slate-600 flex-grow"></div>
                  <p className="text-slate-400 mx-4 uppercase text-sm">
                    Features
                  </p>
                  <div className="border-t h-4 border-slate-600 flex-grow"></div>
                </div>
                <ul className="space-y-4 ml-0 pl-0">
                  {plan.features.map((feature) => (
                    <li
                      key={feature}
                      className="flex ml-0 pl-0 items-center text-slate-300"
                    >
                      <div
                        className={`rounded-full border ${
                          plan.popular ? "border-green-700" : "border-slate-700"
                        } p-1 mr-3`}
                      >
                        <CheckIcon className="h-4 w-4 text-white" />
                      </div>
                      {feature}
                    </li>
                  ))}
                </ul>
              </div>
            </>
          ))}
        </div>
      </div>
    </div>
  );
}
