import React from "react";
import UserList from "./UserList";

export default function Users({ onSelectUser }) {
  return (
    <div className="space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold text-white">Users</h1>
      </div>
      <UserList onSelectUser={onSelectUser} />
    </div>
  );
}
