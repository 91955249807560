import React from "react";
import InputField from "./InputField";
import MessageList from "./MessageList";
import TypingIndicator from "./TypingIndicator";
import SuggestedMessages from "./SuggestedMessages";
import { useAuth0 } from "@auth0/auth0-react";

const ChatBox = ({
  conversation,
  isDemoActive,
  sendMessage,
  isTyping,
  initialMessage,
  userInput,
  highlightInput,
  setUserInput,
  suggestions = false,
  marginTop = true,
}) => {
  const greeting = conversation[0]
    ? conversation[0].content
    : initialMessage
    ? initialMessage
    : "Welcome to 1440! Say hello to get started.";

  return (
    <div className="bg-white max-w-4xl mx-auto text-black dark:bg-black dark:text-white">
      {conversation[0] ? (
        ""
      ) : (
        <h1
          className={`text-3xl md:text-5xl lg:text-6xl lg:w-4/5 ${
            marginTop
              ? "mt-16 md:mt-32 2xl:mt-48  mb-3 font-normal"
              : " mb-3 font-normal md:text-5xl lg:text-7xl xl:text-7xl lg:w-2/3 lg:leading-tight"
          }`}
        >
          {greeting}
        </h1>
      )}
      <div className="mb-8 lg:mb-16">
        <MessageList conversation={conversation} isDemoActive={isDemoActive} />
        {isTyping && <TypingIndicator />}
        {conversation.length === 0 && (
          <SuggestedMessages
            suggestions={suggestions ? suggestions : ["Hello", "What is 1440?"]}
            sendMessage={sendMessage}
          />
        )}
        <InputField
          highlightInput={highlightInput}
          sendMessage={sendMessage}
          userInput={userInput}
          setUserInput={setUserInput}
        />
      </div>
    </div>
  );
};

export default ChatBox;
